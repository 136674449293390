import { Injectable } from '@angular/core';
import { clone } from 'cloneable-ts';
import { Reducer } from 'src/app/core/mvi/store';
import {
  SectionSettingsResultAction,
  SectionSettingsResultActionTypes,
} from './section-settings-result-action';
import { SectionSettingsState } from './section-settings-state';

@Injectable()
export class SectionSettingsReducer
  implements Reducer<SectionSettingsState, SectionSettingsResultAction>
{
  reduce(
    state: SectionSettingsState,
    action: SectionSettingsResultAction,
  ): SectionSettingsState {
    switch (action.type) {
      case SectionSettingsResultActionTypes.SELECT_ITEM:
        return clone(state, { selectedItem: action.item });
    }
  }
}
