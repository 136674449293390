import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges, ViewChild
} from "@angular/core";

@Component({
  selector: 'app-editable-text',
  templateUrl: './editable-text.component.html',
  styleUrls: ['./editable-text.component.scss'],
})
export class EditableTextComponent implements OnChanges, AfterViewInit {
  @Input() styles: string | string[] = ''
  @Input() textStyles: string | string[] = ''
  @Input() value: string = ''
  @Output() onChange = new EventEmitter<string>()

  inputValue: string = ''
  isEditable: boolean = false

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    this.inputEl.nativeElement.style.width = this.textEl.nativeElement.offsetWidth + 'px'
  }

  @ViewChild('text') textEl!: ElementRef;
  @ViewChild('input') inputEl!: ElementRef;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      this.inputValue = this.value
    }
  }

  showInput(){
    this.isEditable = true
    this.inputResizeText()
    this.inputEl.nativeElement.focus()
  }

  inputResizeText(event?: any)
  {
    this.inputEl.nativeElement.style.width = this.textEl.nativeElement.offsetWidth + 'px'
    if(event){
      this.inputValue = event.target.value
      this.onChange.emit(this.inputValue);
    }
  }

  changeValue() {
    if (this.onChange) {
      this.onChange.emit(this.inputValue);
      this.isEditable = false;
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (this.isEditable && !this.el.nativeElement.contains(event.target)) {
      this.changeValue()
    }
  }

  protected readonly console = console;
}
