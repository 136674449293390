import { Component, Input } from "@angular/core";
import { NavigationElement } from "../navigation-element/navigation-element.component";

@Component({
  selector: 'main-navigation-section',
  templateUrl: './navigation-section.component.html',
  styleUrls: ['./navigation-section.component.scss']
})
export class NavigationSectionComponent {
  @Input() navSection: NavigationSection =
  {
    title: '',
    elements: []
  }
  @Input() type: 'hide' | 'show' = 'show'
  @Input() isFirst: boolean = false;

  protected readonly Element = Element;
}

export type NavigationSection = {
  title: string
  elements: NavigationElement[]
}
