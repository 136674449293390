import Paragraph from "@teamtells/paragraph";
import { HeadingOne, HeadingThree, HeadingTwo } from "@teamtells/headings";
import { myNumberedList } from "./my-numbered-list/my-numbered-list";
import { BulletedList } from "@teamtells/lists";
import { EmojiCallout } from "./emoji-callout/emoji-callout";
import Blockquote from "@teamtells/blockquote";
import Code from "@teamtells/code";
import { MyImage } from "./my-image/my-image-plugin";
import { MyVideo } from "./my-video/my-video";
import { MyFile } from "./my-file/my-file-plugin";
import { MyLink } from "./my-link/my-link";
import { myTodoList } from "./my-todo-list/my-todo-list";

export const EDITOR_PLUGINS = [
  Paragraph, HeadingOne, HeadingTwo, HeadingThree,
  myNumberedList, BulletedList, EmojiCallout, Blockquote,
  myTodoList, MyImage, MyVideo, MyFile, Code, MyLink
]
