import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink, RouterOutlet } from "@angular/router";
import { ComponentsModule } from 'src/app/core/components/components.module';
import { EmployeesRolesComponent } from './presentation/employees-roles.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RoleItemComponent } from './components/roles-item/roles-item.component';
import { SvgLock, SvgTripleDot } from "../../../../core/components/svg-components/svg.components";
import { CreateRoleModalComponent } from './components/create-role-modal/create-role-modal.component';
import { Validator } from 'src/app/core/validators/validator';
import { EmptyRule, MaxLengthRule } from 'src/app/core/validators/rule';
import { CreateRoleModalPermissionsComponent } from './components/create-role-modal/components/create-role-modal-access/create-role-modal-access.component';
@NgModule({
  declarations: [
    EmployeesRolesComponent,
    RoleItemComponent,
    CreateRoleModalComponent,
    CreateRoleModalPermissionsComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterOutlet,
    MatProgressSpinnerModule,
    SvgLock,
    SvgTripleDot,
    RouterLink
  ],
  exports: [
    CreateRoleModalPermissionsComponent
  ],
  providers: [
    {
      provide: "NewRoleNameValidator",
      useExisting: Validator,
      useFactory: EmployeesRolesModule.roleNameValidatorFactory
    },
    {
      provide: "NewRoleDescriptionValidator",
      useExisting: Validator,
      useFactory: EmployeesRolesModule.roleDescriptionValidatorFactory
    }
  ]
})
export class EmployeesRolesModule {
  public static roleNameValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите название роли'),
      new MaxLengthRule('Название роли не может быть длиннее 128 символов', 128),
    ]);
  public static roleDescriptionValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите описание роли'),
      new MaxLengthRule('Описание роли не может быть длиннее 180 символов', 180),
    ]);
}
