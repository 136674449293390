import { Injectable } from '@angular/core';
import { Executor, Reducer } from 'src/app/core/mvi/store';
import { MessengerSideBarState } from './sidebar-state';
import { ChatListService } from '../../../chats/domain/chat-list-service';
import {
  MessengerSideBarResultAction,
  MessengerSideBarResultActionTypes,
} from './sidebar-result-action';
import {
  MessengerSideBarAction,
  MessengerSideBarActionTypes,
} from './sidebar-action';
import { MessengerSideBarService } from '../../domain/sidebar-service';

@Injectable({
  providedIn: 'root',
})
export class MessengerSideBarExecutor extends Executor<
  MessengerSideBarState,
  MessengerSideBarAction,
  MessengerSideBarResultAction
> {
  constructor(
    private service: MessengerSideBarService,
    private chatService: ChatListService,
  ) {
    super();
  }

  override init(
    reducer: Reducer<MessengerSideBarState, MessengerSideBarResultAction>,
    getState: () => MessengerSideBarState,
    onReduced: (state: MessengerSideBarState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    this.chatService.getChatData().subscribe((chat) => {
      this.reduce({
        type: MessengerSideBarResultActionTypes.SHOW_CHAT,
        actualchat: chat,
      });
      this.service.getMenuData().subscribe((menu) => {
        this.reduce({
          type: MessengerSideBarResultActionTypes.UPDATE_INFO,
          menuState: menu,
        });
      });
    });
  }

  execute(action: MessengerSideBarAction): void {
    switch (action.type) {
      case MessengerSideBarActionTypes.SHOW_INFO:
        this.closeMenu();
        break;
    }
  }

  private closeMenu() {
    this.service.closeMenu();
  }
}
