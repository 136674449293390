import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { CreateRoleConstants, CreateRoleModalNavItems } from "./common/createRoleConstants";
import { Store } from "src/app/core/mvi/store";
import { CreateRoleModalState } from "./state/create-role-modal-state";
import { CreateRoleModalAction, CreateRoleModalActionTypes } from "./state/create-role-modal-action";
import { CreateRoleModalResultAction } from "./state/create-role-modal-result-action";
import { CreateRoleModalReducer } from "./state/create-role-modal-reducer";
import { CreateRoleModalExecutor } from "./state/create-role-modal-executor";

@Component({
  selector: 'employees-create-role-modal',
  templateUrl: './create-role-modal.component.html',
  styleUrls: ['./create-role-modal.component.scss'],
  providers: [
    CreateRoleModalState,
    CreateRoleModalExecutor,
    CreateRoleModalReducer
  ]
})
export class CreateRoleModalComponent extends Store<
  CreateRoleModalState,
  CreateRoleModalExecutor,
  CreateRoleModalAction,
  CreateRoleModalResultAction
  > implements OnChanges
{

  @Input() isVisible: boolean = false
  @Input() editRoleId: string = ''
  @Output() closeClicked = new EventEmitter()

  protected readonly NAV_ITEMS = CreateRoleConstants.NAV_ITEMS
  protected readonly CreateRoleModalActionTypes = CreateRoleModalActionTypes

  constructor(
    state: CreateRoleModalState,
    executor: CreateRoleModalExecutor,
    reducer: CreateRoleModalReducer,
  ) {
    super(state, executor, reducer);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['editRoleId']){
      if(this.editRoleId != '')
      {
        this.performAction({
          type: CreateRoleModalActionTypes.LOAD_ROLE,
          id: this.editRoleId
        })
      }
    }
  }

  getNavItemFromStep(step: Number): CreateRoleModalNavItems{
    switch (step){
      case 2:
        return CreateRoleModalNavItems.ACCESSES
      // case 3:
      //   return CreateRoleModalNavItems.USERS
      default:
        return CreateRoleModalNavItems.MAIN
    }
  }

  close(){
    this.performAction({
      type: CreateRoleModalActionTypes.INIT
    })
    this.closeClicked.emit()
  }
}
