<div
  class="flex h-screen"
>
  <nav
    class="
      min-w-[256px] max-w-[256px] w-full pb-[13px] border-r-[1px]
      border-tt-border-default bg-tt-bg-nav-sidebar
      flex flex-col justify-between"
  >
    <div>
      <div class="
        px-[20px] py-[10px] border-b-[1px] border-tt-border-default
        flex gap-[14px] items-center"
      >
        <img
          src="assets/team-tells-logo.svg"
          alt="TeamTells"
          class="size-tt-image-size_s"
        />
        <span class="font-bold text-[22px] leading-[27px]">TeamTells</span>
      </div>

      <div class="nav-sections-height overflow-y-auto tiny-scrollbar">
        <main-navigation-section
          *ngFor="let section of state.navSections; let i = index"
          [navSection]=section
          [isFirst]="i === 0"
          type="show"
        />
      </div>

    </div>

    <main-navigation-element
      *ngIf="state.enabledItemsIds.includes(NavItem.COMPANY_SETTINGS)"
      class="px-[4px] pt-[13px]"
      [navElement]="MainConstants.NAVIGATION_SETTINGS_ELEMENT"
      type="show"
    />

  </nav>

  <div class="w-full relative h-full bg-tt-bg-page">
    <main-header class="absolute w-full top-0 left-0 z-50"
      [employee]="state.headerEmployee"
      (logoutClicked)="performAction({
        type: MainActionTypes.LOGOUT
      })"
    />

    <router-outlet
      *ngIf="!state.isLoading"
    ></router-outlet>
  </div>
</div>

<div
  class="w-screen h-screen absolute left-0 top-0 bg-white z-50 flex items-center justify-center"
  *ngIf="state.isLoading"
>
  <mat-spinner
    class="size-[40px]"
  />
</div>

<main-remaining-subscription-modal
  *ngIf="state.isSubscriptionModalVisible"
  [daysLeft]="state.subscriptionDaysLeft"
/>
