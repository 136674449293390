import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { ActionElementStyle, ActionSection } from "../../action-menu/action-menu.component";

const EMPTY_IMAGE_URL = 'assets/empty-image.svg'
const POPUP_SECTIONS: ActionSection[] = [
  {
    elements: [
      {
        id: 'change',
        name: 'Изменить',
        icon: 'assets/popup-pencil.svg'
      },
      {
        id: 'delete',
        name: 'Удалить',
        icon: 'assets/trash-red.svg',
        type: ActionElementStyle.DANGER
      }
    ]
  }
]

@Component({
  selector: 'app-core-input-image',
  templateUrl: './input-image.component.html',
  styleUrls: ['./input-image.component.scss'],
})
export class InputImageComponent implements OnChanges, AfterViewInit {
  @Input() currentImageUrl: string = EMPTY_IMAGE_URL
  @Input() initUrl: string = EMPTY_IMAGE_URL
  @Input() isReadOnly: boolean = false

  @Output() imageUpload = new EventEmitter<string>()
  @Output() imageUrlChanged = new EventEmitter<string>()
  @Output() imageDelete = new EventEmitter()

  @ViewChild('inputImage') inputImageRef!: ElementRef;

  width: number = 0

  fullImagePopupVisibility: boolean = false
  fileDownloaderPopupVisibility: boolean = false

  protected readonly POPUP_SECTIONS = POPUP_SECTIONS

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit(){
    this.width = this.el.nativeElement.offsetWidth
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['initUrl']){
      if(this.initUrl == '')
      {
        this.initUrl = EMPTY_IMAGE_URL
      }
      else
      {
        this.currentImageUrl = this.initUrl
      }
    }
    if(changes['currentImageUrl'])
    {
      if(this.currentImageUrl == '')
      {
        this.currentImageUrl = EMPTY_IMAGE_URL
      }
    }
  }

  imageChange(file: File)
  {
    const reader = new FileReader();
    reader.readAsDataURL(file)

    reader.onload = (event) => {
      console.log('reader', event.target!.result)
      this.currentImageUrl = <string>event.target!.result
      this.imageUpload.emit(<string>event.target!.result)
    }
  }

  downloadClick(){
    if(!this.isReadOnly)
    {
      this.inputImageRef.nativeElement.click()
    }
  }

  onSelectFile(event: any){
    const file = event.target.files[0];
    this.imageChange(file)
  }

  cancelImage(){
    this.currentImageUrl = this.initUrl
    this.imageDelete.emit()
  }

  popupClicked(id: string){
    switch (id){
      case 'change':
        this.fileDownloaderPopupVisibility = true
        break;
      case 'delete':
        this.cancelImage()
        break;
    }
    this.fullImagePopupVisibility = false
  }

  @HostListener("document:wheel", [])
  onScroll(){
    this.fileDownloaderPopupVisibility = false
  }

}
