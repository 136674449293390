import { Injectable } from '@angular/core';
import { Constants } from '../common/constants';
import { SectionEditEntity } from '../../../../domain/section-edit-entity';

@Injectable()
export class SectionSettingsMainState {
  readonly baseSectionData: SectionEditEntity = Constants.INIT_EDIT_SECTION;
  readonly currentSectionData: SectionEditEntity = Constants.INIT_EDIT_SECTION;
  readonly iconUrls: string[] = []
  readonly saveButtonVisible: boolean = false;
  readonly titleError: string = '';
}
