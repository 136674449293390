export class SettingsSubscriptionConstants{
  public static SUBSCRIPTION_MONTH_PRICES = new Map<string, number>()
    .set('0-10', 0)
    .set('10-100', 240)
    .set('100-200', 227)
    .set('200-300', 215)
    .set('300-400', 204)
    .set('400-1000', 192)
  public static SUBSCRIPTION_YEAR_PRICES = new Map<string, number>()
    .set('0-10', 0)
    .set('10-100', 199)
    .set('100-200', 189)
    .set('200-300', 179)
    .set('300-400', 169)
    .set('400-1000', 159)

  public static USER_DECLENSIONS = ['пользователя', 'пользователей', 'пользователей']
}
