import { Component } from '@angular/core';
import { Store } from 'src/app/core/mvi/store';
import { MainAction, MainActionTypes } from './state/main-action';
import { MainExecutor } from './state/main-executor';
import { MainReducer } from './state/main-reducer';
import { MainResultAction } from './state/main-result-action';
import { MainState, Tabs } from './state/main-state';
import { MainConstants } from "./common/main-constants";
import { HeaderService } from '../data/header-service';

@Component({
  selector: 'app-presentation',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  providers: [
    MainState,
    MainExecutor,
    MainReducer,
    HeaderService
  ]
})
export class MainComponent extends Store<
  MainState,
  MainExecutor,
  MainAction,
  MainResultAction
> {
  constructor(state: MainState, executor: MainExecutor, reducer: MainReducer) {
    super(state, executor, reducer);
  }

  protected readonly MainActionTypes = MainActionTypes;
  protected readonly NavItem = Tabs;
  protected readonly MainConstants = MainConstants;
}
