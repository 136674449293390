import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { NewsSummaryEntity } from "../../../../../domain/news-summary-entity";

@Component({
  selector: "app-news-item",
  templateUrl: "./news-item.component.html",
  styleUrls: ["./news-item.component.scss"]
})
export class NewsItemComponent implements AfterViewInit, OnChanges, AfterViewChecked {
  @Input() type: "grid-cell" | "list-element" = "grid-cell";
  @Input() news: NewsSummaryEntity = {
    id: "",
    title: "Обеспечение технической поддержки для пользователей",
    description: "C 1 мая мы начинаем больше времени в техническом отделе уделять не только разработке новых фич, но и значительной доработки C 1 мая мы начинаем больше времени в техническом",
    date: "6 мая 2024",
    image: "assets/empty-section.svg"
  };

  @Output() onClick = new EventEmitter();

  @ViewChild("text") text?: ElementRef;
  @ViewChild("description") description?: ElementRef;

  private isViewInit: boolean = false

  constructor(private renderer: Renderer2) {}

  ngAfterViewChecked(): void {
    this.resizeDescription()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['news'] && this.isViewInit){
      this.resizeDescription()
    }
  }

  ngAfterViewInit(){
    this.isViewInit = true
    this.resizeDescription()
  }

  resizeDescription()
  {
    const textRect = this.text?.nativeElement.getBoundingClientRect();
    const descriptionRect = this.description?.nativeElement.getBoundingClientRect();

    const countDescriptionLines = Math.floor((textRect.height - (descriptionRect.top - textRect.top)) / 18);
    this.renderer.setStyle(this.description?.nativeElement, '-webkit-line-clamp', countDescriptionLines, 1)
  }
}
