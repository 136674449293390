import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { StartSessionDto } from "./dto/start-session-dto";

@Injectable()
export class SessionService {
  private readonly apiUrl: string = `${environment.apiUrl}/session`;

  constructor(private httpClient: HttpClient) {
  }

  public startSession(documentId: string): Observable<StartSessionDto> {
    return this.httpClient.post<StartSessionDto>(`${this.apiUrl}/start/${documentId}`, {});
  }

  public closeSession(sessionId: string): Observable<null> {
    return this.httpClient.post<null>(`${this.apiUrl}/close/${sessionId}`, {});
  }

  public ping(sessionId: string): Observable<null> {
    return this.httpClient.post<null>(`${this.apiUrl}/ping/${sessionId}`, {});
  }
}
