import React, { useEffect } from "react";
import { PluginElementRenderProps, useBlockData, useYooptaEditor, useBlockSelected, Elements } from "@teamtells/editor";
import EmojiCalloutSelectType from "../components/emoji-callout-select-type";

function EmojiCalloutRenderer (props: PluginElementRenderProps) {

  const { children, element, attributes, blockId} = props;

  const editor = useYooptaEditor();
  const block = useBlockData(blockId);
  const [backGroundColor, setBackGroundColor] = React.useState('#0B57D01A');

  useEffect(() => {
    if(typeof element.props.type === 'string')
    {
      switch (element.props.type){
        case 'question':
          setBackGroundColor('#B43BB41A')
          break
        case 'warning':
          setBackGroundColor('#FE84031A')
          break
        case 'success':
        case 'plus':
          setBackGroundColor('#3BB46C1A')
          break
        case 'error':
        case 'minus':
          setBackGroundColor('#E72F181A')
          break
        case 'none':
          setBackGroundColor('#4861851A')
          break
        default:
          setBackGroundColor('#0B57D01A')
          break
      }
    }
  }, [element]);

  return (
    <div
      className={'rounded-[8px] mt-[16px] text-tt-text-primary-without-alpha relative '}
      style={{
        padding: 16 + 'px',
        paddingLeft: (element.props.type === 'none' ? 16 : 56)+ 'px',
        backgroundColor: backGroundColor
      }}
      {...attributes}
    >
      <EmojiCalloutSelectType block={block} editor={editor} props={typeof element.props.type === 'string' ? element.props.type: ''}/>

      {children}
    </div>
  );
}

export default EmojiCalloutRenderer;
