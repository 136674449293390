import { Executor, Reducer } from "src/app/core/mvi/store";
import {
  CreateEmployeeModalResultAction,
  CreateEmployeeModalResultActionTypes
} from "./create-employee-modal-result-action";
import { Inject, Injectable } from "@angular/core";
import { RoleService } from "src/app/core/services/role-service/role-service";
import { EmployeeService } from "src/app/features/employees/data/employee-service";
import { EmployeesListExecutor } from "../../../state/employees-list-executor";
import { EmployeesListActionTypes } from "../../../state/employee-list-action";
import { EmployeeCreateDto } from "src/app/features/employees/data/dto/employee-create-dto";
import { CreateEmployeeModalState, UserForm } from "./create-employee-modal-state";
import { CreateEmployeeModalAction, CreateEmployeeModalActionTypes } from "./create-employee-modal-action";
import { ToastsService } from "src/app/core/components/toast-alert/services/toast-alert.service";
import { Validator } from "../../../../../../../../core/validators/validator";
import { EmployeeRoleDto } from "../../../../../../data/dto/employee-dto";
import { EmployeesConstants } from "../../../../../../common/employees-constants";
import { stringToRgb } from "../../../../../../../../core/utils/string-to-rgb";
import { stringToPhoneNumber } from "../../../../../../../../core/utils/string-to-phone-number";
import { ToastState } from "../../../../../../../../core/components/toast-alert/toast-alert.component";

@Injectable()
export class CreateEmployeeModalExecutor extends Executor<
  CreateEmployeeModalState,
  CreateEmployeeModalAction,
  CreateEmployeeModalResultAction
> {
  constructor(
    private roleService: RoleService,
    private employeeService: EmployeeService,
    private employeeListExecutor: EmployeesListExecutor,
    private toastsService: ToastsService,
    @Inject('NewEmployeeFirstNameValidator')
    private nameValidator: Validator,
    @Inject('NewEmployeeLastNameValidator')
    private surnameValidator: Validator,
    @Inject('NewEmployeePatronymicValidator')
    private patronymicValidator: Validator,
    @Inject('RegistrationEmailValidator')
    private emailValidator: Validator,
    @Inject('RegistrationPhoneNumberValidator')
    private phoneNumberValidator: Validator,
  ) {
    super();
  }

  override init(
    reducer: Reducer<CreateEmployeeModalState,CreateEmployeeModalResultAction>,
    getState: () => CreateEmployeeModalState,
    onReduced: (state: CreateEmployeeModalState) => void)
  {
    super.init(reducer, getState, onReduced);
    this.validateUserForm()
  }

  execute(action: CreateEmployeeModalAction) {
    switch (action.type) {
      case CreateEmployeeModalActionTypes.INIT_VIEW:
        this.reduce({
          type: CreateEmployeeModalResultActionTypes.INIT_VIEW,
          inputs: action.inputs
        })
        break
      case CreateEmployeeModalActionTypes.INIT:
        this.reduce({
          type: CreateEmployeeModalResultActionTypes.INIT,
        })
        break;
      case CreateEmployeeModalActionTypes.SAVE_EMPLOYEE:
        this.handleSaveEmployee()
        break;
      case CreateEmployeeModalActionTypes.LOAD_EMPLOYEE:
        this.handleLoadEmployee(action.id)
        break;
      case CreateEmployeeModalActionTypes.ADD_ROLE:
        this.handleAddRole(action.role)
        break
      case CreateEmployeeModalActionTypes.REMOVE_ROLE:
        this.handleRemoveRole(action.roleId)
        break
      case CreateEmployeeModalActionTypes.CHANGE_SURNAME:
        this.handleChangeSurname(action.value)
        break;
      case CreateEmployeeModalActionTypes.CHANGE_NAME:
        this.handleChangeName(action.value)
        break;
      case CreateEmployeeModalActionTypes.CHANGE_PATRONYMIC:
        this.handleChangePatronymic(action.value)
        break;
      case CreateEmployeeModalActionTypes.CHANGE_EMAIL:
        this.handleChangeEmail(action.value)
        break;
      case CreateEmployeeModalActionTypes.CHANGE_PHONE_NUMBER:
        this.handleChangePhoneNumber(action.value)
        break;
      case CreateEmployeeModalActionTypes.CHANGE_AVATAR_COLOR:
        this.reduce({
          type: CreateEmployeeModalResultActionTypes.CHANGE_AVATAR_COLOR,
          colorRGB: stringToRgb(this.getState().userForm.email.value)
        })
        break;
    }
  }

  private handleChangePatronymic(value: string){
    const newUserForm = {
      ...this.getState().userForm,
      patronymic: {
        value: value,
        error: this.patronymicValidator.validate(value)
      }
    }
    this.reduce({
      type: CreateEmployeeModalResultActionTypes.CHANGE_FORM_DATA,
      newData: newUserForm,
      isValid: this.isUserDataValid(newUserForm)
    })
  }

  private handleChangeName(value: string){
    const newUserForm = {
      ...this.getState().userForm,
      name: {
        value: value,
        error: this.nameValidator.validate(value)
      }
    }
    this.reduce({
      type: CreateEmployeeModalResultActionTypes.CHANGE_FORM_DATA,
      newData: newUserForm,
      isValid: this.isUserDataValid(newUserForm)
    })
  }

  private handleChangeSurname(value: string){
    const newUserForm = {
      ...this.getState().userForm,
      surname: {
        value: value,
        error: this.surnameValidator.validate(value)
      }
    }
    this.reduce({
      type: CreateEmployeeModalResultActionTypes.CHANGE_FORM_DATA,
      newData: newUserForm,
      isValid: this.isUserDataValid(newUserForm)
    })
  }

  private handleChangeEmail(value: string){
    const newUserForm = {
      ...this.getState().userForm,
      email: {
        value: value,
        error: this.emailValidator.validate(value)
      }
    }
    this.reduce({
      type: CreateEmployeeModalResultActionTypes.CHANGE_FORM_DATA,
      newData: newUserForm,
      isValid: this.isUserDataValid(newUserForm)
    })
  }

  private handleChangePhoneNumber(value: string){
    const newPhoneNumber = stringToPhoneNumber(value)
    const newUserForm = {
      ...this.getState().userForm,
      phoneNumber: {
        value: newPhoneNumber,
        error: this.phoneNumberValidator.validate(newPhoneNumber)
      }
    }
    this.reduce({
      type: CreateEmployeeModalResultActionTypes.CHANGE_FORM_DATA,
      newData: newUserForm,
      isValid: this.isUserDataValid(newUserForm)
    })
  }

  private handleRemoveRole(roleId: string){
    let newRoles = this.getState().roles.filter(value => value.id != roleId)

    this.reduce({
      type: CreateEmployeeModalResultActionTypes.CHANGE_ROLES,
      roles: newRoles
    })
  }

  private handleAddRole(role: EmployeeRoleDto){
    let newRoles = this.getState().roles.slice()
    if(newRoles.findIndex(findRole=> findRole.id == role.id) == -1) {
      newRoles.push(role)
    }
    this.reduce({
      type: CreateEmployeeModalResultActionTypes.CHANGE_ROLES,
      roles: newRoles
    })
  }

  private handleSaveEmployee(){
    this.validateUserForm()
    const userData = this.getState().userForm

    if(this.isUserDataValid(userData))
    {
      const createEmployeeDto: EmployeeCreateDto = {
        name: userData.name.value.trim().split(' ').filter((str) => str.length > 0).join(' '),
        surname: userData.surname.value.trim().split(' ').filter((str) => str.length > 0).join(' '),
        patronymic: userData.patronymic.value.trim().split(' ').filter((str) => str.length > 0).join(' '),
        email: userData.email.value,
        phoneNumber: '+' + userData.phoneNumber.value.replace(/\D/g, ''),
        roleIDs: userData.roleIds
      }

      this.reduce({
        type: CreateEmployeeModalResultActionTypes.CHANGE_IS_SENDING_REQUEST,
        value: true
      })

      if(this.getState().loadedEmployeeId == -1)
      {
        this.employeeService.createEmployee(createEmployeeDto)
        .subscribe(()=>{
          this.toastsService.createToast({
            title: 'Сотрудник создан',
            description: '',
            state: ToastState.SUCCESS
          })
          this.fetchEmployeesList()
        })
      }
      else{
        this.employeeService.editEmployee(this.getState().loadedEmployeeId, createEmployeeDto)
        .subscribe(()=>{
          this.fetchEmployeesList()
        })
      }
    }
  }

  private fetchEmployeesList(){
    this.employeeListExecutor.execute({
      type: EmployeesListActionTypes.UPDATE_EMPLOYEES_LIST
    })
    this.employeeListExecutor.execute({
      type: EmployeesListActionTypes.CHANGE_CREATE_EMPLOYEE_MODAL_VISIBLE,
      value: false
    })
  }

  private handleLoadEmployee(id: number){
    this.reduce({
      type: CreateEmployeeModalResultActionTypes.CHANGE_IS_LOADING,
      value: true,
      employeeId: id,
    })

    this.employeeService.getEmployee(id).subscribe((employeeDto)=>{
      const roles = employeeDto.employee.roles.map((role)=>{
        if(!role.isCustom)
        {
          const baseRole = EmployeesConstants.BASE_ROLES.get(role.name)
          if(baseRole)
          {
            role.name = baseRole.name;
            role.description = baseRole.description;
          }
        }
        return role
      })

      const newUserForm: UserForm = {
        surname: { value: employeeDto.employee.surname },
        patronymic: { value: employeeDto.employee.patronymic ? employeeDto.employee.patronymic : ''},
        name:{ value: employeeDto.employee.name},
        phoneNumber: { value:employeeDto.employee.phoneNumber},
        email: { value:employeeDto.employee.email},
        roleIds: employeeDto.employee.roles.map<string>(employeeRole => {
          return employeeRole.id;
        })
      }

      this.reduce({
        type: CreateEmployeeModalResultActionTypes.LOAD_EMPLOYEE,
        userData: newUserForm,
        employeeId: employeeDto.employee.id,
        roles: roles,
        canDelete: employeeDto.canDelete,
        canEdit: employeeDto.canEdit,
        isValid: this.isUserDataValid(newUserForm)
      })

      this.reduce({
        type: CreateEmployeeModalResultActionTypes.CHANGE_IS_LOADING,
        value: false,
      })
    })

  }

  private validateUserForm(){
    const formData = this.getState().userForm
    const newPhoneNumber = stringToPhoneNumber(formData.phoneNumber.value)
    const newFormData: UserForm = {
      surname: { value: formData.surname.value, error: this.surnameValidator.validate(formData.surname.value) },
      name: { value: formData.name.value, error: this.nameValidator.validate(formData.name.value) },
      patronymic: { value: formData.patronymic.value, error: this.patronymicValidator.validate(formData.patronymic.value) },
      phoneNumber: { value: newPhoneNumber, error: this.phoneNumberValidator.validate(newPhoneNumber) },
      email: { value: formData.email.value, error: this.emailValidator.validate(formData.email.value) },
      roleIds: formData.roleIds
    }
    this.reduce({
      type: CreateEmployeeModalResultActionTypes.CHANGE_FORM_DATA,
      newData: newFormData,
      isValid: this.isUserDataValid(newFormData)
    })
    this.getState().inputs?.toArray().forEach((input)=>{
      input.focused = false
    })
  }

  private isUserDataValid(data: UserForm): boolean{
    return data.name.error == null
      && data.surname.error == null
      && data.patronymic.error == null
      && data.email.error == null
      && data.phoneNumber.error == null
  }
}
