import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output, ViewChild
} from "@angular/core";
import { ActionSection } from '../../../../../../../core/components/action-menu/action-menu.component';
import { DocumentItemEntity } from '../../../domain/document-item-entity';

@Component({
  selector: 'section-doc-item',
  templateUrl: './doc-item.component.html',
})
export class DocItemComponent {
  @Input() document: DocumentItemEntity = {
    id: '0',
    title: '',
    isFavorite: false,
    dateOfUpdate: '',
    isDraft: false
  };
  @Output() docClicked = new EventEmitter();
  @Output() editClicked = new EventEmitter();
  @Output() linkClicked = new EventEmitter();
  @Output() markClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();

  popupVisibility: boolean = false;
  popupMenuSections: ActionSection[] = [
    {
      elements: [
        {
          id: 'DELETE',
          name: 'Удалить',
        },
      ],
    },
  ];
  constructor(private el: ElementRef) {}

  @ViewChild('buttons_block') buttonsBlock!: ElementRef
  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.popupVisibility = false;
    }
    if(this.el.nativeElement.contains(event.target) && !this.buttonsBlock.nativeElement.contains(event.target))
    {
      this.docClicked.emit()
    }
  }

  popupClicked() {
    this.deleteClicked.emit();
    this.popupVisibility = false;
  }
}
