import { RatingScaleDto } from '../../../@data/dto/rating-scale.dto';

export type CreateRatingScaleAction =
  | OpenModalAction
  | CloseModalAction
  | ChangeNameAction
  | ChangeLevelNameAction
  | ChangeLevelPointsAction
  | AddLevelAction
  | RemoveLevelAction
  | SaveRatingScaleAction;

export enum CreateRatingScaleActionTypes {
  OPEN_MODAL,
  CLOSE_MODAL,
  CHANGE_NAME,
  CHANGE_LEVEL_NAME,
  CHANGE_LEVEL_POINTS,
  ADD_LEVEL,
  REMOVE_LEVEL,
  SAVE_RATING_SCALE,
}

export interface OpenModalAction {
  readonly type: CreateRatingScaleActionTypes.OPEN_MODAL;
  readonly ratingScale?: RatingScaleDto;
}

export interface CloseModalAction {
  readonly type: CreateRatingScaleActionTypes.CLOSE_MODAL;
}

export interface ChangeNameAction {
  readonly type: CreateRatingScaleActionTypes.CHANGE_NAME;
  readonly name: string;
}

export interface ChangeLevelNameAction {
  readonly type: CreateRatingScaleActionTypes.CHANGE_LEVEL_NAME;
  readonly levelLocalId: string;
  readonly levelName: string;
}

export interface ChangeLevelPointsAction {
  readonly type: CreateRatingScaleActionTypes.CHANGE_LEVEL_POINTS;
  readonly levelLocalId: string;
  readonly levelPoints: string;
}

export interface AddLevelAction {
  readonly type: CreateRatingScaleActionTypes.ADD_LEVEL;
}

export interface RemoveLevelAction {
  readonly type: CreateRatingScaleActionTypes.REMOVE_LEVEL;
  readonly levelLocalId: string;
}

export interface SaveRatingScaleAction {
  readonly type: CreateRatingScaleActionTypes.SAVE_RATING_SCALE;
}
