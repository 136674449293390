<app-core-modal
  [open]="isVisible"
  [isLoading]="state.isSendingRequest || state.isLoading"
  [paddingType]="'none'"
  [className]="
    'w-full flex flex-row relative ' + (state.loadedEmployeeId == -1 ? ' h-full max-w-[1064px] max-h-[644px]' : 'max-w-[702px] min-h-[544px] max-h-[80%]')"
  (onClose)="close()"
>
  <div class="max-w-[362px] w-full flex flex-col gap-[16px] h-full p-[32px] clue-bg rounded-l-[16px]"
    *ngIf="state.loadedEmployeeId == -1"
  >
    <p class="font-semibold text-[22px] leading-[27px] text-tt-text-default">
      Создание пользователя
    </p>
    <p class="text-[15px] leading-[18px] text-tt-text-default">
      Заполните данные для работы
    </p>
    <div class="w-full flex items-center gap-[16px]">
      <img ngSrc="assets/create-employee-modal/gear.png" height="56" width="56" alt="gear" draggable="false">
      <p class="text-[15px] leading-[18px]">Имя пользователя и аватар</p>
    </div>
    <div class="w-full flex items-center gap-[16px]">
      <img ngSrc="assets/create-employee-modal/mailbox.png" height="56" width="56" alt="mailbox" draggable="false">
      <p class="text-[15px] leading-[18px]">Адреса и контакты</p>
    </div>
    <div class="w-full flex items-center gap-[16px]">
      <img ngSrc="assets/create-employee-modal/keys.png" height="56" width="56" alt="keys" draggable="false">
      <p class="text-[15px] leading-[18px]">Роли пользователя в системе </p>
    </div>
  </div>

  <app-core-modal-content
    class="p-[8px] pb-[16px]"
    [ngClass]="{
      'flex-grow': state.loadedEmployeeId == -1,
      'min-h-[544px] w-full': state.loadedEmployeeId != -1
    }"
    [footer]="true"
    *ngIf="!state.isLoading"
  >
    <div class="relative">
      <app-str-to-colored-bg
        class="w-full h-[120px] absolute left-0 top-0"
        [colorRGB]="state.avatarColorRGB"
      />
    </div>
    <div class="pb-[32px]">
      <div class="px-[24px] pt-[60px] flex"
        *ngIf="!state.isLoading"
      >
        <app-core-avatar
          size="5xl"
          [bgRGB]="state.avatarColorRGB"
          [borderSize]="4"
          variant="primary"
          [placeholder]="state.avatarPlaceholder"
          [haveContent]="true"
        >
          <svg-photo/>
        </app-core-avatar>

        <div class="mt-[16px] ml-[16px] flex flex-col gap-[12px] max-w-[502px] w-full">
          <app-core-input-field
            variant="borderless"
            placeholder="Фамилия"
            [textStyle]="'font-semibold text-[22px] leading-[27px]'"
            error="{{ state.userForm.surname.error }}"
            errorShowType="onUnFocused"
            [defaultValue]="state.userForm.surname.value"
            [autoFocus]="true"
            [value]="state.userForm.surname.value"
            (onChange)="performAction({
              type: CreateEmployeeModalActionTypes.CHANGE_SURNAME,
              value: $event
            })"
            #employeeInput
          />

          <div class="flex gap-[16px] w-full">
            <app-core-input-field
              class="max-w-[170px] w-full"
              variant="borderless"
              placeholder="Имя"
              [textStyle]="'font-semibold text-[22px] leading-[27px]'"
              error="{{ state.userForm.name.error }}"
              errorShowType="onUnFocused"
              [defaultValue]="state.userForm.name.value"
              [value]="state.userForm.name.value"
              (onChange)="performAction({
                type: CreateEmployeeModalActionTypes.CHANGE_NAME,
                value: $event
              })"
              #employeeInput
            />
            <app-core-input-field
              [className]="'max-w-[316px] w-full'"
              variant="borderless"
              placeholder="Отчество"
              [textStyle]="'font-semibold text-[22px] leading-[27px]'"
              error="{{ state.userForm.patronymic.error }}"
              errorShowType="onUnFocused"
              [defaultValue]="state.userForm.patronymic.value"
              [value]="state.userForm.patronymic.value"
              (onChange)="performAction({
                type: CreateEmployeeModalActionTypes.CHANGE_PATRONYMIC,
                value: $event
              })"
              #employeeInput
            />
          </div>

          <app-core-input-field
            variant="borderless"
            placeholder="Введите E-mail"
            [textStyle]="'text-[16px] leading-[19px]'"
            error="{{ state.userForm.email.error }}"
            errorShowType="onUnFocused"
            [defaultValue]="state.userForm.email.value"
            [value]="state.userForm.email.value"
            (onChange)="performAction({
              type: CreateEmployeeModalActionTypes.CHANGE_EMAIL,
              value: $event
            })"
            (onUnFocused)="performAction({
              type: CreateEmployeeModalActionTypes.CHANGE_AVATAR_COLOR
            })"
            #employeeInput
          />

          <app-core-input-field
            variant="borderless"
            placeholder="Введите номер телефона"
            [textStyle]="'text-[16px] leading-[19px]'"
            error="{{ state.userForm.phoneNumber.error }}"
            errorShowType="onUnFocused"
            prefix="+"
            mask="0 (000) 000-00-00||00 (000) 000-00-00||000 (000) 000-00-00"
            [defaultValue]="state.userForm.phoneNumber.value"
            [value]="state.userForm.phoneNumber.value"
            (onChange)="performAction({
              type: CreateEmployeeModalActionTypes.CHANGE_PHONE_NUMBER,
              value: $event
            })"
            #employeeInput
          />

          <div class="pt-[4px] flex flex-col gap-[12px] w-full">
            <p
              class="font-medium text-[16px] leading-[19px] text-tt-text-default"
            >
              Роли пользователя
            </p>

            <div class="w-full flex gap-x-[8px] gap-y-[12px] flex-wrap">
              <app-chips
                *ngIf="state.loadedEmployeeId == -1"
                [chips]="{id: '', name: 'Зритель', type: 'readonly'}"
              />
              <app-chips
                *ngFor="let role of state.roles"
                [chips]="{
                  id: role.id,
                  name: role.name,
                  type: role.isEditable ? 'base' : 'readonly'
                }"
                (deleteClicked)="performAction({
                  type: CreateEmployeeModalActionTypes.REMOVE_ROLE,
                  roleId: $event
                })"
              />
            </div>

            <employees-roles-popup
              class="w-max"
              [activeRoleIds]="state.userForm.roleIds"
              (roleClicked)="performAction({
                type: CreateEmployeeModalActionTypes.ADD_ROLE,
                role: $event
              })"
            />
          </div>
        </div>
      </div>

    </div>

    <app-core-modal-content-footer>
      <div class="w-full flex justify-between">
        <app-core-button
          (onClick)="onDelete()"
          variant="soft"
          type="danger"
          size="l"
          *ngIf="canDelete && state.canDelete"
        >
          <span>Удалить</span>
        </app-core-button>

        <div></div>

        <div class="flex gap-[16px]">
          <app-core-button
            variant="soft"
            (onClick)="close()"
          >
            Отмена
          </app-core-button>

          <app-core-button
            size="l"
            *ngIf="state.canEdit"
            [disabled]="!state.isValidForm || state.isEqualInit"
            (onClick)="performAction({
              type: CreateEmployeeModalActionTypes.SAVE_EMPLOYEE
            })"
          >
            <span
              *ngIf="state.loadedEmployeeId == -1"
            >Создать пользователя</span>
            <span
              *ngIf="state.loadedEmployeeId != -1"
            >Сохранить</span>
          </app-core-button>

        </div>
      </div>
    </app-core-modal-content-footer>

  </app-core-modal-content>

</app-core-modal>
