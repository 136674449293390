import { environment } from '../../../../environments/environment';
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import { RegistrationMapper } from './registration-mapper';
import { RegistrationService } from '../domain/registration-service';
import { Injectable } from '@angular/core';
import { DefaultCompanyInfoEntity } from '../domain/model/default-company-info-entity';
import { DefaultCompanyInfoJson } from './json/default-company-info-json';
import { RegistrationEntity } from '../domain/model/registration-entity';
import { RegistrationResult } from '../domain/model/registration-result';

@Injectable({
  providedIn: 'root',
})
export class RegistrationServiceImpl extends RegistrationService {
  constructor(
    private http: HttpClient,
    private mapper: RegistrationMapper,
  ) {
    super();
  }

  getDefaultCompanyInfo(): DefaultCompanyInfoEntity {
    return {
      specializations: [
        {
          id: 'medicine-and-health-care',
          text: 'Медицина и здравоохранение',
        },
        {
          id: 'finance-and-accounting',
          text: 'Финансы и бухгалтерия',
        },
        {
          id: 'IT',
          text: 'Информационные технологии',
        },
        {
          id: 'education',
          text: 'Образование',
        },
        {
          id: 'manufacturing-and-engineering',
          text: 'Производство и инженерия',
        },
        {
          id: 'marketing-and-analytics',
          text: 'Маркетинг и аналитика',
        },
        {
          id: 'other',
          text: 'Другое',
        },
      ],
      stuffSizes: [
        {
          id: 1,
          max: 100,
          min: 1,
        },
        {
          id: 2,
          min: 101,
          max: 300,
        },
        {
          id: 3,
          min: 301,
          max: 1000,
        },
        {
          id: 4,
          max: null,
          min: 1001,
        },
      ],
    };
  }

  registerCompany(
    registration: RegistrationEntity,
  )
    : Observable<RegistrationResult>
  {
    const body = this.mapper.mapRegistration(registration);
    return this.http
      .post<any>(`${environment.apiUrl}/company_new/company/create`, body)
      .pipe(
        map((response) => {
          return RegistrationResult.SUCCESS;
        }),
        catchError((err) => {
          if (err.status == HttpStatusCode.Forbidden) {
            return of(RegistrationResult.EMAIL_EXISTS_ERROR);
          } else {
            return of(RegistrationResult.SERVER_ERROR);
          }
        }),
      );
  }
}
