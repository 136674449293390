import { Component, Input } from '@angular/core';

@Component({
  selector: '[app-core-with-highlighting]',
  templateUrl: './text-with-highlighting.component.html',
})
export class TextWithHighlightingComponent {
  @Input() text: HighlightedPartsStr[] = [];
}

export interface HighlightedPartsStr{
  stringPart: string,
  isHighlighted: boolean
}
