import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'sections-select-icon-window',
  templateUrl: './select-icon-window.component.html',
})
export class SelectIconWindowComponent {
  @Input() iconUrls: string[] = [];
  @Input() currentIconUrl: string = '';
  @Input() title: string = '';
  @Input() variant: 'relative' | 'absolute' = 'relative';
  @Output() onChange = new EventEmitter<string>();

  public isOpen: boolean = false;

  constructor(private el: ElementRef) {}

  downloadClicked() {}

  iconClicked(url: string) {
    this.onChange.emit(url);
    this.isOpen = false;
  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }
}
