import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  CreateSkillResultAction,
  CreateSkillResultActionTypes,
} from './create-skill-result-action';
import { CreateSkillState } from './create-skill-state';

@Injectable()
export class CreateSkillReducer
  implements Reducer<CreateSkillState, CreateSkillResultAction>
{
  reduce(
    state: CreateSkillState,
    action: CreateSkillResultAction,
  ): CreateSkillState {
    switch (action.type) {
      case CreateSkillResultActionTypes.RESET_STATE:
        return {
          ...state,
          currentStep: 1,
          description: '',
          name: '',
          nameError: null,
          error: null,
          isOpen: false,
          isLoading: false,
          selectedRatingScaleError: null,
          selectedRatingScale: null,
        };

      case CreateSkillResultActionTypes.OPEN_MODAL:
        return { ...state, isOpen: true };

      case CreateSkillResultActionTypes.CLOSE_MODAL:
        return { ...state, isOpen: false };

      case CreateSkillResultActionTypes.CHANGE_NAME:
        return { ...state, name: action.name, nameError: null };

      case CreateSkillResultActionTypes.CHANGE_NAME_ERROR_STATUS:
        return { ...state, nameError: action.nameError };

      case CreateSkillResultActionTypes.CHANGE_DESCRIPTION:
        return { ...state, description: action.description };

      case CreateSkillResultActionTypes.CHANGE_RATING_SCALES_ERROR_STATUS:
        return { ...state, ratingScalesError: action.ratingScalesError };

      case CreateSkillResultActionTypes.CHANGE_LOADING_STATUS:
        return { ...state, isLoading: action.isLoading };

      case CreateSkillResultActionTypes.CHANGE_ERROR_STATUS:
        return { ...state, error: action.error };

      case CreateSkillResultActionTypes.CHANGE_RATING_SCALES_IS_LOADING_STATUS:
        return {
          ...state,
          ratingScalesIsLoading: action.ratingScalesIsLoading,
        };

      case CreateSkillResultActionTypes.CHANGE_RATING_SCALES:
        return {
          ...state,
          ratingScales: action.ratingScales,
        };

      case CreateSkillResultActionTypes.SELECT_RATING_SCALE:
        return {
          ...state,
          selectedRatingScale: action.ratingScale,
          selectedRatingScaleError: null,
        };

      case CreateSkillResultActionTypes.UNSELECT_RATING_SCALE:
        return { ...state, selectedRatingScale: null };

      case CreateSkillResultActionTypes.VALIDATION_ERROR:
        return {
          ...state,
          nameError: action.nameError,
          selectedRatingScaleError: action.selectedRatingScaleError,
        };

      case CreateSkillResultActionTypes.CHANGE_LEVEL_DESCRIPTION:
        if (state.selectedRatingScale) {
          return {
            ...state,
            selectedRatingScale: {
              ...state.selectedRatingScale,
              levels: state.selectedRatingScale.levels.map((level) => {
                if (level.id === action.levelId) {
                  return {
                    ...level,
                    description: action.levelDescription,
                    descriptionError: null,
                  };
                }
                return level;
              }),
            },
          };
        } else {
          return state;
        }

      case CreateSkillResultActionTypes.CHANGE_LEVEL_DESCRIPTION_ERROR_STATUS:
        if (state.selectedRatingScale) {
          return {
            ...state,
            selectedRatingScale: {
              ...state.selectedRatingScale,
              levels: state.selectedRatingScale.levels.map((level) => {
                if (level.id === action.levelId) {
                  return {
                    ...level,
                    descriptionError: action.levelDescriptionError,
                  };
                }
                return level;
              }),
            },
          };
        } else {
          return state;
        }

      case CreateSkillResultActionTypes.CHANGE_CURRENT_STEP:
        return { ...state, currentStep: action.step };
    }
  }
}
