import { NgModule } from '@angular/core';
import { SectionContentComponent } from './presentation/view/section-content.component';
import { ComponentsModule } from '../../../../core/components/components.module';
import { CommonModule } from '@angular/common';
import { DocItemComponent } from './presentation/components/doc-item/doc-item.component';
import {
  SvgBookmark,
  SvgBookmarkFill,
  SvgDocument,
  SvgLink,
  SvgPencil,
  SvgTripleDot,
} from '../../../../core/components/svg-components/svg.components';
@NgModule({
  declarations: [SectionContentComponent, DocItemComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    SvgDocument,
    SvgPencil,
    SvgLink,
    SvgBookmark,
    SvgBookmarkFill,
    SvgTripleDot,
  ],
})
export class SectionContentModule {}
