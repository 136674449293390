import { Component } from '@angular/core';
import { NewsSummaryEntity } from "../../../domain/news-summary-entity";

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent {
  listType: 'grid-cell' | 'list-element' = 'grid-cell';

  news: NewsSummaryEntity[] = [{
    id: '',
    title: 'Обеспечение технической поддержки для пользователей',
    description: 'C 1 мая мы начинаем больше времени в техническом отделе уделять не только разработке новых фич, но и значительC 1 мая мы начинаем больше времени в техническом отделе уделять не только разработке новых фич, но и значительC 1 мая мы начинаем больше времени в техническом отделе уделять не только разработке новых фич, но и значительC 1 мая мы начинаем больше времени в техническом отделе уделять не только разработке новых фич, но и значительной доработки C 1 мая мы начинаем больше времени в техническом',
    date: '6 мая 2024',
    image: 'assets/empty-section.svg',
  },
    {
      id: '',
      title: 'Обеспечение технической поддержки для пользователей Обеспечение технической поддержки для пользователей Обеспечение технической поддержки для пользователей',
      description: 'C 1 мая мы начинаем больше времени в техническом отделе уделять не только разработке новых фич, но и значительной доработки C 1 мая мы начинаем больше времени в техническом',
      date: '6 мая 2024',
      image: 'assets/empty-section.svg',
    },
    {
      id: '',
      title: 'Обеспечение технической',
      description: 'C 1 мая мы C 1 мая мы C 1 мая мы C 1 мая мы C 1 мая мы C 1 мая мы C 1 мая мы C 1 мая мы C 1 мая мы C 1 мая мы C 1 мая мы C 1 мая мы C 1 мая мы C 1 мая мы  1 мая мы начинаем боC 1 мая мы начинаем боC 1 мая мы начинаем боC 1 мая мы начинаем боC 1 мая мы начинаем боC 1 мая мы начинаем боC 1 мая мы начинаем боC 1 мая мы начинаем боC 1 мая мы начинаем боC 1 мая мы начинаем боC 1 мая мы начинаем боC 1 мая мы начинаем больше времени в техническом отделе уделять не только разработке новых фич, но и значительной доработки C 1 мая мы начинаем больше времени в техническом',
      date: '6 мая 2024',
      image: 'assets/empty-section.svg',
    },
    {
      id: '',
      title: 'Обеспечение технической поддержки для пользователей',
      description: 'C 1 мая мы начинаем больше времени в техническом отделе уделять не только разработке новых фич, но и значительной доработки C 1 мая мы начинаем больше времени в техническом',
      date: '6 мая 2024',
      image: 'assets/empty-section.svg',
    },
    {
      id: '',
      title: 'Обеспечение технической поддержки для пользователей',
      description: 'C 1 мая мы начинаем больше времени в техническом отделе уделять не только разработке новых фич, но и значительной доработки C 1 мая мы начинаем больше времени в техническом',
      date: '6 мая 2024',
      image: 'assets/empty-section.svg',
    },]
}
