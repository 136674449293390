import { EmojiCalloutType } from "../types/emoji-callout-type";
import React from "react";
import { ReactSVG } from "react-svg";

function calloutTypeImage(type: string){
  let src = 'assets/editor/callout/info.svg'
  switch (type){
    case "question":
      src = "assets/editor/callout/question-mark.svg"
      break
    case "warning":
      src = "assets/editor/callout/warning.svg"
      break
    case "success":
      src = "assets/editor/callout/success.svg"
      break
    case "error":
      src = "assets/editor/callout/error.svg"
      break
    case "plus":
      src = "assets/editor/callout/plus.svg"
      break
    case "minus":
      src = "assets/editor/callout/minus.svg"
      break
    case "none":
      src='none'
      break
  }
  return (
    <div>
      {src !== 'none' && <ReactSVG src={src} width={24} height={24} />}
    </div>
  )
}

export default calloutTypeImage
