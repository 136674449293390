import {
  useBlockData,
  PluginElementRenderProps,
  useYooptaEditor,
  useBlockSelected,
  useYooptaReadOnly,
} from '@teamtells/editor';
import { FileComponent } from "../components/file-component";
import { Placeholder } from "../components/file-placeholder";
import { FileBlockOptions } from "../components/file-block-options";

const FileRender = ({ extendRender, ...props }: PluginElementRenderProps) => {
  const { element, attributes, children, blockId } = props;
  const { name, src, format, size } = element.props || {};
  const block = useBlockData(blockId);
  const editor = useYooptaEditor();
  const isReadOnly = useYooptaReadOnly();

  const blockSelected = useBlockSelected({ blockId });

  if (!src) {
    if (isReadOnly) return <></>;

    return (
      <Placeholder attributes={attributes} blockId={blockId}>
        {children}
      </Placeholder>
    );
  }

  return (
    <div
      contentEditable={false}
      draggable={false}
      className={`mt-4 relative flex yoopta-file`}
      {...attributes}
    >
      {blockSelected && (
        <div className="yoopta-selected-block" />
      )}
      <FileComponent name={name} format={format} src={src} size={size} blockId={blockId} align={block.meta.align} />
      {!isReadOnly && <FileBlockOptions block={block} editor={editor} props={element.props} />}
      {children}
    </div>
  );
};

export { FileRender };
