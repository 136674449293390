import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-core-button-add',
  templateUrl: './button-add.component.html',
})
export class ButtonAddComponent {
  @Input() position: 'start' | 'end' | 'center' = 'start';

  @Output() onClick = new EventEmitter<MouseEvent>();

  onClickValue(event: MouseEvent) {
    this.onClick.emit(event);
  }
}
