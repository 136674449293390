import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { SettingsSubscriptionConstants } from '../../../common/settings-subscriptioon-constants';
import { NumberFormatStyle } from '@angular/common';

@Component({
  selector: 'settings-subscription-order',
  templateUrl: './settings-subscription-order.component.html',
  styleUrls: ['./settings-subscription-order.component.scss']
})
export class SettingsSubscriptionOrderComponent {
  @ViewChild('sliderBar') sliderBarEl?: ElementRef
  @ViewChild('slider') sliderEl?: ElementRef
  @ViewChild('sliderSeparator10') sliderSeparator10El?: ElementRef
  @ViewChild('sliderSeparator100') sliderSeparator100El?: ElementRef
  @ViewChild('sliderSeparator200') sliderSeparator200El?: ElementRef
  @ViewChild('sliderSeparator300') sliderSeparator300El?: ElementRef
  @ViewChild('sliderSeparator400') sliderSeparator400El?: ElementRef

  numberFormat: Intl.NumberFormat

  subscriptionType: 'year' | 'month' = 'year'
  sliderValue: number = 1
  onePersonPrice: number = SettingsSubscriptionConstants.SUBSCRIPTION_YEAR_PRICES.get('0-10')!
  subscriptionPrice: number = this.onePersonPrice * this.sliderValue
  priceId: string = '0-10'

  isInvoicingModalVisible: boolean = false

  protected readonly SettingsSubscriptionConstants = SettingsSubscriptionConstants

  constructor(private renderer: Renderer2) {
    this.numberFormat = new Intl.NumberFormat()
  }

  onInputChange(value: string, onUnfocused: boolean = false) {
    if(value != '' || onUnfocused){
      const numberValue = value == '' ? 1 : Number(value) < 1000 ? Number(value) < 1? 1 : Number(value) : 1000
      const sliderWidth = this.sliderEl?.nativeElement.offsetWidth
      let sliderPercentOffset = 0

      if(0 < numberValue && numberValue <= 10){
        const separator10Left = this.sliderSeparator10El?.nativeElement.offsetLeft - sliderWidth / 2
        sliderPercentOffset = this.calcSliderPercentLeftOffset(numberValue, 1, 0, separator10Left, 9)
        this.priceId = '0-10'
      }

      if(10 < numberValue && numberValue <= 100){
        const separator10Left = this.sliderSeparator10El?.nativeElement.offsetLeft - sliderWidth / 2
        const separator100Left = this.sliderSeparator100El?.nativeElement.offsetLeft - sliderWidth / 2
        sliderPercentOffset = this.calcSliderPercentLeftOffset(numberValue, 10, separator10Left, separator100Left, 90)
        this.priceId = '10-100'
      }

      if(100 < numberValue && numberValue <= 200){
        const separator100Left = this.sliderSeparator100El?.nativeElement.offsetLeft - sliderWidth / 2
        const separator200Left = this.sliderSeparator200El?.nativeElement.offsetLeft - sliderWidth / 2
        sliderPercentOffset = this.calcSliderPercentLeftOffset(numberValue, 100, separator100Left, separator200Left, 100)
        this.priceId = '100-200'
      }

      if(200 < numberValue && numberValue <= 300){
        const separator200Left = this.sliderSeparator200El?.nativeElement.offsetLeft - sliderWidth / 2
        const separator300Left = this.sliderSeparator300El?.nativeElement.offsetLeft - sliderWidth / 2
        sliderPercentOffset = this.calcSliderPercentLeftOffset(numberValue, 200, separator200Left, separator300Left, 100)
        this.priceId = '200-300'
      }

      if(300 < numberValue && numberValue <= 400){
        const separator300Left = this.sliderSeparator300El?.nativeElement.offsetLeft - sliderWidth / 2
        const separator400Left = this.sliderSeparator400El?.nativeElement.offsetLeft - sliderWidth / 2
        sliderPercentOffset = this.calcSliderPercentLeftOffset(numberValue, 300, separator300Left, separator400Left, 100)
        this.priceId = '300-400'
      }

      if(400 < numberValue && numberValue <= 1000){
        const separator400Left = this.sliderSeparator400El?.nativeElement.offsetLeft - sliderWidth / 2
        const separator1000Left = this.sliderBarEl?.nativeElement.offsetWidth - sliderWidth
        sliderPercentOffset = this.calcSliderPercentLeftOffset(numberValue, 400, separator400Left, separator1000Left, 600)
        this.priceId = '400-1000'
      }

      this.renderer.setStyle(this.sliderEl?.nativeElement, 'left', `${sliderPercentOffset}%`);
      this.sliderValue = numberValue
      this.calcPrices()
    }
  }

  sliderClick(event: MouseEvent){
    if(this.sliderBarEl && this.sliderEl)
    {
      event.preventDefault();
      const sliderHTML = this.sliderEl.nativeElement as HTMLElement
      const maxOffset = this.sliderBarEl.nativeElement.offsetWidth - sliderHTML.offsetWidth
      const maxOffsetPercent = 1 - sliderHTML.offsetWidth / this.sliderBarEl.nativeElement.offsetWidth
      let newOffset = event?.offsetX - sliderHTML.offsetWidth / 2;
      newOffset = newOffset >= 0 ? newOffset <= maxOffset ? newOffset : maxOffset : 0
      const percentOffset = newOffset / maxOffset * maxOffsetPercent * 100
      this.renderer.setStyle(this.sliderEl?.nativeElement, 'left', `${percentOffset}%`);
      this.checkValue()
    }
  }

  separatorClick(id: string){
    if(this.sliderBarEl && this.sliderEl)
    {
      let separatorLeft = 0
      switch(id){
        case '10':
          separatorLeft = this.sliderSeparator10El?.nativeElement.offsetLeft
          break;
        case '100':
          separatorLeft = this.sliderSeparator100El?.nativeElement.offsetLeft
          break;
        case '200':
          separatorLeft = this.sliderSeparator200El?.nativeElement.offsetLeft
          break;
        case '300':
          separatorLeft = this.sliderSeparator300El?.nativeElement.offsetLeft
          break;
        case '400':
          separatorLeft = this.sliderSeparator400El?.nativeElement.offsetLeft
          break;
      }
      const sliderHTML = this.sliderEl.nativeElement as HTMLElement
      const maxOffset = this.sliderBarEl.nativeElement.offsetWidth - sliderHTML.offsetWidth
      const maxOffsetPercent = 1 - sliderHTML.offsetWidth / this.sliderBarEl.nativeElement.offsetWidth
      let newOffset = separatorLeft - sliderHTML.offsetWidth / 2;
      newOffset = newOffset >= 0 ? newOffset <= maxOffset ? newOffset : maxOffset : 0
      const percentOffset = newOffset / maxOffset * maxOffsetPercent * 100
      this.renderer.setStyle(this.sliderEl?.nativeElement, 'left', `${percentOffset}%`);
      this.checkValue()
    }

  }

  private calcSliderPercentLeftOffset(
    number: number,
    minValue: number,
    leftSeparatorOffset: number,
    rightSeparatorOffset: number,
    rangeSize: number
  ): number {
    const sliderBarWidth = this.sliderBarEl?.nativeElement.offsetWidth
    const sliderLeftOffset = (number - minValue) * (rightSeparatorOffset - leftSeparatorOffset) / rangeSize + leftSeparatorOffset
    return sliderLeftOffset / sliderBarWidth * 100
  }

  sliderMove(event: MouseEvent){
    if(this.sliderBarEl && this.sliderEl)
    {
      event.preventDefault();
      const startX = event?.pageX;
      const sliderHTML = this.sliderEl.nativeElement as HTMLElement
      let isMoving = true;
      const maxOffset = this.sliderBarEl.nativeElement.offsetWidth - sliderHTML.offsetWidth
      const maxOffsetPercent = 1 - sliderHTML.offsetWidth / this.sliderBarEl.nativeElement.offsetWidth
      const currentPosition = maxOffset / maxOffsetPercent * Number(sliderHTML.style.left.replace('%', '')) / 100

      const onMouseMove = (moveEvent: MouseEvent) => {
        if (isMoving) {
          const deltaX = moveEvent?.pageX - startX;
          let newOffset = currentPosition + deltaX;
          newOffset = newOffset >= 0 ? newOffset <= maxOffset ? newOffset : maxOffset : 0
          const percentOffset = newOffset / maxOffset * maxOffsetPercent * 100
          this.renderer.setStyle(this.sliderEl?.nativeElement, 'left', `${percentOffset}%`);
          this.checkValue()
        }
      };

      const onMouseUp = () => {
        isMoving = false;
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    }
  }

  checkValue(){
    const sliderLeft = this.sliderEl?.nativeElement.offsetLeft
    const separator10Left = this.sliderSeparator10El?.nativeElement.offsetLeft - this.sliderEl?.nativeElement.offsetWidth / 2
    const separator100Left = this.sliderSeparator100El?.nativeElement.offsetLeft - this.sliderEl?.nativeElement.offsetWidth / 2
    const separator200Left = this.sliderSeparator200El?.nativeElement.offsetLeft - this.sliderEl?.nativeElement.offsetWidth / 2
    const separator300Left = this.sliderSeparator300El?.nativeElement.offsetLeft - this.sliderEl?.nativeElement.offsetWidth / 2
    const separator400Left = this.sliderSeparator400El?.nativeElement.offsetLeft - this.sliderEl?.nativeElement.offsetWidth / 2
    const rightBorder = this.sliderBarEl?.nativeElement.offsetWidth - this.sliderEl?.nativeElement.offsetWidth

    if(sliderLeft <= separator10Left){
      this.sliderValue = Math.round(sliderLeft / separator10Left * 9) + 1
    }
    if(sliderLeft > separator10Left && sliderLeft <= separator100Left){
      this.sliderValue = Math.round((sliderLeft - separator10Left) / (separator100Left - separator10Left) * 90) + 10
    }
    if(sliderLeft > separator100Left && sliderLeft <= separator200Left){
      this.sliderValue = Math.round((sliderLeft - separator100Left) / (separator200Left - separator100Left) * 100) + 100
    }
    if(sliderLeft > separator200Left && sliderLeft <= separator300Left){
      this.sliderValue = Math.round((sliderLeft - separator200Left) / (separator300Left - separator200Left) * 100) + 200
    }
    if(sliderLeft > separator300Left && sliderLeft <= separator400Left){
      this.sliderValue = Math.round((sliderLeft - separator300Left) / (separator400Left - separator300Left) * 100) + 300
    }
    if(sliderLeft > separator400Left && sliderLeft <= rightBorder){
      this.sliderValue = Math.round((sliderLeft - separator400Left) / (rightBorder - separator400Left) * 600) + 400
    }

    this.calcPrices()
  }

  calcPrices(){
    this.priceId = this.sliderValue <= 10 ? '0-10'
      : this.sliderValue <= 100 && this.sliderValue > 10 ? '10-100'
      : this.sliderValue <= 200 && this.sliderValue > 100 ? '100-200'
      : this.sliderValue <= 300 && this.sliderValue > 200 ? '200-300'
      : this.sliderValue <= 400 && this.sliderValue > 300 ? '300-400'
      : this.sliderValue <= 1000 && this.sliderValue > 400 ? '400-1000'
      : '0-10'

    switch(this.subscriptionType)
    {
      case 'year':
        this.onePersonPrice = SettingsSubscriptionConstants.SUBSCRIPTION_YEAR_PRICES.get(this.priceId)!
        break
      case 'month':
        this.onePersonPrice = SettingsSubscriptionConstants.SUBSCRIPTION_MONTH_PRICES.get(this.priceId)!
        break
    }

    this.subscriptionPrice = this.onePersonPrice * this.sliderValue
  }

  changeSubscriptionType(type: 'year' | 'month'){
    this.subscriptionType = type
    this.calcPrices()
  }
}
