import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-core-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  @Input() className: string | string[] = [];
  @Input() open = false;
  @Input() isLoading = false;
  @Input() paddingType: 'base' | 'none' = 'base'

  @Output() public onClose = new EventEmitter<void>();

  onCloseModal(event: MouseEvent) {
    if (this.onClose && event.target === event.currentTarget) {
      this.onClose.emit();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const { open } = changes;
    if (open) {
      const currentOpen = (open.currentValue ?? false) as boolean;
      if (currentOpen) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    }
  }
}
