import { CompetenciesAndSkillsDto } from '../../../@data/dto/competencies-and-skills.dto';
import { CompetenciesAndSkillsError } from './competencies-and-skills-state';

export type CompetenciesAndSkillsResultAction =
  | ChangeSearchResultAction
  | UpdateCompetenciesAndSkillsResultAction
  | ToggleSelectedEntityResultAction
  | ChangeLoadingStatusResultAction
  | ChangeErrorStatusResultAction
  | OpenCompetencyResultAction
  | CloseCompetencyResultAction
  | OpenFolderResultAction
  | CloseFolderResultAction;

export enum CompetenciesAndSkillsResultActionTypes {
  UPDATE_COMPETENCIES_AND_SKILLS,
  TOGGLE_SELECTED_ENTITY,
  CHANGE_SEARCH,
  CHANGE_LOADING_STATUS,
  CHANGE_ERROR_STATUS,
  OPEN_FOLDER,
  CLOSE_FOLDER,
  OPEN_COMPETENCY,
  CLOSE_COMPETENCY,
}

export interface OpenFolderResultAction {
  readonly type: CompetenciesAndSkillsResultActionTypes.OPEN_FOLDER;
  readonly folder: string;
}

export interface CloseFolderResultAction {
  readonly type: CompetenciesAndSkillsResultActionTypes.CLOSE_FOLDER;
  readonly folder: string;
}

export interface OpenCompetencyResultAction {
  readonly type: CompetenciesAndSkillsResultActionTypes.OPEN_COMPETENCY;
  readonly competency: string;
}

export interface CloseCompetencyResultAction {
  readonly type: CompetenciesAndSkillsResultActionTypes.CLOSE_COMPETENCY;
  readonly competency: string;
}

export interface ChangeSearchResultAction {
  readonly type: CompetenciesAndSkillsResultActionTypes.CHANGE_SEARCH;
  readonly search: string;
}

export interface UpdateCompetenciesAndSkillsResultAction {
  readonly type: CompetenciesAndSkillsResultActionTypes.UPDATE_COMPETENCIES_AND_SKILLS;
  readonly competenciesAndSkills: CompetenciesAndSkillsDto;
}

export interface ToggleSelectedEntityResultAction {
  readonly type: CompetenciesAndSkillsResultActionTypes.TOGGLE_SELECTED_ENTITY;
  readonly selectedEntity: string | null;
  readonly selected: boolean;
}

export interface ChangeLoadingStatusResultAction {
  readonly type: CompetenciesAndSkillsResultActionTypes.CHANGE_LOADING_STATUS;
  readonly isLoading: boolean;
}

export interface ChangeErrorStatusResultAction {
  readonly type: CompetenciesAndSkillsResultActionTypes.CHANGE_ERROR_STATUS;
  readonly error: CompetenciesAndSkillsError | null;
}
