<div class="flex flex-row gap-2">
  <app-core-input-field
    placeholder="Введите название уровня"
    class="flex-grow"
    variant="standard"
    [value]="level.name"
    [defaultValue]="level.name"
    [error]="level.nameError"
    [showError]="false"
    (onChange)="this.onChangeName.emit($event)"
  />
  <app-core-input-field
    placeholder="0"
    [hidePlaceholderOnFocus]="true"
    className="w-[70px] text-center"
    type="number"
    textAlign="center"
    variant="standard"
    [value]="level.points ? level.points.toString() : ''"
    [defaultValue]="level.points ? level.points.toString() : ''"
    [error]="level.pointsError"
    [showError]="false"
    (onChange)="this.onChangeValue.emit($event)"
  />
  <button
    class="w-[40px] h-[40px] flex items-center justify-center text-tt-icon-default"
    (click)="onRemoveLevel($event)"
  >
    <svg-x-mark />
  </button>
</div>
