import { Component } from '@angular/core';
import { Store } from '../../../../../../core/mvi/store';
import { SectionState, NavigationItem } from '../state/section-state';
import { SectionExecutor } from '../state/section-executor';
import { SectionAction, SectionActionTypes } from '../state/section-action';
import { SectionResultAction } from '../state/section-result-action';
import { SectionReducer } from '../state/section-reducer';
import { Constants } from '../common/constants';
import { SectionService } from '../../domain/section-service';
import { SectionServiceImpl } from '../../data/section-service-impl';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['../common/section.component.scss'],
  providers: [
    SectionExecutor,
    SectionReducer,
    SectionState,
    {
      provide: SectionService,
      useClass: SectionServiceImpl,
    },
  ],
})
export class SectionComponent extends Store<
  SectionState,
  SectionExecutor,
  SectionAction,
  SectionResultAction
> {
  constructor(
    state: SectionState,
    executor: SectionExecutor,
    reducer: SectionReducer,
  ) {
    super(state, executor, reducer);
  }

  protected readonly document = document;
  protected readonly SectionActionTypes = SectionActionTypes;
  protected readonly Constants = Constants;
  protected readonly NavigationItem = NavigationItem;
}
