import { SectionContentService } from '../domain/section-content-service';
import { BehaviorSubject, catchError, Observable, of } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { getDocumentsJson } from './json/section-content-response-json';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DocumentItemEntity } from '../domain/document-item-entity';
import { errorToResponseStatus, ResponseStatus } from "../../../../../core/utils/response-status";

@Injectable()
export class SectionContentServiceImpl implements SectionContentService {
  private mutableSection: BehaviorSubject<Array<DocumentItemEntity>> =
    new BehaviorSubject<Array<DocumentItemEntity>>([]);

  documents: Observable<Array<DocumentItemEntity>> =
    this.mutableSection.asObservable();

  constructor(private http: HttpClient) {}

  fetchDocuments(sectionId: number) {
    this.http
      .get<getDocumentsJson>(
        `${environment.apiUrl}/documentation/section/${sectionId}/documentsList`,
      )
      .pipe(
        map((documentsResponse) => {
          const listDocuments: Array<DocumentItemEntity> =
            documentsResponse.documents.map((document) => {
              return new DocumentItemEntity(
                document.id,
                document.title,
                document.isFavorite,
                document.editedAt
                  ? document.editedAt.toString()
                  : document.createdAt.toString(),
                document.isDraft
              );
            });
          return listDocuments;
        }),
      )
      .subscribe((listDocuments) => {
        this.mutableSection.next(listDocuments);
      });
  }

  getDocuments(): Observable<Array<DocumentItemEntity>> {
    return this.documents;
  }

  createDocument(
    sectionId: number,
    title: string,
    parentDocumentId: string | null,
  ): Observable<string> {
    const body = {
      title: title,
      parentDocumentId: parentDocumentId,
      sectionId: sectionId,
    };

    return this.http
      .post<string>(`${environment.apiUrl}/documentation/document/create`, body)
      .pipe(
        map((documentId) => {
          return documentId;
        }),
      );
  }

  deleteDocument(id: string): Observable<null> {
    return this.http.delete<null>(
      `${environment.apiUrl}/documentation/document/${id}`,
    );
  }

  updateDocumentFavorite(id: string, isFavorite: boolean): Observable<ResponseStatus> {
    const body = {
      documentId: id,
      isFavorite: isFavorite,
    };

    return this.http.post<null>(
      `${environment.apiUrl}/documentation/document/updateFavorite`,
      body,
    ).pipe(
      map(() => {
        return ResponseStatus.SUCCESS;
      }),
      catchError((err) => {
        return of(errorToResponseStatus(err));
      }),
    );;
  }
}
