import { Elements, useYooptaEditor, useYooptaPluginOptions } from '@teamtells/editor';
import { ImagePluginOptions } from "../types/image-plugin-options";
import { ImageElementProps } from "@teamtells/image";
import { onImageUpload } from "../utils/on-image-upload";
import { useInjected } from "@bubblydoo/angular-react";
import { Editor2ServiceImpl } from "../../../data/editor-service-impl";

type Props = {
  onClose: () => void;
  blockId: string;
  accept?: string;
  onSetLoading: (_s: boolean) => void;
};

const FileUploader = ({ accept = 'image/*', onClose, blockId, onSetLoading }: Props) => {
  const options = useYooptaPluginOptions<ImagePluginOptions>('Image');
  const editor = useYooptaEditor();
  const editorService = useInjected(Editor2ServiceImpl)

  const upload = async (file: File) => {
    onClose();
    onSetLoading(true);

    try {
      const data = await onImageUpload(file, editorService);
      const defaultImageProps = editor.plugins["Image"].elements["image"].props as ImageElementProps;

      Elements.updateElement<'image', ImageElementProps>(editor, blockId, {
        type: 'image',
        props: {
          src: data.src,
          alt: data.alt,
          sizes: data.sizes || defaultImageProps.sizes,
          bgColor: data.bgColor || defaultImageProps.bgColor,
          fit: data.fit || defaultImageProps.fit || 'fill',
        },
      });
    } catch (error) {
    } finally {
      onSetLoading(false);
    }
  };

  const onChange = (e: any) => {
    const file = e.target.files[0];
    if (file) upload(file);
  };

  return (
    <div className="user-select-none transition-bg duration-20 ease-in white-space-nowrap rounded-[4px] h-[32px] px-[12px] border border-solid border-[rgba(55,53,47,0.16)] w-full cursor-pointer">
      <label
        htmlFor="image-uploader"
        className="text-[14px] leading-[1.2] font-medium cursor-pointer w-full flex items-center justify-center h-full"
      >
        <input
          type="file"
          id="image-uploader"
          className="absolute left-0 top-0 invisible"
          accept={options?.accept || accept}
          onChange={onChange}
          multiple={false}
        />
        Загрузить изображение
      </label>
    </div>
  );
};

export { FileUploader };
