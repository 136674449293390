import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import {
  HighlightedPartsStr
} from "../../../../../../../core/components/text-with-highlighting/text-with-highlighting.component";
import { ruNumWord } from "../../../../../../../core/utils/ru-num-word";

@Component({
  selector: 'space-item',
  templateUrl: './space-item.component.html',
  styleUrls: ['../../common/spaces.component.scss']
})
export class SpaceItemComponent implements OnChanges{
  @Input() space: SpaceSummeryEntity = {
    id: '-1',
    name: '',
    sectionsCount: 10,
    documentsCount: 10,
    nameParts: []
  }

  documents: string = ''
  sections: string = ''

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['space']){
      this.documents = ruNumWord(this.space.documentsCount, documentsStr)
      this.sections = ruNumWord(this.space.sectionsCount, sectionsStr)
    }
  }
}

const documentsStr = ['документ', 'документа', 'документов']
const sectionsStr = ['раздел', 'раздела', 'разделов']

export interface SpaceSummeryEntity{
  id: string,
  name: string,
  sectionsCount: number,
  documentsCount: number,
  nameParts: HighlightedPartsStr[]
}
