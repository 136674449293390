import { Observable } from 'rxjs';
import { DefaultCompanyInfoEntity } from './model/default-company-info-entity';
import { RegistrationResult } from './model/registration-result';
import { RegistrationEntity } from './model/registration-entity';

export abstract class RegistrationService {
  abstract getDefaultCompanyInfo(): DefaultCompanyInfoEntity;

  abstract registerCompany(
    registration: RegistrationEntity,
  ): Observable<RegistrationResult>;
}
