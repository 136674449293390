import { Injectable } from '@angular/core';
import { YooptaEditorComponent } from "../../../../../core/components/yoopta-editor/yoopta-editor.component";

@Injectable()
export class DocumentState {
  readonly id: string = ''
  readonly longreadId: string = ''
  readonly knowledgeBaseID: string = ''
  readonly sessionId: string = ''
  readonly pingIntervalId: number = -1

  readonly title: string = '';
  readonly initTitle: string = ''

  readonly saveModalVisible: boolean = false
  readonly isLoadingSaveModal: boolean = false

  readonly backModalVisible: boolean = false
  readonly isLoadingBackModal: boolean = false

  readonly afkModalVisible: boolean = false

  readonly alreadyEditModalVisible: boolean = false

  readonly editorComponent?: YooptaEditorComponent
  readonly isLoading: boolean = false
}

