import { Injectable } from '@angular/core';
import { LevelDto } from '../../../@data/dto/level.dto';
import { RatingScaleDto } from '../../../@data/dto/rating-scale.dto';

@Injectable()
export class CreateSkillState {
  readonly name: string = '';
  readonly nameError: string | null = null;
  readonly description: string = '';
  readonly currentStep: 1 | 2 = 1;
  readonly selectedRatingScale: RatingScaleEntity | null = null;
  readonly selectedRatingScaleError: string | null = null;
  readonly ratingScales: RatingScaleEntity[] = [];
  readonly ratingScalesError: string | null = null;
  readonly ratingScalesIsLoading: boolean = true;
  readonly isOpen: boolean = false;
  readonly isLoading: boolean = false;
  readonly error: CreateSkillError | null = null;
}

export interface LevelEntity extends LevelDto {
  description: string;
  descriptionError: string | null;
}

export interface RatingScaleEntity extends Omit<RatingScaleDto, 'levels'> {
  levels: LevelEntity[];
}

export enum CreateSkillError {
  INCORRECT_CREDENTIALS,
  CONNECTION_ERROR,
}
