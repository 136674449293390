<div class="flex flex-col"
  [ngClass]="{
    'absolute w-screen h-screen left-0 top-0 z-[100] bg-white': state.isFullScreen,
    'relative content-height w-full': !state.isFullScreen
  }"
>
  <div class="h-[16px] shrink-0"></div>

  <div class="absolute flex gap-[16px] right-[16px] top-[16px]">
    <button class="
      w-[40px] h-[40px] p-[8px]
      rounded-[24px]
      bg-tt-bg-secondary"
      (click)="performAction({
        type: SectionEditorActionTypes.MOVE_TO_EDIT_DOCUMENT
      })"
    >
      <svg-edit-pencil class="min-h-[24px] min-w-[24px] flex justify-center items-center text-tt-primary"></svg-edit-pencil>
    </button>

    <button class="
      w-[40px] h-[40px] p-[8px]
      rounded-[24px]
      bg-tt-bg-secondary"
      *ngIf="!state.isFullScreen"
      (click)="performAction({
        type: SectionEditorActionTypes.SWITCH_FULL_SCREEN,
        value: true
      })"
    >
      <svg-to-full-screen class="min-h-[24px] min-w-[24px] text-tt-primary"/>
    </button>

    <button
      class="
      w-[40px] h-[40px] p-[8px]
      rounded-[24px]
      bg-tt-bg-secondary"
      *ngIf="state.isFullScreen"
      (click)="performAction({
        type: SectionEditorActionTypes.SWITCH_FULL_SCREEN,
        value: false
      })"
    >
      <svg-to-normal-screen class="min-h-[24px] min-w-[24px] text-tt-primary"/>
    </button>

  </div>
</div>

<section-editor-already-edited-modal
  *ngIf="state.isVisibleAlreadyOpenModal"
  (closeClicked)="performAction({
    type: SectionEditorActionTypes.CLOSE_ALREADY_OPEN_MODAL
  })"
  [documentTitle]="state.documentTitle"
  [userName]="'Воронин Дмитрий Евгеньвич'"
/>
