import { Elements, useYooptaEditor } from '@teamtells/editor';
import { ChangeEvent, useState } from 'react';
import { ImageElementProps } from "@teamtells/image";
import { onImageUrlUpload } from "../utils/on-image-url-upload";
import { useInjected } from "@bubblydoo/angular-react";
import { Editor2ServiceImpl } from "../../../data/editor-service-impl";

const EmbedUploader = ({ blockId, onClose, onSetLoading }: any) => {
  const editor = useYooptaEditor();
  const [value, setValue] = useState('');
  const editorService = useInjected(Editor2ServiceImpl)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

  const embed = async () => {
    if (value.length === 0) return;
    onSetLoading(true)
    onClose();

    try {
      const data = await onImageUrlUpload(value, editorService);
      const defaultImageProps = editor.plugins["Image"].elements["image"].props as ImageElementProps;

      Elements.updateElement<'image', ImageElementProps>(editor, blockId, {
        type: 'image',
        props: {
          src: data.src,
          alt: data.alt,
          sizes: data.sizes || defaultImageProps.sizes,
          bgColor: data.bgColor || defaultImageProps.bgColor,
          fit: data.fit || defaultImageProps.fit || 'fill',
        },
      });
    } catch (error) {
    } finally {
      onSetLoading(false);
    }

  };

  const isEmpty = value.length === 0;

  return (
    <div className="yoopta-uploader-embed-box user-select-none">
      <input
        type="text"
        placeholder="Вставьте ссылку на изображение"
        value={value}
        className="yoopta-uploader-embed-input"
        onChange={onChange}
      />
      <button
        type="button"
        className="yoopta-button user-select-none yoopta-uploader-embed-button"
        disabled={isEmpty}
        onClick={embed}
      >
        Добавить
      </button>
    </div>
  );
};

export { EmbedUploader };
