<div class="flex flex-col gap-[16px]">
  <app-core-input-field
    label="Email"
    variant="standard"
    placeholder=""
    error="{{ emailError }}"
    [defaultValue]="employeeContactsData.email"
    [required]="true"
    (onChange)="emailChange($event)"
  />

  <app-core-input-field
    label="Номер телефона"
    variant="standard"
    placeholder=""
    error="{{ phoneNumberError }}"
    prefix="+"
    mask="0 (000) 000-00-00||00 (000) 000-00-00||000 (000) 000-00-00"
    [defaultValue]="employeeContactsData.phoneNumber"
    [required]="true"
    (onChange)="phoneNumberChange($event)"
  />
</div>
