import { SectionSummaryEntity, SectionPrivacy } from './section-summary-entity';
import { Observable } from 'rxjs';
import { CreateSectionResponse } from './create-section-response';
import { ResponseStatus } from '../../../../../core/utils/response-status';

export abstract class SectionsService {
  abstract sections: Observable<Array<SectionSummaryEntity>>;

  abstract fetchSections(): void;

  abstract createSection(
    section: SectionSummaryEntity,
    sectionVisibility: SectionPrivacy,
  ): Observable<CreateSectionResponse>;

  abstract updateIsFavoriteSection(
    sectionId: number,
    isFavorite: boolean,
  ): Observable<ResponseStatus>;

  abstract deleteSection(sectionId: number): void;

  abstract getIcons(): Observable<string[]>;
}
