import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-core-icon-button',
  templateUrl: './icon-button.component.html',
})
export class IconButtonComponent {
  @Input() className: string | string[] = [];
  @Input() disabled = false;

  @Output() onClick = new EventEmitter<MouseEvent>();

  onClickValue(event: MouseEvent) {
    this.onClick.emit(event);
  }
}
