import { Component } from '@angular/core';
import {
  PageLayoutFolderRoutes,
  PageLayoutRoute,
  isPageLayoutFolderRoutes,
} from 'src/app/core/components/page-layout/page-layout.component';

@Component({
  selector: 'app-grade',
  templateUrl: './grade.component.html',
})
export class GradeComponent {
  onClickFolderRoutes(id: string) {
    this.routes = this.routes.map((route) => {
      if (isPageLayoutFolderRoutes(route) && route.id === id) {
        return {
          ...route,
          opened: !route.opened,
        };
      }

      return route;
    });
  }

  routes: (PageLayoutRoute | PageLayoutFolderRoutes)[] = [
    {
      title: 'Главная',
      path: '/grade',
      exact: true,
    },
    {
      id: 'control',
      title: 'Управление',
      opened: true,
      children: [
        {
          title: 'Оценочные сессии',
          path: '/grade/sessions',
          exact: false,
        },
        {
          title: 'Профили компетенций',
          path: '/grade/profiles',
          exact: false,
        },
        {
          title: 'Компетенции и навыки',
          path: '/grade/skills',
          exact: false,
        },
        {
          title: 'Оценочные шкалы',
          path: '/grade/scales',
          exact: false,
        },
      ],
    },
    {
      title: 'Настройки доступа',
      path: '/grade/settings',
      exact: false,
    },
    {
      title: 'Журнал действий',
      path: '/grade/logs',
      exact: false,
    },
  ];
}
