<div [ngClass]="className">
  <button
    (click)="onClickValue($event)"
    [disabled]="disabled"
    class="relative overflow-hidden flex items-center justify-center transition-colors group size-[48px] outline-tt-informer-info-icon"
    [ngClass]="{
      'cursor-pointer': !disabled,
    }"
  >
    <div
      class="flex justify-center items-center h-[40px] w-[40px] rounded-[20px] text-tt-icon-button-icon-default
        group-hover:bg-tt-icon-button-background-hover group-hover:text-tt-icon-button-icon-hover
        group-active:bg-tt-icon-button-background-focus group-active:text-tt-icon-button-icon-hover
      "
    >
      <ng-content />
    </div>

  </button>
</div>
