import { Executor } from "src/app/core/mvi/store";
import { CreateRoleModalResultAction, CreateRoleModalResultActionTypes } from "./create-role-modal-result-action";
import { Inject, Injectable } from "@angular/core";
import {
  CreateRoleModalClueTexts,
  CreateRoleModalClueTitles,
  CreateRoleModalNavItems
} from "../common/createRoleConstants";
import { RoleService } from "src/app/core/services/role-service/role-service";
import { CreateRoleModalState, StepViewState } from "./create-role-modal-state";
import { CreateRoleModalAction, CreateRoleModalActionTypes } from "./create-role-modal-action";
import { Validator } from "src/app/core/validators/validator";
import { EmployeesRolesExecutor } from "../../../state/employees-roles-executor";
import { EmployeesRolesActionTypes } from "../../../state/employees-roles-action";
import { ToastsService } from "../../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../../../core/components/toast-alert/toast-alert.component";

@Injectable()
export class CreateRoleModalExecutor extends Executor<
  CreateRoleModalState,
  CreateRoleModalAction,
  CreateRoleModalResultAction
> {
  constructor(
    private roleService: RoleService,
    private rolesExecutor: EmployeesRolesExecutor,
    private toastsService: ToastsService,
    @Inject('NewRoleNameValidator') private roleNameValidator: Validator,
    @Inject('NewRoleDescriptionValidator') private roleDescriptionValidator: Validator
  ) {
    super();
  }

  execute(action: CreateRoleModalAction) {
    switch (action.type) {
      case CreateRoleModalActionTypes.CHANGE_NAV_ITEM:
        this.handleChangeNavItem(action.id, action.isCheckCurrent)
        break;
      case CreateRoleModalActionTypes.CHANGE_ROLE_NAME:
        const roleNameError = this.roleNameValidator.validate(action.value)
        this.reduce({
          type: CreateRoleModalResultActionTypes.CHANGE_ROLE_NAME,
          value: action.value,
          error: roleNameError,
          isValid: this.getState().roleDescriptionError == null && roleNameError == null
        })
        break;
      case CreateRoleModalActionTypes.LOAD_ROLE:
        this.roleService.getRole(action.id).subscribe((role)=>{
          this.reduce({
            type: CreateRoleModalResultActionTypes.LOAD_ROLE,
            id: action.id,
            name: role.name,
            description: role.description,
            permissions: role.permissions,
          })
        })
        break;
      case CreateRoleModalActionTypes.CHANGE_ROLE_DESCRIPTION:
        const roleErrorDescription = this.roleDescriptionValidator.validate(action.value)
        this.reduce({
          type: CreateRoleModalResultActionTypes.CHANGE_ROLE_DESCRIPTION,
          value: action.value,
          error: roleErrorDescription,
          isValid: this.getState().roleNameError == null && roleErrorDescription == null
        })
        break;
      case CreateRoleModalActionTypes.SAVE_ROLE:
        this.handleSaveRole()
        break;

      case CreateRoleModalActionTypes.INIT:
        this.reduce({
          type: CreateRoleModalResultActionTypes.INIT,
        })
        break;
    }
  }

  private handleSaveRole(){
    if(this.checkMainValid())
    {
      const createRole = {
        name: this.getState().roleName.trim().split(' ').filter((str) => str.length > 0).join(' '),
        description: this.getState().roleDescription.trim().split(' ').filter((str) => str.length > 0).join(' '),
        permissions: this.getState().permissions
      }
      if(this.getState().loadedRoleId == '')
      {
        this.reduce({
          type: CreateRoleModalResultActionTypes.CHANGE_IS_CREATING,
          value: true
        })
        this.roleService.createRole(createRole).subscribe(()=>{
          this.toastsService.createToast({
            title: 'Роль создана',
            description: '',
            state: ToastState.SUCCESS
          })
          this.rolesExecutor.execute({
            type: EmployeesRolesActionTypes.UPDATE_ROLES
          })
          this.reduce({
            type: CreateRoleModalResultActionTypes.CHANGE_IS_CREATING,
            value: false
          })
          this.rolesExecutor.execute({
            type: EmployeesRolesActionTypes.CHANGE_CREATE_ROLE_MODAL_VISIBLE,
            value: false
          })
        })
      }
      else {
        this.roleService.updateRole(
          this.getState().loadedRoleId,
          createRole
        ).subscribe(()=>{
          this.rolesExecutor.execute({
            type: EmployeesRolesActionTypes.UPDATE_ROLES
          })
          this.rolesExecutor.execute({
            type: EmployeesRolesActionTypes.CHANGE_CREATE_ROLE_MODAL_VISIBLE,
            value: false
          })
        })
      }
    }
  }

  private handleChangeNavItem(id: string, isCheckCurrent: boolean){
    let result: StepViewState = this.getState().stepViewState
      switch (id){
        case CreateRoleModalNavItems.MAIN:
          result = { ...result,
            selectedNavItem: CreateRoleModalNavItems.MAIN,
            stepNumber: 1,
            clueText: CreateRoleModalClueTexts.MAIN,
            clueTitle: CreateRoleModalClueTitles.MAIN,
          }
          break;
        case CreateRoleModalNavItems.ACCESSES:
          if(!isCheckCurrent || this.checkMainValid()){
            result = { ...result,
              selectedNavItem: CreateRoleModalNavItems.ACCESSES,
              stepNumber: 2,
              clueText: CreateRoleModalClueTexts.ACCESSES,
              clueTitle: CreateRoleModalClueTitles.ACCESSES,
            }
          }
          else{
            result = { ...result,
              isShowErrorMain: true
            }
          }
          break;
        // case CreateRoleModalNavItems.USERS:
        //   if(true){
        //     result = { ...result,
        //       selectedNavItem: CreateRoleModalNavItems.USERS,
        //       stepNumber: 3,
        //       clueText: CreateRoleModalClueTexts.USERS,
        //       clueTitle: CreateRoleModalClueTitles.USERS,
        //     }
        //   }
        //   else{
        //     result = { ...result,
        //       isShowErrorContacts: true
        //     }
        //   }
        //   break;
      }

    this.reduce({
      type: CreateRoleModalResultActionTypes.CHANGE_NAV_ITEM,
      stepState: result
    })
  }

  private checkMainValid(): boolean{
    this.reduce({
      type: CreateRoleModalResultActionTypes.CHECK_NAME_DESCRIPTION_VALID,
      errorName: this.roleNameValidator.validate(this.getState().roleName),
      errorDescription: this.roleDescriptionValidator.validate(this.getState().roleDescription)
    })
    return this.isValid()
  }

  private isValid(): boolean{
    return this.getState().roleNameError == null && this.getState().roleDescriptionError == null
  }
}
