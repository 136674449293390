export function stringToPhoneNumber(str: string): string {
  let phoneNumber = str.replace(/\D/g, '')

  if (phoneNumber.length >= 11 && phoneNumber.length <= 13) {
    phoneNumber = '+' + phoneNumber.slice(0, phoneNumber.length - 10) +
      ' (' + phoneNumber.slice(phoneNumber.length - 10, phoneNumber.length - 7) +
      ') ' + phoneNumber.slice(phoneNumber.length -7, phoneNumber.length -4) +
      '-' + phoneNumber.slice(phoneNumber.length -4, phoneNumber.length -2) +
      '-' + phoneNumber.slice(phoneNumber.length -2);

    return phoneNumber
  }

  return str
}
