import {Inject, Injectable} from '@angular/core';
import {Executor, Reducer} from 'src/app/core/mvi/store';
import {Validator} from 'src/app/core/validators/validator';
import {RegistrationAction, RegistrationActionTypes,} from './registration-action';
import {RegistrationResultAction, RegistrationResultActionTypes,} from './registration-result-action';
import {RegistrationState} from './registration-state';
import {RegistrationService} from '../../domain/registration-service';
import {RegistrationResult} from '../../domain/model/registration-result';
import { DefaultCompanyInfoEntity } from "../../domain/model/default-company-info-entity";

@Injectable()
export class RegistrationExecutor extends Executor<
  RegistrationState,
  RegistrationAction,
  RegistrationResultAction
> {

  private companyInfo: DefaultCompanyInfoEntity

  constructor(
    @Inject('RegistrationEmailValidator') private emailValidator: Validator,
    @Inject('RegistrationNameValidator') private nameValidator: Validator,
    @Inject('RegistrationSurnameValidator') private surnameValidator: Validator,
    @Inject('RegistrationPatronymicValidator') private patronymicValidator: Validator,
    @Inject('RegistrationPhoneNumberValidator')
    private phoneNumberValidator: Validator,
    private service: RegistrationService,
  ) {
    super();
    this.companyInfo = this.service.getDefaultCompanyInfo();
  }

  override init(
    reducer: Reducer<RegistrationState, RegistrationResultAction>,
    getState: () => RegistrationState,
    onReduced: (state: RegistrationState) => void,
  ) {
    super.init(reducer, getState, onReduced);

    this.reduce({
      type: RegistrationResultActionTypes.UPDATE_REGISTRATION_INFO,
      info: this.companyInfo,
    });
  }

  execute(action: RegistrationAction) {
    switch (action.type) {
      case RegistrationActionTypes.CHANGE_NAME:
        this.reduce({
          type: RegistrationResultActionTypes.CHANGE_NAME,
          name: action.name,
        });
        break;

      case RegistrationActionTypes.CHANGE_SURNAME:
        this.reduce({
          type: RegistrationResultActionTypes.CHANGE_SURNAME,
          surname: action.surname,
        });
        break;

      case RegistrationActionTypes.CHANGE_PATRONYMIC:
        this.reduce({
          type: RegistrationResultActionTypes.CHANGE_PATRONYMIC,
          patronymic: action.patronymic,
        });
        break;

      case RegistrationActionTypes.CHANGE_EMAIL:
        this.reduce({
          type: RegistrationResultActionTypes.CHANGE_EMAIL,
          email: action.email,
        });
        break;

      case RegistrationActionTypes.CHANGE_PHONE_NUMBER:
        this.reduce({
          type: RegistrationResultActionTypes.CHANGE_PHONE_NUMBER,
          phoneNumber: action.phoneNumber,
        });
        break;

      case RegistrationActionTypes.CHANGE_SPECIALIZING:
        this.reduce({
          type: RegistrationResultActionTypes.CHANGE_SPECIALIZING,
          specializing:
            this.getState().specializations.find(
              (s) => s.id === action.specializingId,
            ) ?? null,
        });
        break;

      case RegistrationActionTypes.REMOVE_SPECIALIZING:
        this.reduce({
          type: RegistrationResultActionTypes.REMOVE_SPECIALIZING,
        });
        break;

      case RegistrationActionTypes.CHANGE_STAFF_SIZE:
        this.reduce({
          type: RegistrationResultActionTypes.CHANGE_STAFF_SIZE,
          staffSize:
            this.getState().possibleStaffSize.find(
              (s) => s.id === action.staffSizeId,
            ) ?? null,
        });
        break;

      case RegistrationActionTypes.REMOVE_STAFF_SIZE:
        this.reduce({
          type: RegistrationResultActionTypes.REMOVE_STAFF_SIZE,
        });
        break;

      case RegistrationActionTypes.CREATE:
        this.handleCreate();
        break;
    }
  }

  private handleCreate() {
    const state = this.getState();
    const nameError = this.nameValidator.validate(state.name);
    const surnameError = this.surnameValidator.validate(state.surname)
    const patronymicError = this.patronymicValidator.validate(state.patronymic)
    const phoneNumberError = this.phoneNumberValidator.validate(state.phoneNumber);
    const emailError = this.emailValidator.validate(state.email);
    const staffSizeError = state.selectedStaffSize
      ? null
      : 'Укажите количество сотрудников в компании';
    const specializingError = state.selectedSpecializing
      ? null
      : 'Укажите в какой отрасли работает ваша компания';

    if (
      specializingError != null ||
      staffSizeError != null ||
      nameError != null ||
      phoneNumberError != null ||
      emailError != null ||
      surnameError != null ||
      patronymicError != null
    ) {
      this.reduce({
        type: RegistrationResultActionTypes.VALIDATION_ERROR,
        emailError: emailError != null ? emailError : '',
        phoneNumberError: phoneNumberError != null ? phoneNumberError : '',
        nameError: nameError != null ? nameError : '',
        staffSizeError: staffSizeError != null ? staffSizeError : '',
        specializingError: specializingError != null ? specializingError : '',
        patronymicError: patronymicError != null ? patronymicError : '',
        surnameError: surnameError != null ? surnameError : ''
      });
      return;
    }

    this.reduce({
      type: RegistrationResultActionTypes.CHANGE_STATUS_STATE,
      status: 'create_loading',
    });

    const stuffSizes = this.companyInfo.stuffSizes.find((stuffSize) => stuffSize.id == Number(state.selectedStaffSize!.id));

    this.service
      .registerCompany({
        name: state.name.trim().split(' ').filter((str) => str.length > 0).join(' '),
        surname: state.surname.trim().split(' ').filter((str) => str.length > 0).join(' '),
        patronymic: state.patronymic == '' ? undefined : state.patronymic,
        phone: '+' + state.phoneNumber.replace(/\D/g, ''),
        email: state.email.trim().split(' ').filter((str) => str.length > 0).join(' '),
        stuffSize: stuffSizes ? stuffSizes : {id: 0, min: 0, max: 0},
        specializationId: state.selectedSpecializing?.id!,
      })
      .subscribe((result) => {
        switch (result) {
          case RegistrationResult.SUCCESS:
            this.reduce({
              type: RegistrationResultActionTypes.CHANGE_STATUS_STATE,
              status: 'success',
            });
            break;

          case RegistrationResult.EMAIL_EXISTS_ERROR:
            this.reduce({
              type: RegistrationResultActionTypes.CHANGE_STATUS_STATE,
              status: 'already_exist_error',
            });
            break;

          case RegistrationResult.SERVER_ERROR:
            this.reduce({
              type: RegistrationResultActionTypes.CHANGE_STATUS_STATE,
              status: 'create_error',
            });
            break;
        }
      });
  }
}
