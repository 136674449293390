import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from "@angular/core";

@Component({
  selector: 'app-core-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements AfterViewInit {
  @Input() size: 'm' | 'l' | 'xl' = 'm';
  @Input() className: string | string[] = [];
  @Input() variant: 'solid' | 'soft' = 'solid';
  @Input() type: 'danger' | 'basic' = 'basic'
  @Input() loading = false;
  @Input() disabled = false;

  @Output() onClick = new EventEmitter<MouseEvent>();

  @ViewChild('button') private button?: ElementRef;

  isPressed: boolean = false

  onClickValue(event: MouseEvent) {
    this.onClick.emit(event);
  }

  ngAfterViewInit(): void {
    const buttonEl = this.button?.nativeElement as HTMLElement;
    buttonEl.addEventListener('mousedown', (event: MouseEvent) => {
      event.preventDefault();
      this.isPressed = true;

      const onMouseUp = (event: MouseEvent) => {
        this.isPressed = false;
        buttonEl.blur()
        document.removeEventListener('mouseup', onMouseUp);
      }

      document.addEventListener('mouseup', onMouseUp);
    })
  }
}
