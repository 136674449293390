import { DropdownItem } from "../../../../../core/components/fields/dropdown-field/dropdown-field.component";

export class SettingsMainConstants{
  public static readonly LANGUAGE_DROPDOWNS: DropdownItem[] = [
    {
      id: 'ru',
      name: 'Русский'
    },
    {
      id: 'en',
      name: 'English'
    },
    {
      id: 'cn',
      name: '中国人'
    },
  ]
}
