import { NavTab } from "src/app/core/components/nav-tabs/nav-tabs.component";
import { EmployeesRoutesPath } from "src/app/routes/employees-routes";
import { Tabs } from "../../main/presentation/state/main-state";
import { ActionElement } from "../../../core/components/action-menu/action-menu.component";

export class EmployeesConstants{
  public static EMPLOYEES = 'Моя команда'
  public static NAV_TABS: NavTab[] = [
    {
      id: Tabs.EMPLOYEES,
      url: EmployeesRoutesPath.LIST,
      title: 'Главная',
    },
    {
      id: Tabs.ROLE,
      url: EmployeesRoutesPath.ROLES,
      title: 'Роли',
    },
  ]

  public static EDIT_ACTION_MENU: ActionElement = {
    id: 'edit',
    name: 'Редактировать'
  }

  public static DELETE_ACTION_MENU: ActionElement = {
    id: 'delete',
    name: 'Удалить'
  }

  public static readonly OWNER = 'owner'

  public static readonly BASE_ROLES = new Map<string, BaseRolesData>()
    .set(
        'owner',
        {
            name: 'Владелец',
            description: 'Владелец организации, имеет полный доступ в системе ко всем ресурсам TeamTells'
        },
    ).set(
        'admin',
        {
            name: 'Администратор',
            description: 'Полный доступ в системе ко всем ресурсам TeamTells, включая управление пользователями, правами доступа и оплатой счетов.'
        },
    ).set(
        'viewer', {
            name: 'Зритель',
            description: 'Доступ на чтение ко всем ресурсам TeamTells.'
        },
    ).set(
        'editor', {
            name: 'Редактор',
            description: 'Управляет разделом "База знаний".'
        }
    )
}


export interface BaseRolesData{
  name: string,
  description: string
}
