import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { ComponentsModule } from 'src/app/core/components/components.module';
import { MainComponent } from './presentation/main.component';
import { NavigationSectionComponent } from './presentation/components/navigation-section/navigation-section.component';
import {
  SvgArrow,
  SvgBell,
  SvgBreadCrumbsArrow,
  SvgHeaderArrow,
  SvgPencilHeader, SvgTripleDot,
  SvgXMark
} from "../../core/components/svg-components/svg.components";
import { NavigationElementComponent } from './presentation/components/navigation-element/navigation-element.component';
import { MainHeaderComponent } from './presentation/components/main-header/main-header.component';
import { EmployeeService } from '../employees/data/employee-service';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RemainingSubscriptionModalComponent } from './presentation/components/remaining-subscription-modal/remaining-subscription-modal.component';
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [
    MainComponent,
    NavigationSectionComponent,
    NavigationElementComponent,
    MainHeaderComponent,
    RemainingSubscriptionModalComponent
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    RouterOutlet,
    ComponentsModule,
    RouterLink,
    RouterLinkActive,
    SvgArrow,
    SvgBell,
    SvgHeaderArrow,
    SvgBreadCrumbsArrow,
    SvgXMark,
    SvgPencilHeader,
    SvgTripleDot,
    MatProgressSpinnerModule,
    MatTooltipModule
  ],
  providers: [
    EmployeeService
  ]
})
export class MainModule {}
