<div>
  <div
    class="group/doc-item cursor-pointer flex justify-between rounded-tt-border-radius_m h-10 pr-2 hover:bg-tt-line-unaccented-hover"
    [ngClass]="{
      'bg-tt-line-unaccented-select':
        isActive
    }"
    (click)="onOpenDocumentClicked(document.id, $event)"
  >
    <div class="flex overflow-hidden">
      <div class="flex truncate">
        <div
          class="flex items-center justify-between pl-2 py-2"
          [style.margin-left]="getMarginLeft()"
        >
          <div class="flex gap-[8px] items-center">
            <svg-arrow
              class="document-icon"
              [ngClass]="{ 'rotate-90': isOpen }"
              *ngIf="document.documents.length != 0"
              (click)="onArrowClicked(document.id)"
            ></svg-arrow>

            <svg-dot
              *ngIf="document.documents.length == 0"
              class="document-icon -rotate-180"
            ></svg-dot>

            <div
              class="font-unselected leading-[18px]"
              [ngClass]="{ 'font-selected': isActive }"
            >
              {{ document.title }}
            </div>

            <p class="
                flex items-center
                py-[2px] px-[8px] max-h-[20px]
                rounded-tt-border-radius_s bg-tt-unaccented-separator-color
                text-[12px] leading-[16px]
              "
              *ngIf="document.isDraft"
            >
              Черновик
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="ml-2 my-auto  relative group-hover/doc-item:block"
      [ngClass]="{
        'block': isPopupVisible,
        'hidden': !isPopupVisible
      }"
      #popup
    >
      <svg-triple-dot
        class="document-icon"
        (click)="isPopupVisible = !isPopupVisible"
      ></svg-triple-dot>

      <app-core-action-menu
        class="absolute top-[32px] right-[-8px]"
        *ngIf="isPopupVisible"
        [sections]="POPUP_SECTIONS"
        (elementClicked)="popupAction($event)"
      />
    </div>

  </div>

  <div *ngIf="isOpen">
    <app-document-item
      [level]="level + 1"
      *ngFor="let item of document.documents"
      [document]="item"
      [openDocuments]="openDocuments"
      [idActiveDocument]="idActiveDocument"
      (arrowClicked)="onArrowClicked($event)"
      (createDocumentClicked)="onCreateDocumentClicked($event)"
      (openDocumentClicked)="onOpenDocumentClicked($event)"
    />
  </div>
</div>
