import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { SpaceComponent } from './presentation/space.component';
import {
  SvgPlus,
  SvgHeaderArrow,
  SvgTripleDot,
  SvgArrow,
  SpaceSettingsHome,
  SvgPerson,
  SvgDocText,
  SvgTrash
} from "../../../../core/components/svg-components/svg.components";
import { SectionItemComponent } from './presentation/components/section-item/section-item.component';
import { DocumentItemComponent } from './presentation/components/document-item/document-item.component';
import { SpaceSettingsComponent } from './presentation/components/space-settings/space-settings.component';
import { DocumentHeaderTripleDotComponent } from './presentation/components/document-header-triple-dot/document-header-triple-dot.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DocumentsDragAndDropService } from "./services/documents-drag-and-drop.service";
import { DropBoxToRootComponent } from "./presentation/components/drop-box-to-root/drop-box-to-root.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [
    SpaceComponent,
    SectionItemComponent,
    DocumentItemComponent,
    SpaceSettingsComponent,
    DocumentHeaderTripleDotComponent,
    DropBoxToRootComponent,
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    ComponentsModule,
    SvgPlus,
    SvgHeaderArrow,
    SvgTripleDot,
    SvgArrow,
    SpaceSettingsHome,
    SvgPerson,
    MatProgressSpinnerModule,
    SvgDocText,
    SvgTrash,
    MatTooltipModule
  ],
  providers: [
    DocumentsDragAndDropService
  ]
})
export class SpaceModule {

}
