import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { SvgAddFolder } from 'src/app/core/components/svg-components/svg.components';
import { CompetencyProfilesComponent } from './presentation/view/competency-profiles.component';

@NgModule({
  declarations: [CompetencyProfilesComponent],
  exports: [CompetencyProfilesComponent],
  imports: [CommonModule, ComponentsModule, RouterOutlet, SvgAddFolder],
})
export class CompetencyProfilesModule {}
