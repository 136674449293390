<app-core-settings-container>
  <app-core-settings-nav-container
    [title]="'Фильтры для разделов'"
  >
    <app-core-settings-nav-item
      [isSelected]="state.currentNavItem == SpaceSettingsNavItems.MAIN"
      (onClick)="performAction({
        type: SpaceSettingsActionTypes.CHANGE_NAV_ITEM,
        navItem: SpaceSettingsNavItems.MAIN
      })"
    >
      <svg-space-settings-home/>
      <p>Основное</p>

    </app-core-settings-nav-item>

  </app-core-settings-nav-container>

  <div class="flex flex-col justify-between w-full"
    *ngIf="state.currentNavItem == SpaceSettingsNavItems.MAIN"
  >
    <div>
      <app-core-settings-content-element
        [title]="'Основное'"
      >
        <p>Здесь вы можете управлять основными параметрами пространства, которые видят сотрудники вашей компании</p>
      </app-core-settings-content-element>

      <div class="h-[1px] w-full bg-tt-border-default"></div>

      <app-core-settings-content-element>
        <app-core-input-field
          label="Название базы знаний"
          variant="standard"
          placeholder=""
          error="{{state.spaceTitleError}}"
          [charsCounterMax]="100"
          [defaultValue]="state.spaceTitle"
          [value]="state.newSpaceTitle"
          (onChange)="performAction({
            type: SpaceSettingsActionTypes.CHANGE_SPACE_TITLE,
            value: $event
          })"
        />
      </app-core-settings-content-element>
    </div>

    <app-core-settings-content-element
      *ngIf="state.spaceTitle != state.newSpaceTitle"
    >
      <div class="flex w-full justify-end gap-[16px]">
        <app-core-button
          variant="soft"
          (onClick)="performAction({
          type: SpaceSettingsActionTypes.CANCEL_TITLE
        })"
        >
          Отменить
        </app-core-button>
        <app-core-button
          (onClick)="performAction({
          type: SpaceSettingsActionTypes.SAVE_TITLE
        })"
        >
          Сохранить
        </app-core-button>
      </div>

    </app-core-settings-content-element>
  </div>

</app-core-settings-container>

<div class="mt-[48px] max-w-[935px] w-full h-[1px] bg-tt-border-header-popup-without-alpha"></div>

<app-core-settings-button
  class="mt-[24px] flex justify-center"
  (onClick)="performAction({
    type: SpaceSettingsActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
    value: true
  })"
>
  <svg-trash class="min-h-[24px] min-w-[24px] max-h-[24px] max-w-[24px]"/>
  <p class="text-[14px]">Удалить пространство</p>
</app-core-settings-button>

<app-core-alert-modal
  *ngIf="state.isDeleteAlertVisible"
  [title]="'Удалить пространство'"
  type="warning"
  [description]="'После удаления пространства оно удаляется навсегда. Пространство не может быть востановлено.'"
  [cancelButtonText]="'Отменить'"
  [confirmButtonText]="'Удалить'"
  [isLoading]="state.alertIsLoading"
  (onConfirmClicked)="performAction({
    type: SpaceSettingsActionTypes.DELETE
  })"
  (onCancelClicked)="performAction({
    type: SpaceSettingsActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
    value: false
  })"
>
  <p>Пространство с именем “<span class="font-medium">{{state.spaceTitle}}</span>” будет удалено. Вы уверены, что хотите удалить это пространство?</p>

</app-core-alert-modal>



