import { Injectable } from "@angular/core";

@Injectable()
export class EditorChangesService {
  private timeouts: EditorChangesTimeout[] = [];
  editorChanged = false;

  init(){
    this.editorChanged = false;
    this.timeouts.forEach(timeout => {
      clearTimeout(timeout.id)
    })
    this.timeouts = []
  }

  onChanges(value: boolean) {
    this.editorChanged = value;
    this.timeouts = this.timeouts.map((timeout)=>{
      clearTimeout(timeout.id)
      const id = setTimeout(timeout.fn, timeout.time)
      return {...timeout, id: id}
    })
  }

  setTimer(fiveMinFn: () => void, tenMinFn: () => void){
    this.timeouts = []
    this.timeouts.push({
      id: setTimeout(fiveMinFn, 300000),
      fn: fiveMinFn,
      time: 300000
    })
    this.timeouts.push({
      id: setTimeout(tenMinFn, 600000),
      fn: tenMinFn,
      time: 600000
    })
  }
}

export type EditorChangesTimeout = {
  id: number,
  fn: () => void,
  time: number
}
