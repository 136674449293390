<div
  class="group flex justify-between h-[56px] px-4 py-2 border-tt-separator-color border-t-[1px] hover:bg-tt-hover-color"
>
  <div class="flex py-2 truncate items-center gap-[12px]">
    <svg-document class="min-w-6 min-h-6 fill-tt-icon-default" />
    <p class="text-tt-text-default font-normal text-tt-font-size_l">
      {{ document.title }}
    </p>
    <p class="
        flex items-center
        py-[2px] px-[8px] max-h-[20px]
        rounded-tt-border-radius_s bg-tt-unaccented-separator-color
        text-[12px] leading-[16px]
      "
       *ngIf="document.isDraft"
    >
      Черновик
    </p>
  </div>
  <div class="flex ml-[16px]">
    <p class="my-auto text-tt-font-size_s text-nowrap text-tt-icon-default">
      Обновлено {{ document.dateOfUpdate }}
    </p>
    <div #buttons_block class="ml-12 w-[208px] flex justify-end gap-3 relative">
      <svg-pencil
        (click)="editClicked.emit()"
        class="hidden fill-tt-icon-default cursor-pointer p-[8px] w-10 h-10 group-hover:block"
      />
      <svg-link
        (click)="linkClicked.emit()"
        class="hidden fill-tt-icon-default p-[8px] cursor-pointer w-10 h-10 group-hover:block"
      />
      <div class="cursor-pointer w-10 h-10" (click)="markClicked.emit()">
        <svg-bookmark-fill *ngIf="document.isFavorite" />
        <svg-bookmark
          *ngIf="!document.isFavorite"
          class="fill-tt-icon-default"
        />
      </div>
      <svg-triple-dot
        class="fill-tt-icon-default cursor-pointer p-[8px] w-10 h-10"
        (click)="popupVisibility = !popupVisibility"
      />
      <app-core-action-menu
        class="absolute z-50 top-[23.5px] right-[0px]"
        *ngIf="popupVisibility"
        variant="standard"
        [sections]="popupMenuSections"
        (elementClicked)="popupClicked()"
      />
    </div>
  </div>
</div>
