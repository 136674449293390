import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  DocumentStartLoadResultAction,
  SpaceResultAction,
  SpaceResultActionTypes
} from "./space-result-action";
import { SpaceState } from './space-state';
import { SpaceConstants } from "../common/space-constants";
import { DocumentSummaryEntity } from "../../domain/document-summery-entity";

@Injectable()
export class SpaceReducer
  implements Reducer<SpaceState, SpaceResultAction>
{
  reduce(state: SpaceState, action: SpaceResultAction): SpaceState {
    switch (action.type) {
      case SpaceResultActionTypes.CHANGE_NAV_ITEM:
        return {...state, currentNavItem: action.navItem}
      case SpaceResultActionTypes.FILTER_CONTENT:
        return {...state,
          searchFieldValue: action.searchStr,
          filteredDocuments: action.filteredDocuments,
          filteredSections: action.filteredSections
        }
      case SpaceResultActionTypes.UPDATE_SPACE:
        return {...state,
          id: action.id,
          name: action.name,
          permissions: action.permissions,
          navTabs: action.navTabs,
        }
      case SpaceResultActionTypes.UPDATE_CONTENT:
        return {...state,
          sections: action.sections,
          documents: this.sortDocuments(action.documents)
        }
      case SpaceResultActionTypes.CHANGE_IS_SPACE_LOADING:
        return {...state,
          isSpaceLoading: action.value
        }
      case SpaceResultActionTypes.CHANGE_IS_CONTENT_LOADING:
        return {...state,
          isContentLoading: action.value
        }
      case SpaceResultActionTypes.OPEN_DOCUMENT:
        return {...state,
          selectedDocument: action.document,
          documents: action.documents,
          sections: action.sections,
        }
      case SpaceResultActionTypes.UPDATE_SPACE_TITLE:
        return {...state,
          name: action.title
        }
      case SpaceResultActionTypes.DOCUMENT_END_LOAD:
        return {...state,
          selectedDocumentUpdatedAt: action.updatedAt,
          selectedDocumentIsLoading: false
        }
      case SpaceResultActionTypes.DOCUMENT_START_LOAD:
        return {...state,
          selectedDocumentIsLoading: true
        }
      case SpaceResultActionTypes.INIT_SELECTED_DOCUMENT:
        return {...state, selectedDocument: SpaceConstants.SELECTED_DOCUMENT_INIT
        }
      case SpaceResultActionTypes.CHANGE_IS_OPEN_DELETE_DOCUMENT_MODAL:
        return {...state, isDeleteModalOpen: action.value, alertDocumentName: action.name, alertDocumentId: action.id}
      case SpaceResultActionTypes.CHANGE_RENAME_MODAL_VISIBILITY:
        return {...state, isRenameModalOpen: action.value, alertDocumentName: action.name, alertDocumentId: action.id}
      case SpaceResultActionTypes.CHANGE_ALERT_NAME:
        return {...state, alertDocumentName: action.value}
      case SpaceResultActionTypes.CHANGE_ALERT_IS_LOADING:
        return {...state, alertIsLoading: action.value}
      case SpaceResultActionTypes.CHANGE_DOCUMENT_LIST_VISIBLE:
        return {...state, isDocumentListVisible: action.value}
    }
  }

  sortDocuments(documents: DocumentSummaryEntity[]): DocumentSummaryEntity[] {
    return documents.map((doc)=> {
      return {...doc, documents: this.sortDocuments(doc.documents)}
    })
  }
}
