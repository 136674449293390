<div class="flex flex-col items-center max-w-[720px] w-full">
  <textarea class="mt-[20px] resize-none w-full border-0 outline-0 font-medium text-tt-font-size_6xl text-tt-text-primary-without-alpha"
    *ngIf="isHaveTitle"
    maxlength='100'
    autoResizableTextarea
    [text]="title"
    [placeholder]="SpaceConstants.EMPTY_DOCUMENT_NAME"
    [(ngModel)]="title"
    (input)="onChangeTitle($event)"
    [readOnly]="isReadOnly"
    (keydown.enter)="onEnterClickTitle($event);false"
    (keydown.shift.enter)="onEnterClickTitle($event);false"
  ></textarea>

  <react-wrapper
    *ngIf="isEditorLoaded"
    class="w-full"
    [component]="YooptaEditor"
    [props]="props"
  />
</div>
