import { Component } from '@angular/core';
import { Store } from '../../../../../../../core/mvi/store';
import { SectionSettingsMainState } from './state/section-settings-main-state';
import { SectionSettingsMainExecutor } from './state/section-settings-main-executor';
import {
  SectionSettingsMainAction,
  SectionSettingsMainActionTypes,
} from './state/section-settings-main-action';
import { SectionSettingsMainResultAction } from './state/section-settings-main-result-action';
import { SectionSettingsMainReducer } from './state/section-settings-main-reducer';
import { Constants } from './common/constants';
import { DocumentationService } from "../../../../../data/documentation-service";

@Component({
  selector: 'section-settings-main',
  templateUrl: './section-settings-main.component.html',
  styleUrls: ['../../common/section-settings.component.scss'],
  providers: [
    SectionSettingsMainState,
    SectionSettingsMainExecutor,
    SectionSettingsMainReducer,
    DocumentationService
  ],
})
export class SectionSettingsMainComponent extends Store<
  SectionSettingsMainState,
  SectionSettingsMainExecutor,
  SectionSettingsMainAction,
  SectionSettingsMainResultAction
> {
  constructor(
    state: SectionSettingsMainState,
    executor: SectionSettingsMainExecutor,
    reducer: SectionSettingsMainReducer,
  ) {
    super(state, executor, reducer);
  }

  protected readonly Constants = Constants;
  protected readonly SectionSettingsMainActionTypes =
    SectionSettingsMainActionTypes;
}
