import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentComponent } from "./presentation/view/document.component";
import { ComponentsModule } from "../../../core/components/components.module";
import { SvgArrow, SvgDocumentArrowBack } from "../../../core/components/svg-components/svg.components";
import { RouterOutlet } from "@angular/router";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { EditorChangesService } from "../../../core/components/yoopta-editor/data/editor-changes-service";
import { SessionService } from "../../../core/services/session-service/session-service";
import { StopwatchComponent } from "./presentation/components/stopwatch/stopwatch.component";

@NgModule({
  declarations: [
    DocumentComponent,
    StopwatchComponent
  ],
  exports: [
    DocumentComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    SvgArrow,
    RouterOutlet,
    SvgDocumentArrowBack,
    MatProgressSpinnerModule
  ],
  providers: [EditorChangesService, SessionService]
})
export class DocumentModule { }
