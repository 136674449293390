<div class="py-tt_2xl px-tt_l mx-auto max-w-[2100px]">
  <section
    class="relative h-[120px] w-full rounded-tt-border-radius_l overflow-hidden"
    [style.background]="'url(' + headerImage + ') no-repeat center center'"
    style="background-size: cover"
  >
    <div
      class="absolute h-full w-full top-0 left-0 z-10"
      [style.background]="
        'linear-gradient(87.16deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.04) 100%)'
      "
    ></div>
    <div
      class="absolute h-full w-full top-0 left-0 z-20 p-tt_l flex flex-col justify-end"
    >
      <h2
        class="text-tt-font-size_4xl text-tt-text-contrast-field font-semibold"
      >
        {{ title }}
      </h2>
    </div>
  </section>
  <div title="" class="min-h-screen mt-tt_l flex flex-row gap-tt_l">
    <section
      class="page-layout-content bg-tt-bg-default rounded-tt-border-radius_l p-tt_l w-full"
    >
      <ng-content />
    </section>
    <div class="max-w-[344px] w-[344px] min-w-[344px]">
      <div class="sticky top-[76px]">
        <section
          *ngIf="routes.length !== 0"
          class="bg-tt-bg-default rounded-tt-border-radius_l p-tt_s w-full"
        >
          <div *ngFor="let routeItem of routes; trackBy: routeTrackByFc">
            <a
              *ngIf="isRoute(routeItem)"
              [routerLink]="[routeItem.path]"
              [routerLinkActiveOptions]="{ exact: routeItem.exact }"
              routerLinkActive="bg-tt-secondary-selected-section-color"
              class="text-tt-text-default bg-tt-bg-default hover:bg-tt-secondary-selected-section-color block py-tt_sm px-tt_s truncate w-full h-tt-button-height_m text-tt-font-size_l rounded-tt-border-radius_m transition-colors duration-75"
            >
              {{ routeItem.title }}
            </a>
            <div *ngIf="isFolderRoutes(routeItem)">
              <button
                type="button"
                (click)="onClickFolderRoutes.emit(routeItem.id)"
                class="text-tt-text-default bg-tt-bg-default hover:bg-tt-secondary-selected-section-color truncate flex justify-between items-center gap-tt_l w-full h-tt-button-height_m px-tt_s text-start text-tt-font-size_l rounded-tt-border-radius_m transition-colors duration-75"
              >
                {{ routeItem.title }}
                <svg-bordered-arrow
                  class="text-tt-icon-field transition-transform duration-150"
                  [class.rotate-180]="routeItem.opened"
                />
              </button>
              <div *ngIf="routeItem.opened">
                <a
                  *ngFor="
                    let route of routeItem.children;
                    trackBy: routeTrackByFc
                  "
                  [routerLinkActiveOptions]="{ exact: route.exact }"
                  [routerLink]="[route.path]"
                  routerLinkActive="nav-item__active"
                  class="nav-item block py-tt_sm truncate w-full h-tt-button-height_m ps-tt_2xl pe-tt_s text-tt-font-size_l rounded-tt-border-radius_m transition-colors duration-75"
                >
                  {{ route.title }}
                </a>
              </div>
            </div>
          </div>
        </section>
        <div class="w-full">
          <ng-content select="[sidebar]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
