<app-core-main-content-card>
  <p
    class="bottom-3 text-[20px] font-[500] not-italic leading-normal text-start w-fit pb-3"
  >
    Вход в аккаунт
  </p>
  <div
    class="flex justify-between cursor-pointer"
    (click)="navigateToChangePassword()"
  >
    <div>
      <p
        class="text-[16px] font-[500] not-italic leading-normal text-start w-max"
      >
        Изменить пароль
      </p>
      <p
        class="text-[14px] font-[400] not-italic leading-normal text-tt-text-placeholder w-max"
      >
        Будет использовать, если настроен вход через e-mail и паролю
      </p>
    </div>
    <div class="-rotate-90">
      <app-arrow-icon pictureColor="#BCC1C6" class=""></app-arrow-icon>
    </div>
  </div>
</app-core-main-content-card>
