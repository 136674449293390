<div class="w-0 h-0">
  <div
    #menuContent
    [ngClass]="hidenStyle"
    [ngStyle]="{ left: leftOffset, top: topOffset, width: width }"
    class="z-50 overflow-hidden py-tt_s relative bg-tt-bg-default rounded-tt-border-radius_m shadow-tt-shadow-popup-menu"
  >
    <ng-content />
  </div>
</div>
