<div class="child-routes-size px-[24px] tiny-scrollbar"
  (scroll)="onScroll()"
  #scrollable
>
  <app-core-page-header
    [title]="'Моя команда'"
    [navTabs]="navTabs"
    navTabType="link"
  />

  <router-outlet></router-outlet>

</div>
