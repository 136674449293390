import { Component, Input, OnInit } from "@angular/core";
import { timer } from "rxjs";

@Component({
  selector: 'app-core-stopwatch',
  templateUrl: './stopwatch.component.html',
  styleUrls: ['./stopwatch.component.scss']
})
export class StopwatchComponent implements OnInit {
  @Input() time: number = 0;
  @Input() type: 'moveBack' | 'moveForward' = 'moveForward';
  @Input() stopTime: number = 0;

  stop: boolean = false
  value: string = ''

  ngOnInit(): void {
    this.getDisplayTimer(this.time);
    const startTime = Date.now();
    const endTime = startTime + (this.type === 'moveForward' ? this.stopTime : this.time) * 1000;
    timer(0, 1000).subscribe(() => {
      if(Date.now() >= endTime){
        this.stop = true
        this.getDisplayTimer(this.stopTime);
      }
      if(!this.stop){
        const newTime = this.type === 'moveBack' ? this.time - (Date.now() - startTime) / 1000 : (Date.now() - startTime) / 1000;
        this.getDisplayTimer(newTime);
      }
    });
  }

  getDisplayTimer(time: number) {
    let minutes = "" + Math.floor(time % 3600 / 60);
    let seconds = "" + Math.floor(time % 3600 % 60);

    if (Number(seconds) < 10) {
      seconds = '0' + seconds;
    } else {
      seconds = '' + seconds;
    }

    this.value = minutes + ':' + seconds;
  }
}
