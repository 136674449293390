<div class="pt-3 px-[16px] content-height flex flex-col">
  <div class="shrink-0">
    <div class="flex justify-between">
      <p class="text-tt-font-size_4xl font-medium text-tt-text-default">Все страницы</p>
      <app-core-button
        (onClick)="
          performAction({
            type: SectionContentActionTypes.ADD_DOCUMENT
          })
        "
        >Добавить</app-core-button
      >
    </div>

    <app-core-search-field
      className="mt-6 w-full"
      (onChange)="
        performAction({
          type: SectionContentActionTypes.CHANGE_SEARCH_FIELD,
          value: $event
        })
      "
    />

    <app-filter-menu
      className="mt-6"
      [menuItems]="state.filterItems"
      (menuItemClicked)="
        performAction({
          type: SectionContentActionTypes.CHANGE_FILTER_ITEM,
          param: $event
        })
      "
      [currentSortType]="state.filterState.sortParam"
      [sortTypes]="Constants.SORT_POPUP_SECTION"
      (sortTypeClicked)="
        performAction({
          type: SectionContentActionTypes.CHANGE_SORT_ITEM,
          id: $event
        })
      "
    />
  </div>

  <div class="mt-6 overflow-y-scroll tiny-scrollbar">
    <div>
      <section-doc-item
        *ngFor="let doc of state.filteredDocuments"
        [document]="doc"
        (deleteClicked)="
          performAction({
            type: SectionContentActionTypes.DELETE_DOCUMENT,
            documentId: doc.id
          })
        "
        (markClicked)="
          performAction({
            type: SectionContentActionTypes.FAVORITE_DOCUMENT,
            documentId: doc.id
          })
        "
        (editClicked)="
          performAction({
            type: SectionContentActionTypes.EDIT_DOCUMENT,
            documentId: doc.id
          })
        "
        (linkClicked)="
          performAction({
            type: SectionContentActionTypes.LINK_DOCUMENT,
            documentId: doc.id
          })
        "
        (docClicked)="
          performAction({
            type: SectionContentActionTypes.MOVE_TO_DOCUMENT_EDITOR,
            documentId: doc.id
          })
        "
      />
    </div>
    <div
      *ngIf="state.documents.length > 0"
      class="w-full h-[1px] bg-tt-separator-color"
    ></div>
  </div>
</div>
