import { Blocks, Elements, UI, useYooptaPluginOptions, YooEditor, YooptaBlockData } from '@teamtells/editor';
import {
  RowSpacingIcon,
  SizeIcon,
  WidthIcon,
  TextAlignCenterIcon,
  TextAlignLeftIcon,
  TextAlignRightIcon,
  UpdateIcon,
  TextIcon,
} from '@radix-ui/react-icons';
import { useState } from 'react';
import { flip, inline, offset, shift, useFloating } from '@floating-ui/react';
import { ImageElementProps } from "@teamtells/image";
import { ImagePluginOptions } from "../types/image-plugin-options";
import { InputAltText } from "./input-alt-text";
import { Loader } from "../../../components/loader";
import { ReactSVG } from "react-svg";
import { YooptaEditorConstants } from "../../../common/yoopta-editor-constants";
import { onFileDownload } from "../../../utils/on-file-download";
import { useInjected } from "@bubblydoo/angular-react";
import { Editor2ServiceImpl } from "../../../data/editor-service-impl";
import { onImageUpload } from "../utils/on-image-upload";

type ImagePluginElements = 'image'

const ALIGN_ICONS = {
  left: TextAlignLeftIcon,
  center: TextAlignCenterIcon,
  right: TextAlignRightIcon,
};

const { ExtendedBlockActions, BlockOptionsMenuGroup, BlockOptionsMenuItem, BlockOptionsSeparator } = UI;

type Props = {
  editor: YooEditor;
  block: YooptaBlockData;
  props?: ImageElementProps;
};

const ImageBlockOptions = ({ editor, block, props: imageProps }: Props) => {
  const options = useYooptaPluginOptions<ImagePluginOptions>('Image');
  const [isAltTextOpen, setIsAltTextOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [altText, setAltText] = useState<string>(imageProps?.alt || '');
  const editorService = useInjected(Editor2ServiceImpl)

  const { refs, floatingStyles } = useFloating({
    placement: 'left',
    open: isAltTextOpen,
    onOpenChange: setIsAltTextOpen,
    middleware: [inline(), flip(), shift(), offset(10)],
  });

  const onSetLoading = (slate: boolean) => setLoading(slate);

  const onSetAltText = (text: string) => setAltText(text);
  const onSaveAltText = () => {
    if (!altText) return;
    Elements.updateElement<ImagePluginElements, ImageElementProps>(editor, block.id, {
      type: 'image',
      props: { alt: altText },
    });

    setIsAltTextOpen(false);
  };

  const onDeleteAltText = () => {
    setAltText('');
    Elements.updateElement<ImagePluginElements, ImageElementProps>(editor, block.id, {
      type: 'image',
      props: { alt: '' },
    });

    setIsAltTextOpen(false);
  };

  const onCover = () => {
    Elements.updateElement<ImagePluginElements, ImageElementProps>(editor, block.id, {
      type: 'image',
      props: { fit: 'cover' },
    });
  };

  const onFit = () => {
    Elements.updateElement<ImagePluginElements, ImageElementProps>(editor, block.id, {
      type: 'image',
      props: { fit: 'contain' },
    });
  };

  const onFill = () => {
    Elements.updateElement<ImagePluginElements, ImageElementProps>(editor, block.id, {
      type: 'image',
      props: { fit: 'fill' },
    });
  };

  const onDownload = () => {
    if(imageProps && imageProps.src)
    {
      onFileDownload(imageProps.src, editorService, imageProps.alt);
    }
  };

  const currentAlign = block?.meta?.align || 'center';
  // @ts-ignore
  const AlignIcon = ALIGN_ICONS[currentAlign];

  const onToggleAlign = () => {
    const aligns = ['left', 'center', 'right'];
    if (!block) return;

    const nextAlign = aligns[(aligns.indexOf(currentAlign) + 1) % aligns.length] as YooptaBlockData['meta']['align'];
    Blocks.updateBlock(editor, block.id, { meta: { ...block.meta, align: nextAlign } });
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    onSetLoading(true);

    try {
      const data = await onImageUpload(file, editorService);
      const defaultImageProps = editor.plugins["Image"].elements["image"].props as ImageElementProps;

      Elements.updateElement<ImagePluginElements, ImageElementProps>(editor, block.id, {
        type: 'image',
        props: {
          src: data.src,
          alt: data.alt,
          sizes: data.sizes || defaultImageProps.sizes,
          bgColor: imageProps?.bgColor || data.bgColor || defaultImageProps.bgColor,
          fit: imageProps?.fit || data.fit || defaultImageProps.fit || 'fill',
        },
      });
    } catch (error) {
    } finally {
      onSetLoading(false);
    }
  };

  return (
    <ExtendedBlockActions onClick={() => editor.setSelection([block.meta.order])} className="yoopta-extended-block-action yoopta-image-options">
      <BlockOptionsSeparator />
      <BlockOptionsMenuGroup>
        <BlockOptionsMenuItem>
          <button type="button" className="yoopta-block-options-button justify-between" onClick={onFit}>
            <span className="flex">
              <RowSpacingIcon width={16} height={16} className="w-4 h-4" style={{ marginRight: '8px' }}/>
              Уместить
            </span>
            {imageProps?.fit === 'contain' && (
              <ReactSVG src={YooptaEditorConstants.BLOCK_OPTIONS_SVG_PATH + 'checkmark.svg'} width={16} height={16} className="w-4 h-4" />
            )}
          </button>
        </BlockOptionsMenuItem>
        <BlockOptionsMenuItem>
          <button type="button" className="yoopta-block-options-button justify-between" onClick={onFill}>
            <span className="flex">
              <WidthIcon width={16} height={16} className="w-4 h-4" style={{ marginRight: '8px' }}/>
              Растянуть
            </span>
            {imageProps?.fit === 'fill' && (
              <ReactSVG src={YooptaEditorConstants.BLOCK_OPTIONS_SVG_PATH + 'checkmark.svg'} width={16} height={16} className="w-4 h-4" />
            )}
          </button>
        </BlockOptionsMenuItem>
        <BlockOptionsMenuItem>
          <button type="button" className="yoopta-block-options-button justify-between" onClick={onCover}>
            <span className="flex">
              <SizeIcon width={16} height={16} className="w-4 h-4" style={{ marginRight: '8px' }}/>
              Обрезать по краям
            </span>
            {imageProps?.fit === 'cover' && (
              <ReactSVG src={YooptaEditorConstants.BLOCK_OPTIONS_SVG_PATH + 'checkmark.svg'} width={16} height={16} className="w-4 h-4" />
            )}
          </button>
        </BlockOptionsMenuItem>
      </BlockOptionsMenuGroup>
      <BlockOptionsSeparator />
      <BlockOptionsMenuGroup>
        {isAltTextOpen && (
          <InputAltText
            value={altText}
            onChange={onSetAltText}
            floatingStyles={floatingStyles}
            onClose={() => setIsAltTextOpen(false)}
            refs={refs}
            onDelete={onDeleteAltText}
            onSave={onSaveAltText}
          />
        )}
        <BlockOptionsMenuItem>
          <button
            type="button"
            className="yoopta-block-options-button"
            ref={refs.setReference}
            onClick={() => setIsAltTextOpen(true)}
          >
            <TextIcon width={16} height={16} className="w-4 h-4" style={{ marginRight: '8px' }}/>
            Альтернативный текст
          </button>
        </BlockOptionsMenuItem>
        <BlockOptionsMenuItem>
          <label
            htmlFor="image-uploader"
            className="yoopta-block-options-button"
            data-disabled={loading}
          >
            <input
              type="file"
              accept={options.accept}
              multiple={false}
              id="image-uploader"
              className="absolute hidden"
              onChange={onUpload}
              disabled={loading}
            />
            {loading ? (
              <Loader className="user-select-none" width={16} height={16}/>
            ) : (
              <UpdateIcon width={16} height={16} className="w-4 h-4"/>
            )}
            <span  style={{ marginLeft: '8px' }}>Заменить изображение</span>
          </label>
        </BlockOptionsMenuItem>
      </BlockOptionsMenuGroup>
      <BlockOptionsSeparator />
      <BlockOptionsMenuGroup>
        <BlockOptionsMenuItem>
          <button
            type="button"
            className="yoopta-block-options-button"
            onClick={onToggleAlign}
          >
            <AlignIcon width={16} height={16} className="w-4 h-4" style={{ marginRight: '8px' }}/>
            Выравнивание
          </button>
        </BlockOptionsMenuItem>
        <BlockOptionsMenuItem>
          <button type="button" className="yoopta-block-options-button" onClick={onDownload}>
            <ReactSVG src={YooptaEditorConstants.BLOCK_OPTIONS_SVG_PATH + 'download.svg'} width={16} height={16} className="w-4 h-4 " style={{ marginRight: '8px' }}/>
            Скачать
          </button>
        </BlockOptionsMenuItem>
      </BlockOptionsMenuGroup>
    </ExtendedBlockActions>
  );
};

export { ImageBlockOptions };
