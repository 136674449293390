import { FilterMenuItem } from '../../../../../../core/components/filter-menu/filter-menu.component';
import { ActionElement } from '../../../../../../core/components/action-menu/action-menu.component';
import { FilterParams } from './sections-state';
import { SectionSummaryEntity } from '../../domain/section-summary-entity';
import {
  AutocompleteItem
} from "../../../../../../core/components/fields/autocomplete-field/autocomplete-field.component";

export type SectionsResultAction =
  | SelectFilterParamsResultAction
  | SelectSortParamsResultAction
  | UpdateSectionListResultAction
  | ChangeCreateSectionModalVisibilityResultAction
  | ChangeSectionMarkResultAction
  | ChangeSearchFieldResultAction
  | ChangeCreateSectionDataResultAction
  | UpdateIconsListResultAction
  | ChangeIsLoadingResultAction;

export enum SectionsResultActionTypes {
  SELECT_FILTER_PARAMS,
  SELECT_SORT_PARAMS,
  UPDATE_SECTIONS_LIST,
  CHANGE_CREATE_SECTION_MODAL_VISIBILITY,
  CHANGE_SECTION_MARK,
  SEARCH_FIELD_CHANGE,
  CHANGE_CREATE_SECTION_DATA,
  UPDATE_ICONS_LIST,
  CHANGE_IS_LOADING,
}

export interface UpdateIconsListResultAction{
  readonly type: SectionsResultActionTypes.UPDATE_ICONS_LIST;
  readonly iconsList: string[]
}

export interface ChangeIsLoadingResultAction {
  readonly type: SectionsResultActionTypes.CHANGE_IS_LOADING;
  readonly isLoading: boolean;
}

export interface ChangeCreateSectionDataResultAction {
  readonly type: SectionsResultActionTypes.CHANGE_CREATE_SECTION_DATA;
  readonly createSectionData: SectionSummaryEntity;
  readonly titleError: string;
  readonly privacy: AutocompleteItem;
}
export interface SelectFilterParamsResultAction {
  readonly type: SectionsResultActionTypes.SELECT_FILTER_PARAMS;
  readonly param: FilterParams;
  readonly filteredSections: SectionSummaryEntity[];
  readonly filterItems: FilterMenuItem[];
}

export interface SelectSortParamsResultAction {
  readonly type: SectionsResultActionTypes.SELECT_SORT_PARAMS;
  readonly sortParam: ActionElement;
  readonly filteredSections: SectionSummaryEntity[];
}

export interface UpdateSectionListResultAction {
  readonly type: SectionsResultActionTypes.UPDATE_SECTIONS_LIST;
  readonly sections: SectionSummaryEntity[];
  readonly filteredSections: SectionSummaryEntity[];
}

export interface ChangeCreateSectionModalVisibilityResultAction {
  readonly type: SectionsResultActionTypes.CHANGE_CREATE_SECTION_MODAL_VISIBILITY;
  readonly visibility: boolean;
}

export interface ChangeSectionMarkResultAction {
  readonly type: SectionsResultActionTypes.CHANGE_SECTION_MARK;
  readonly sections: SectionSummaryEntity[];
  readonly filteredSections: SectionSummaryEntity[];
}

export interface ChangeSearchFieldResultAction {
  readonly type: SectionsResultActionTypes.SEARCH_FIELD_CHANGE;
  readonly filter: string;
  readonly filteredSections: SectionSummaryEntity[];
}
