import { DocumentationService } from "../../../../data/documentation-service";
import { ActionElement } from "../../../../../../core/components/action-menu/action-menu.component";
import { Executor } from "../../../../../../core/mvi/store";
import { FilterParams, SectionsState, SortParams } from "./sections-state";
import { SectionsAction, SectionsActionTypes } from "./sections-action";
import { SectionsResultAction, SectionsResultActionTypes } from "./sections-result-action";
import { Injectable } from "@angular/core";
import { SectionsNavigator } from "../../navigation/sections.navigator";
import { clone } from "cloneable-ts";
import { Constants } from "../common/сonstants";
import { ToastsService } from "../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../../core/components/toast-alert/toast-alert.component";
import { SectionsService } from "../../domain/sections-service";
import { SectionPrivacy, SectionSummaryEntity } from "../../domain/section-summary-entity";
import { ResponseStatus } from "../../../../../../core/utils/response-status";

@Injectable()
export class SectionsExecutor extends Executor<
  SectionsState,
  SectionsAction,
  SectionsResultAction
> {
  constructor(
    private sectionService: SectionsService,
    private navigator: SectionsNavigator,
    private toastsService: ToastsService,
    private documentationService: DocumentationService
  ) {
    super();
  }

  execute(action: SectionsAction) {
    const state = this.getState();
    switch (action.type) {
      case SectionsActionTypes.SELECT_FILTER_PARAMS:
        this.handleChangeFilterParam(action.param);
        break;
      case SectionsActionTypes.SELECT_SORT_PARAMS:
        this.handleChangeSortParam(action.sortParamId);
        break;
      case SectionsActionTypes.UPDATE_SECTIONS_LIST:
        this.handleUpdateSectionsList();
        break;
      case SectionsActionTypes.CHANGE_CREATE_SECTION_MODAL_VISIBILITY:
        this.handleChangeSectionModalVisibility(action.visibility)
        break;
      case SectionsActionTypes.CHANGE_SECTION_MARK:
        this.handleChangeSectionMark(action.sectionId);
        break;
      case SectionsActionTypes.MOVE_TO_SECTION:
        this.navigator.navigateToSection(action.sectionId);
        break;
      case SectionsActionTypes.COPY_SECTION_URL:
        this.navigator.copySectionUrl(action.sectionId);
        break;
      case SectionsActionTypes.CREATE_SECTION:
        this.handleCreateSection();
        break;
      case SectionsActionTypes.EDIT_SECTION:
        this.navigator.navigateToSectionEdit(action.sectionId);
        break;
      case SectionsActionTypes.DELETE_SECTION:
        this.sectionService.deleteSection(action.sectionId);
        break;
      case SectionsActionTypes.SEARCH_FIELD_CHANGE:
        this.reduce({
          type: SectionsResultActionTypes.SEARCH_FIELD_CHANGE,
          filter: action.value,
          filteredSections: this.calculateFilteredSections(
            state.sections,
            state.filtersState.filterParam,
            action.value,
            state.filtersState.sortParam,
          ),
        });
        break;
      case SectionsActionTypes.CHANGE_CREATE_SECTION_TITLE:
        this.reduce({
          type: SectionsResultActionTypes.CHANGE_CREATE_SECTION_DATA,
          createSectionData: clone(this.getState().createSectionDto, {
            title: action.title,
          }),
          titleError: action.title ? '' : Constants.EMPTY_TITLE_ERROR,
          privacy: state.currentPrivacyValue,
        });
        break;
      case SectionsActionTypes.CHANGE_CREATE_SECTION_ICON:
        this.reduce({
          type: SectionsResultActionTypes.CHANGE_CREATE_SECTION_DATA,
          createSectionData: clone(state.createSectionDto, {
            url: action.iconUrl,
          }),
          titleError: state.createSectionTitleError,
          privacy: state.currentPrivacyValue,
        });
        break;
      case SectionsActionTypes.CHANGE_CREATE_SECTION_PRIVACY:
        const findPrivacy = Constants.PRIVACY_FIELD_VALUES.find((val) => {
          return val.id == action.privacy;
        });
        this.reduce({
          type: SectionsResultActionTypes.CHANGE_CREATE_SECTION_DATA,
          createSectionData: clone(state.createSectionDto),
          titleError: state.createSectionTitleError,
          privacy: findPrivacy ? findPrivacy : state.currentPrivacyValue,
        });
        break;
    }
  }

  handleChangeSectionModalVisibility(visibility: boolean)
  {
    if(visibility){
      this.documentationService.getIcons().subscribe((icons)=> {
        this.reduce({
          type: SectionsResultActionTypes.UPDATE_ICONS_LIST,
          iconsList: icons
        })
      })
    }
    this.reduce({
      type: SectionsResultActionTypes.CHANGE_CREATE_SECTION_MODAL_VISIBILITY,
      visibility: visibility,
    });
  }

  handleCreateSection(): void {
    const state = this.getState();
    if (state.createSectionDto.title) {
      this.sectionService
        .createSection(state.createSectionDto, SectionPrivacy.PUBLIC)
        .subscribe((response) => {
          if (response.status == ResponseStatus.SUCCESS) {
            this.navigator.navigateToSection(response.id);
          } else {
            this.toastsService.createToast({
              title: 'Возникла ошибка при создании.',
              description: 'Попробуйте позднее.',
              state: ToastState.ERROR,
            });
          }
        });
    } else {
      this.reduce({
        type: SectionsResultActionTypes.CHANGE_CREATE_SECTION_DATA,
        createSectionData: clone(state.createSectionDto),
        titleError: Constants.EMPTY_TITLE_ERROR,
        privacy: state.currentPrivacyValue,
      });
    }
  }

  handleChangeFilterParam(filterParam: FilterParams) {
    const state = this.getState();
    this.reduce({
      type: SectionsResultActionTypes.SELECT_FILTER_PARAMS,
      param: filterParam,
      filteredSections: this.calculateFilteredSections(
        state.sections,
        filterParam,
        state.filtersState.filter,
        state.filtersState.sortParam,
      ),
      filterItems: state.filterItems.map((item) => {
        item.isActive = item.id == filterParam.valueOf();
        return item;
      }),
    });
  }

  handleChangeSortParam(sortId: string) {
    const state = this.getState();
    const sortParam = Constants.SORT_POPUP_SECTION[0].elements.find((elem) => {
      return elem.id == sortId;
    });
    if (sortParam) {
      this.reduce({
        type: SectionsResultActionTypes.SELECT_SORT_PARAMS,
        sortParam: sortParam,
        filteredSections: this.calculateFilteredSections(
          state.sections,
          state.filtersState.filterParam,
          state.filtersState.filter,
          sortParam,
        ),
      });
    }
  }

  handleChangeSectionMark(sectionId: number) {
    const state = this.getState();

    let isFavoriteSection: boolean | null = null;
    const prevSections = state.sections;
    const prevFilteredSections = state.filteredSections;

    const sections = state.sections.map((section) => {
      if (section.id == sectionId) {
        isFavoriteSection = !section.isFavorite;
        return clone(section, { isFavorite: isFavoriteSection });
      } else {
        return section;
      }
    });

    const filteredSections = state.filteredSections.map((section) => {
      if (section.id == sectionId) {
        isFavoriteSection = !section.isFavorite;
        return clone(section, { isFavorite: isFavoriteSection });
      } else {
        return section;
      }
    });

    this.reduce({
      type: SectionsResultActionTypes.CHANGE_SECTION_MARK,
      sections: sections,
      filteredSections: filteredSections,
    });

    if (isFavoriteSection != null) {
      this.sectionService
        .updateIsFavoriteSection(sectionId, isFavoriteSection)
        .subscribe((status) => {
          if (status != ResponseStatus.SUCCESS) {
            this.toastsService.createToast({
              title: 'Возникла ошибка при добавлении в отмеченные.',
              description: 'Попробуйте позднее.',
              state: ToastState.ERROR,
            });
            this.reduce({
              type: SectionsResultActionTypes.CHANGE_SECTION_MARK,
              sections: prevSections,
              filteredSections: prevFilteredSections,
            });
          }
        });
    }
  }

  handleUpdateSectionsList() {
    const state = this.getState();
    this.sectionService.sections.subscribe((sections) => {
      this.reduce({
        type: SectionsResultActionTypes.UPDATE_SECTIONS_LIST,
        sections: sections,
        filteredSections: this.calculateFilteredSections(
          sections,
          state.filtersState.filterParam,
          state.filtersState.filter,
          state.filtersState.sortParam,
        ),
      });
    });
    this.reduce({
      type: SectionsResultActionTypes.CHANGE_IS_LOADING,
      isLoading: true,
    });
    this.sectionService.fetchSections();
  }

  calculateFilteredSections(
    sections: Array<SectionSummaryEntity>,
    filterParam: FilterParams,
    filter: string,
    sortParam: ActionElement,
  ) {
    return sections
      .filter((section) => {
        let showWithFavorite = true;
        if (filterParam == FilterParams.MARKED) {
          showWithFavorite = section.isFavorite;
        }
        return (
          section.title.toLowerCase().includes(filter.toLowerCase()) &&
          showWithFavorite
        );
      })
      .sort((sectionA, sectionB): number => {
        const titleA = sectionA.title.toLowerCase();
        const titleB = sectionB.title.toLowerCase();
        switch (sortParam.id) {
          case SortParams.ALPHABET_REVERT:
            return titleA < titleB ? 1 : -1;
          default:
            return titleA < titleB ? -1 : 1;
        }
      });
  }
}
