import { SettingsMainNavItems } from "./settings-main-state"
import { DropdownItem } from "../../../../../../core/components/fields/dropdown-field/dropdown-field.component";

export type SettingsMainResultAction =
  | InitResultAction
  | ChangeOrganisationTitleResultAction
  | CancelTitleResultAction
  | ChangeNavItemResultAction
  | ChangeLanguageResultAction
  | ChangeDeleteAlertModalVisibilityResultAction

export enum SettingsMainResultActionTypes {
  INIT,
  CHANGE_ORGANISATION_NAME,
  CANCEL_TITLE,
  CHANGE_NAV_ITEM,
  CHANGE_LANGUAGE,
  CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
}

export interface ChangeDeleteAlertModalVisibilityResultAction{
  readonly type: SettingsMainResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY
  readonly value: boolean
}

export interface ChangeLanguageResultAction {
  readonly type: SettingsMainResultActionTypes.CHANGE_LANGUAGE
  readonly language?: DropdownItem
}

export interface ChangeNavItemResultAction {
  readonly type: SettingsMainResultActionTypes.CHANGE_NAV_ITEM
  readonly navItem: SettingsMainNavItems
}

export interface CancelTitleResultAction {
  readonly type: SettingsMainResultActionTypes.CANCEL_TITLE
}

export interface InitResultAction {
  readonly type: SettingsMainResultActionTypes.INIT
  readonly organisationId: string,
  readonly organisationTitle: string
}

export interface ChangeOrganisationTitleResultAction {
  readonly type: SettingsMainResultActionTypes.CHANGE_ORGANISATION_NAME
  readonly newOrganisationTitle: string
  readonly organisationTitleError: string | null
}
