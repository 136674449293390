import { Injectable } from '@angular/core';
import { ChatState } from '../domain/model/messenger-chat-state';

@Injectable({
  providedIn: 'root',
})
export class ChatListDataService {
  readonly Chats: ChatState[] = [
    {
      chatEntity: {
        id: 0,
        name: 'Личный чат',
        lastMessage: null,
        isGroup: false,
        users: [{ id: 0, name: 'Kdk', isAdmin: false }],
      },
      isSelected: false,
      unreadCount: 0,
    },
    {
      chatEntity: {
        id: 1,
        name: 'Групповой1',
        lastMessage: null,
        isGroup: true,
        users: [
          { id: 0, name: 'Kdk', isAdmin: false },
          { id: 0, name: 'Kdk', isAdmin: false },
        ],
      },
      isSelected: false,
      unreadCount: 0,
    },
    {
      chatEntity: {
        id: 2,
        name: 'Групповой2',
        lastMessage: null,
        isGroup: true,
        users: [],
      },
      isSelected: false,
      unreadCount: 0,
    },
  ];
}
