<app-core-main-content-card>
  <div class="flex flex-col gap-6">
    <div class="flex flex-row gap-4 items-center">
      <p
        style="line-height: normal"
        class="text-[28px] font-[500] not-italic w-fit text-center"
      >
        Изменить пароль
      </p>
    </div>
    <div class="flex flex-col gap-3">
      <p class="text-[20px] not-italic font-[500] leading-normal">
        Текущий пароль
      </p>
      <p class="text-[16px] not-italic font-normal leading-normal">
        Введите пароль, который используете сейчас
      </p>
      <app-core-input-field
        type="password"
        placeholder="Текущий пароль"
        [error]="state.actualPasswordError"
        (onChange)="onActualPasswordChange($event)"
      />
    </div>
    <div class="flex flex-col gap-3">
      <p class="text-[20px] not-italic font-[500] leading-normal">
        Новый пароль
      </p>
      <div class="flex flex-col gap-3">
        <p class="text-[16px] not-italic font-normal leading-normal">
          Введите пароль, который хотите использовать при входе
        </p>
        <app-core-input-field
          type="password"
          placeholder="Новый пароль"
          [error]="state.newPasswordError"
          (onChange)="onNewPasswordChange($event)"
        />
        <p class="text-[16px] not-italic font-normal leading-normal">
          Повторите новый пароль
        </p>
        <app-core-input-field
          type="password"
          placeholder="Новый пароль"
          [error]="state.verificationPasswordError"
          (onChange)="onVerificationPasswordChange($event)"
        />
      </div>
      <div class="pl-2 pt-[14px]">
        <app-core-button (onClick)="buttonClick($event)">
          Изменить
        </app-core-button>
      </div>
    </div>
  </div>
</app-core-main-content-card>
