<div class="flex flex-col justify-between w-full h-full">
  <div>
    <app-core-settings-content-element
      class="text-tt-text-primary text-[14px]"
      [title]="'Основное'"
    >
      <p>Здесь вы можете управлять основными параметрами роли, которые видят сотрудники вашей компании</p>
    </app-core-settings-content-element>

    <div class="h-[1px] w-full bg-tt-border-default"></div>

    <app-core-settings-content-element>
      <div class="flex flex-col gap-[24px]">
        <app-core-input-field
          label="Название роли"
          variant="standard"
          placeholder=""
          error="{{nameError}}"
          [readonly]="isReadOnly"
          [charsCounterMax]="128"
          [defaultValue]="data.name"
          [value]="data.name"
          (onChange)="nameChange($event)"
        />

        <app-core-textarea-field
          label="Описание роли"
          variant="standard"
          placeholder=""
          error="{{descriptionError}}"
          [readonly]="isReadOnly"
          [charsCounterMax]="180"
          [defaultValue]="data.description"
          [value]="data.description"
          (onChange)="descriptionChange($event)"
        />
      </div>

    </app-core-settings-content-element>
  </div>

  <app-core-settings-content-element
    *ngIf="data.name != initData.name || data.description != initData.description"
  >
    <div class="flex w-full justify-end gap-[16px]">
      <app-core-button
        variant="soft"
        (onClick)="decline()"
      >
        Отменить
      </app-core-button>
      <app-core-button
        (onClick)="save()"
      >
        Сохранить
      </app-core-button>
    </div>

  </app-core-settings-content-element>
</div>
