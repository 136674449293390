<div class="flex flex-col gap-[9px]">
  <div class="permission-block">
    <div class="permission-title-block">
      <p class="permission-title-text">Сотрудники</p>
    </div>
    <div class="pl-[16px] permission-block">
      <div class="permission-title-block">
        <p class="permission-text">Просмотр сотрудников</p>
        <app-core-checkbox-field
          [value]="permissions.company.viewEmployee"
          [inactive]='true'
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">Создание и редактирование сотрудников</p>
        <app-core-checkbox-field
          [value]="permissions.company.createEmployee"
          (onToggle)="createEditEmployeeToggle()"
          [inactive]="isReadOnly"
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">Удаление сотрудников</p>
        <app-core-checkbox-field
          [value]="permissions.company.deleteEmployee"
          (onToggle)="permissions.company.deleteEmployee = !permissions.company.deleteEmployee"
          [inactive]="isReadOnly"
        />
      </div>
    </div>
  </div>

  <div class="permission-block">
    <div class="permission-title-block">
      <p class="permission-title-text">Роли</p>
    </div>
    <div class="pl-[16px] permission-block">
      <div class="permission-title-block">
        <p class="permission-text">Просмотр ролей</p>
        <app-core-checkbox-field
          [value]="permissions.role.viewRole"
          [inactive]='true'
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">Создание и редактирование ролей</p>
        <app-core-checkbox-field
          [value]="permissions.role.createRole"
          (onToggle)="createEditRoleToggle()"
          [inactive]="isReadOnly"
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">Удаление ролей</p>
        <app-core-checkbox-field
          [value]="permissions.role.deleteRole"
          (onToggle)="permissions.role.deleteRole = !permissions.role.deleteRole"
          [inactive]="isReadOnly"
        />
      </div>
    </div>
  </div>

  <div class="permission-block">
    <div class="permission-title-block">
      <p class="permission-title-text">База знаний</p>
    </div>
    <div class="pl-[16px] permission-block">
      <div class="permission-title-block">
        <p class="permission-text">Просмотр пространств</p>
        <app-core-checkbox-field
          [value]="permissions.knowledgeBase.viewKnowledgeBase"
          [inactive]='true'
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">Создание пространств</p>
        <app-core-checkbox-field
          [value]="permissions.knowledgeBase.createKnowledgeBase"
          (onToggle)="permissions.knowledgeBase.createKnowledgeBase = !permissions.knowledgeBase.createKnowledgeBase "
          [inactive]="isReadOnly"
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">Редактирование любых пространств компании</p>
        <app-core-checkbox-field
          [value]="permissions.knowledgeBase.editKnowledgeBase"
          (onToggle)="permissions.knowledgeBase.editKnowledgeBase = !permissions.knowledgeBase.editKnowledgeBase"
          [inactive]="isReadOnly"
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">Удаление любых пространств компании</p>
        <app-core-checkbox-field
          [value]="permissions.knowledgeBase.deleteKnowledgeBase"
          (onToggle)="permissions.knowledgeBase.deleteKnowledgeBase = !permissions.knowledgeBase.deleteKnowledgeBase"
          [inactive]="isReadOnly"
        />
      </div>
    </div>
  </div>

  <div class="permission-block pb-[9px]">
    <div class="permission-title-block">
      <p class="permission-title-text">Настройки</p>
    </div>
    <div class="pl-[16px] permission-block">
      <div class="permission-title-block">
        <p class="permission-text">Управление параметрами платформы</p>
        <app-core-checkbox-field
          [value]="permissions.settings.main"
          (onToggle)="permissions.settings.main = !permissions.settings.main"
          [inactive]="isReadOnly"
        />
      </div>
      <div class="permission-title-block">
        <p class="permission-text">Оплата лицензии</p>
        <app-core-checkbox-field
          [value]="permissions.settings.billing"
          (onToggle)="permissions.settings.billing = !permissions.settings.billing"
          [inactive]="isReadOnly"
        />
      </div>
    </div>
  </div>
</div>
