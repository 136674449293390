<app-hover-instruments-box
  *ngIf="!isReadOnly"
>
  <div class="flex">
    <a class="size-[32px] cursor-pointer flex justify-center items-center">
      <svg-video-instruments-open-link/>
    </a>

    <div class="h-[32px] w-[1px] bg-tt-editor-instrument-separator"></div>

    <button class="size-[32px] flex justify-center items-center text-tt-icon-contrast-background">
      <svg-link/>
    </button>

    <div class="h-[32px] w-[1px] bg-tt-editor-instrument-separator"></div>

    <div>
      <button class="w-[32px] h-[32px] text-tt-icon-contrast-background cursor-pointer flex justify-center items-center"
        (click)="changePopupVisibility()"
      >
        <svg-triple-dot class="w-[24px] h-[24px]"/>
      </button>
      <app-core-action-menu
        appPopup
        popupOffsetXSide="right"
        *ngIf="popupVisibility"
        (popupClose)="changePopupVisibility(false)"
        [sections]="POPUP_SECTIONS"
        (elementClicked)="popupClicked($event)"
      />
    </div>
  </div>


</app-hover-instruments-box>
