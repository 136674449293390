<div [ngClass]="className">
  <label
    *ngIf="variant === 'standard' && label"
    class="inline-block mb-tt_extra-s text-tt-font-size_l text-tt-text-secondary"
  >
    {{ label }}
    <span class="text-[#F53232]" *ngIf="required">*</span>
  </label>

  <div
    class="relative w-full"
    [ngClass]="{
      'h-tt-height-field_xl': variant === 'outlined',
      'h-tt-height-field_m': variant === 'standard'
    }"
  >
    <button
      (focus)="onFocus()"
      (blur)="onUnFocus()"
      (click)="onClickDropwdown()"
      [ngClass]="{
        'ring-tt-border-failure outline-tt-border-failure bg-tt-bg-failure':
          error,
        'ring-2 rounded-t-tt-border-radius_m': opened,
        'rounded-tt-border-radius_m': !opened,
        'bg-tt-field-background-color': !error,
        'px-tt_xl': variant === 'outlined',
        'px-tt_l': variant === 'standard'
      }"
      class="text-tt-font-size_l font-normal text-tt-text-default h-full w-full outline outline-1 outline-tt-border-default ring-tt-primary focus:ring-2 focus:outline-none"
      type="button"
    >
      <p class="flex justify-between items-center">
        <span
          *ngIf="selectedItem"
          class="text-tt-text-default"
          [ngClass]="{
            'text-tt-font-size_xl': variant === 'outlined',
            'text-tt-font-size_l': variant === 'standard'
          }"
        >
          {{ selectedItem.name }}
        </span>
        <span *ngIf="!selectedItem" class="text-tt-text-placeholder">
          {{ placeholder }}
          <span
            class="text-[#F53232]"
            *ngIf="required && variant === 'outlined'"
          >
            *
          </span>
        </span>

        <span class="flex items-center gap-[6px]">
          <button
            *ngIf="this.onUnselect.observed && selectedItem"
            (click)="onUnselectItem($event, selectedItem!.id)"
            type="button"
            class="flex justify-center items-center h-[28px] w-[28px] hover:bg-tt-bg-default rounded-full transition"
          >
            <svg-x-mark class="text-tt-icon-default" />
          </button>
          <button
            *ngIf="
              (this.onSelect.observed && this.items.length !== 0) ||
              this.onClick.observed
            "
            type="button"
            class="flex justify-center items-center h-[28px] w-[28px] hover:bg-tt-bg-default rounded-full transition"
            [class.rotate-180]="opened"
          >
            <svg-bordered-arrow class="text-tt-icon-default" />
          </button>
        </span>
      </p>
    </button>
    <div
      *ngIf="items.length !== 0"
      [class.hidden]="!opened"
      class="z-50 absolute w-full text-start bg-white rounded-b-tt-border-radius_m tt-shadow-dropdown ring-1 ring-tt-border-default overflow-hidden"
      [ngClass]="{
        'top-[59px]': variant === 'outlined',
        'top-[43px]': variant === 'standard'
      }"
    >
      <button
        type="button"
        *ngFor="let item of items; trackBy: trackItemByFn"
        (click)="onSelectItem(item.id)"
        class="text-tt-font-size_l font-normal text-tt-text-default
          cursor-pointer py-2 min-h-[44px] px-[20px] w-full hover:bg-tt-light-blue
          flex items-center"
      >
        <span class="text-tt-text-default">{{ item.name }}</span>
      </button>
    </div>
  </div>
  <app-field-error-message [error]="error" />
</div>
