import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from 'src/app/core/components/components.module';
import {
  SvgArrowRight,
  SvgLoading,
} from 'src/app/core/components/svg-components/svg.components';
import { EmptyRule, MaxLengthRule } from 'src/app/core/validators/rule';
import { Validator } from 'src/app/core/validators/validator';
import {
  emailValidatorFactory,
  phoneNumberValidatorFactory,
} from 'src/app/core/validators/validators';
import { RegistrationComponent } from './presentation/view/registration.component';
import { RegistrationServiceImpl } from './data/registration-service-impl';
import { RegistrationService } from './domain/registration-service';

@NgModule({
  declarations: [RegistrationComponent],
  imports: [CommonModule, ComponentsModule, SvgLoading, SvgArrowRight],
  providers: [
    {
      provide: 'RegistrationEmailValidator',
      useExisting: Validator,
      useFactory: emailValidatorFactory,
    },
    {
      provide: 'RegistrationNameValidator',
      useExisting: Validator,
      useFactory: RegistrationModule.nameValidatorFactory,
    },
    {
      provide: 'RegistrationSurnameValidator',
      useExisting: Validator,
      useFactory: RegistrationModule.surnameValidatorFactory,
    },
    {
      provide: 'RegistrationPatronymicValidator',
      useExisting: Validator,
      useFactory: RegistrationModule.patronymicValidatorFactory,
    },
    {
      provide: 'RegistrationPhoneNumberValidator',
      useExisting: Validator,
      useFactory: phoneNumberValidatorFactory,
    },
    {
      provide: RegistrationService,
      useClass: RegistrationServiceImpl,
    },
  ],
})
export class RegistrationModule {
  public static nameValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите имя'),
      new MaxLengthRule('Имя не может быть больше 50 символов', 50),
    ]);
  
    public static surnameValidatorFactory = () =>
      new Validator([
        new EmptyRule('Введите фамилию'),
        new MaxLengthRule('Фамилия не может быть больше 50 символов', 50),
      ]);
    
    public static patronymicValidatorFactory = () =>
      new Validator([
        new MaxLengthRule('Отчество не может быть больше 50 символов', 50),
      ]);
}
