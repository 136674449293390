import { SectionSummaryEntity } from '../../domain/section-summary-entity';
import { Injectable } from '@angular/core';
import { FilterMenuItem } from '../../../../../../core/components/filter-menu/filter-menu.component';
import { ActionElement } from '../../../../../../core/components/action-menu/action-menu.component';
import { Constants } from '../common/сonstants';

@Injectable()
export class SectionsState {
  readonly sections: SectionSummaryEntity[] = [];
  readonly filteredSections: SectionSummaryEntity[] = [];
  readonly createSectionDto: SectionSummaryEntity = Constants.EMPTY_SECTION;
  readonly createSectionTitleError: string = '';
  readonly iconUrls = Constants.MOCK_ICON_URLS;
  readonly filterItems: FilterMenuItem[] = Constants.INIT_FILTER_MENU_ITEMS;
  readonly currentPrivacyValue = Constants.PRIVACY_FIELD_VALUES[0];
  readonly filtersState: FiltersState = new FiltersState();
  readonly createSectionModalVisibility: boolean = false;
  readonly isLoading: boolean = true;
}

export class FiltersState {
  readonly filter: string = '';
  readonly filterParam: FilterParams = FilterParams.ALL;
  readonly sortParam: ActionElement =
    Constants.SORT_POPUP_SECTION[0].elements[0];
}

export enum SortParams {
  ALPHABET = 'alphabet',
  ALPHABET_REVERT = 'alphabet-revert',
  NEW_TO_OLD = 'new-to-old',
  OLD_TO_NEW = 'old-to-new',
}

export enum FilterParams {
  ALL,
  MARKED,
  MY,
}
