import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastsService } from 'src/app/core/components/toast-alert/services/toast-alert.service';
import { ToastState } from 'src/app/core/components/toast-alert/toast-alert.component';
import { Executor, Reducer } from 'src/app/core/mvi/store';
import { RatingScaleService } from '../../../@data/services/rating-scale.service';
import {
  RatingScalesAction,
  RatingScalesActionTypes,
} from './rating-scales-action';
import {
  RatingScalesResultAction,
  RatingScalesResultActionTypes,
} from './rating-scales-result-action';
import { RatingScalesError, RatingScalesState } from './rating-scales-state';

@Injectable()
export class RatingScalesExecutor
  extends Executor<
    RatingScalesState,
    RatingScalesAction,
    RatingScalesResultAction
  >
  implements OnDestroy
{
  constructor(
    private ratingScalesService: RatingScaleService,
    private toastsService: ToastsService,
  ) {
    super();
  }

  private subscription: Subscription = new Subscription();

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  override init(
    reducer: Reducer<RatingScalesState, RatingScalesResultAction>,
    getState: () => RatingScalesState,
    onReduced: (state: RatingScalesState) => void,
  ) {
    super.init(reducer, getState, onReduced);

    this.subscription.add(
      this.ratingScalesService.getRatingScales().subscribe({
        next: (ratingScales) => {
          this.reduce({
            type: RatingScalesResultActionTypes.UPDATE_RATING_SCALES,
            ratingScales: ratingScales.scales,
          });
        },
        error: (error: HttpErrorResponse) => {
          this.reduce({
            type: RatingScalesResultActionTypes.CHANGE_ERROR_STATUS,
            error: (() => {
              switch (error.status) {
                case HttpStatusCode.Unauthorized:
                  return RatingScalesError.INCORRECT_CREDENTIALS;
                default:
                  return RatingScalesError.CONNECTION_ERROR;
              }
            })(),
          });
        },
        complete: () => {
          this.reduce({
            type: RatingScalesResultActionTypes.CHANGE_LOADING_STATUS,
            isLoading: false,
          });
        },
      }),
    );

    this.subscription.add(
      this.ratingScalesService.createRatingScaleEvent$.subscribe(() => {
        this.ratingScalesService.getRatingScales().subscribe({
          next: (ratingScales) => {
            this.reduce({
              type: RatingScalesResultActionTypes.UPDATE_RATING_SCALES,
              ratingScales: ratingScales.scales,
            });
          },
        });
      }),
    );
  }

  execute(action: RatingScalesAction) {
    switch (action.type) {
      case RatingScalesActionTypes.TOGGLE_SELECTED_RATING_SCALE:
        this.reduce({
          type: RatingScalesResultActionTypes.TOGGLE_SELECTED_RATING_SCALE,
          selectedRatingScale: action.selectedRatingScale,
          selected: action.selected,
        });
        break;

      case RatingScalesActionTypes.CHANGE_SEARCH:
        this.reduce({
          type: RatingScalesResultActionTypes.CHANGE_SEARCH,
          search: action.search,
        });
        break;

      case RatingScalesActionTypes.DELETE_RATING_SCALE:
        {
          this.reduce({
            type: RatingScalesResultActionTypes.CHANGE_LOADING_DELETE_STATUS,
            isLoadingDelete: action.ratingScaleId,
          });
          this.ratingScalesService
            .deleteRatingScale(action.ratingScaleId)
            .subscribe({
              next: () => {
                this.reduce({
                  type: RatingScalesResultActionTypes.UPDATE_RATING_SCALES,
                  ratingScales: this.getState().ratingScales.filter(
                    (ratingScale) => ratingScale.id !== action.ratingScaleId,
                  ),
                });
                this.reduce({
                  type: RatingScalesResultActionTypes.CHANGE_ERROR_DELETE_STATUS,
                  errorDelete: null,
                });
                this.toastsService.createToast({
                  title: 'Оценочная шкала успешно удалена',
                  description: '',
                  state: ToastState.SUCCESS,
                });
              },
              error: (error) => {
                this.toastsService.createToast({
                  title: 'Не удалось удалить оценочную шкалу',
                  description: 'Код ошибки: ' + error.status,
                  state: ToastState.ERROR,
                });
                this.reduce({
                  type: RatingScalesResultActionTypes.CHANGE_ERROR_DELETE_STATUS,
                  errorDelete: (() => {
                    switch (error.status) {
                      case HttpStatusCode.Unauthorized:
                        return RatingScalesError.INCORRECT_CREDENTIALS;
                      default:
                        return RatingScalesError.CONNECTION_ERROR;
                    }
                  })(),
                });
              },
              complete: () => {
                this.reduce({
                  type: RatingScalesResultActionTypes.CHANGE_LOADING_DELETE_STATUS,
                  isLoadingDelete: null,
                });
              },
            });
        }
        break;
    }
  }
}
