import { SpaceSettingsInput } from "../space-settings.component"
import { SpaceSettingsNavItems } from "./space-settings-state"

export type SpaceSettingsAction =
  | InitAction
  | ChangeSpaceTitleAction
  | SaveTitleAction
  | CancelTitleAction
  | ChangeNavItemAction
  | DeleteAction
  | ChangeDeleteAlertModalVisibilityAction

export enum SpaceSettingsActionTypes {
  INIT,
  CHANGE_SPACE_TITLE,
  SAVE_TITLE,
  CANCEL_TITLE,
  CHANGE_NAV_ITEM,
  DELETE,
  CHANGE_DELETE_ALERT_MODAL_VISIBILITY
}

export interface ChangeDeleteAlertModalVisibilityAction{
  readonly type: SpaceSettingsActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY
  readonly value: boolean
}

export interface DeleteAction {
  readonly type: SpaceSettingsActionTypes.DELETE,
}

export interface ChangeNavItemAction {
  readonly type: SpaceSettingsActionTypes.CHANGE_NAV_ITEM,
  readonly navItem: SpaceSettingsNavItems
}

export interface CancelTitleAction {
  readonly type: SpaceSettingsActionTypes.CANCEL_TITLE
}

export interface SaveTitleAction {
  readonly type: SpaceSettingsActionTypes.SAVE_TITLE
}

export interface InitAction {
  readonly type: SpaceSettingsActionTypes.INIT
  readonly input: SpaceSettingsInput
}

export interface ChangeSpaceTitleAction {
  readonly type: SpaceSettingsActionTypes.CHANGE_SPACE_TITLE
  readonly value: string
}
