import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { PlatformLocation } from "@angular/common";
import { Clipboard} from "@angular/cdk/clipboard";
import { ToastState } from "../../../../../core/components/toast-alert/toast-alert.component";
import { ToastsService } from "../../../../../core/components/toast-alert/services/toast-alert.service";

@Injectable({
  providedIn: 'root',
})
export class SectionNavigator {
  baseUrl: string;

  constructor(
    private router: Router,
    private clipboard: Clipboard,
    private platform: PlatformLocation,
    private toastService: ToastsService)
  {
    this.baseUrl = platform.port ? 'http://' + platform.hostname + ':' + platform.port : 'https://' + platform.hostname
  }

  openContent(sectionId: number) {
    this.router.navigate(['section', sectionId, 'content']);
  }

  openSettings(sectionId: number) {
    this.router.navigate(['section', sectionId, 'settings']);
  }

  openDocument(sectionId: number, documentId: string) {
    this.router.navigate(['section', sectionId, 'document', documentId]);
  }

  openEditDocument(documentId: string) {
    window.open(`${this.baseUrl}/document/${documentId}`, '_blank');
  }

  copySectionUrl(id: number){
    this.copyToClipboard(`${this.baseUrl}/section/${id}/content`)
  }

  copyDocumentUrl(id: string)
  {
    this.copyToClipboard(`${this.baseUrl}/document/${id}`)
  }

  copyToClipboard(text: string){
    if(this.clipboard.copy(text)){
      this.toastService.createToast({
        title: 'Ссылка скопированна',
        description: 'Ссылка скопированна в буфер обмена',
        state: ToastState.SUCCESS
      })
    }
    else {
      this.toastService.createToast({
        title: 'Произошла ошибка',
        description: 'Не удалось скопировать ссылку',
        state: ToastState.ERROR
      })
    }
  }
}
