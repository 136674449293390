import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { EmployeeSummeryEntity } from "../../../features/employees/domain/employee";
import { stringToRgb } from "../../utils/string-to-rgb";
import { ActionElementStyle, ActionSection } from "../action-menu/action-menu.component";
import { EmployeeDto } from "../../../features/employees/data/dto/employee-dto";

const sections: ActionSection[] = [
  {
    elements: [{
      id: 'edit',
      name: 'Редактировать'
    },]
  }
]

@Component({
  selector: "app-user-card",
  templateUrl: "./user-card.component.html",
  styleUrls: ["./user-card.component.scss"]
})
export class UserCardComponent implements OnChanges {
  @Input() employee: EmployeeSummeryEntity = {
    id: -1,
    name: '',
    surname: '',
    icon: '',
    email: 'Voronindim@mail.ru',
    phoneNumber: '+7 (902) 433-83-82',
    nameParts: [],
    emailParts: []
  }
  @Input() canEdit: boolean = false

  @Input() nameType: 'SurnameNamePatronymic' | 'SurnameName' | 'SurnameNP' | 'NameSurname' = 'SurnameNamePatronymic'

  @Output() messageClicked = new EventEmitter()
  @Output() linkClicked = new EventEmitter()
  @Output() editClicked = new EventEmitter()
  @Output() deleteClicked = new EventEmitter()

  @ViewChild('topBg') topBg?: ElementRef;

  phoneNumber: string = ''

  avatarPlaceHolder: string = 'ДЕ'
  avatarBgRGB: string = stringToRgb(this.employee.email)

  isPopupVisible: boolean = false

  constructor(private renderer: Renderer2) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['employee']){

      this.phoneNumber = this.employee.phoneNumber.replace(/\D/g, '')
      this.phoneNumber = '+' + this.phoneNumber.slice(0, this.phoneNumber.length - 10) +
        ' (' + this.phoneNumber.slice(this.phoneNumber.length - 10, this.phoneNumber.length - 7) +
        ') ' + this.phoneNumber.slice(this.phoneNumber.length -7, this.phoneNumber.length -4) +
        '-' + this.phoneNumber.slice(this.phoneNumber.length -4, this.phoneNumber.length -2) +
        '-' + this.phoneNumber.slice(this.phoneNumber.length -2);

      this.avatarPlaceHolder = (this.employee.surname.charAt(0) + this.employee.name.charAt(0)).toUpperCase()
      this.avatarBgRGB = stringToRgb(this.employee.email)
      if(this.topBg?.nativeElement != undefined)
      {
        this.renderer.setStyle(this.topBg?.nativeElement, 'background', `linear-gradient(87.91deg, rgba(${this.avatarBgRGB}, 0.25) 0%, rgba(${this.avatarBgRGB}, 0.15) 100%)`, 1)
      }
    }
  }

  onElementClick(id: string){
    switch(id){
      case 'edit':
        this.editClicked.emit()
        break;
      case 'delete':
        this.deleteClicked.emit()
        break;
    }
    this.isPopupVisible = false
  }

  protected readonly sections = sections;
}
