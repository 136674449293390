import { Component } from '@angular/core';
import { Store } from 'src/app/core/mvi/store';
import {
  CompetenciesAndSkillsAction,
  CompetenciesAndSkillsActionTypes,
} from '../state/competencies-and-skills-action';
import { CompetenciesAndSkillsExecutor } from '../state/competencies-and-skills-executor';
import { CompetenciesAndSkillsReducer } from '../state/competencies-and-skills-reducer';
import { CompetenciesAndSkillsResultAction } from '../state/competencies-and-skills-result-action';
import { CompetenciesAndSkillsState } from '../state/competencies-and-skills-state';

@Component({
  selector: 'app-competencies-and-skills',
  templateUrl: './competencies-and-skills.component.html',
})
export class CompetenciesAndSkillsComponent extends Store<
  CompetenciesAndSkillsState,
  CompetenciesAndSkillsExecutor,
  CompetenciesAndSkillsAction,
  CompetenciesAndSkillsResultAction
> {
  constructor(
    state: CompetenciesAndSkillsState,
    executor: CompetenciesAndSkillsExecutor,
    reducer: CompetenciesAndSkillsReducer,
  ) {
    super(state, executor, reducer);
  }

  protected readonly CompetenciesAndSkillsActionTypes =
    CompetenciesAndSkillsActionTypes;
}
