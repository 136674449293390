import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { GradeHomeComponent } from './presentation/view/grade-home.component';

@NgModule({
  declarations: [GradeHomeComponent],
  exports: [GradeHomeComponent],
  imports: [CommonModule, ComponentsModule],
})
export class GradeHomeModule {}
