import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Inject,
  Input, OnChanges,
  Output, signal, SimpleChanges,
  ViewChild
} from "@angular/core";
import {DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter} from '@angular/material/core';
import { MatDatepickerIntl } from "@angular/material/datepicker";
import { Validator } from "src/app/core/validators/validator";

@Component({
  selector: 'create-employee-modal-main',
  templateUrl: './create-employee-modal-main.component.html',
  styleUrls: ['./create-employee-modal-main.component.scss'],
  providers: [NativeDateAdapter],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateEmployeeModalMainComponent implements OnChanges{
  @Input() mainEmployeeData: EmployeeMainData = {
    name:'',
    surname: '',
    patronymic: '',
    birthdayDate: null,
    gender: 0,
    avatarUrl: "",
    nameError: null,
    surnameError: null,
    patronymicError: null,
    birthdayDateError: null,
  }
  @Input() showErrors: boolean = false

  @Output() onChange = new EventEmitter<EmployeeMainData>()

  @ViewChild('calendar') calendarRef!: ElementRef
  @ViewChild('calendarButton') calendarButtonRef!: ElementRef

  isCalendarVisible: boolean = false
  selectedDate: Date | null = null;
  previousDate: Date | null = null;
  birthdayDate: string = '00.00.0000'
  private readonly _adapter = inject<DateAdapter<unknown, unknown>>(DateAdapter);
  private readonly _locale = signal(inject<unknown>(MAT_DATE_LOCALE));
  private readonly _intl = inject(MatDatepickerIntl);
  
  nameError: string | null = null
  surnameError: string | null = null
  patronymicError: string | null = null
  birthdayDateError: string | null = null

  constructor(
    @Inject('NewEmployeeFirstNameValidator') 
    private nameValidator: Validator,
    @Inject('NewEmployeeLastNameValidator') 
    private surnameValidator: Validator,
    @Inject('NewEmployeePatronymicValidator') 
    private patronymicValidator: Validator,
  ){
    this._locale.set('ru')
    this._adapter.setLocale(this._locale());
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['mainEmployeeData'] 
    ){
      this.checkNameValid()
      this.checkPatronymicValid()
      this.checkSurnameValid()
      if(this.mainEmployeeData.birthdayDate != null)
      {
        this.previousDate = this.mainEmployeeData.birthdayDate
        this.selectedDate = this.mainEmployeeData.birthdayDate
        this.birthdayDate = this.dateToStringDMY(this.mainEmployeeData.birthdayDate)  
      }

      this.checkBirthdayValid()
    }
    
    if(changes['showErrors']){
      if(this.showErrors){
        this.patronymicError = this.mainEmployeeData.patronymicError
        this.surnameError = this.mainEmployeeData.surnameError
        this.nameError = this.mainEmployeeData.nameError
        this.birthdayDateError = this.mainEmployeeData.birthdayDateError
      }
    }
  }

  patronymicChange(str: string)
  {
    this.mainEmployeeData.patronymic = str
    this.checkPatronymicValid()
    this.patronymicError = this.mainEmployeeData.patronymicError
    this.onChange.emit(this.mainEmployeeData)
  }

  nameChange(str: string){
    this.mainEmployeeData.name = str
    this.checkNameValid()
    this.nameError = this.mainEmployeeData.nameError
    this.onChange.emit(this.mainEmployeeData)
  }

  surnameChange(str: string){
    this.mainEmployeeData.surname = str
    this.checkSurnameValid()
    this.surnameError = this.mainEmployeeData.surnameError
    this.onChange.emit(this.mainEmployeeData)
  }

  dateInputChange(str: string){
    this.birthdayDate = str
    this.mainEmployeeData.birthdayDate = this.stringDMYToDate(this.birthdayDate)
    this.checkBirthdayValid()
    this.birthdayDateError = this.mainEmployeeData.birthdayDateError

    if(this.mainEmployeeData.birthdayDateError == null){
      this.selectedDate = this.mainEmployeeData.birthdayDate
      this.previousDate = this.selectedDate
    }

    this.onChange.emit(this.mainEmployeeData)
  }

  dateApply(){
    if(this.selectedDate != null)
    {
      this.previousDate = this.selectedDate
      this.mainEmployeeData.birthdayDate = this.selectedDate
      this.datepickerClose()
      this.onChange.emit(this.mainEmployeeData)
    }
  }

  datepickerClose(){
    this.checkBirthdayValid() 
    this.birthdayDateError = this.mainEmployeeData.birthdayDateError
    this.birthdayDate = this.previousDate != null ? this.dateToStringDMY(this.previousDate) : '00.00.0000'
    this.isCalendarVisible = false
  }

  birthDayChange(date: Date | null){
    if(date != null){
      this.birthdayDate = this.dateToStringDMY(date)
    }
  }

  checkNameValid(){
    this.mainEmployeeData.nameError = this.nameValidator.validate(this.mainEmployeeData.name)
  }

  checkSurnameValid() {
    this.mainEmployeeData.surnameError = this.surnameValidator.validate(this.mainEmployeeData.surname)
  }

  checkPatronymicValid() {
    this.mainEmployeeData.patronymicError = this.patronymicValidator.validate(this.mainEmployeeData.patronymic)
  }

  checkBirthdayValid() {
    const pattern: RegExp = /^(0[1-9]|[12][0-9]|3[01])(\.)(0[1-9]|1[1,2])(\.)(19|20)\d{2}$/

    if(this.mainEmployeeData.birthdayDate == null) {
      this.mainEmployeeData.birthdayDateError = 'Введите дату рождения'
    }
    else {
      if(!pattern.test(this.birthdayDate)){
        this.mainEmployeeData.birthdayDateError = ' '
      }
      else{
        this.mainEmployeeData.birthdayDateError = null
      }
    }
  }

  selectGender(id: number){
    this.mainEmployeeData.gender = id == 0 || id == 1 || id == 2? id: 0
    this.onChange.emit(this.mainEmployeeData)
  }

  dateToStringDMY(date: Date): string{
    const month = date.getMonth() + 1
    const day = date.getDate()
    return (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + date.getFullYear()
  }

  stringDMYToDate(str: string): Date{
    let parseStr = str.split('.')
    return new Date(Number(parseStr[2]), Number(parseStr[1]) - 1, Number(parseStr[0]))
  }

  protected readonly GENDERS = GENDERS;
}

export interface EmployeeMainData {
  name: string,
  surname: string,
  patronymic: string,
  birthdayDate: Date | null,
  gender: 0 | 1 | 2,
  avatarUrl: string,
  nameError: string | null 
  surnameError: string | null
  patronymicError: string | null
  birthdayDateError: string | null
}

const GENDERS: {id: number, name: string}[] = [
  {
    id: 0,
    name: 'Не выбранно',
  },
  {
    id: 1,
    name: 'Мужской',
  },
  {
    id: 2,
    name: 'Женский',
  },
]
