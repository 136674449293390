import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ComponentsModule } from 'src/app/core/components/components.module';
import {
  SvgCalendar, SvgChipsX,
  SvgLoading, SvgPhoto, SvgQuestionMarkCircle,
  SvgTripleDot,
  SvgXMark
} from "src/app/core/components/svg-components/svg.components";
import {
  DateRule,
  EmptyRule,
  MaxLengthRule,
} from 'src/app/core/validators/rule';
import { Validator } from 'src/app/core/validators/validator';
import {
  emailValidatorFactory,
  passwordValidatorFactory,
  phoneNumberValidatorFactory,
} from 'src/app/core/validators/validators';
import { EmployeeService } from 'src/app/features/employees/data/employee-service';
import { EmployeesListComponent } from './presentation/employees-list.component';
import { CreateEmployeeModalComponent } from './presentation/components/create-employee-modal/create-employee-modal.component';
import { CreateEmployeeModalMainComponent } from './presentation/components/create-employee-modal/components/create-employee-modal-main/create-employee-modal-main.component';
import { CreateEmployeeModalContactsComponent } from './presentation/components/create-employee-modal/components/create-employee-modal-contacts/create-employee-modal-contacts.component';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatCardModule } from "@angular/material/card";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {
  CreateEmployeeModalRolesComponent
} from "./presentation/components/create-employee-modal/components/create-employee-modal-roles/create-employee-modal-roles.component";
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { EmployeeTrComponent } from './presentation/components/employee-tr/employee-tr.component';
import { ChipsComponent } from './presentation/components/create-employee-modal/components/role-chips/chips.component';
import { RolesPopupComponent } from './presentation/components/create-employee-modal/components/roles-popup/roles-popup.component';
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [
    EmployeesListComponent,
    CreateEmployeeModalComponent,
    CreateEmployeeModalMainComponent,
    CreateEmployeeModalContactsComponent,
    CreateEmployeeModalRolesComponent,
    EmployeeTrComponent,
    ChipsComponent,
    RolesPopupComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterOutlet,
    SvgXMark,
    SvgTripleDot,
    SvgLoading,
    MatNativeDateModule,
    MatCardModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    SvgCalendar,
    MatProgressSpinnerModule,
    MatTooltipModule,
    NgOptimizedImage,
    SvgPhoto,
    SvgChipsX,
    SvgQuestionMarkCircle
  ],
  providers: [
    {
      provide: "NewEmployeeFirstNameValidator",
      useExisting: Validator,
      useFactory: EmployeesListModule.firstNameValidatorFactory
    },
    {
      provide: "NewEmployeeLastNameValidator",
      useExisting: Validator,
      useFactory: EmployeesListModule.lastNameValidatorFactory
    },
    {
      provide: "NewEmployeeDateOfBirthValidator",
      useExisting: Validator,
      useFactory: EmployeesListModule.dateOfBirthValidatorFactory
    },
    {
      provide: "NewEmployeePatronymicValidator",
      useExisting: Validator,
      useFactory: EmployeesListModule.patronymicValidatorFactory
    }
  ],
})
export class EmployeesListModule {

  public static firstNameValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите имя'),
      new MaxLengthRule('Имя не может быть длиннее 50 символов', 50),
    ]);

  public static lastNameValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите фамилию'),
      new MaxLengthRule('Фамилия не может быть длиннее 50 символов', 50),
    ]);

  public static dateOfBirthValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите дату рождения'),
      new DateRule(
        ' ',
      ),
    ]);

  public static nameValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите название департамента'),
      new MaxLengthRule(
        'Название департамента не должно быть больше 50 символов',
        50,
      ),
    ]);

  public static patronymicValidatorFactory = () =>
      new Validator([
        new MaxLengthRule('Отчество не может быть больше 50 символов', 50),
      ]);
}
