import { SettingsMainState } from "./settings-main-state";
import { SettingsMainAction, SettingsMainActionTypes } from "./settings-main-action";
import { SettingsMainResultAction, SettingsMainResultActionTypes } from "./settings-main-result-action";
import { Inject, Injectable } from "@angular/core";
import { Executor, Reducer } from "src/app/core/mvi/store";
import { HeaderService } from "../../../../../main/data/header-service";
import { Validator } from "../../../../../../core/validators/validator";
import { SettingsMainConstants } from "../../common/settings-main-constants";
import { CompanyService } from "../../../../../../core/services/company-service/company-service";
import { ToastsService } from "../../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../../core/components/toast-alert/toast-alert.component";

@Injectable()
export class SettingsMainExecutor extends Executor<
  SettingsMainState,
  SettingsMainAction,
  SettingsMainResultAction
> {
  constructor(
    private headerService: HeaderService,
    @Inject('CompanyTitleValidator')
    private companyTitleValidator: Validator,
    private companyService: CompanyService,
    private toastService: ToastsService
  ) {
    super();
  }
  override init(
    reducer: Reducer<SettingsMainState, SettingsMainResultAction>,
    getState: () => SettingsMainState,
    onReduced: (state: SettingsMainState) => void,
  ) {
    super.init(reducer, getState, onReduced);

    this.headerService.header.subscribe((header)=>{
      this.reduce({
        type: SettingsMainResultActionTypes.INIT,
        organisationId: '-1',
        organisationTitle: header.organisationName
      })
    })
  }

  execute(action: SettingsMainAction) {
    switch (action.type) {
      case SettingsMainActionTypes.DELETE:
        this.companyService.deleteCompany(this.getState().organisationId).subscribe()
        break
      case SettingsMainActionTypes.CHANGE_ORGANISATION_NAME:
        this.reduce({
          type: SettingsMainResultActionTypes.CHANGE_ORGANISATION_NAME,
          newOrganisationTitle: action.value,
          organisationTitleError: this.companyTitleValidator.validate(action.value)
        })
        break;
      case SettingsMainActionTypes.CANCEL_TITLE:
        this.reduce({
          type: SettingsMainResultActionTypes.CANCEL_TITLE
        })
        break
      case SettingsMainActionTypes.SAVE_TITLE:
        if(!this.getState().organisationTitleError)
        {
          const prevTitle = this.getState().organisationTitle
          this.headerService.changeOrganisationName(this.getState().newOrganisationTitle)
          this.companyService.updateCompany(this.getState().newOrganisationTitle).subscribe({
            error: result => {
              this.headerService.changeOrganisationName(prevTitle)
              this.toastService.createToast({
                title: 'Произошла ошибка',
                description: 'Попробуйте позднее',
                state: ToastState.ERROR
              })
            }
          })
        }
        break
      case SettingsMainActionTypes.CHANGE_NAV_ITEM:
        this.reduce({
          type: SettingsMainResultActionTypes.INIT,
          organisationId: this.getState().organisationId,
          organisationTitle: this.getState().organisationTitle
        })
        this.reduce({
          type: SettingsMainResultActionTypes.CHANGE_NAV_ITEM,
          navItem: action.navItem
        })
        break;
      case SettingsMainActionTypes.CHANGE_LANGUAGE:
        this.reduce({
          type: SettingsMainResultActionTypes.CHANGE_LANGUAGE,
          language: SettingsMainConstants.LANGUAGE_DROPDOWNS.find((item)=> item.id == action.id)
        })
        break
      case SettingsMainActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY:
        this.reduce({
          type: SettingsMainResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
          value: action.value
        })
        break
    }
  }
}
