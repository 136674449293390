<div [ngClass]=styles>
  <div class="flex">
    <div
      class="flex text-[14px] h-[18px] text-tt-text-secondary"
      *ngFor="let element of pathEntities"
    >
      <p [ngClass]="{'cursor-pointer': isClickable}" (click)="pathClicked(element.id)">
        {{ element.text }}
      </p>
      <p>&nbsp;/&nbsp;</p>
    </div>
  </div>
</div>
