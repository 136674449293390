import { Component } from '@angular/core';

@Component({
  selector: 'app-core-loading-shading',
  templateUrl: './loading-shading.component.html',
  host: {
    class: 'absolute left-0 top-0 bg-tt-informer-loading-background w-full h-full z-50 flex justify-center items-center',
  }
})
export class LoadingShadingComponent {

}
