import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { MainRoutesPaths } from "../../../../../routes/main-routes";
import { SettingsRoutesPaths } from "../../../../../routes/settings-routes";

@Component({
  selector: 'main-remaining-subscription-modal',
  templateUrl: './remaining-subscription-modal.component.html',
  styleUrls: [
    './remaining-subscription-modal.component.scss',
    '../../../../settings/modules/subscription/presentation/components/settings-subscription-order/settings-subscription-order.component.scss'
  ]
})
export class RemainingSubscriptionModalComponent {
  @Input() daysLeft: number | undefined = 0

  isVisible: boolean = true;

  constructor(private router: Router) {}

  close(){
    this.isVisible = false;
  }

  goToPayment(){
    this.router.navigateByUrl(`/${MainRoutesPaths.SETTINGS}/${SettingsRoutesPaths.BILLING}`)
    this.isVisible = false;
  }
}
