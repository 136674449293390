import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SvgEditPencil,
  SvgLink,
  SvgToFullScreen,
  SvgToNormalScreen, SvgXMark
} from "../../../core/components/svg-components/svg.components";
import { SectionEditorComponent } from "./impl/presentation/view/section-editor.component/section-editor.component";
import { AlreadyEditedModalComponent } from './impl/presentation/components/already-edited-modal/already-edited-modal.component';
import { ComponentsModule } from "../../../core/components/components.module";


@NgModule({
  declarations: [
    SectionEditorComponent,
    AlreadyEditedModalComponent
  ],
  imports: [
    CommonModule,
    SvgEditPencil,
    SvgLink,
    SvgToFullScreen,
    SvgToNormalScreen,
    ComponentsModule,
    SvgXMark
  ]
})
export class SectionEditorModule { }
