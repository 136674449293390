import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { stringToRgb } from "../../utils/string-to-rgb";

@Component({
  selector: "app-str-to-colored-bg",
  templateUrl: "./str-to-colored-bg.component.html",
  styleUrls: ["./str-to-colored-bg.component.scss"]
})
export class StrToColoredBgComponent implements OnChanges, AfterViewInit{
  @Input() str: string = '';
  @Input() color: string = ''

  @ViewChild('coloredBox') coloredBox?: ElementRef;

  @Input() colorRGB: string = '129, 129, 129'

  constructor(private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    if(this.coloredBox?.nativeElement != undefined){
      this.renderer.setStyle(this.coloredBox?.nativeElement, 'background', `linear-gradient(87.91deg, rgba(${this.colorRGB}, 0.25) 0%, rgba(${this.colorRGB}, 0.15) 100%)`, 1)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['str'])
    {
      if(this.str != ''){
        this.colorRGB = stringToRgb(this.str)
      }
      if(this.coloredBox?.nativeElement != undefined)
      {
        this.renderer.setStyle(this.coloredBox?.nativeElement, 'background', `linear-gradient(87.91deg, rgba(${this.colorRGB}, 0.25) 0%, rgba(${this.colorRGB}, 0.15) 100%)`, 1)
      }
    }
    if(changes['colorRGB']){
      if(this.coloredBox?.nativeElement != undefined) {
        this.renderer.setStyle(this.coloredBox?.nativeElement, 'background', `linear-gradient(87.91deg, rgba(${this.colorRGB}, 0.25) 0%, rgba(${this.colorRGB}, 0.15) 100%)`, 1)
      }
    }
  }
}
