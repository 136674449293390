<div
  [style.padding-left.px]="depth * 32"
  class="flex items-center h-tt-button-height_l py-tt_extra-s rounded-tt-border-radius_m bg-tt-bg-default transition-colors duration-75 hover:bg-tt-hover-color"
>
  <div class="whitespace-nowrap px-tt_s flex items-center gap-tt_s">
    <svg-skill class="text-tt-icon-default" />
    <span class="text-tt-font-size_l text-tt-text-default">
      {{ skill.name }}
    </span>
  </div>
</div>
