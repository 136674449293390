import { Injectable } from '@angular/core';
import { Reducer } from '../../../../../../core/mvi/store';
import { SectionState } from './section-state';
import {
  SectionResultAction,
  SectionResultActionTypes,
} from './section-result-action';
import { clone } from 'cloneable-ts';
import { SectionEntity } from '../../domain/section-entity';
import { Constants } from '../common/constants';
import { PathEntity } from "../../../../../../core/components/path-line/path-line.component";

@Injectable()
export class SectionReducer
  implements Reducer<SectionState, SectionResultAction>
{
  reduce(state: SectionState, action: SectionResultAction): SectionState {
    switch (action.type) {
      case SectionResultActionTypes.UPDATE_SECTION:
        return this.updateSection(state, action.section);
      case SectionResultActionTypes.CHANGE_DOCUMENT_OPEN_STATE:
        return this.changeDocumentOpenState(state, action.documentId);
      case SectionResultActionTypes.CHANGE_ACTIVE_NAV_ITEM:
        return clone(state, {
          navItem: action.navItem,
          pathEntities: action.pathEntities,
          idActiveDocument: action.documentId,
          openDocuments: this.openPathDocuments(state, action.pathEntities)
        });
    }
  }

  private updateSection(
    state: SectionState,
    newSection: SectionEntity,
  ): SectionState {
    return clone(state, {
      documents: newSection.documents,
      title: newSection.title,
      pathEntities: [{ id: Constants.NO_ID.toString(), text: newSection.title }],
      id: newSection.id,
      url: newSection.thumbnailUrl,
      isLoading: newSection.id == Constants.NO_ID,
    });
  }

  private openPathDocuments(state: SectionState, pathEntities: PathEntity[]): string[]
  {
    const openDocuments = state.openDocuments
    const docToOpen = pathEntities.map((pathEntity, i)=>{
      if(i != 0 && state.openDocuments.indexOf(pathEntity.id) == -1)
      {
        return pathEntity.id
      }
      return undefined
    })

    docToOpen.forEach((doc)=>{
      if(doc !== undefined)
      {
        openDocuments.push(doc)
      }
    })

    return openDocuments
  }

  private changeDocumentOpenState(
    state: SectionState,
    documentId: string,
  ): SectionState {
    if (
      state.openDocuments.find((id) => {
        return id == documentId;
      }) == undefined
    ) {
      const newOpenDocumentsSet = state.openDocuments;
      newOpenDocumentsSet.push(documentId);
      return clone(state, { openDocuments: newOpenDocumentsSet });
    } else {
      const newOpenDocumentsSet = state.openDocuments.filter(
        (itemDocumentId) => {
          return itemDocumentId !== documentId;
        },
      );
      return clone(state, { openDocuments: newOpenDocumentsSet });
    }
  }
}
