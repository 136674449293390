<div class="flex justify-between items-center gap-tt_l">
  <h2 class="text-tt-font-size_3xl text-tt-text-default font-medium">
    Оценочные шкалы
  </h2>
  <app-create-rating-scale>
    <app-core-button>Добавить</app-core-button>
  </app-create-rating-scale>
</div>

<app-core-search-field
  className="my-tt_l"
  (onChange)="
    performAction({
      type: RatingScalesActionTypes.CHANGE_SEARCH,
      search: $event
    })
  "
/>

<app-core-skeleton
  *ngIf="state.isLoading && !state.error"
  className="rounded-tt-border-radius_m w-full h-tt-button-height_l"
  gap="8px"
  [amount]="20"
/>

<div *ngIf="state.error">Error!</div>

<div *ngIf="!state.isLoading && !state.error">
  <ng-container
    *ngFor="
      let ratingScale of state.ratingScales;
      trackBy: ratingScaleTrackByFn
    "
  >
    <app-rating-scale-item
      *ngIf="filterBySearchStr(ratingScale.name, state.search)"
      [deleteLoading]="state.isLoadingDelete === ratingScale.id"
      [ratingScale]="ratingScale"
      [selected]="state.selectedRatingScale === ratingScale.id"
      (onDeleteRatingScale)="
        performAction({
          type: RatingScalesActionTypes.DELETE_RATING_SCALE,
          ratingScaleId: ratingScale.id
        })
      "
      (onToggleMenu)="
        performAction({
          type: RatingScalesActionTypes.TOGGLE_SELECTED_RATING_SCALE,
          selectedRatingScale: ratingScale.id,
          selected: $event
        })
      "
    />
  </ng-container>
</div>
