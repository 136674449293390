import { Component, Input } from '@angular/core';
import { Store } from 'src/app/core/mvi/store';
import { RatingScaleDto } from '../../../@data/dto/rating-scale.dto';
import {
  CreateRatingScaleAction,
  CreateRatingScaleActionTypes,
} from '../state/create-rating-scale-action';
import { CreateRatingScaleExecutor } from '../state/create-rating-scale-executor';
import { CreateRatingScaleReducer } from '../state/create-rating-scale-reducer';
import { CreateRatingScaleResultAction } from '../state/create-rating-scale-result-action';
import { CreateRatingScaleState } from '../state/create-rating-scale-state';

@Component({
  providers: [
    CreateRatingScaleState,
    CreateRatingScaleReducer,
    CreateRatingScaleExecutor,
  ],
  selector: 'app-create-rating-scale',
  templateUrl: './create-rating-scale.component.html',
})
export class CreateRatingScaleComponent extends Store<
  CreateRatingScaleState,
  CreateRatingScaleExecutor,
  CreateRatingScaleAction,
  CreateRatingScaleResultAction
> {
  constructor(
    state: CreateRatingScaleState,
    executor: CreateRatingScaleExecutor,
    reducer: CreateRatingScaleReducer,
  ) {
    super(state, executor, reducer);
  }

  @Input() ratingScale?: RatingScaleDto;

  protected readonly CreateRatingScaleActionTypes =
    CreateRatingScaleActionTypes;

  levelTrackByFn(_: number, level: any) {
    return level.localId;
  }
}
