export type SectionEditorResultAction = SwitchFullScreenResultAction
  | InitResultAction
  | ChangeAlreadyOpenModalVisibilityResultAction;

export enum SectionEditorResultActionTypes {
  SWITCH_FULL_SCREEN,
  CHANGE_ALREADY_OPEN_MODAL_VISIBILITY,
  INIT
}
export interface InitResultAction {
  readonly type: SectionEditorResultActionTypes.INIT;
  readonly documentId: string
}

export interface SwitchFullScreenResultAction {
  readonly type: SectionEditorResultActionTypes.SWITCH_FULL_SCREEN;
  readonly value: boolean
}

export interface ChangeAlreadyOpenModalVisibilityResultAction {
  readonly type: SectionEditorResultActionTypes.CHANGE_ALREADY_OPEN_MODAL_VISIBILITY;
  readonly value: boolean
}
