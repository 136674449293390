import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import {
  ActionElement,
  ActionElementStyle,
  ActionSection
} from "src/app/core/components/action-menu/action-menu.component";
import { RoleSummeryEntity } from "src/app/features/employees/domain/role";
import { MainRoutesPaths } from "../../../../../../routes/main-routes";
import { Router } from "@angular/router";
import { EmployeesConstants } from "../../../../common/employees-constants";

@Component({
  selector: "app-employees-roles-item",
  templateUrl: "./roles-item.component.html",
  styleUrls: ["./roles-item.component.scss"]
})
export class RoleItemComponent implements OnChanges {
  @Input() role: RoleSummeryEntity = {
    id: "-1",
    name: "",
    description: "",
    nameParts: [],
    canEdit: false
  };
  @Input() canDelete: boolean = false;
  @Input() canEdit: boolean = false;

  @Output() deleteClicked = new EventEmitter();
  @Output() editClicked = new EventEmitter();

  isPopupVisible: boolean = false;

  isHover: boolean = false;

  sections: ActionSection[] = [];

  constructor(private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['canEdit'] || changes['canDelete']){
      const newActionElements: ActionElement[] = []
      if(this.canEdit){
        newActionElements.push(EmployeesConstants.EDIT_ACTION_MENU)
      }
      if(this.canDelete){
        newActionElements.push(EmployeesConstants.DELETE_ACTION_MENU)
      }

      this.sections = [
        {
          elements: newActionElements
        }
      ]
    }
  }

  onElementClick(id: string){
    switch(id){
      case 'edit':
        this.router.navigateByUrl('/' + MainRoutesPaths.ROLE + '/' + this.role.id)
        break;
      case 'delete':
        this.deleteClicked.emit()
        break;
    }
    this.isPopupVisible = false
  }

  protected readonly MainRoutesPaths = MainRoutesPaths;
}
