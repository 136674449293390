import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  SpaceSettingsResultAction,
  SpaceSettingsResultActionTypes,
} from './space-settings-result-action';
import { SpaceSettingsState } from './space-settings-state';

@Injectable()
export class SpaceSettingsReducer
  implements Reducer<SpaceSettingsState, SpaceSettingsResultAction>
{
  reduce(state: SpaceSettingsState, action: SpaceSettingsResultAction): SpaceSettingsState {
    switch (action.type) {
      case SpaceSettingsResultActionTypes.INIT:
        return {...state,
          spaceId: action.spaceId,
          spaceTitle: action.spaceTitle,
          newSpaceTitle: action.spaceTitle,
          spaceTitleError: ''
        }
      case SpaceSettingsResultActionTypes.CHANGE_SPACE_TITLE:
        return {...state,
          newSpaceTitle: action.newSpaceTitle,
          spaceTitleError: action.spaceTitleError
        }
      case SpaceSettingsResultActionTypes.CANCEL_TITLE:
        return {...state,
          newSpaceTitle: state.spaceTitle,
          spaceTitleError: ''
        }
      case SpaceSettingsResultActionTypes.CHANGE_NAV_ITEM:
        return {...state,
          currentNavItem: action.navItem
        }
      case SpaceSettingsResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY:
        return {...state, isDeleteAlertVisible: action.value}
      case SpaceSettingsResultActionTypes.CHANGE_ALERT_IS_LOADING:
        return {...state, alertIsLoading: action.value}
    }
  }
}
