import { NavItem } from './section-settings-state';

export type SectionSettingsResultAction = ChangeItemResultAction;
export enum SectionSettingsResultActionTypes {
  SELECT_ITEM,
}

export interface ChangeItemResultAction {
  readonly type: SectionSettingsResultActionTypes.SELECT_ITEM;
  readonly item: NavItem;
}
