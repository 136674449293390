import { Component, Input } from "@angular/core";
import { SectionActionTypes } from "../../state/section-action";
import { NavigationItem } from "../../state/section-state";

@Component({
  selector: 'section-menu-box',
  templateUrl: './section-menu-box.component.html',
  styleUrls: ['./section-menu-box.component.scss']
})
export class SectionMenuBoxComponent {
  @Input() isSelect: boolean = false
  protected readonly SectionActionTypes = SectionActionTypes;
  protected readonly NavigationItem = NavigationItem;
}
