<div class="overflow-x-auto">
  <div class="min-w-min">
    <app-folder-item
      *ngFor="let folder of competenciesAndSkills.folders"
      [folder]="folder"
      [depth]="0"
      [opened]="openedFolders.includes(folder.id)"
      [openedFolders]="openedFolders"
      [openedCompetencies]="openedCompetencies"
      (onOpenFolder)="onOpenFolder.emit($event)"
      (onOpenCompetency)="onOpenCompetency.emit($event)"
      (onCloseFolder)="onCloseFolder.emit($event)"
      (onCloseCompetency)="onCloseCompetency.emit($event)"
    />

    <app-competency-item
      *ngFor="let competency of competenciesAndSkills.competencies"
      [competency]="competency"
      [depth]="0"
      [opened]="openedCompetencies.includes(competency.id)"
      (onOpenCompetency)="onOpenCompetency.emit($event)"
      (onCloseCompetency)="onCloseCompetency.emit($event)"
    />

    <app-skill-item
      *ngFor="let skill of competenciesAndSkills.skills"
      [skill]="skill"
      [depth]="0"
    />
  </div>
</div>
