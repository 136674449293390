import { Injectable } from '@angular/core';
import { ChatState } from '../../../chats/domain/model/messenger-chat-state';

@Injectable({
  providedIn: 'root',
})
export class MessengerSideBarState {
  readonly actualChat: ChatState | null = null;
  showChatInfo: boolean = true;
}
