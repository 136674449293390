import { StepViewState } from "./create-role-modal-state"
import { PermissionsDto } from "../../../../../../../core/services/role-service/dto/create-role-dto";

export type CreateRoleModalResultAction =
  | ChangeNavItemResultAction
  | ChangeIsLoadingResultAction
  | ChangeRoleDescriptionResultAction
  | ChangeRoleNameResultAction
  | CheckNameDescriptionValidResultAction
  | InitResultAction
  | LoadRoleResultAction
  | ChangeIsCreatingResultAction

export enum CreateRoleModalResultActionTypes {
  CHANGE_NAV_ITEM,
  CHANGE_IS_LOADING,
  CHANGE_IS_CREATING,
  CHANGE_ROLE_NAME,
  CHANGE_ROLE_DESCRIPTION,
  CHECK_NAME_DESCRIPTION_VALID,
  INIT,
  LOAD_ROLE
}

export interface LoadRoleResultAction{
  readonly type: CreateRoleModalResultActionTypes.LOAD_ROLE;
  readonly id: string
  readonly name: string
  readonly description: string
  readonly permissions: PermissionsDto
}

export interface InitResultAction {
  readonly type: CreateRoleModalResultActionTypes.INIT;
}

export interface CheckNameDescriptionValidResultAction {
  readonly type: CreateRoleModalResultActionTypes.CHECK_NAME_DESCRIPTION_VALID;
  readonly errorName: string | null
  readonly errorDescription: string | null
}

export interface ChangeRoleDescriptionResultAction {
  readonly type: CreateRoleModalResultActionTypes.CHANGE_ROLE_DESCRIPTION;
  readonly value: string
  readonly error: string | null
  readonly isValid: boolean
}

export interface ChangeRoleNameResultAction {
  readonly type: CreateRoleModalResultActionTypes.CHANGE_ROLE_NAME;
  readonly value: string
  readonly error: string | null
  readonly isValid: boolean
}

export interface ChangeIsCreatingResultAction{
  readonly type: CreateRoleModalResultActionTypes.CHANGE_IS_CREATING
  readonly value: boolean
}

export interface ChangeIsLoadingResultAction{
  readonly type: CreateRoleModalResultActionTypes.CHANGE_IS_LOADING
  readonly value: boolean
}

export interface ChangeNavItemResultAction{
  readonly type: CreateRoleModalResultActionTypes.CHANGE_NAV_ITEM
  readonly stepState: StepViewState
}
