import { EmployeeSummeryEntity } from "../../../../domain/employee";
import { EmployeeDeleteProps } from "../../../../domain/employee-delete-props";

export type EmployeesListAction =
  | ChangeCreateEmployeeModalVisibleAction
  | UpdateEmployeesListAction
  | EditEmployeeAction
  | DeleteEmployeeAction
  | ChangeSearchFieldAction
  | ChangeDeleteAlertModalVisibilityAction

export enum EmployeesListActionTypes {
  UPDATE_EMPLOYEES_LIST,
  CHANGE_NAV_ITEM,
  CHANGE_CREATE_EMPLOYEE_MODAL_VISIBLE,
  EDIT_EMPLOYEE,
  DELETE_EMPLOYEE,
  CHANGE_SEARCH_FIELD,
  CHANGE_DELETE_ALERT_MODAL_VISIBILITY
}

export interface ChangeDeleteAlertModalVisibilityAction{
  readonly type: EmployeesListActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY
  readonly value: boolean
  readonly deleteProps?: EmployeeDeleteProps
}

export interface ChangeSearchFieldAction{
  readonly type: EmployeesListActionTypes.CHANGE_SEARCH_FIELD;
  readonly value: string
}

export interface EditEmployeeAction{
  readonly type: EmployeesListActionTypes.EDIT_EMPLOYEE;
  readonly id: number
}

export interface DeleteEmployeeAction{
  readonly type: EmployeesListActionTypes.DELETE_EMPLOYEE;
}

export interface UpdateEmployeesListAction{
  readonly type: EmployeesListActionTypes.UPDATE_EMPLOYEES_LIST;
}

export interface ChangeCreateEmployeeModalVisibleAction {
  readonly type: EmployeesListActionTypes.CHANGE_CREATE_EMPLOYEE_MODAL_VISIBLE;
  readonly value: boolean
}
