import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Store } from "../../../../../../../core/mvi/store";
import { InvoicingModalState } from "./state/invoicing-modal-state";
import { InvoicingModalExecutor } from "./state/invoicing-modal-executor";
import { InvoicingModalAction, InvoicingModalActionTypes } from "./state/invoicing-modal-action";
import { InvoicingModalResultAction } from "./state/invoicing-modal-result-action";
import { InvoicingModalReducer } from "./state/invoicing-modal-reducer";
import { SettingsSubscriptionConstants } from "../../../common/settings-subscriptioon-constants";
import { ruNumWord } from "../../../../../../../core/utils/ru-num-word";

@Component({
  selector: "app-invoicing-modal",
  templateUrl: "./invoicing-modal.component.html",
  styleUrls: ["" +
  "./invoicing-modal.component.scss",
    "../../../../../../employees/modules/employees-list/presentation/components/create-employee-modal/create-employee-modal.component.scss"
  ],
  providers: [
    InvoicingModalState,
    InvoicingModalExecutor,
    InvoicingModalReducer
  ]
})
export class InvoicingModalComponent extends Store<
  InvoicingModalState,
  InvoicingModalExecutor,
  InvoicingModalAction,
  InvoicingModalResultAction
> implements OnChanges {
  @Input() countUsers: number = 0;
  @Input() periodType: "month" | "year" = "month";

  @Output() close = new EventEmitter()

  usersStr: string = SettingsSubscriptionConstants.USER_DECLENSIONS[0]

  constructor(
    state: InvoicingModalState,
    executor: InvoicingModalExecutor,
    reducer: InvoicingModalReducer
  ) {
    super(state, executor, reducer);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes){
      this.performAction({
        type: InvoicingModalActionTypes.INIT_ON_CHANGES,
        countUsers: this.countUsers,
        periodType: this.periodType
      })
      console.log(ruNumWord(this.countUsers, SettingsSubscriptionConstants.USER_DECLENSIONS))
      this.usersStr = ruNumWord(this.countUsers, SettingsSubscriptionConstants.USER_DECLENSIONS)
    }
  }

  protected readonly InvoicingModalActionTypes = InvoicingModalActionTypes;
}
