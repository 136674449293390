import { Executor, Reducer } from "src/app/core/mvi/store";
import { InvoicingModalResultAction, InvoicingModalResultActionTypes } from "./invoicing-modal-result-action";
import { Inject, Injectable } from "@angular/core";
import { InvoicingModalState } from "./invoicing-modal-state";
import { InvoicingModalAction, InvoicingModalActionTypes } from "./invoicing-modal-action";
import { Validator } from "../../../../../../../../core/validators/validator";
import {
  FormField
} from "../../../../../../../employees/modules/employees-list/presentation/components/create-employee-modal/state/create-employee-modal-state";

@Injectable()
export class InvoicingModalExecutor extends Executor<
  InvoicingModalState,
  InvoicingModalAction,
  InvoicingModalResultAction
> {
  constructor(
    @Inject('InvoicingModalOrganisationNameValidator')
    private organisationNameValidator: Validator,
    @Inject('InvoicingModalLegalAddressValidator')
    private legalAddressValidator: Validator,
    @Inject('InvoicingModalKPPValidator')
    private KPPValidator: Validator,
    @Inject('InvoicingModalINNValidator')
    private INNValidator: Validator,
    @Inject('InvoicingModalSoloProprietorINNValidator')
    private soloProprietorINNValidator: Validator,
    @Inject('RegistrationPhoneNumberValidator')
    private phoneNumberValidator: Validator,
  ) {
    super();
  }

  override init(
    reducer: Reducer<InvoicingModalState,InvoicingModalResultAction>,
    getState: () => InvoicingModalState,
    onReduced: (state: InvoicingModalState) => void)
  {
    super.init(reducer, getState, onReduced);
    this.validateFields()
  }

  execute(action: InvoicingModalAction) {
    switch (action.type) {
      case InvoicingModalActionTypes.CHANGE_INN:
        this.reduce({
          type: InvoicingModalResultActionTypes.CHANGE_INN,
          value: {
            value: action.value,
            error: this.getState().isISoloProprietor ? this.soloProprietorINNValidator.validate(action.value) : this.INNValidator.validate(action.value)
          }
        })
        break;
      case InvoicingModalActionTypes.CHANGE_KPP:
        this.reduce({
          type: InvoicingModalResultActionTypes.CHANGE_KPP,
          value: {
            value: action.value,
            error: this.KPPValidator.validate(action.value)
          }
        })
        break;
      case InvoicingModalActionTypes.CHANGE_LEGAL_ADDRESS:
        this.reduce({
          type: InvoicingModalResultActionTypes.CHANGE_LEGAL_ADDRESS,
          value: {
            value: action.value,
            error: this.legalAddressValidator.validate(action.value)
          }
        })
        break;
      case InvoicingModalActionTypes.CHANGE_ORGANISATION_NAME:
        this.reduce({
          type: InvoicingModalResultActionTypes.CHANGE_ORGANISATION_NAME,
          value: {
            value: action.value,
            error: this.organisationNameValidator.validate(action.value)
          }
        })
        break;
      case InvoicingModalActionTypes.CHANGE_PHONE_NUMBER:
        this.reduce({
          type: InvoicingModalResultActionTypes.CHANGE_PHONE_NUMBER,
          value: {
            value: action.value,
            error: this.phoneNumberValidator.validate(action.value)
          }
        })
        break;
      case InvoicingModalActionTypes.TOGGLE_I_SOLE_PROPRIETOR:
        this.reduce({
          type: InvoicingModalResultActionTypes.TOGGLE_I_SOLE_PROPRIETOR,
          value: !this.getState().isISoloProprietor
        })
        break;
      case InvoicingModalActionTypes.INIT_ON_CHANGES:
        this.reduce({
          type: InvoicingModalResultActionTypes.INIT_ON_CHANGES,
          countUsers: action.countUsers,
          periodType: action.periodType,
        })
        break
      case InvoicingModalActionTypes.OPEN_INVOICE_PAYMENT_MODAL:
        this.reduce({
          type: InvoicingModalResultActionTypes.OPEN_INVOICE_PAYMENT_MODAL
        })
        break
    }
  }

  private validateFields(){
    const state = this.getState()
    this.reduce({
      type: InvoicingModalResultActionTypes.VALIDATE_FIELDS,
      organisationName: { ...state.organisationName, error: this.organisationNameValidator.validate(state.organisationName.value) },
      legalAddress: { ...state.legalAddress, error: this.legalAddressValidator.validate(state.legalAddress.value) },
      INN: { ...state.INN, error: state.isISoloProprietor ? this.soloProprietorINNValidator.validate(state.INN.value) : this.INNValidator.validate(state.INN.value) },
      KPP: { ...state.KPP, error: this.KPPValidator.validate(state.KPP.value) },
      phoneNumber: { ...state.phoneNumber, error: this.phoneNumberValidator.validate(state.phoneNumber.value) },
    })
  }
}
