import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { KnowledgeBaseComponent } from './presentation/knowledge-base.component';
import { KnowledgeBaseService } from './data/knowledge-base-service';
import { Validator } from "../../core/validators/validator";
import { EmptyRule, MaxLengthRule } from "../../core/validators/rule";
import { SpacesModule } from "./modules/spaces/spaces.module";
import { SpaceModule } from "./modules/space/space.module";

@NgModule({
  declarations: [
    KnowledgeBaseComponent
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    SpacesModule,
    SpaceModule,
    RouterOutlet,
  ],
  providers: [
    KnowledgeBaseService,
    {
      provide: "SpaceTitleValidator",
      useExisting: Validator,
      useFactory: KnowledgeBaseModule.spaceTitleValidatorFactory
    },
  ]
})
export class KnowledgeBaseModule {
  public static spaceTitleValidatorFactory = () =>
    new Validator([
      new EmptyRule('Название пространства не может быть пустым'),
      new MaxLengthRule('Название пространства не может быть длиннее 100 символов', 100),
    ]);

}
