import { RatingScaleDto } from '../../../@data/dto/rating-scale.dto';
import { CreateRatingScaleError } from './create-rating-scale-state';

export type CreateRatingScaleResultAction =
  | SetDefaultRatingScaleResultAction
  | OpenModalResultAction
  | CloseModalResultAction
  | ChangeNameResultAction
  | ResetStateResultAction
  | ChangeNameErrorStatusResultAction
  | ChangeLevelNameResultAction
  | ChangeLevelPointsResultAction
  | AddLevelResultAction
  | RemoveLevelResultAction
  | ChangeLevelNameErrorStatusResultAction
  | ChangeLevelPointsErrorStatusResultAction
  | ChangeLoadingStatusResultAction
  | ChangeErrorStatusResultAction;

export enum CreateRatingScaleResultActionTypes {
  SET_DEFAULT_RATING_SCALE,
  RESET_STATE,
  OPEN_MODAL,
  CLOSE_MODAL,
  CHANGE_NAME,
  CHANGE_NAME_ERROR_STATUS,
  CHANGE_LEVEL_NAME,
  CHANGE_LEVEL_POINTS,
  ADD_LEVEL,
  REMOVE_LEVEL,
  CHANGE_LEVEL_NAME_ERROR_STATUS,
  CHANGE_LEVEL_POINTS_ERROR_STATUS,
  CHANGE_LOADING_STATUS,
  CHANGE_ERROR_STATUS,
}

export interface SetDefaultRatingScaleResultAction {
  readonly type: CreateRatingScaleResultActionTypes.SET_DEFAULT_RATING_SCALE;
  readonly ratingScale: RatingScaleDto;
}

export interface ResetStateResultAction {
  readonly type: CreateRatingScaleResultActionTypes.RESET_STATE;
}

export interface OpenModalResultAction {
  readonly type: CreateRatingScaleResultActionTypes.OPEN_MODAL;
}

export interface CloseModalResultAction {
  readonly type: CreateRatingScaleResultActionTypes.CLOSE_MODAL;
}

export interface ChangeNameResultAction {
  readonly type: CreateRatingScaleResultActionTypes.CHANGE_NAME;
  readonly name: string;
}

export interface ChangeNameErrorStatusResultAction {
  readonly type: CreateRatingScaleResultActionTypes.CHANGE_NAME_ERROR_STATUS;
  readonly nameError: string | null;
}

export interface ChangeLevelNameResultAction {
  readonly type: CreateRatingScaleResultActionTypes.CHANGE_LEVEL_NAME;
  readonly levelLocalId: string;
  readonly levelName: string;
}

export interface ChangeLevelPointsResultAction {
  readonly type: CreateRatingScaleResultActionTypes.CHANGE_LEVEL_POINTS;
  readonly levelLocalId: string;
  readonly levelPoints: number;
}

export interface AddLevelResultAction {
  readonly type: CreateRatingScaleResultActionTypes.ADD_LEVEL;
}

export interface RemoveLevelResultAction {
  readonly type: CreateRatingScaleResultActionTypes.REMOVE_LEVEL;
  readonly levelLocalId: string;
}

export interface ChangeLevelNameErrorStatusResultAction {
  readonly type: CreateRatingScaleResultActionTypes.CHANGE_LEVEL_NAME_ERROR_STATUS;
  readonly levelId: string;
  readonly levelNameError: string | null;
}

export interface ChangeLevelPointsErrorStatusResultAction {
  readonly type: CreateRatingScaleResultActionTypes.CHANGE_LEVEL_POINTS_ERROR_STATUS;
  readonly levelLocalId: string;
  readonly levelPointsError: string | null;
}

export interface ChangeLoadingStatusResultAction {
  readonly type: CreateRatingScaleResultActionTypes.CHANGE_LOADING_STATUS;
  readonly isLoading: boolean;
}

export interface ChangeErrorStatusResultAction {
  readonly type: CreateRatingScaleResultActionTypes.CHANGE_ERROR_STATUS;
  readonly error: CreateRatingScaleError | null;
}
