<div
  class="flex justify-between gap-tt_l w-full min-h-[112px] py-tt_l border-b border-tt-separator-color"
>
  <div class="flex flex-row items-center gap-tt_l">
    <div
      class="flex justify-center items-center relative min-h-[80px] min-w-[80px] h-[80px] w-[80px]"
    >
      <span class="font-extralight text-tt-font-size_3xl text-tt-primary">
        72%
      </span>
      <svg
        class="absolute top-0 left-0 text-tt-primary"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_12_5" fill="white">
          <path
            d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40ZM8 40C8 57.6731 22.3269 72 40 72C57.6731 72 72 57.6731 72 40C72 22.3269 57.6731 8 40 8C22.3269 8 8 22.3269 8 40Z"
          />
        </mask>
        <path
          d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40ZM8 40C8 57.6731 22.3269 72 40 72C57.6731 72 72 57.6731 72 40C72 22.3269 57.6731 8 40 8C22.3269 8 8 22.3269 8 40Z"
          stroke="#DCE1E6"
          stroke-width="37.8415"
          mask="url(#path-1-inside-1_12_5)"
        />
        <mask id="path-2-inside-2_12_5" fill="white">
          <path
            d="M35.893 4.23504C35.641 2.04032 37.218 0.0357402 39.4269 0.00407802C45.9591 -0.0895542 52.4369 1.41848 58.2835 4.42311C65.3199 8.03923 71.126 13.6607 74.9676 20.5766C78.8092 27.4926 80.5137 35.3924 79.8655 43.277C79.2174 51.1617 76.2457 58.6771 71.3264 64.8729C66.407 71.0686 59.7608 75.6665 52.2283 78.085C44.6958 80.5036 36.6153 80.6341 29.0085 78.4602C21.4018 76.2863 14.6106 71.9056 9.49358 65.872C5.24189 60.8587 2.30475 54.8913 0.915247 48.5079C0.445378 46.3493 2.04032 44.359 4.23503 44.107V44.107C6.42975 43.8549 8.38841 45.4396 8.9121 47.5858C10.0865 52.3985 12.3672 56.8917 15.5949 60.6976C19.6885 65.5245 25.1215 69.029 31.2068 70.7682C37.2922 72.5073 43.7566 72.4028 49.7826 70.468C55.8086 68.5332 61.1256 64.8549 65.0611 59.8983C68.9966 54.9417 71.3739 48.9294 71.8924 42.6216C72.4109 36.3139 71.0473 29.9941 67.9741 24.4613C64.9008 18.9286 60.2559 14.4314 54.6268 11.5385C50.1883 9.2575 45.2951 8.05466 40.3415 8.0018C38.1324 7.97822 36.145 6.42975 35.893 4.23504V4.23504Z"
          />
        </mask>
        <path
          d="M35.893 4.23504C35.641 2.04032 37.218 0.0357402 39.4269 0.00407802C45.9591 -0.0895542 52.4369 1.41848 58.2835 4.42311C65.3199 8.03923 71.126 13.6607 74.9676 20.5766C78.8092 27.4926 80.5137 35.3924 79.8655 43.277C79.2174 51.1617 76.2457 58.6771 71.3264 64.8729C66.407 71.0686 59.7608 75.6665 52.2283 78.085C44.6958 80.5036 36.6153 80.6341 29.0085 78.4602C21.4018 76.2863 14.6106 71.9056 9.49358 65.872C5.24189 60.8587 2.30475 54.8913 0.915247 48.5079C0.445378 46.3493 2.04032 44.359 4.23503 44.107V44.107C6.42975 43.8549 8.38841 45.4396 8.9121 47.5858C10.0865 52.3985 12.3672 56.8917 15.5949 60.6976C19.6885 65.5245 25.1215 69.029 31.2068 70.7682C37.2922 72.5073 43.7566 72.4028 49.7826 70.468C55.8086 68.5332 61.1256 64.8549 65.0611 59.8983C68.9966 54.9417 71.3739 48.9294 71.8924 42.6216C72.4109 36.3139 71.0473 29.9941 67.9741 24.4613C64.9008 18.9286 60.2559 14.4314 54.6268 11.5385C50.1883 9.2575 45.2951 8.05466 40.3415 8.0018C38.1324 7.97822 36.145 6.42975 35.893 4.23504V4.23504Z"
          stroke="currentColor"
          stroke-width="37.8415"
          mask="url(#path-2-inside-2_12_5)"
        />
      </svg>
    </div>
    <div>
      <span class="text-tt-text-default text-tt-font-size_l">
        Оценка менеджеров 2+ категории. Май 2024
      </span>
      <br />
      <span class="text-tt-text-secondary text-tt-font-size_m">
        Сессия для проведения оценки менеджеров 1 категории, реботающих более
        полугода в компании
      </span>
      <br />
      <span>
        <span class="text-tt-text-secondary text-tt-font-size_l">
          Менеджер:
        </span>
        <span class="text-tt-text-secondary text-tt-font-size_l">
          Воронин Дмитрий
        </span>
      </span>
    </div>
  </div>
  <div class="h-full">
    <span class="text-tt-text-secondary text-tt-font-size_l">
      с 11 апреля 2025г.
    </span>
  </div>
</div>
