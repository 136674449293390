import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './presentation/settings.component';
import { SettingsMainModule } from './modules/main/settings-main.module';
import { SettingsSubscriptionModule } from './modules/subscription/settings-subscription.module';
import { RouterOutlet } from '@angular/router';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { Validator } from "../../core/validators/validator";
import { EmptyRule, MaxLengthRule } from "../../core/validators/rule";

@NgModule({
  declarations: [
    SettingsComponent
  ],
  imports: [
    CommonModule,
    SettingsMainModule,
    SettingsSubscriptionModule,
    RouterOutlet,
    ComponentsModule
  ],
  providers: [
    {
      provide: "CompanyTitleValidator",
      useExisting: Validator,
      useFactory: SettingsModule.companyTitleValidatorFactory
    },
  ]
})
export class SettingsModule {
  public static companyTitleValidatorFactory = () =>
    new Validator([
      new EmptyRule('Название пространства не может быть пустым'),
      new MaxLengthRule('Название пространства не может быть длиннее 100 символов', 100),
    ]);
}
