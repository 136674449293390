import {Injectable} from '@angular/core';
import {DefaultCompanyInfoJson} from './json/default-company-info-json';
import {DefaultCompanyInfoEntity} from '../domain/model/default-company-info-entity';
import {RegistrationEntity} from '../domain/model/registration-entity';
import {RegistrationJson} from './json/registration-json';

@Injectable({
  providedIn: 'root',
})
export class RegistrationMapper {
  mapDefaultCompanyInfo(
    json: DefaultCompanyInfoJson,
  ): DefaultCompanyInfoEntity {
    return {
      specializations: json.specializations.map((value) => {
        return {
          id: value.id,
          text: value.text,
        };
      }),
      stuffSizes: json.stuffSizes.map((value) => {
        return {
          id: value.id,
          max: value.max,
          min: value.min,
        };
      }),
    };
  }

  mapRegistration(registration: RegistrationEntity): RegistrationJson {
    return {
      owner: {
        name: registration.name.trim().split(' ').filter((str) => str.length > 0).join(' '),
        surname: registration.surname.trim().split(' ').filter((str) => str.length > 0).join(' '),
        patronymic: registration.patronymic ? registration.patronymic.trim().split(' ').filter((str) => str.length > 0).join(' ') : undefined,
        phoneNumber: registration.phone,
        email: registration.email,
      },
      companyType: registration.specializationId,
      employeesCount: {
        min: registration.stuffSize.min, max: registration.stuffSize.max ?? 1001
      }
    };
  }
}
