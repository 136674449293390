import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Validator } from "src/app/core/validators/validator";

@Component({
  selector: 'create-employee-modal-contacts',
  templateUrl: './create-employee-modal-contacts.component.html',
  styleUrls: ['./create-employee-modal-contacts.component.scss']
})
export class CreateEmployeeModalContactsComponent implements OnChanges {
  @Input() employeeContactsData: EmployeeContactsData = {
    email: '',
    phoneNumber: '',
    emailError: null,
    phoneNumberError: null
  }
  @Input() showErrors: boolean = false

  @Output() onChange = new EventEmitter<EmployeeContactsData>()

  emailError: string | null = null 
  phoneNumberError: string | null = null 

  constructor(
    @Inject('RegistrationEmailValidator') 
    private emailValidator: Validator,
    @Inject('RegistrationPhoneNumberValidator') 
    private phoneNumberValidator: Validator,
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['employeeContactsData']){
      this.emailCheckValid()
      this.phoneNumberCheckValid()
    }
    if(changes['showErrors']){
      if(this.showErrors){
        this.emailError = this.employeeContactsData.emailError
        this.phoneNumberError = this.employeeContactsData.phoneNumberError
      }
    }
  }

  emailChange(str: string){
    this.employeeContactsData.email = str
    this.emailCheckValid()
    this.emailError = this.employeeContactsData.emailError
    this.onChange.emit(this.employeeContactsData)
  }

  emailCheckValid(){
    this.employeeContactsData.emailError = this.emailValidator.validate(this.employeeContactsData.email)
  }

  phoneNumberChange(str: string){
    this.employeeContactsData.phoneNumber = str
    this.phoneNumberCheckValid()
    this.phoneNumberError = this.employeeContactsData.phoneNumberError
    this.onChange.emit(this.employeeContactsData)
  }

  phoneNumberCheckValid(){
    this.employeeContactsData.phoneNumberError = this.phoneNumberValidator.validate( this.employeeContactsData.phoneNumber)
  }
}

export interface EmployeeContactsData{
  email: string,
  phoneNumber: string,
  emailError: string | null,
  phoneNumberError: string | null,
}
