import { Injectable } from "@angular/core";
import { Executor, Reducer } from "../../../../../../core/mvi/store";
import { SectionEditorState } from "./section-editor-state";
import { SectionEditorAction, SectionEditorActionTypes } from "./section-editor-action";
import { SectionEditorResultAction, SectionEditorResultActionTypes } from "./section-editor-result-action";
import { SectionEditorNavigator } from "../../navigation/section-editor-navigator";
import { ActivatedRoute } from "@angular/router";

@Injectable()
export class SectionEditorExecutor extends Executor<
  SectionEditorState,
  SectionEditorAction,
  SectionEditorResultAction
> {
  constructor(
    private navigator: SectionEditorNavigator,
    private route: ActivatedRoute
  ) {
    super();
  }

  override init(
    reducer: Reducer<SectionEditorState, SectionEditorResultAction>,
    getState: () => SectionEditorState,
    onReduced: (state: SectionEditorState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    const documentId = this.route.snapshot.paramMap.get('id')
    this.reduce({
      type: SectionEditorResultActionTypes.INIT,
      documentId: documentId !== null ? documentId: '-1'
    })
  }

  execute(action: SectionEditorAction) {
    switch (action.type)
    {
      case SectionEditorActionTypes.SWITCH_FULL_SCREEN:
        this.reduce({
            type: SectionEditorResultActionTypes.SWITCH_FULL_SCREEN,
            value: action.value
          })
        break;
      case SectionEditorActionTypes.MOVE_TO_EDIT_DOCUMENT:
        // this.reduce({
        //   type: SectionEditorResultActionTypes.CHANGE_ALREADY_OPEN_MODAL_VISIBILITY,
        //   value: true
        // })
        this.navigator.openEditDocument(this.getState().documentId)
        break;
      case SectionEditorActionTypes.CLOSE_ALREADY_OPEN_MODAL:
        this.reduce({
          type: SectionEditorResultActionTypes.CHANGE_ALREADY_OPEN_MODAL_VISIBILITY,
          value: false
        })
        break;
    }
  }
}
