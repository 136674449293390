<app-core-page-shading>
  <div class="flex items-center justify-center w-full">
    <div class="px-[16px] pt-[48px] pb-[24px] relative bg-white rounded-tt-border-radius_l">
      <button class="absolute top-0 right-[-40px] flex justify-center items-center w-[32px] h-[32px] rounded-[24px] bg-white"
        (click)="closeClicked.emit()"
      >
        <svg-x-mark></svg-x-mark>
      </button>
      <p class="text-center px-[15px] flex flex-col gap-[16px] w-full">
        <span class="text-[20px] leading-[30px] font-medium text-tt-text-default">Извините, документ уже редактируется </span>
        <span class="text-[14px] leading-[18px] font-normal text-tt-text-default">Документ “{{documentTitle}}” в данный момент редактируется пользователем
          <br/>
          <span class="text-tt-text-primary">{{userName}}</span>
        </span>
      </p>
      <div class="flex mt-[48px] justify-center">
        <app-core-button variant="solid"
          (onClick)="closeClicked.emit()"
        >
          Понятно
        </app-core-button>
      </div>
    </div>
  </div>
</app-core-page-shading>
