<div class="w-full h-full flex flex-col gap-[8px]">
  <p class="text-tt-text-primary text-[14px] font-normal leading-[18px]">
    Изображение <span class="text-[#F53232]">*</span>
  </p>

  <div class="rounded-[16px] overflow-hidden w-[309px] h-[174px] relative group">
    <div
      class="w-full h-full justify-center items-center empty-image-color"
      [ngClass]="{
        'absolute left-0 top-0 opacity-80 z-10 hidden group-hover:flex': image != '',
        'flex': image == ''
      }"
    >
      <button
        class="h-[40px] rounded-[8px] border-[1px] border-tt-text-contrast px-[22px] flex items-center"
        (click)="selectClicked()"
      >
        <p class="text-[14px] leading-[17px] font-medium text-tt-text-contrast">
          <span *ngIf="image == ''">Выбрать изображение</span>
          <span *ngIf="image != ''">Изменить изображение</span>
        </p>
      </button>
    </div>

    <img
      *ngIf="image != ''"
      src="{{image}}"
      class="w-full h-full object-cover"
      alt=""
    />
  </div>

</div>

<input type="file" accept="image/*" class="hidden" #inputImage (change)="onSelectFile($event)">
