import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-news-list-sort-popup',
  templateUrl: './sort-popup.component.html',
  styleUrls: ['./sort-popup.component.scss']
})
export class SortPopupComponent {
  @Input() currentId: string = ''
  @Input() name: string = 'Сортировка по'

  isOpen: boolean = false
}
