import { CreateSkillError, RatingScaleEntity } from './create-skill-state';

export type CreateSkillResultAction =
  | OpenModalResultAction
  | CloseModalResultAction
  | ChangeNameResultAction
  | ChangeNameErrorStatusResultAction
  | ChangeLoadingStatusResultAction
  | ChangeErrorStatusResultAction
  | ChangeDescriptionResultAction
  | ChangeLevelDescriptionResultAction
  | ChangeLevelDescriptionErrorStatusResultAction
  | ChangeCurrentStepResultAction
  | ChangeRatingScalesResultAction
  | ChangeRatingScalesErrorStatusResultAction
  | ChangeRatingScalesIsLoadingStatusResultAction
  | SelectRatingScaleResultAction
  | UnselectRatingScaleResultAction
  | ValidationResultAction
  | ResetStateResultAction;

export enum CreateSkillResultActionTypes {
  RESET_STATE,
  OPEN_MODAL,
  CLOSE_MODAL,
  CHANGE_NAME,
  CHANGE_NAME_ERROR_STATUS,
  CHANGE_DESCRIPTION,
  CHANGE_LEVEL_DESCRIPTION,
  CHANGE_LEVEL_DESCRIPTION_ERROR_STATUS,
  CHANGE_RATING_SCALES,
  CHANGE_RATING_SCALES_ERROR_STATUS,
  CHANGE_RATING_SCALES_IS_LOADING_STATUS,
  SELECT_RATING_SCALE,
  UNSELECT_RATING_SCALE,
  CHANGE_CURRENT_STEP,
  CHANGE_LOADING_STATUS,
  CHANGE_ERROR_STATUS,
  VALIDATION_ERROR,
}

export interface ResetStateResultAction {
  readonly type: CreateSkillResultActionTypes.RESET_STATE;
}

export interface SelectRatingScaleResultAction {
  readonly type: CreateSkillResultActionTypes.SELECT_RATING_SCALE;
  readonly ratingScale: RatingScaleEntity;
}

export interface UnselectRatingScaleResultAction {
  readonly type: CreateSkillResultActionTypes.UNSELECT_RATING_SCALE;
}

export interface ValidationResultAction {
  readonly type: CreateSkillResultActionTypes.VALIDATION_ERROR;
  readonly nameError: string | null;
  readonly selectedRatingScaleError: string | null;
}

export interface ChangeRatingScalesResultAction {
  readonly type: CreateSkillResultActionTypes.CHANGE_RATING_SCALES;
  readonly ratingScales: RatingScaleEntity[];
}

export interface ChangeRatingScalesErrorStatusResultAction {
  readonly type: CreateSkillResultActionTypes.CHANGE_RATING_SCALES_ERROR_STATUS;
  readonly ratingScalesError: string | null;
}

export interface ChangeRatingScalesIsLoadingStatusResultAction {
  readonly type: CreateSkillResultActionTypes.CHANGE_RATING_SCALES_IS_LOADING_STATUS;
  readonly ratingScalesIsLoading: boolean;
}

export interface OpenModalResultAction {
  readonly type: CreateSkillResultActionTypes.OPEN_MODAL;
}

export interface CloseModalResultAction {
  readonly type: CreateSkillResultActionTypes.CLOSE_MODAL;
}

export interface ChangeCurrentStepResultAction {
  readonly type: CreateSkillResultActionTypes.CHANGE_CURRENT_STEP;
  readonly step: 1 | 2;
}

export interface ChangeNameResultAction {
  readonly type: CreateSkillResultActionTypes.CHANGE_NAME;
  readonly name: string;
}

export interface ChangeNameErrorStatusResultAction {
  readonly type: CreateSkillResultActionTypes.CHANGE_NAME_ERROR_STATUS;
  readonly nameError: string | null;
}

export interface ChangeDescriptionResultAction {
  readonly type: CreateSkillResultActionTypes.CHANGE_DESCRIPTION;
  readonly description: string;
}

export interface ChangeLevelDescriptionResultAction {
  readonly type: CreateSkillResultActionTypes.CHANGE_LEVEL_DESCRIPTION;
  readonly levelId: string;
  readonly levelDescription: string;
}

export interface ChangeLevelDescriptionErrorStatusResultAction {
  readonly type: CreateSkillResultActionTypes.CHANGE_LEVEL_DESCRIPTION_ERROR_STATUS;
  readonly levelId: string;
  readonly levelDescriptionError: string | null;
}

export interface ChangeLoadingStatusResultAction {
  readonly type: CreateSkillResultActionTypes.CHANGE_LOADING_STATUS;
  readonly isLoading: boolean;
}

export interface ChangeErrorStatusResultAction {
  readonly type: CreateSkillResultActionTypes.CHANGE_ERROR_STATUS;
  readonly error: CreateSkillError | null;
}
