import { Observable } from 'rxjs';
import { SectionEditEntity } from './section-edit-entity';

export abstract class SectionSettingsService {
  abstract section: Observable<SectionEditEntity>;

  abstract fetchSection(sectionId: number): void;

  abstract getSection(): Observable<SectionEditEntity>;

  abstract updateSection(section: SectionEditEntity): Observable<null>;
}
