import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { DocumentDto, DocumentFileDto } from "./dto/documentDto";
import { environment } from "../../../../../environments/environment";

@Injectable({providedIn: 'root'})
export class Editor2ServiceImpl {
  private readonly apiUrl: string = `${environment.apiUrl}/editor/document`;

  constructor(private httpClient: HttpClient) {
  }

  saveDocument(id: string, document: any, usedFileIds: string[]): Observable<null> {
    return this.httpClient.post<null>(`${this.apiUrl}/${id}`, {data: document, usedFiles: usedFileIds})
  }

  createDocument(): Observable<{id: string}> {
    return this.httpClient.post<{id: string}>(`${this.apiUrl}/create`, {})
  }

  getDocumentBy(uid: string): Observable<DocumentDto> {
    return this.httpClient.get<DocumentDto>(`${this.apiUrl}/${uid}`)
  }

  uploadExternalFile(documentId: string, url: string): Observable<DocumentFileDto> {
    return this.httpClient.post<DocumentFileDto>(`${this.apiUrl}/${documentId}/external-file`, {url: url})
  }

  uploadFile(documentId: string, data: FormData): Observable<DocumentFileDto> {
    return this.httpClient.post<DocumentFileDto>(`${this.apiUrl}/${documentId}/file`, data)
  }

  downloadFile(fileId: string): Observable<Blob>{
    return this.httpClient.get(`${environment.apiUrl}/editor/file/${fileId}`, {
      responseType: 'blob'
    })
  }
}
