import { Component, ElementRef, ViewChild } from "@angular/core";
import { SettingsConstants } from '../common/settings-constants';
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { HeaderService } from '../../main/data/header-service';
import { Tabs } from "../../main/presentation/state/main-state";
import { MainRoutesPaths } from "../../../routes/main-routes";
import { filter } from "rxjs";
import { NavTab } from "../../../core/components/nav-tabs/nav-tabs.component";
import { SettingsRoutesPaths } from "../../../routes/settings-routes";
import { EmployeesConstants } from "../../employees/common/employees-constants";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['../../main/presentation/main.component.scss']
})
export class SettingsComponent {
  @ViewChild('scrollable', { read: ElementRef }) scrollableEl?: ElementRef

  protected readonly SettingsConstants = SettingsConstants

  navTabs: NavTab[] = []

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private headerService: HeaderService
  ) {
    headerService.ChangeStyle('bg-tt-bg-page')
    headerService.ChangeBreadCrumbs([])
    const tabs = localStorage.getItem('tabs')
    if(tabs){
      const tabsArray = JSON.parse(tabs) as string[]
      this.navTabs = SettingsConstants.NAV_TABS.filter((tab) => tabsArray.includes(tab.id))
      if(route.children.length == 0){
        if(tabs.includes(Tabs.COMPANY_SETTINGS))
        {
          router.navigateByUrl(`${MainRoutesPaths.SETTINGS}/${SettingsRoutesPaths.MAIN}`)
        }
        else if (tabs.includes(Tabs.BILLING)){
          router.navigateByUrl(`${MainRoutesPaths.SETTINGS}/${SettingsRoutesPaths.BILLING}`)
        }
      }
      router.events.pipe(
        filter(event => event instanceof NavigationStart)
      ).subscribe((event) => {
        if(event instanceof NavigationStart && event.url.replaceAll('/', '') == MainRoutesPaths.SETTINGS){
          if(tabs.includes(Tabs.COMPANY_SETTINGS))
          {
            router.navigateByUrl(`${MainRoutesPaths.SETTINGS}/${SettingsRoutesPaths.MAIN}`)
          }
          else if (tabs.includes(Tabs.BILLING)){
            router.navigateByUrl(`${MainRoutesPaths.SETTINGS}/${SettingsRoutesPaths.BILLING}`)
          }
        }
      });
    }
  }

  onScroll(){
    if(this.scrollableEl){
      if(this.scrollableEl.nativeElement.scrollTop > 0){
        this.headerService.ChangeStyle('bg-tt-bg-page border-b-[1px] border-tt-border-default')
      }
      else{
        this.headerService.ChangeStyle('bg-tt-bg-page')
      }
      if(this.scrollableEl.nativeElement.scrollTop > 50){
        this.headerService.ChangeBreadCrumbs([
          {
            name: SettingsConstants.SETTINGS,
            href: '/' + MainRoutesPaths.SETTINGS
          }
        ])
      }
      else{
        this.headerService.ChangeBreadCrumbs([])
      }
    }
  }
}
