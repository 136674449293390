<p
  *ngIf="error"
  class="w-full flex items-center text-tt-text-placeholder text-sm mt-2"
>
  <svg
    class="min-w-[16px]"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_321_1565)">
      <path
        d="M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668Z"
        stroke="#F93232"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 6L6 10"
        stroke="#F93232"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 6L10 10"
        stroke="#F93232"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_321_1565">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
  <span class="ms-2">{{ error }}</span>
</p>
