import { Injectable } from "@angular/core";
import { Reducer } from "../../../../../../core/mvi/store";
import { SectionEditorState } from "./section-editor-state";
import { SectionEditorResultAction, SectionEditorResultActionTypes } from "./section-editor-result-action";

@Injectable()
export class SectionEditorReducer
  implements Reducer<SectionEditorState, SectionEditorResultAction> {
  reduce(state: SectionEditorState, action: SectionEditorResultAction): SectionEditorState {
    switch (action.type) {
      case SectionEditorResultActionTypes.SWITCH_FULL_SCREEN:
        return { ...state, isFullScreen: action.value}
      case SectionEditorResultActionTypes.INIT:
        return { ...state, documentId: action.documentId}
      case SectionEditorResultActionTypes.CHANGE_ALREADY_OPEN_MODAL_VISIBILITY:
        return { ...state, isVisibleAlreadyOpenModal: action.value}
    }
  }
}
