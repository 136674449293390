import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnDestroy,
  OnInit,
  Output,
  Renderer2
} from "@angular/core";
import { fromEvent, Subscription } from "rxjs";

@Directive({ selector: '[appPopup]' })
export class PopupDirective implements AfterViewInit, OnInit, OnDestroy{
  @Input() parentOffset: Offsets = {x: 0, y: 0}
  @Input() popupOffsetXSide: 'left' | 'right' | 'center' = 'right'
  @Input() popupOffsetYSide: 'top' | 'bottom' = 'bottom'
  @Input() overflowSide: 'base' | 'bigger' = 'bigger'
  @Input() isScrollClose: boolean = true;
  @Output() popupClose = new EventEmitter()

  private parentElement: HTMLElement | null = null
  private thisElement: HTMLElement | null = null

  private initHeight: number = 0
  private currentOffsetXSide: 'left' | 'right' | 'center' = 'right'
  private currentOffsetYSide: 'top' | 'bottom' = 'bottom'

  private resizeObs?: Subscription

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.resizeObs = fromEvent(window, 'resize').subscribe(() => {
      this.onResize();
    });
  }

  ngOnDestroy() {
    if(this.resizeObs){
      this.resizeObs.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.thisElement = this.elementRef?.nativeElement as HTMLElement;
    this.parentElement = this.thisElement.parentElement
    this.initHeight = this.thisElement.offsetHeight
    this.renderer.setStyle(this.thisElement, 'position', 'fixed')
    this.renderer.addClass(this.thisElement, 'animate-popup_appearance')
    this.setPosition()

    this.renderer.setStyle(this.thisElement, 'transform-origin', this.currentOffsetXSide + ' ' + (this.currentOffsetYSide === 'top' ? 'bottom' : 'top'))
  }

  setPosition(){
    if(this.parentElement && this.thisElement)
    {
      const parentRect = this.parentElement.getBoundingClientRect()
      let offsets: Offsets = {x: 0, y: 0}
      offsets.x = this.getXOffset(parentRect)
      offsets.y = this.getYOffset(parentRect)

      this.renderer.setStyle(this.thisElement, 'z-index', 50)
      this.renderer.setStyle(this.thisElement, 'top', offsets.y + 'px')
      this.renderer.setStyle(this.thisElement, 'left', offsets.x + 'px')
    }
  }

  @HostListener("document:wheel", [])
  onScroll(){
    if(this.isScrollClose)
    {
      this.popupClose.emit()
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent){
    const element = event.target as HTMLElement
    if (this.parentElement != null && !this.parentElement.contains(element)) {
      this.popupClose.emit();
    }
  }

  //@HostListener('window:resize', ['$event'])
  onResize() {
    this.setPosition()
  }

  getXOffset(parentRect: DOMRect): number{
    if(this.thisElement)
    {
      switch (this.popupOffsetXSide){
        case 'left':
          this.currentOffsetXSide = 'left'
          return parentRect.left + this.parentOffset.x
        case 'right':
          this.currentOffsetXSide = 'right'
          return parentRect.left + parentRect.width - this.thisElement.offsetWidth + this.parentOffset.x
        case "center":
          this.currentOffsetXSide = 'center'
          return parentRect.left + parentRect.width / 2 - this.thisElement.offsetWidth / 2 + this.parentOffset.x
      }
    }
    return 0
  }

  getYOffset(parentRect: DOMRect): number {
    switch (this.popupOffsetYSide) {
      case 'top':
        return this.getYOffsetSideTop(parentRect)
      case "bottom":
        return this.getYOffsetSideBottom(parentRect)
    }
  }

  private getYOffsetSideTop(parentRect: DOMRect): number
  {
    if(this.thisElement)
    {
      let result = parentRect.top - this.parentOffset.y - this.initHeight
      this.currentOffsetYSide = 'top'
      if (result < 0) {
        switch (this.overflowSide){
          case 'base':
            this.setNewHeight(result)
            result = 0
            break;
          case 'bigger':
            const bottomOffset = parentRect.top + parentRect.height + this.parentOffset.y
            const bottomHeightDelta = window.innerHeight - bottomOffset - this.initHeight
            if(result > bottomHeightDelta){
              this.setNewHeight(result)
              result = 0
            }
            else{
              if(bottomHeightDelta < 0){
                this.setNewHeight(bottomHeightDelta)
              }
              else {
                this.setInitHeight()
              }
              this.currentOffsetYSide = 'bottom'
              result = bottomOffset
            }
            break;
        }
      }
      else if(this.initHeight != this.thisElement.offsetHeight){
        this.setInitHeight()
        this.currentOffsetYSide = 'bottom'
      }
      return result
    }
    return 0
  }

  private getYOffsetSideBottom(parentRect: DOMRect): number
  {
    if(this.thisElement)
    {
      let result = parentRect.top + parentRect.height + this.parentOffset.y
      let deltaHeight = window.innerHeight - result - this.initHeight
      this.currentOffsetYSide = 'bottom'

      if (deltaHeight < 0) {
        switch (this.overflowSide){
          case 'base':
            this.setNewHeight(deltaHeight)
            break;
          case 'bigger':
            const topHeightDelta = parentRect.top - this.parentOffset.y - this.initHeight
            if(deltaHeight > topHeightDelta){
              this.setNewHeight(deltaHeight)
            }
            else{
              this.currentOffsetYSide = 'top'
              if(topHeightDelta < 0)
              {
                this.setNewHeight(topHeightDelta)
                result = 0
              }
              else{
                if(this.initHeight != this.thisElement.offsetHeight){
                  this.setInitHeight()
                }
                result = topHeightDelta
              }
            }
            break;
        }
      }
      return result
    }
    return 0
  }

  private setNewHeight(deltaHeight: number){
    if(this.thisElement)
    {
      const newHeight = this.initHeight + deltaHeight
      this.renderer.setStyle(this.thisElement, 'height', newHeight + 'px')
    }
  }

  private setInitHeight(){
    if(this.thisElement)
    {
      this.renderer.setStyle(this.thisElement, 'height', this.initHeight + 'px')
    }
  }
}

export interface Offsets{
  x: number,
  y: number
}
