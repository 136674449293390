import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { LinkToolRenderProps } from "../types/link-tool-render-props";
import { Link } from "../types/link";
import { isValidURL } from "../../../../../utils/is-valid-url"

const DEFAULT_LINK_VALUE: Link = {
  url: '',
  title: '',
  target: '_blank',
  rel: 'noreferrer',
};

const MyLinkToolRender = (props: LinkToolRenderProps) => {
  const { withLink = true, withTitle = true } = props;
  const [link, setLink] = useState(props?.link || DEFAULT_LINK_VALUE);
  const addButton = useRef(null)
  const deleteButton = useRef(null)

  const angularAddButton = React.createElement('angular-core-button', {}, props.link.url ? 'Обновить' : 'Добавить')
  const angularAddButtonDisabled = React.createElement('angular-core-button', { disabled: true }, props.link.url ? 'Обновить' : 'Добавить')
  const angularDeleteButton = React.createElement('angular-core-button', { variant: 'soft', type:'danger'}, 'Удалить')

  const onSave = () => {
    if (!link.url) return;
    let result = link.url
    if(!(result.startsWith('http://') || result.startsWith('https://'))) {
      result = 'https://' + result
    }
    if(isValidURL(result)){
      props.onSave({...link, url: result});
    }

  };

  const onDelete = () => {
    props.onDelete();
  };

  const onChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setLink((p) => ({ ...p, [target.name]: target.value }));
  };

  useEffect(() => {
    if (props.link) setLink(props.link);
  }, [props.link]);

  return (
    <div
      className="yoopta-link-tool flex flex-col gap-[8px]"
      style={{
        boxShadow: '0px 1px 4px 0px #00000040'
      }}
    >
      {withTitle && (
        <div
          className='flex flex-col gap-[8px]'
        >
          <label htmlFor="title" className="yoopta-link-tool-label">
            Текст
          </label>
          <input
            id="title"
            type="text"
            className="yoopta-link-tool-input"
            name="title"
            value={link.title}
            onChange={onChange}
            placeholder="Введите текст"
            autoComplete="off"
          />
        </div>
      )}
      {withLink && (
        <div
          className='flex flex-col gap-[8px]'
        >
          <label htmlFor="url" className="yoopta-link-tool-label">
            URL-ссылки
          </label>
          <input
            id="url"
            type="text"
            className="yoopta-link-tool-input"
            name="url"
            value={link.url}
            onChange={onChange}
            placeholder="Вставьте ссылку"
            autoComplete="off"
          />
        </div>
      )}
      <div
        className="flex justify-between"
      >
        <button
          type="button"
          onClick={onSave}
          ref={addButton}
          disabled={!link.url}
        >
          {link.url && angularAddButton}
          {!link.url && angularAddButtonDisabled}
        </button>

        <button
          type="button"
          onClick={onDelete}
          ref={deleteButton}
        >
          {angularDeleteButton}
        </button>
      </div>
    </div>
  );
};

export { MyLinkToolRender };
