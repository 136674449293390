import { FilterParams } from './section-content-state';
import { DocumentItemEntity } from '../../domain/document-item-entity';

export type SectionContentAction =
  | ChangeItemAction
  | AddDocumentAction
  | ChangeSearchFieldAction
  | ChangeSortItemAction
  | FavoriteDocumentAction
  | DeleteDocumentAction
  | EditDocumentAction
  | LinkDocumentAction
  | MoveToDocumentEditorAction;

export enum SectionContentActionTypes {
  CHANGE_FILTER_ITEM,
  ADD_DOCUMENT,
  CHANGE_SEARCH_FIELD,
  CHANGE_SORT_ITEM,
  FAVORITE_DOCUMENT,
  DELETE_DOCUMENT,
  EDIT_DOCUMENT,
  LINK_DOCUMENT,
  MOVE_TO_DOCUMENT_EDITOR,
}

export interface MoveToDocumentEditorAction {
  readonly type: SectionContentActionTypes.MOVE_TO_DOCUMENT_EDITOR;
  readonly documentId: string
}

export interface ChangeItemAction {
  readonly type: SectionContentActionTypes.CHANGE_FILTER_ITEM;
  readonly param: FilterParams;
}

export interface AddDocumentAction {
  readonly type: SectionContentActionTypes.ADD_DOCUMENT;
}

export interface ChangeSearchFieldAction {
  readonly type: SectionContentActionTypes.CHANGE_SEARCH_FIELD;
  readonly value: string;
}

export interface ChangeSortItemAction {
  readonly type: SectionContentActionTypes.CHANGE_SORT_ITEM;
  readonly id: string;
}

export interface FavoriteDocumentAction {
  readonly type: SectionContentActionTypes.FAVORITE_DOCUMENT;
  readonly documentId: string;
}

export interface EditDocumentAction {
  readonly type: SectionContentActionTypes.EDIT_DOCUMENT;
  readonly documentId: string;
}
export interface LinkDocumentAction {
  readonly type: SectionContentActionTypes.LINK_DOCUMENT;
  readonly documentId: string;
}

export interface DeleteDocumentAction {
  readonly type: SectionContentActionTypes.DELETE_DOCUMENT;
  readonly documentId: string;
}
