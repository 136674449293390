import { Injectable } from '@angular/core';

@Injectable()
export class SectionSettingsState {
  readonly selectedItem: NavItem = NavItem.MAIN;
}

export enum NavItem {
  MAIN,
  ACCESS,
}
