import { Injectable } from '@angular/core';
import { clone } from 'cloneable-ts';
import { Reducer } from 'src/app/core/mvi/store';
import {
  SectionsResultAction,
  SectionsResultActionTypes,
} from './sections-result-action';
import { SectionsState } from './sections-state';

@Injectable()
export class SectionsReducer
  implements Reducer<SectionsState, SectionsResultAction>
{
  reduce(state: SectionsState, action: SectionsResultAction): SectionsState {
    switch (action.type) {
      case SectionsResultActionTypes.SELECT_FILTER_PARAMS:
        return clone(state, {
          filtersState: clone(state.filtersState, {
            filterParam: action.param,
          }),
          filteredSections: action.filteredSections,
          filterItems: action.filterItems,
        });
      case SectionsResultActionTypes.SELECT_SORT_PARAMS:
        return clone(state, {
          filtersState: clone(state.filtersState, {
            sortParam: action.sortParam,
          }),
          filteredSections: action.filteredSections,
        });
      case SectionsResultActionTypes.UPDATE_SECTIONS_LIST:
        return clone(state, {
          sections: action.sections,
          filteredSections: action.filteredSections,
          isLoading: false,
        });
      case SectionsResultActionTypes.CHANGE_IS_LOADING:
        return clone(state, {
          isLoading: action.isLoading,
        });
      case SectionsResultActionTypes.CHANGE_CREATE_SECTION_MODAL_VISIBILITY:
        return clone(state, {
          createSectionModalVisibility: action.visibility,
        });
      case SectionsResultActionTypes.CHANGE_SECTION_MARK:
        return clone(state, {
          sections: action.sections,
          filteredSections: action.filteredSections,
        });
      case SectionsResultActionTypes.SEARCH_FIELD_CHANGE:
        return clone(state, {
          filtersState: clone(state.filtersState, {
            filter: action.filter,
          }),
          filteredSections: action.filteredSections,
        });
      case SectionsResultActionTypes.CHANGE_CREATE_SECTION_DATA:
        return clone(state, {
          createSectionDto: action.createSectionData,
          createSectionTitleError: action.titleError,
          currentPrivacyValue: action.privacy,
        });
      case SectionsResultActionTypes.UPDATE_ICONS_LIST:
        return clone(state, {iconUrls: action.iconsList})
    }
  }
}
