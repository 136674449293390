<div [ngClass]="className">
  <div class="flex justify-between text-tt-text-primary text-[14px] font-normal leading-[18px] mb-[8px]"
    *ngIf="variant !== 'borderless'"
  >
    <label
      *ngIf="variant === 'standard' && label"
      class="inline-block"
    >
      {{ label }}
      <span class="text-[#F53232]" *ngIf="required">*</span>
    </label>

    <label
      *ngIf="charsCounterMax != 0 && focused"
      class="inline-block"
    >
      {{ countChars }} / {{ charsCounterMax }}
    </label>
  </div>

  <div
    class="w-full"
    [ngClass]="{
      'h-tt-height-field_xl': variant === 'outlined',
      'h-tt-height-field_m': variant === 'standard',
      'h-[38px]': variant === 'borderless',
      'border-b-[1px] border-tt-border-input': variant === 'borderless' && !focused,
      'border-tt-text-failure': variant === 'borderless' && focused !== undefined && !focused && error,
      'border-b-[2px] border-tt-primary': variant === 'borderless' && focused,
    }"
  >
    <div
      class="relative w-full h-full flex items-center"
      [ngClass]="textStyle"
    >
      <input
        #inputField
        [(ngModel)]="localValue"
        (input)="onChangeValue($event)"
        (focus)="onFocus()"
        (blur)="onUnFocus()"
        [type]="currentType"
        [maxlength]="charsCounterMax > 0 ? charsCounterMax : null"
        [autocomplete]="autocomplete"
        [readonly]="readonly"
        [disabled]="readonly"
        [autofocus]="autoFocus"
        [mask]="mask"
        [prefix]="prefix"
        [showMaskTyped]="showMaskTyped"
        [attr.shownMaskExpression]="
          shownMaskExpression ? shownMaskExpression : null
        "
        [class.text-center]="textAlign === 'center'"
        [class.text-start]="textAlign === 'start'"
        [class.text-end]="textAlign === 'end'"
        [class.text-justify]="textAlign === 'justify'"
        [ngClass]="{
          'pr-[48px]': type === 'password',
          'text-tt-font-size_l font-normal text-tt-text-default': textStyle == [] || textStyle == '',
          'pt-0': !placeholder,
          'bg-tt-bg-failure outline-tt-border-failure ring-tt-border-failure':
            (error && !(errorShowType === 'onUnFocused' && focused)) && variant !== 'borderless',
          'bg-tt-field-background-color': !(error && !(errorShowType == 'onUnFocused' && focused)) && variant !== 'borderless',
          'px-tt_xl pt-[14px] tracking-wider': variant === 'outlined',
          'px-tt_l': variant === 'standard',
          'placeholder-tt-text-placeholder bg-transparent outline-none': variant === 'borderless',
          'rounded-tt-border-radius_m field peer outline outline-1 outline-tt-border-default ring-tt-primary focus:ring-2 focus:outline-none': variant !== 'borderless',
        }"
        class="w-full h-full"
        [placeholder]="
        variant === 'outlined'
          ? ' '
          : placeholder && !(hidePlaceholderOnFocus && focused)
            ? placeholder
            : ''
        "
      />

      <label
        *ngIf="charsCounterMax != 0 && variant === 'borderless'"
        [ngClass]="textStyle"
        class="absolute right-0 top-[9px]"
      >
        <p class="text-tt-text-placeholder text-nowrap">{{ countChars }} / {{ charsCounterMax }}</p>
      </label>

      <label
        *ngIf="variant === 'outlined' && placeholder"
        class="pointer-events-none absolute left-[20px] top-[10px] flex h-full w-full select-none text-tt-font-size_s tracking-wider font-normal leading-none text-tt-icon-field transition-all peer-placeholder-shown:text-tt-font-size_l peer-placeholder-shown:top-[20px] peer-focus:text-tt-font-size_s peer-focus:leading-none peer-focus:top-[10px]"
      >
        {{ placeholder }}
        <span class="text-[#F53232]" *ngIf="required">*</span>
      </label>
      <label
        *ngIf="type === 'password' && !isPasswordVisible"
        class="absolute right-[16px] flex justify-end items-center h-full text-tt-icon-default"
      >
        <button
          class="w-[24px] h-[24px]"
          (click)="changePasswordVisible()"
        >
          <svg-password-eye
          />
        </button>
      </label>

      <label
        *ngIf="type == 'password' && isPasswordVisible"
        class="absolute right-[16px] flex justify-end items-center h-full text-tt-icon-default"
      >
        <button
          class="w-[24px] h-[24px]"
          (click)="changePasswordVisible()"
          *ngIf="isPasswordVisible"
        >
          <svg-password-eye-close
          />
        </button>
      </label>
    </div>

  </div>
  <app-field-error-message
    *ngIf="errorShowType === 'onChange' || errorShowType ==='onUnFocused' && focused !== undefined && !focused"
    [error]="error"
  />
</div>
