import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastsService } from 'src/app/core/components/toast-alert/services/toast-alert.service';
import { Executor, Reducer } from 'src/app/core/mvi/store';
import { CompetenciesAndSkillsService } from '../../../@data/services/competencies-and-skills.service';
import {
  CompetenciesAndSkillsAction,
  CompetenciesAndSkillsActionTypes,
} from './competencies-and-skills-action';
import {
  CompetenciesAndSkillsResultAction,
  CompetenciesAndSkillsResultActionTypes,
} from './competencies-and-skills-result-action';
import {
  CompetenciesAndSkillsError,
  CompetenciesAndSkillsState,
} from './competencies-and-skills-state';

@Injectable()
export class CompetenciesAndSkillsExecutor
  extends Executor<
    CompetenciesAndSkillsState,
    CompetenciesAndSkillsAction,
    CompetenciesAndSkillsResultAction
  >
  implements OnDestroy
{
  constructor(
    private competenciesAndSkillsService: CompetenciesAndSkillsService,
    private toastsService: ToastsService,
  ) {
    super();
  }

  private subscription: Subscription = new Subscription();

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  override init(
    reducer: Reducer<
      CompetenciesAndSkillsState,
      CompetenciesAndSkillsResultAction
    >,
    getState: () => CompetenciesAndSkillsState,
    onReduced: (state: CompetenciesAndSkillsState) => void,
  ): void {
    super.init(reducer, getState, onReduced);

    this.subscription.add(
      this.competenciesAndSkillsService.getCompetencyAndSkills().subscribe({
        next: (competenciesAndSkills) => {
          this.reduce({
            type: CompetenciesAndSkillsResultActionTypes.UPDATE_COMPETENCIES_AND_SKILLS,
            competenciesAndSkills,
          });
        },
        error: (error: HttpErrorResponse) => {
          this.reduce({
            type: CompetenciesAndSkillsResultActionTypes.CHANGE_ERROR_STATUS,
            error: (() => {
              switch (error.status) {
                case HttpStatusCode.Unauthorized:
                  return CompetenciesAndSkillsError.INCORRECT_CREDENTIALS;
                default:
                  return CompetenciesAndSkillsError.CONNECTION_ERROR;
              }
            })(),
          });
        },
        complete: () => {
          this.reduce({
            type: CompetenciesAndSkillsResultActionTypes.CHANGE_LOADING_STATUS,
            isLoading: false,
          });
        },
      }),
    );

    this.subscription.add(
      this.competenciesAndSkillsService.createSkillEvent$.subscribe(() => {
        this.competenciesAndSkillsService.getCompetencyAndSkills().subscribe({
          next: (competenciesAndSkills) => {
            this.reduce({
              type: CompetenciesAndSkillsResultActionTypes.UPDATE_COMPETENCIES_AND_SKILLS,
              competenciesAndSkills,
            });
          },
        });
      }),
    );
  }

  execute(action: CompetenciesAndSkillsAction): void {
    console.log(action);
    switch (action.type) {
      case CompetenciesAndSkillsActionTypes.OPEN_FOLDER:
        this.reduce({
          type: CompetenciesAndSkillsResultActionTypes.OPEN_FOLDER,
          folder: action.folder,
        });
        break;

      case CompetenciesAndSkillsActionTypes.CLOSE_FOLDER:
        this.reduce({
          type: CompetenciesAndSkillsResultActionTypes.CLOSE_FOLDER,
          folder: action.folder,
        });
        break;

      case CompetenciesAndSkillsActionTypes.OPEN_COMPETENCY:
        this.reduce({
          type: CompetenciesAndSkillsResultActionTypes.OPEN_COMPETENCY,
          competency: action.competency,
        });
        break;

      case CompetenciesAndSkillsActionTypes.CLOSE_COMPETENCY:
        this.reduce({
          type: CompetenciesAndSkillsResultActionTypes.CLOSE_COMPETENCY,
          competency: action.competency,
        });
        break;

      case CompetenciesAndSkillsActionTypes.CHANGE_SEARCH:
        this.reduce({
          type: CompetenciesAndSkillsResultActionTypes.CHANGE_SEARCH,
          search: action.search,
        });
        break;

      case CompetenciesAndSkillsActionTypes.TOGGLE_SELECTED_ENTITY:
        this.reduce({
          type: CompetenciesAndSkillsResultActionTypes.TOGGLE_SELECTED_ENTITY,
          selectedEntity: action.selectedEntity,
          selected: action.selected,
        });
    }
  }
}
