<div
  class="w-[240px] flex flex-col py-4 bg-tt-bg-default rounded-tt-border-radius_l gap-2"
>
  <app-profile-menu-item
    *ngFor="let item of menuItems"
    [params]="item.itemEntity"
    (navigate)="onNavItemClick($event)"
  >
    <div class="flex gap-[8px] items-center">
      <img [src]="item.icon" alt="" class="w-8 h-8" />
      <p class="text-[16px] font-[400] text-center not-italic leading-normal">
        {{ item.text }}
      </p>
    </div>
  </app-profile-menu-item>
</div>
