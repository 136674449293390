export type SpacesAction =
  | ChangeVisibleCreateSpaceModalAction
  | CreateSpaceAction
  | UpdateSpacesAction
  | FilterSpacesAction

export enum SpacesActionTypes {
  CHANGE_VISIBLE_CREATE_SPACE_MODAL,
  CREATE_SPACE,
  UPDATE_SPACES,
  FILTER_SPACES,
}

export interface FilterSpacesAction {
  readonly type: SpacesActionTypes.FILTER_SPACES
  readonly filterStr: string
}

export interface UpdateSpacesAction {
  readonly type: SpacesActionTypes.UPDATE_SPACES
}

export interface ChangeVisibleCreateSpaceModalAction {
  readonly type: SpacesActionTypes.CHANGE_VISIBLE_CREATE_SPACE_MODAL;
  readonly value: boolean
}

export interface CreateSpaceAction {
  readonly type: SpacesActionTypes.CREATE_SPACE;
  readonly spaceName: string
}