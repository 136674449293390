import { Blocks, UI, YooEditor, YooptaBlockData } from '@teamtells/editor';
import { ExternalLinkIcon, TextAlignCenterIcon, TextAlignLeftIcon, TextAlignRightIcon } from '@radix-ui/react-icons';
import { FileElementProps } from "@teamtells/file";
import { onFileDownload } from "../../../utils/on-file-download";
import { useInjected } from "@bubblydoo/angular-react";
import { Editor2ServiceImpl } from "../../../data/editor-service-impl";


const ALIGN_ICONS: any = {
  left: TextAlignLeftIcon,
  center: TextAlignCenterIcon,
  right: TextAlignRightIcon,
};

const { ExtendedBlockActions, BlockOptionsMenuGroup, BlockOptionsMenuItem, BlockOptionsSeparator } = UI;

type Props = {
  editor: YooEditor;
  block: YooptaBlockData;
  props?: FileElementProps;
};

const FileBlockOptions = ({ editor, block, props: fileProps }: Props) => {
  const editorService = useInjected(Editor2ServiceImpl)

  const onOpen = () => {
    if (fileProps && fileProps.src){
      onFileDownload(fileProps.src, editorService, fileProps.name);
    }
  };

  const currentAlign = block?.meta?.align || 'center';
  const AlignIcon = ALIGN_ICONS[currentAlign];

  const onToggleAlign = () => {
    const aligns = ['left', 'center', 'right'];
    if (!block) return;

    const nextAlign = aligns[(aligns.indexOf(currentAlign) + 1) % aligns.length] as YooptaBlockData['meta']['align'];
    Blocks.updateBlock(editor, block.id, { meta: { ...block.meta, align: nextAlign } });
  };

  return (
    <ExtendedBlockActions className='yoopta-extended-block-action' onClick={() => editor.setSelection([block.meta.order])}>
      <BlockOptionsSeparator />
      <BlockOptionsMenuGroup>
        <BlockOptionsMenuItem>
          <button type="button" className="yoopta-block-options-button" onClick={onToggleAlign}>
            <AlignIcon width={16} height={16} className="w-4 h-4" style={{ marginRight: '8px' }} />
            Выравнивание
          </button>
        </BlockOptionsMenuItem>
        <BlockOptionsMenuItem>
          <button type="button" className="yoopta-block-options-button" onClick={onOpen}>
            <ExternalLinkIcon width={16} height={16} className="w-4 h-4" style={{ marginRight: '8px' }}/>
            Открыть
          </button>
        </BlockOptionsMenuItem>
      </BlockOptionsMenuGroup>
    </ExtendedBlockActions>
  );
};

export { FileBlockOptions };
