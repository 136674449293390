import { NavigationSection } from "../components/navigation-section/navigation-section.component";
import { NavigationElement } from "../components/navigation-element/navigation-element.component";
import { MainRoutesPaths } from "src/app/routes/main-routes";
import { Tabs } from "../state/main-state";
import {
  SvgNavKnowledgeBase,
  SvgNavMyTeam,
  SvgNavNews,
  SvgNavSettings
} from "../../../../core/components/svg-components/svg.components";

export class MainConstants {

  public static NAVIGATION_SECTIONS: NavigationSection[] = [
    {
      title: 'Коммуникации',
      elements: [
        {
          id: Tabs.NEWS,
          name: 'Новости',
          icon: SvgNavNews,
          url: MainRoutesPaths.NEWS,
          tabIds: [Tabs.NEWS]
        },
        {
          id: Tabs.EMPLOYEES,
          name: 'Моя команда',
          icon: SvgNavMyTeam,
          url: MainRoutesPaths.EMPLOYEES,
          tabIds: [Tabs.EMPLOYEES, Tabs.ROLE]
        },
      ]
    },
    {
      title: 'Развитие',
      elements: [
        {
          id: Tabs.KNOWLEDGE_BASE,
          name: 'База знаний',
          icon: SvgNavKnowledgeBase,
          url: MainRoutesPaths.KNOWLEDGE_BASE,
          tabIds: [Tabs.KNOWLEDGE_BASE]
        },
      ]
    }
  ]
  public static NAVIGATION_SETTINGS_ELEMENT: NavigationElement = {
    id: Tabs.COMPANY_SETTINGS,
    name: 'Настройки',
    icon: SvgNavSettings,
    url: MainRoutesPaths.SETTINGS,
    tabIds: [Tabs.COMPANY_SETTINGS]
  }

}
