import { EmptyRule, Rule } from './rule';
import { rule } from 'postcss';

export class Validator {
  constructor(private rules: Rule[]) {}

  validate(value: String): string | null {
    let error: string | null = null;

    for (let rule of this.rules) {
      if (!rule.check(value)) {
        error = rule.error;
        break;
      }
    }
    return error;
  }
}
