import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { HeaderEntity, HeaderService } from "../../../data/header-service";
import { stringToRgb } from "src/app/core/utils/string-to-rgb";

@Component({
  selector: "main-header",
  templateUrl: "./main-header.component.html",
  styleUrls: [
    "./main-header.component.scss",
    "../../../../knowledge-base/modules/spaces/presentation/common/spaces.component.scss"
  ]
})
export class MainHeaderComponent implements OnChanges {
  @Input() employee: HeaderEmployee = {
    name: "",
    surname: "",
    email: "",
    avatarUrl: "",
    userAvatarPlaceholder: "",
    userAvatarColor: ""
  };

  @Output() logoutClicked = new EventEmitter();

  @ViewChild("topBg") topBg?: ElementRef;

  header: HeaderEntity = {
    organisationName: "",
    breadCrumbs: []
  };

  isPopupVisible: boolean = false;

  constructor(
    private headerService: HeaderService,
    private renderer: Renderer2
  ) {
    headerService.header.subscribe((value) => {
      this.header = value;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['employee'] && !changes['employee'].isFirstChange()) {
      this.employee.name = this.employee.name.trim().split(' ').filter((str) => str.length > 0).join(' ')
      this.employee.surname = this.employee.surname.trim().split(' ').filter((str) => str.length > 0).join(' ')
      this.employee.patronymic = this.employee.patronymic?.trim().split(' ').filter((str) => str.length > 0).join(' ')
      this.employee.userAvatarPlaceholder = this.employee.surname.charAt(0) + this.employee.name.charAt(0)
      this.employee.userAvatarColor = stringToRgb(this.employee.email)
    }
  }

  changePopupTopBg(): void {
    if(this.topBg?.nativeElement != undefined)
    {
      this.renderer.setStyle(this.topBg?.nativeElement, 'background', `linear-gradient(87.91deg, rgba(${this.employee.userAvatarColor}, 0.25) 0%, rgba(${this.employee.userAvatarColor}, 0.15) 100%)`, 1)
    }
  }

  openPopup(){
    this.isPopupVisible = !this.isPopupVisible
    setTimeout(() => this.changePopupTopBg())
  }
}

export interface HeaderEmployee{
  name: string,
  surname: string,
  patronymic?: string
  email: string,
  avatarUrl: string
  userAvatarPlaceholder: string,
  userAvatarColor: string
}
