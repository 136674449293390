import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class CreateRatingScaleState {
  readonly id: string | null = null;
  readonly name: string = '';
  readonly nameError: string | null = null;
  readonly levels: LevelEntity[] = [
    {
      localId: '1',
      name: '',
      nameError: null,
      points: null,
      pointsError: null,
    },
  ];
  readonly isOpen: boolean = false;
  readonly isLoading: boolean = false;
  readonly error: CreateRatingScaleError | null = null;
}

export interface LevelEntity {
  id?: string;
  localId: string;
  name: string;
  points: number | null;
  nameError: string | null;
  pointsError: string | null;
}

export enum CreateRatingScaleError {
  INCORRECT_CREDENTIALS,
  CONNECTION_ERROR,
}
