import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NavTab } from "../../nav-tabs/nav-tabs.component";
import {
  CreateRoleModalActionTypes
} from "../../../../features/employees/modules/employees-roles/components/create-role-modal/state/create-role-modal-action";

@Component({
  selector: 'app-core-modal-content',
  templateUrl: './modal-content.component.html',
})
export class ModalContentComponent {
  @Input() className: string | string[] = [];
  @Input() footer = false;
  @Input() horizontalClue = false;
  @Input() parentPaddings: boolean = false
  @Input() overflowY: 'auto' | 'scroll' = 'auto';
  @Input() contentHeight: 'auto' | 'full' = 'auto'
  @Input() withNavTabs: boolean = false
  @Input() selectedTabId: string = ''
  @Input() tabs: NavTab[] = []

  @Output() tabClicked = new EventEmitter<string>();
  protected readonly CreateRoleModalActionTypes = CreateRoleModalActionTypes;
}
