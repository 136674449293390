import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsListComponent } from './presentation/news-list.component';
import { SortPopupComponent } from './presentation/components/sort-popup/sort-popup.component';
import { SvgChecklist, SvgGrid2x2, SvgHeaderArrow } from "../../../../core/components/svg-components/svg.components";
import { NewsItemComponent } from './presentation/components/news-item/news-item.component';
import { RouterLink } from "@angular/router";
import { GridListToggleButtonComponent } from './presentation/components/grid-list-toggle-button/grid-list-toggle-button.component';

@NgModule({
  declarations: [
    NewsListComponent,
    SortPopupComponent,
    NewsItemComponent,
    GridListToggleButtonComponent
  ],
  imports: [
    CommonModule,
    SvgHeaderArrow,
    SvgGrid2x2,
    SvgChecklist,
    RouterLink
  ],
  exports: [
    GridListToggleButtonComponent,
    NewsItemComponent
  ]
})
export class NewsListModule { }
