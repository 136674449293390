<app-auth-layout>
  <section
    class="
      relative mt-4 mb-16 p-tt_2xl sm:p-[44px] w-full max-w-tt-auth-container
      bg-tt-bg-default rounded-tt-border-radius_l sm:rounded-tt-border-radius_4xl
      border-[1px] border-tt-border-default"
    (keyup.enter)="performAction({ type: AuthorizationActionTypes.LOGIN })"
  >
    <h2
      class="text-tt-font-size_3xl sm:text-tt-font-size_5xl block text-center"
    >
      Вход в <span class="text-tt-primary">TeamTells</span>
    </h2>

    <div class="mt-6 flex flex-col gap-6">
      <app-core-input-field
        placeholder="E-mail"
        type="text"
        [error]="state.emailError.toString()"
        (onChange)="
          performAction({
            type: AuthorizationActionTypes.CHANGE_EMAIL,
            email: $event
          })
        "
      />
      <app-core-input-field
        placeholder="Пароль"
        type="password"
        [error]="state.passwordError.toString()"
        (onChange)="
          performAction({
            type: AuthorizationActionTypes.CHANGE_PASSWORD,
            password: $event
          })
        "
      />
      <div class="flex justify-between items-center h-6">
        <app-core-checkbox-field
          [value]="state.rememberMe"
          (onToggle)="
            performAction({
              type: AuthorizationActionTypes.TOGGLE_REMEMBER_ME
            })
          "
          label="Запомнить меня"
        />
        <a type="button" class="text-sm" href="/reset-password">
          Забыли пароль?
        </a>
      </div>
      <app-core-button
        className="w-full"
        size="xl"
        [loading]="state.isLoading"
        (onClick)="performAction({ type: AuthorizationActionTypes.LOGIN })"
      >
        Войти
      </app-core-button>
    </div>
    
    <div
      class="absolute left-0 bottom-[-44px] w-full flex justify-between items-center mt-tt_xl"
    >
      <a class="text-tt-text-secondary" href="https://teamtells.ru">
        teamtells.ru
      </a>
      <a class="text-sm font-medium flex items-center" href="/signup">
        Зарегистрироваться
        <svg-arrow-right class="h-3 w-2 ms-2" />
      </a>
    </div>
  </section>
</app-auth-layout>
