import { UI } from '@teamtells/editor';
import { CSSProperties, useState } from 'react';
import { EmbedUploader } from "./embed-uploader";
import { FileUploader } from "./file-uploader";

type Props = {
  floatingStyles: CSSProperties;
  refs: any;
  blockId: string;
  onClose: () => void;
  onSetLoading: (_s: boolean) => void;
};
const { Overlay, Portal } = UI;

type Tab = 'upload' | 'embed';

const ImageUploader = ({ floatingStyles, refs, onClose, blockId, onSetLoading }: Props) => {
  const [activeTab, setActiveTab] = useState<Tab>('upload');

  const switchTab = (tab: Tab) => setActiveTab(tab);

  const isUploader = activeTab === 'upload';
  const isEmbed = activeTab === 'embed';

  const getTabStyles = (isActive: boolean) => ({
    borderBottom: isActive ? '2px solid #2483e2' : '2px solid transparent',
  });

  return (
    <Portal id="uploader-portal">
      <Overlay lockScroll className="z-[100]" onClick={onClose}>
        <div ref={refs.setFloating} style={floatingStyles} onClick={(e) => e.stopPropagation()}>
          <div className="yoopta-uploader-box">
            <div className="yoopta-uploader-nav">
              <button
                type="button"
                onClick={() => switchTab('upload')}
                style={getTabStyles(isUploader)}
                className={`yoopta-button yoopta-uploader-nav-button user-select-none`}
              >
                Загрузить
              </button>
              <button
                type="button"
                onClick={() => switchTab('embed')}
                style={getTabStyles(isEmbed)}
                className={
                  'yoopta-button yoopta-uploader-nav-button user-select-none'
                }
              >
                По ссылке
              </button>
            </div>
            <div className="yoopta-uploader-uploaders-box">
              {isEmbed && <EmbedUploader onClose={onClose} blockId={blockId} onSetLoading={onSetLoading}/>}
              {isUploader && <FileUploader onClose={onClose} blockId={blockId} onSetLoading={onSetLoading} />}
            </div>
          </div>
        </div>
      </Overlay>
    </Portal>
  );
};

export { ImageUploader };
