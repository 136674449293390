import { PermissionsDto } from "../../../../../../../core/services/role-service/dto/create-role-dto";

export type CreateRoleModalAction =
  | ChangeNavItemAction
  | InitAction
  | ChangeRoleNameAction
  | ChangeRoleDescriptionAction
  | SaveRoleAction
  | LoadRoleAction

export enum CreateRoleModalActionTypes {
  CHANGE_NAV_ITEM,
  INIT,
  CHANGE_ROLE_NAME,
  CHANGE_ROLE_DESCRIPTION,
  SAVE_ROLE,
  LOAD_ROLE,
}

export interface LoadRoleAction {
  readonly type: CreateRoleModalActionTypes.LOAD_ROLE;
  readonly id: string
}

export interface SaveRoleAction {
  readonly type: CreateRoleModalActionTypes.SAVE_ROLE;
}

export interface ChangeRoleDescriptionAction {
  readonly type: CreateRoleModalActionTypes.CHANGE_ROLE_DESCRIPTION;
  readonly value: string
}

export interface ChangeRoleNameAction {
  readonly type: CreateRoleModalActionTypes.CHANGE_ROLE_NAME;
  readonly value: string
}

export interface ChangeNavItemAction {
  readonly type: CreateRoleModalActionTypes.CHANGE_NAV_ITEM;
  readonly id: string
  readonly isCheckCurrent: boolean
}

export interface InitAction {
  readonly type: CreateRoleModalActionTypes.INIT;
}
