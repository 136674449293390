import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import {
  AutocompleteItem
} from "../../../../../../../../../core/components/fields/autocomplete-field/autocomplete-field.component";

@Component({
  selector: 'create-employee-modal-roles',
  templateUrl: './create-employee-modal-roles.component.html',
  styleUrls: ['./create-employee-modal-roles.component.scss']
})
export class CreateEmployeeModalRolesComponent implements OnChanges{
  @Input() roleId: string = ''
  @Input() roles: AutocompleteItem[] = []
  @Output() onChange = new EventEmitter<string>()

  currentRole: AutocompleteItem = {
    id: '',
    name: '',
    description: ''
  }

  changeRole(id: string){
    this.currentRole = this.findRole(id)
    this.onChange.emit(id)
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['roleId']){
      this.currentRole = this.findRole(this.roleId)
    }
  }

  findRole(id: string): AutocompleteItem{
    const findRole = this.roles.find((role) => { return role.id == id})
    if(findRole != undefined){
      return findRole
    }
    else {
      return this.roles[0]
    }
  }


}

export interface EmployeeRole{
  id: string,
  title: string,
  description: string
}
