import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
  selector: 'app-input-news-image',
  templateUrl: './input-news-image.component.html',
  styleUrls: ['./input-news-image.component.scss']
})
export class InputNewsImageComponent {
  @Input() image: string = '';
  @Output() imageUpload = new EventEmitter<string>();

  @ViewChild('inputImage') inputImageRef!: ElementRef;

  selectClicked(){
    this.inputImageRef.nativeElement.click()
  }

  onSelectFile(event: any){
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file)

    reader.onload = (event) => {
      this.image = <string>event.target!.result
      this.imageUpload.emit(<string>event.target!.result)
    }
  }

}
