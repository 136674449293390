import {Injectable} from '@angular/core';
import {clone} from 'cloneable-ts';
import {Reducer} from 'src/app/core/mvi/store';
import {MainResultAction, MainResultActionTypes} from './main-result-action';
import {MainState} from './main-state';

@Injectable()
export class MainReducer implements Reducer<MainState, MainResultAction> {
  reduce(state: MainState, action: MainResultAction): MainState {
    switch (action.type) {
      case MainResultActionTypes.SELECT_ITEM:
        return {...state, selectedItem: action.item};
      case MainResultActionTypes.SHOW_SIDEBAR:
        return { ...state,
          showSideBar: !state.showSideBar
        }
      case MainResultActionTypes.INIT_MAIN:
        return {...state,
          companyName: action.companyName,
          headerEmployee: action.userInfo,
          enabledItemsIds: action.enabledNavItems,
          subscriptionDaysLeft: action.subscriptionDaysLeft,
          navSections: action.navSections
        };
      case MainResultActionTypes.CHANGE_IS_LOADING:
        return {...state,
          isLoading: action.value
        }
      case MainResultActionTypes.CHANGE_SUBSCRIPTION_WINDOW_VISIBILITY:
        return {...state, isSubscriptionModalVisible: action.value}
    }
  }
}
