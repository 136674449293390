import { FileIcon } from '@radix-ui/react-icons';
import { CSSProperties, useRef, useState } from "react";
import { Elements, useYooptaEditor, useYooptaPluginOptions } from "@teamtells/editor";
import { FileElementProps } from "@teamtells/file";
import { Loader } from "../../../components/loader";
import { FilePluginOptions } from "../types/file-plugin-options";
import { useInjected } from "@bubblydoo/angular-react";
import { Editor2ServiceImpl } from "../../../data/editor-service-impl";
import { onFileUpload } from "../utils/on-file-upload";

const Placeholder = ({ attributes, children, blockId }: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const options = useYooptaPluginOptions<FilePluginOptions>('File');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const editorService = useInjected(Editor2ServiceImpl)

  const loadingStyles: CSSProperties = {
    width: '100%',
    transition: 'width 100ms ease-in',
  };

  const onSetLoading = (slate: boolean) => setLoading(slate);
  const openFileInput = () => {
    fileInputRef.current?.click()
  }

  const editor = useYooptaEditor();

  const upload = async (file: File) => {
    onSetLoading(true);

    try {
      const response = await onFileUpload(file, editorService);

      Elements.updateElement<'file', FileElementProps>(editor, blockId, {
        type: 'file',
        props: {
          src: response.src,
          name: response.name || file.name,
          size: response.size || file.size,
          format: response.format,
        },
      });
    } catch (error) {
    } finally {
      onSetLoading(false);
    }
  };

  const onChange = (e: any) => {
    const file = e.target.files[0];
    if (file) upload(file);
  };

  return (
    <div
      className="w-full user-select-none mt-[20px] mb-[10px] relative flex"
      {...attributes}
      contentEditable={false}
    >
      <button
        type="button"
        className={`yoopta-button yoopta-upload-placeholder`}
        onClick={() => openFileInput()}
      >
        {loading ? (
          <Loader className="mr-2 user-select-none" width={24} height={24} />
        ) : (
          <FileIcon className="mr-2 user-select-none" width={24} height={24} />
        )}
        <span className="font-medium">{loading ? 'Загрузка...' : 'Нажмите для загрузки файла'}</span>
        {loading && (
          <div
            className="yoopta-button absolute top-0 left-0 h-full bg-[rgba(55,53,47,0.16)]"
            style={loadingStyles}
          />
        )}
      </button>
      <input
        type="file"
        id="file-uploader"
        className="hidden"
        accept={options?.accept || ''}
        onChange={onChange}
        ref={fileInputRef}
        multiple={false}
      />

      {children}
    </div>
  );
};

export { Placeholder };
