import { Reducer } from 'src/app/core/mvi/store';
import { Injectable } from '@angular/core';
import { clone } from 'cloneable-ts';
import { MessengerSideBarState } from './sidebar-state';
import {
  MessengerSideBarResultAction,
  MessengerSideBarResultActionTypes,
} from './sidebar-result-action';

@Injectable({
  providedIn: 'root',
})
export class MessengerSidebarReducer
  implements Reducer<MessengerSideBarState, MessengerSideBarResultAction>
{
  reduce(
    state: MessengerSideBarState,
    action: MessengerSideBarResultAction,
  ): MessengerSideBarState {
    switch (action.type) {
      case MessengerSideBarResultActionTypes.UPDATE_INFO:
        return clone(state, { showChatInfo: action.menuState });
      case MessengerSideBarResultActionTypes.SHOW_CHAT:
        return clone(state, { actualChat: action.actualchat });
    }
  }
}
