import { EmployeeDeleteProps } from "../../../domain/employee-delete-props";
import { RoleDeleteProps } from "../../../domain/role-delete-props";

export type EmployeesRolesAction =
  | UpdateRolesAction
  | ChangeCreateRoleModalVisibleAction
  | FilterRolesAction
  | DeleteRoleAction
  | EditRoleAction
  | ChangeDeleteAlertModalVisibilityAction

export enum EmployeesRolesActionTypes {
  UPDATE_ROLES,
  CHANGE_CREATE_ROLE_MODAL_VISIBLE,
  FILTER_ROLES,
  DELETE_ROLE,
  EDIT_ROLE,
  CHANGE_DELETE_ALERT_MODAL_VISIBILITY
}

export interface ChangeDeleteAlertModalVisibilityAction{
  readonly type: EmployeesRolesActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY
  readonly value: boolean
  readonly deleteProps?: RoleDeleteProps
}

export interface EditRoleAction {
  readonly type: EmployeesRolesActionTypes.EDIT_ROLE;
  readonly id: string
}

export interface DeleteRoleAction {
  readonly type: EmployeesRolesActionTypes.DELETE_ROLE;
}

export interface ChangeCreateRoleModalVisibleAction{
  readonly type: EmployeesRolesActionTypes.CHANGE_CREATE_ROLE_MODAL_VISIBLE;
  readonly value: boolean
}

export interface UpdateRolesAction{
  readonly type: EmployeesRolesActionTypes.UPDATE_ROLES;
}

export interface FilterRolesAction{
  readonly type: EmployeesRolesActionTypes.FILTER_ROLES;
  readonly filterStr: string
}
