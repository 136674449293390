<div class="flex w-full justify-center py-[44px]">
  <div class="max-w-[935px] w-full rounded-tt-border-radius_m overflow-hidden border-[1px] border-tt-border-default bg-tt-light-blue">
    <div class="w-full px-[16px]">
      <div class="w-full flex py-[10px] gap-[16px]">
        <app-core-search-field class="w-full"
          placeholder="Поиск по названию"
          (onChange)="performAction({
            type: EmployeesActionTypes.FILTER_ROLES,
            filterStr: $event
          })"
        />
        <app-core-button
          *ngIf="state.canCreate"
          (onClick)="performAction({
            type: EmployeesActionTypes.CHANGE_CREATE_ROLE_MODAL_VISIBLE,
            value: true
          })"
        >
          Создать роль
        </app-core-button>

      </div>

      <div class="h-[33px] px-[8px] flex justify-between items-center">
        <p class="table-column-header-text">Название</p>
        <p class="table-column-header-text">Действия</p>
      </div>

    </div>

    <div
      class="w-full"
      *ngFor="let role of state.filteredRoles; let i = index"
    >
      <app-employees-roles-item
        [role]="role"
        [canEdit]="state.canEdit"
        [canDelete]="state.canDelete"
        (editClicked)="performAction({
          type: EmployeesActionTypes.EDIT_ROLE,
          id: role.id
        })"
        (deleteClicked)="performAction({
          type: EmployeesRolesActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
          value: true,
          deleteProps: {id: role.id, name: role.name}
        })"
      />

      <div *ngIf="i != state.roles.length - 1" class="h-[1px] w-full bg-tt-separator-color" ></div>
    </div>


    <div class="w-full flex justify-center p-[24px] bg-white"
      *ngIf="state.isLoading"
    >
      <mat-spinner class="w-[48px] h-[48px]"></mat-spinner>
    </div>

    <app-core-image-stub
      class="bg-white"
      *ngIf="!state.isLoading && state.filteredRoles.length == 0"
      [imageSrc]="''"
      [title]="'Не нашли совпадений'"
      [description]="'Попробуйте изменить параметры поиска'"
    />
  </div>
</div>

<employees-create-role-modal
  *ngIf="state.isCreateRoleModalVisible"
  [isVisible]="state.isCreateRoleModalVisible"
  [editRoleId]="state.editRoleId"
  (closeClicked)="performAction({
    type: EmployeesActionTypes.CHANGE_CREATE_ROLE_MODAL_VISIBLE,
    value: false
  })"
/>

<app-core-alert-modal
  *ngIf="state.isDeleteAlertVisible"
  [title]="'Удалить роль'"
  type="warning"
  [cancelButtonText]="'Отменить'"
  [confirmButtonText]="'Удалить'"
  [isLoading]="state.isDeleting"
  (onConfirmClicked)="performAction({
    type: EmployeesRolesActionTypes.DELETE_ROLE
  })"
  (onCancelClicked)="performAction({
    type: EmployeesRolesActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
    value: false
  })"
>
  <p>Роль с именем “<span class="font-medium">{{state.roleDeleteProps?.name}}</span>” будет удалена. Вы уверены, что хотите удалить эту роль?</p>

</app-core-alert-modal>
