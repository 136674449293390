import { Routes } from "@angular/router";
import { NewsComponent } from "../features/news/presentation/news.component";
import { NewsListComponent } from "../features/news/modules/news-list/presentation/news-list.component";

export enum NewsRoutesPath { LIST = 'list' }

export const NEWS_ROUTES: Routes = [
  {
    path: NewsRoutesPath.LIST,
    component: NewsListComponent,
  },
];
