import { Component } from '@angular/core';
import { Store } from "../../../../../../../core/mvi/store";
import { SectionEditorExecutor } from "../../state/section-editor-executor";
import { SectionEditorReducer } from "../../state/section-editor-reducer";
import { SectionEditorState } from "../../state/section-editor-state";
import { SectionEditorAction, SectionEditorActionTypes } from "../../state/section-editor-action";
import { SectionEditorResultAction } from "../../state/section-editor-result-action";

@Component({
  selector: 'app-section-editor.component',
  templateUrl: './section-editor.component.html',
  styleUrls: [
    '../../../../../section/impl/presentation/common/section.component.scss',
    '../../common/section-editor.component.scss'],
  providers: [
    SectionEditorExecutor,
    SectionEditorReducer,
    SectionEditorState,
  ],
})
export class SectionEditorComponent extends Store<
  SectionEditorState,
  SectionEditorExecutor,
  SectionEditorAction,
  SectionEditorResultAction
> {
  constructor(
    state: SectionEditorState,
    executor: SectionEditorExecutor,
    reducer: SectionEditorReducer,
  ) {
    super(state, executor, reducer);
  }

  protected readonly SectionEditorActionTypes = SectionEditorActionTypes;
}
