import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  CreateRatingScaleResultAction,
  CreateRatingScaleResultActionTypes,
} from './create-rating-scale-result-action';
import { CreateRatingScaleState } from './create-rating-scale-state';

@Injectable({
  providedIn: 'any',
})
export class CreateRatingScaleReducer
  implements Reducer<CreateRatingScaleState, CreateRatingScaleResultAction>
{
  reduce(
    state: CreateRatingScaleState,
    action: CreateRatingScaleResultAction,
  ): CreateRatingScaleState {
    switch (action.type) {
      case CreateRatingScaleResultActionTypes.SET_DEFAULT_RATING_SCALE:
        return {
          ...state,
          id: action.ratingScale.id,
          name: action.ratingScale.name,
          levels: action.ratingScale.levels.map((level) => ({
            nameError: null,
            pointsError: null,
            localId: Date.now().toString() + level.name + Math.random(),
            ...level,
          })),
          nameError: null,
        };

      case CreateRatingScaleResultActionTypes.RESET_STATE:
        return {
          ...state,
          name: '',
          levels: [
            {
              localId: '1',
              name: '',
              nameError: null,
              points: null,
              pointsError: null,
            },
          ],
          isOpen: false,
          isLoading: false,
          error: null,
          nameError: null,
        };

      case CreateRatingScaleResultActionTypes.OPEN_MODAL:
        return { ...state, isOpen: true };

      case CreateRatingScaleResultActionTypes.CLOSE_MODAL:
        return { ...state, isOpen: false };

      case CreateRatingScaleResultActionTypes.CHANGE_NAME:
        return { ...state, name: action.name, nameError: null };

      case CreateRatingScaleResultActionTypes.CHANGE_NAME_ERROR_STATUS:
        return { ...state, nameError: action.nameError };

      case CreateRatingScaleResultActionTypes.CHANGE_LEVEL_NAME:
        return {
          ...state,
          levels: state.levels.map((level) => {
            if (level.localId === action.levelLocalId) {
              return {
                ...level,
                name: action.levelName,
                nameError: null,
              };
            }
            return level;
          }),
        };

      case CreateRatingScaleResultActionTypes.CHANGE_LEVEL_POINTS:
        return {
          ...state,
          levels: state.levels.map((level) => {
            if (level.localId === action.levelLocalId) {
              return {
                ...level,
                points: action.levelPoints,
                pointsError: null,
              };
            }
            return level;
          }),
        };

      case CreateRatingScaleResultActionTypes.ADD_LEVEL:
        return {
          ...state,
          levels: [
            ...state.levels,
            {
              localId: Date.now().toString(),
              name: '',
              points: null,
              nameError: null,
              pointsError: null,
            },
          ],
        };

      case CreateRatingScaleResultActionTypes.REMOVE_LEVEL:
        return {
          ...state,
          levels: state.levels.filter(
            (level) => level.localId !== action.levelLocalId,
          ),
        };

      case CreateRatingScaleResultActionTypes.CHANGE_LEVEL_NAME_ERROR_STATUS:
        return {
          ...state,
          levels: state.levels.map((level) => {
            if (level.localId === action.levelId) {
              return {
                ...level,
                nameError: action.levelNameError,
              };
            }
            return level;
          }),
        };

      case CreateRatingScaleResultActionTypes.CHANGE_LEVEL_POINTS_ERROR_STATUS:
        return {
          ...state,
          levels: state.levels.map((level) => {
            if (level.localId === action.levelLocalId) {
              return {
                ...level,
                pointsError: action.levelPointsError,
              };
            }
            return level;
          }),
        };

      case CreateRatingScaleResultActionTypes.CHANGE_LOADING_STATUS:
        return { ...state, isLoading: action.isLoading };

      case CreateRatingScaleResultActionTypes.CHANGE_ERROR_STATUS:
        return { ...state, error: action.error };
    }
  }
}
