<div
  class="child-routes-size px-[24px]"
  (scroll)="onScroll()"
  #scrollable
>
  <app-core-page-header
    [title]="'Настройки'"
    [navTabs]="navTabs"
    navTabType="link"
  />

  <router-outlet></router-outlet>
</div>
