import { Injectable } from "@angular/core";
import { DocumentSummaryEntity } from "../domain/document-summery-entity";

@Injectable()
export class DocumentsDragAndDropService {
  private dragDocument?: DocumentSummaryEntity

  setDragDocument(document: DocumentSummaryEntity | undefined) {
    this.dragDocument = document;
  }

  getDragDocument(): DocumentSummaryEntity | undefined {
    return this.dragDocument;
  }
}
