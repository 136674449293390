import { Component } from '@angular/core';
import { NavItem, SectionSettingsState } from '../state/section-settings-state';
import {
  SectionSettingsAction,
  SectionSettingsActionTypes,
} from '../state/section-settings-action';
import { Store } from '../../../../../../core/mvi/store';
import { SectionSettingsExecutor } from '../state/section-settings-executor';
import { SectionSettingsResultAction } from '../state/section-settings-result-action';
import { SectionSettingsReducer } from '../state/section-settings-reducer';
import { SectionSettingsService } from '../../domain/section-settings-service';
import { SectionSettingsServiceImpl } from '../../data/section-settings-service-impl';

@Component({
  selector: 'app-section-settings',
  templateUrl: './section-settings.component.html',
  styleUrls: [
    '../common/section-settings.component.scss',
    '../../../../../main/presentation/main.component.scss',
  ],
  providers: [
    SectionSettingsState,
    SectionSettingsExecutor,
    SectionSettingsReducer,
    {
      provide: SectionSettingsService,
      useClass: SectionSettingsServiceImpl,
    },
  ],
})
export class SectionSettingsComponent extends Store<
  SectionSettingsState,
  SectionSettingsExecutor,
  SectionSettingsAction,
  SectionSettingsResultAction
> {
  constructor(
    state: SectionSettingsState,
    executor: SectionSettingsExecutor,
    reducer: SectionSettingsReducer,
  ) {
    super(state, executor, reducer);
  }

  protected readonly NavItem = NavItem;
  protected readonly SectionSettingsActionTypes = SectionSettingsActionTypes;
}
