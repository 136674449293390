import { Component, EventEmitter, Output } from "@angular/core";
import { NewsSummaryEntity } from "../../../domain/news-summary-entity";
import { dateToDMonthYString } from "../../../../../core/utils/dateToDMonthYString";

@Component({
  selector: 'app-news-creation-window',
  templateUrl: './news-creation-window.component.html',
  styleUrls: ['./news-creation-window.component.scss']
})
export class NewsCreationWindowComponent {
  news: NewsSummaryEntity = {
    id: '',
    title: '',
    description: '',
    image: '',
    date: this.dateToString(new Date(Date.now())),
  }

  set title(title: string) {
    this.news = {...this.news, title: title} ;
  }

  set description(description: string) {
    this.news = {...this.news, description: description} ;
  }

  set image(image: string) {
    this.news = {...this.news, image: image} ;
  }

  titleError: string | null = null
  isPublish: boolean = false;
  previewType: 'grid-cell' | 'list-element' = 'grid-cell';

  @Output() backClicked: EventEmitter<any> = new EventEmitter();

  dateToString(date: Date): string {
    return dateToDMonthYString(date);
  }
}

