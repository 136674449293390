<button
  (click)="onClickValue($event)"
  ngClass="className"
  [class.justify-center]="position === 'center'"
  [class.justify-start]="position === 'start'"
  [class.justify-end]="position === 'end'"
  class="w-full h-tt-height-field_m text-tt-primary text-tt-font-size_l px-1 flex items-center flex-row gap-3"
>
  <svg-plus />
  <ng-content />
</button>
