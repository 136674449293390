import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RoleAllDto } from './dto/role-all-dto';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreateRoleDto } from './dto/create-role-dto';
import { RoleDto } from "./dto/role-dto";
import { GetRoleDto } from "./dto/get-role-dto";

@Injectable({providedIn: 'root'})
export class RoleService {

  private readonly apiUrl: string = `${environment.apiUrl}/company_new/roles`;

  constructor(private httpClient: HttpClient) { }

  public getRoles(): Observable<RoleAllDto> {
    return this.httpClient.get<RoleAllDto>(`${this.apiUrl}/all`);
  }

  public getUserRoles(id: number): Observable<RoleAllDto> {
    return this.httpClient.get<RoleAllDto>(`${this.apiUrl}/user/${id}`);
  }

  public getNewUserRoles(): Observable<RoleAllDto>{
    return this.httpClient.get<RoleAllDto>(`${this.apiUrl}/new-user`);
  }

  public createRole(role: CreateRoleDto): Observable<null> {
    return this.httpClient.post<null>(`${this.apiUrl}/create-role`, role)
  }

  public updateRole(id: string, role: CreateRoleDto): Observable<null> {
    return this.httpClient.put<null>(`${this.apiUrl}/${id}`, role)
  }

  public deleteRole(id: string): Observable<null>{
    return this.httpClient.delete<null>(`${this.apiUrl}/${id}`)
  }

  public getRole(id: string): Observable<GetRoleDto> {
    return this.httpClient.get<GetRoleDto>(`${this.apiUrl}/${id}`)
  }
}
