import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent {
  @Input() chips: ChipsEntity = {
    id: '',
    name: '',
    type: 'base'
  }
  @Input() maxWidth?: number

  @Output() deleteClicked = new EventEmitter<string>()
}

export type ChipsEntity = {
  id: string,
  name: string,
  type: 'readonly' | 'base'
}
