import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Executor, Reducer } from "../../../../core/mvi/store";
import { AuthService } from "../../../authorization/domain/auth.service";
import { MainAction, MainActionTypes } from "./main-action";
import { MainResultAction, MainResultActionTypes } from "./main-result-action";
import { MainState, Tabs } from "./main-state";
import { MainRoutesPaths } from "src/app/routes/main-routes";
import { CompanyService } from "../../../../core/services/company-service/company-service";
import { HeaderService } from "../../data/header-service";
import { MainConstants } from "../common/main-constants";
import { NavigationSection } from "../components/navigation-section/navigation-section.component";
import { SubscriptionShow } from "../../domain/subscription-show";


@Injectable()
export class MainExecutor extends Executor<
  MainState,
  MainAction,
  MainResultAction
> {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private headerService: HeaderService,
  ) {
    super();
  }

  override init(
    reducer: Reducer<MainState, MainResultAction>,
    getState: () => MainState,
    onReduced: (state: MainState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    this.handleInit()
  }

  execute(action: MainAction) {
    switch (action.type) {
      case MainActionTypes.SELECT_ITEM:
        this.reduce({
          type: MainResultActionTypes.SELECT_ITEM,
          item: action.item,
        });
        this.showPage(action.item);
        break;
      case MainActionTypes.LOGOUT:
        this.authService.logout();
        break;
      case MainActionTypes.SHOW_SIDEBAR:
        this.reduce({
          type: MainResultActionTypes.SHOW_SIDEBAR,
        });
        break;
      case MainActionTypes.CHANGE_SUBSCRIPTION_WINDOW_VISIBILITY:
        this.reduce({
          type: MainResultActionTypes.CHANGE_SUBSCRIPTION_WINDOW_VISIBILITY,
          value: action.value
        })
    }
  }

  private showPage(item: Tabs) {
    switch (item) {
      case Tabs.KNOWLEDGE_BASE:
        this.router.navigate(['knowledge-base']);
        break;
      case Tabs.EMPLOYEES:
        this.router.navigate(['employees']);
        break;
      case Tabs.COMPANY_SETTINGS:
        this.router.navigate(['settings']);
        break;
    }
  }

  handleInit(){
    if(this.route.children.length == 0){
      this.router.navigateByUrl(`${MainRoutesPaths.KNOWLEDGE_BASE}`)
    }

    this.reduce({
      type: MainResultActionTypes.CHANGE_IS_LOADING,
      value: true
    })

    this.companyService.getEnvironment().subscribe((environmentDto)=>{
      localStorage.setItem("tabs", JSON.stringify(environmentDto.tabs));

      this.headerService.changeOrganisationName(environmentDto.companyName)

      this.reduce({
        type: MainResultActionTypes.INIT_MAIN,
        enabledNavItems: environmentDto.tabs,
        companyName: environmentDto.companyName,
        userInfo: {
          name: environmentDto.userInfo.name,
          surname: environmentDto.userInfo.surname,
          patronymic: environmentDto.userInfo.patronymic,
          email: environmentDto.userInfo.email,
          avatarUrl: '',
          userAvatarPlaceholder: '',
          userAvatarColor: ''
        },
        subscriptionDaysLeft: environmentDto.subscription?.daysLeft,
        navSections: this.getNavSections(environmentDto.tabs)
      })

      this.reduce({
        type: MainResultActionTypes.CHANGE_IS_LOADING,
        value: false
      })
      this.checkSubscription()
    })
  }

  checkSubscription(){
    const daysLeft = this.getState().subscriptionDaysLeft
    if(daysLeft && daysLeft <= 14){
      const subscriptionShowsStr = localStorage.getItem("subscriptionShows");
      const account = this.authService.getAccount()

      if(account)
      {
        let isShow: boolean = false;
        if(subscriptionShowsStr)
        {
          let subscriptionShows = JSON.parse(subscriptionShowsStr) as SubscriptionShow[]
          const findUserShow = subscriptionShows.find((show) => show.userId == account.userId)
          if(findUserShow)
          {
            const lastShowDate = new Date(findUserShow.lastShowDate).setHours(0,0,0,0)
            const currentDate = new Date().setHours(0, 0, 0, 0)
            const daysBetweenLastShow = (currentDate - lastShowDate) / (1000 * 60 * 60 * 24);

            if(daysBetweenLastShow >= 1){
              if(daysLeft <= 3){
                isShow = true
              }
              if(daysLeft <= 7 && daysLeft + daysBetweenLastShow > 7){
                isShow = true
              }
              if(daysLeft <= 14 && daysLeft + daysBetweenLastShow > 14){
                isShow = true
              }
            }
            if(isShow){
              const newShows = subscriptionShows.map((show) => {
                if(show.userId == account.userId){
                  show.lastShowDate = new Date().getTime()
                }
                return show
              })
              localStorage.setItem("subscriptionShows", JSON.stringify(newShows));
            }
          }
        }
        else{
          if(daysLeft <= 14){
            isShow = true
            const newShows: SubscriptionShow[] = [{
              userId: account.userId,
              lastShowDate: new Date().getTime()
            }]
            localStorage.setItem("subscriptionShows", JSON.stringify(newShows));
          }
        }

        this.reduce({
          type: MainResultActionTypes.CHANGE_SUBSCRIPTION_WINDOW_VISIBILITY,
          value: isShow
        })
      }
    }
  }

  getNavSections(tabs: string[]): NavigationSection[]{
    let result: NavigationSection[] = []
    MainConstants.NAVIGATION_SECTIONS.forEach((section)=>{
      const filteredElements = section.elements.filter((element)=>{
        let result = false
        element.tabIds?.forEach((tabId)=>{
          if(tabs.includes(tabId)){
            result = true
          }
        })
        return result
      })
      if(filteredElements.length > 0){
        result.push({
          title: section.title,
          elements: filteredElements
        })
      }
    })
    return result
  }
}
