export type RatingScalesAction =
  | ToggleSelectedRatingScaleAction
  | ChangeSearchAction
  | DeleteRatingScaleAction;

export enum RatingScalesActionTypes {
  TOGGLE_SELECTED_RATING_SCALE,
  CHANGE_SEARCH,
  DELETE_RATING_SCALE,
}

export interface ToggleSelectedRatingScaleAction {
  readonly type: RatingScalesActionTypes.TOGGLE_SELECTED_RATING_SCALE;
  readonly selectedRatingScale: string;
  readonly selected: boolean;
}

export interface ChangeSearchAction {
  readonly type: RatingScalesActionTypes.CHANGE_SEARCH;
  readonly search: string;
}

export interface DeleteRatingScaleAction {
  readonly type: RatingScalesActionTypes.DELETE_RATING_SCALE;
  readonly ratingScaleId: string;
}
