import { EmployeeSummeryEntity } from "../../../../domain/employee";
import { EmployeeDeleteProps } from "../../../../domain/employee-delete-props";

export type EmployeesListResultAction =
  | ChangeCreateEmployeeModalVisibleResultAction
  | UpdateEmployeesListResultAction
  | EditEmployeeResultAction
  | ChangeLoadingResultAction
  | ChangeSearchFieldResultAction
  | ChangeDeleteAlertModalVisibilityResultAction
  | ChangeIsDeletingResultAction

export enum EmployeesListResultActionTypes {
  UPDATE_EMPLOYEES_LIST,
  CHANGE_NAV_ITEM,
  CHANGE_CREATE_EMPLOYEE_MODAL_VISIBLE,
  EDIT_EMPLOYEE,
  CHANGE_IS_LOADING,
  CHANGE_IS_DELETING,
  CHANGE_SEARCH_FIELD,
  CHANGE_DELETE_ALERT_MODAL_VISIBILITY
}

export interface ChangeIsDeletingResultAction{
  readonly type: EmployeesListResultActionTypes.CHANGE_IS_DELETING;
  readonly value: boolean
}

export interface ChangeDeleteAlertModalVisibilityResultAction{
  readonly type: EmployeesListResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY
  readonly value: boolean
  readonly deleteProps?: EmployeeDeleteProps
}

export interface ChangeSearchFieldResultAction{
  readonly type: EmployeesListResultActionTypes.CHANGE_SEARCH_FIELD;
  readonly value: string,
  readonly filteredEmployees: EmployeeSummeryEntity[]
}

export interface ChangeLoadingResultAction{
  readonly type: EmployeesListResultActionTypes.CHANGE_IS_LOADING;
  readonly value: boolean
}

export interface UpdateEmployeesListResultAction{
  readonly type: EmployeesListResultActionTypes.UPDATE_EMPLOYEES_LIST;
  readonly canCreate: boolean,
  readonly canEdit: boolean,
  readonly canDelete: boolean,
  readonly ownerId: number
  readonly employees: EmployeeSummeryEntity[]
  readonly filteredEmployees: EmployeeSummeryEntity[]
}

export interface ChangeCreateEmployeeModalVisibleResultAction {
  readonly type: EmployeesListResultActionTypes.CHANGE_CREATE_EMPLOYEE_MODAL_VISIBLE;
  readonly value: boolean
}

export interface EditEmployeeResultAction {
  readonly type: EmployeesListResultActionTypes.EDIT_EMPLOYEE;
  readonly id: number
}
