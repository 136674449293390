<div [ngClass]="className" class="relative w-full">
  <input
    type="search"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    class="
      w-full pl-[44px]
      placeholder:text-tt-search-placeholder text-tt-search-text
      ring-tt-search-border-focus focus:ring-2 focus:outline-none focus:bg-tt-search-background-focus"
    (input)="onChangeValue($event)"
    (keyup.enter)="onSubmitValue($event)"
    [ngClass]="{
      'rounded-[25px] h-[48px] pr-[16px] text-tt-font-size_2xl bg-tt-search-xl-background': size == 'xl',
      'rounded-[8px] h-tt-button-height_m pr-[8px] text-tt-font-size_l bg-tt-search-background': size == 'l',
    }"
  />
  <svg-search
    class="absolute cursor-pointer left-[12px] size-[24px] text-tt-search-icon"
    [ngClass]="{
      'top-[12px]': size == 'xl',
      'top-[6px]': size == 'l',
    }"
  />
</div>
