import { Component } from '@angular/core';

@Component({
  selector: 'app-hover-instruments-box',
  templateUrl: './hover-instruments-box.component.html',
  styleUrls: ['./hover-instruments-box.component.scss']
})
export class HoverInstrumentsBoxComponent {

}
