<div class="w-full flex justify-center">
  <div class="max-w-[1256px] w-full mt-[44px]">
    <div class="flex justify-between">
      <div class="flex gap-[8px]">
        <app-news-list-sort-popup

        />
        <app-news-list-sort-popup

        />
        <app-news-list-sort-popup

        />
      </div>

      <app-grid-list-toggle-button
        [currentType]="listType"
        (toggleGrid)="listType = 'grid-cell'"
        (toggleList)="listType = 'list-element'"
      />
    </div>

    <div class="mt-[40px] w-full flex gap-[24px] flex-wrap">
      <app-news-item
        *ngFor="let newsItem of news"
        [news]="newsItem"
        [type]="listType"
      />
    </div>

  </div>

</div>
