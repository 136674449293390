import { Routes } from "@angular/router";
import { SpaceComponent } from "../features/knowledge-base/modules/space/presentation/space.component";
import { SpacesComponent } from "../features/knowledge-base/modules/spaces/presentation/spaces.component";

export enum KnowledgeBasePaths {SPACE = 'space', SPACES = ''}

export const KNOWLEDGE_BASE_ROUTES: Routes = [
  {
    path: KnowledgeBasePaths.SPACE + '/:spaceId',
    component: SpaceComponent,
  },
  {
    path: '',
    component: SpacesComponent,
  },
]
