export type CompetenciesAndSkillsAction =
  | ToggleSelectedEntityAction
  | ChangeSearchAction
  | OpenFolderAction
  | CloseFolderAction
  | OpenCompetencyAction
  | CloseCompetencyAction;

export enum CompetenciesAndSkillsActionTypes {
  TOGGLE_SELECTED_ENTITY,
  CHANGE_SEARCH,
  OPEN_FOLDER,
  CLOSE_FOLDER,
  OPEN_COMPETENCY,
  CLOSE_COMPETENCY,
}

export interface OpenFolderAction {
  readonly type: CompetenciesAndSkillsActionTypes.OPEN_FOLDER;
  readonly folder: string;
}

export interface CloseFolderAction {
  readonly type: CompetenciesAndSkillsActionTypes.CLOSE_FOLDER;
  readonly folder: string;
}

export interface OpenCompetencyAction {
  readonly type: CompetenciesAndSkillsActionTypes.OPEN_COMPETENCY;
  readonly competency: string;
}

export interface CloseCompetencyAction {
  readonly type: CompetenciesAndSkillsActionTypes.CLOSE_COMPETENCY;
  readonly competency: string;
}

export interface ToggleSelectedEntityAction {
  readonly type: CompetenciesAndSkillsActionTypes.TOGGLE_SELECTED_ENTITY;
  readonly selectedEntity: string;
  readonly selected: boolean;
}

export interface ChangeSearchAction {
  readonly type: CompetenciesAndSkillsActionTypes.CHANGE_SEARCH;
  readonly search: string;
}
