import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-grid-list-toggle-button',
  templateUrl: './grid-list-toggle-button.component.html',
  styleUrls: ['./grid-list-toggle-button.component.scss']
})
export class GridListToggleButtonComponent {
  @Input() currentType: 'grid-cell' | 'list-element' = 'grid-cell';
  @Output() toggleGrid = new EventEmitter();
  @Output() toggleList = new EventEmitter();
}
