<div class="h-[28px] py-[5px] pl-[12px] rounded-[8px] flex items-center gap-[4px] max-w-[502px]"
  [ngClass]="{
    'chips-readonly-bg text-tt-text-secondary pr-[12px]': chips.type === 'readonly',
    'chips-base-bg text-tt-primary pr-[8px]': chips.type === 'base',
  }"
>
  <p class="flex-grow text-[15px] leading-[18px] line-clamp-1">
    {{chips.name}}
  </p>

  <button class="shrink-0 min-w-[20px] min-h-[20px] rounded-[6px] hover:bg-tt-button-hover"
    *ngIf="chips.type !== 'readonly'"
    (click)="deleteClicked.emit(chips.id)"
  >
    <svg-chips-x
      class="min-w-[20px] min-h-[20px] text-tt-primary"
    />
  </button>
</div>
