import { NavTab } from "src/app/core/components/nav-tabs/nav-tabs.component";
import { Tabs } from "../../main/presentation/state/main-state";
import { SettingsRoutesPaths } from "../../../routes/settings-routes";

export class SettingsConstants {
  public static SETTINGS: string = 'Настройки'

  public static NAV_TABS: NavTab[] = [
    {
      id: Tabs.COMPANY_SETTINGS,
      url: SettingsRoutesPaths.MAIN,
      title: 'Главная',
    },
    {
      id: Tabs.BILLING,
      url: SettingsRoutesPaths.BILLING,
      title: 'Подписка',
    },
  ]
}
