import { Injectable } from '@angular/core';
import { SectionSettingsService } from '../domain/section-settings-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SectionEditEntity } from '../domain/section-edit-entity';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { map } from "rxjs/operators";
import { SectionPrivacy } from "../../../sections/impl/domain/section-summary-entity";
import { GetSectionWithOwnerJson } from "./json/get-section-with-owner-json";
import { Constants } from "../presentation/components/section-settings-main/common/constants";
import {
  AutocompleteItem
} from "../../../../../core/components/fields/autocomplete-field/autocomplete-field.component";

@Injectable()
export class SectionSettingsServiceImpl implements SectionSettingsService {
  private mutableSection: BehaviorSubject<SectionEditEntity> =
    new BehaviorSubject<SectionEditEntity>(
      new SectionEditEntity(
        0,
        '',
        { id: '-1', name: '' },
        { id: 'PUBLIC', name: '' },
        'assets/empty-image.svg',
      ),
    );

  section: Observable<SectionEditEntity> = this.mutableSection.asObservable();

  constructor(private http: HttpClient) {}

  fetchSection(sectionId: number): void {
    this.http
      .get<GetSectionWithOwnerJson>(
        `${environment.apiUrl}/documentation/section/${sectionId}/withOwner`,
      )
      .pipe(
        map((getSectionResponse) => {
          const section: SectionEditEntity = {
            id: getSectionResponse.id,
            title: getSectionResponse.title,
            owner: {
              id: getSectionResponse.ownerId.toString(),
              name: getSectionResponse.ownerName
            },
            privacy: this.privacyToAutoComplete(getSectionResponse.visibility),
            icon: getSectionResponse.thumbnailUrl
          };
          return section;
        }),
      )
      .subscribe((section) => {
        this.mutableSection.next(section);
      });
  }

  getSection(): Observable<SectionEditEntity> {
    return this.section;
  }

  updateSection(section: SectionEditEntity): Observable<null> {
    const body = {
      title: section.title,
      thumbnailUrl: section.icon,
      sectionVisibility: section.privacy.id,
    };
    return this.http.patch<null>(
      `${environment.apiUrl}/documentation/section/${section.id}`,
      body,
    );
  }

  private privacyToAutoComplete(privacy: SectionPrivacy): AutocompleteItem{
    const result = Constants.PRIVACY_FIELD_VALUES.find((value)=>{
      return value.id == privacy
    })
    return result !== undefined ? result: Constants.PRIVACY_FIELD_VALUES[1]
  }
}
