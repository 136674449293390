import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-core-tooltip-box',
  templateUrl: './tooltip-box.component.html',
})

export class TooltipBoxComponent {
  @Input() tooltip: string = '';
  @Input() position: TooltipPosition = 'above';
}

export type TooltipPosition = 'above' | 'below' | 'left' | 'right' | 'before' | 'after'
