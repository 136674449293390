import { Tabs } from './main-state';
import { HeaderEmployee } from "../components/main-header/main-header.component";
import { NavigationSection } from "../components/navigation-section/navigation-section.component";

export type MainResultAction =
  | ChangeItemResultAction
  | SideBarResultAction
  | InitMainResultAction
  | ChangeIsLoadingResultAction
  | ChangeSubscriptionWindowVisibilityResultAction

export enum MainResultActionTypes {
  SELECT_ITEM,
  SHOW_SIDEBAR,
  INIT_MAIN,
  CHANGE_IS_LOADING,
  CHANGE_SUBSCRIPTION_WINDOW_VISIBILITY
}

export interface ChangeSubscriptionWindowVisibilityResultAction {
  readonly type: MainResultActionTypes.CHANGE_SUBSCRIPTION_WINDOW_VISIBILITY;
  readonly value: boolean
}

export interface ChangeIsLoadingResultAction {
  readonly type: MainResultActionTypes.CHANGE_IS_LOADING;
  readonly value: boolean;
}


export interface ChangeItemResultAction {
  readonly type: MainResultActionTypes.SELECT_ITEM;
  readonly item: Tabs;
}

export interface SideBarResultAction {
  readonly type: MainResultActionTypes.SHOW_SIDEBAR;
}

export interface InitMainResultAction {
  readonly type: MainResultActionTypes.INIT_MAIN;
  readonly enabledNavItems: string[]
  readonly companyName: string
  readonly userInfo: HeaderEmployee
  readonly subscriptionDaysLeft?: number
  readonly navSections: NavigationSection[]
}
