<div
  [style.padding-left.px]="depth * 32"
  (click)="onToggle()"
  class="flex items-center h-tt-button-height_l py-tt_extra-s rounded-tt-border-radius_m bg-tt-bg-default transition-colors duration-75 hover:bg-tt-hover-color cursor-pointer"
>
  <div class="whitespace-nowrap px-tt_s flex items-center gap-tt_s">
    <svg-bordered-arrow
      class="text-tt-icon-field -rotate-90 transition-transform duration-75"
      [ngClass]="{ 'rotate-0': opened }"
    />
    <svg-competency class="text-tt-icon-default" />
    <span class="text-tt-font-size_l text-tt-text-default">
      {{ competency.name }}
    </span>
  </div>
</div>

<ng-container *ngIf="opened">
  <app-skill-item
    *ngFor="let skill of competency.skills"
    [skill]="skill"
    [depth]="depth + 1"
  />
</ng-container>
