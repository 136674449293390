export class SectionSummaryEntity {
  constructor(
    readonly id: number,
    readonly title: string,
    readonly url: string,
    readonly isFavorite: boolean,
  ) {}
}

export enum SectionPrivacy {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}
