<div
  *ngIf="amount <= 1"
  [ngClass]="className"
  class="skeleton rounded-tt-border-radius_l"
></div>

<div *ngIf="amount > 1" class="flex flex-col" [ngStyle]="{ gap: gap }">
  <div
    *ngFor="let item of [].constructor(amount)"
    [ngClass]="className"
    class="skeleton rounded-tt-border-radius_l"
  ></div>
</div>
