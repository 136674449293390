<div class="flex">
  <div class="w-full max-w-[720px]">
    <div class="w-full flex flex-col gap-[16px]">
      <app-core-input-field
        [required]="true"
        variant="standard"
        [defaultValue]="state.currentSectionData.title"
        label="Название"
        placeholder="Название раздела"
        type="text"
        [error]="state.titleError"
        (onChange)="
          performAction({
            type: SectionSettingsMainActionTypes.CHANGE_TITLE,
            title: $event
          })
        "
      />
      <!--<app-core-autocomplete-field ПОЛЕ С ВЛАДЕЛЬЦЕМ
        variant="standard"
        [staticItems]="false"
        [required]="true"
        [items]="Constants.OWNER_FIELD_VALUES"
        [selectedItem]="state.currentSectionData.owner"
        label="Владелец раздела"
        (onSelect)="
          performAction({
            type: SectionSettingsMainActionTypes.CHANGE_OWNER,
            ownerId: $event
          })
        "
      />-->
      <app-core-autocomplete-field
        variant="standard"
        [staticItems]="false"
        [items]="Constants.PRIVACY_FIELD_VALUES"
        [selectedItem]="state.currentSectionData.privacy"
        label="Приватность раздела"
        (onSelect)="
          performAction({
            type: SectionSettingsMainActionTypes.CHANGE_PRIVACY,
            privacyId: $event
          })
        "
      />
      <div>
        <p class="text-tt-font-size_l text-tt-text-secondary mb-1">Изображение раздела</p>
        <app-core-input-icon
          [windowIcons]="state.iconUrls"
          [initUrl]="state.baseSectionData.icon"
          [currentImageUrl]="state.currentSectionData.icon"
          (imageChanged)="performAction({
            type: SectionSettingsMainActionTypes.CHANGE_ICON,
            iconUrl: $event
          })"
        />
      </div>

    </div>
    <div *ngIf="state.saveButtonVisible" class="flex mt-[20px] gap-[16px]">
      <app-core-button
        variant="solid"
        (onClick)="
          performAction({
            type: SectionSettingsMainActionTypes.SAVE_CHANGES
          })
        "
        >Сохранить</app-core-button
      >
      <app-core-button
        variant="soft"
        (onClick)="
          performAction({
            type: SectionSettingsMainActionTypes.DROP_CHANGES
          })
        "
        >Отменить</app-core-button
      >
    </div>
  </div>
</div>
