import { Component, ElementRef, EventEmitter, Output, ViewChild } from "@angular/core";
import { DocumentsDragAndDropService } from "../../../services/documents-drag-and-drop.service";
import { MovingDocumentProps } from "../document-item/document-item.component";

@Component({
  selector: 'space-drop-to-root',
  templateUrl: './drop-box-to-root.component.html',
  styleUrls: ['../document-item/document-item.component.scss']
})
export class DropBoxToRootComponent {
  @Output() onDrop = new EventEmitter<MovingDocumentProps>();

  @ViewChild('dropEl') dropEl?: ElementRef

  isDragOver: boolean = false

  constructor(private dragAndDropService: DocumentsDragAndDropService) {
  }

  dragEnter(event: DragEvent){
    event.preventDefault()
    this.isDragOver = true
  }

  dragLeave(event: DragEvent){
    event.preventDefault()
    if (!this.dropEl?.nativeElement?.contains(event.relatedTarget as Node | null)) {
      this.isDragOver = false
    }
  }

  dragOver(event: DragEvent){
    event.preventDefault()
  }

  drop(event: DragEvent){
    this.isDragOver = false
    const document = this.dragAndDropService.getDragDocument()
    if(document && !(document.parentId == undefined && document.nextDocumentId == undefined)){
      this.onDrop.emit({
        document: document,
        moveToDocumentId: undefined
      })
    }
  }
}
