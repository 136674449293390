import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { GradeComponent } from './presentation/view/grade.component';

@NgModule({
  declarations: [GradeComponent],
  exports: [GradeComponent],
  imports: [CommonModule, ComponentsModule, RouterOutlet],
})
export class GradeModule {}
