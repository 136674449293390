<section class="pr-[16px]" [ngClass]="className" [class.h-full]="direction === 'vertical'">
  <div
    class="p-[16px] rounded-[12px] bg-tt-modal-promt-background flex justify-between gap-4 overflow-y-auto tiny-scrollbar overflow-x-hidden w-full"
    [ngClass]="{
      'flex-col h-full max-w-[350px]': direction === 'vertical',
      'absolute left-0 right-0 top-0 flex-row h-[150px]':
        direction === 'horizontal'
    }"
  >
    <div class="flex flex-col gap-3">
      <span
        *ngIf="step"
        class="text-tt-font-size_l text-tt-modal-promt-steps"
      >
        Шаг {{ step!.current }} из {{ step!.total }}
      </span>
      <h2
        class="text-tt-font-size_3xl text-tt-modal-promt-title font-medium"
      >
        {{ title }}
      </h2>
      <div class="text-tt-font-size_l text-tt-modal-promt-subtitle">
        <ng-content />
      </div>
    </div>
    <div
      *ngIf="image"
      class="flex-grow-0"
      [ngClass]="{
        'max-h-48 text-start': direction === 'vertical',
        'min-w-32 max-h-32 text-end': direction === 'horizontal'
      }"
    >
      <img
        class="h-full inline-block"
        src="{{ image!.url }}"
        alt="{{ image!.alt }}"
        draggable="false"
      />
    </div>
  </div>
</section>
