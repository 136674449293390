import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  SettingsMainResultAction,
  SettingsMainResultActionTypes,
} from './settings-main-result-action';
import { SettingsMainState } from './settings-main-state';

@Injectable()
export class SettingsMainReducer
  implements Reducer<SettingsMainState, SettingsMainResultAction>
{
  reduce(state: SettingsMainState, action: SettingsMainResultAction): SettingsMainState {
    switch (action.type) {
      case SettingsMainResultActionTypes.INIT:
        return {...state,
          organisationId: action.organisationId,
          organisationTitle: action.organisationTitle,
          newOrganisationTitle: action.organisationTitle,
          organisationTitleError: ''
        }
      case SettingsMainResultActionTypes.CHANGE_ORGANISATION_NAME:
        return {...state,
          newOrganisationTitle: action.newOrganisationTitle,
          organisationTitleError: action.organisationTitleError
        }
      case SettingsMainResultActionTypes.CANCEL_TITLE:
        return {...state,
          newOrganisationTitle: state.organisationTitle,
          organisationTitleError: ''
        }
      case SettingsMainResultActionTypes.CHANGE_NAV_ITEM:
        return {...state,
          currentNavItem: action.navItem
        }
      case SettingsMainResultActionTypes.CHANGE_LANGUAGE:
        return {...state, selectedLanguage: action.language ? action.language: state.selectedLanguage}
      case SettingsMainResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY:
        return {...state, isDeleteAlertVisible: action.value}
    }
  }
}
