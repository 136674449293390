import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-core-image-stub',
  templateUrl: './image-stub.component.html',
  styleUrls: ['./image-stub.component.scss'],
  host: {
    class: 'w-full flex flex-col items-center p-[24px]',
  }
})
export class ImageStubComponent {
  @Input() imageSrc?: string;
  @Input() size: 'medium' | 'large' = 'medium';
  @Input() title: string = ''
  @Input() description: string = ''
}
