import { FilterMenuItem } from '../../../../../../core/components/filter-menu/filter-menu.component';
import { DocumentItemEntity } from '../../domain/document-item-entity';
import { FiltersState } from './section-content-state';

export type SectionContentResultAction =
  | ChangeItemResultAction
  | UpdateDocumentsResultAction
  | ChangeFilterStateResultAction
  | ChangeDocumentMarkResultAction;
export enum SectionContentResultActionTypes {
  CHANGE_FILTER_ITEM,
  UPDATE_DOCUMENTS,
  CHANGE_FILTER_STATE,
  CHANGE_DOCUMENT_MARK
}
export interface ChangeDocumentMarkResultAction {
  readonly type: SectionContentResultActionTypes.CHANGE_DOCUMENT_MARK;
  readonly documents: DocumentItemEntity[];
  readonly filteredDocuments: DocumentItemEntity[];
}
export interface ChangeItemResultAction {
  readonly type: SectionContentResultActionTypes.CHANGE_FILTER_ITEM;
  readonly items: FilterMenuItem[];
}

export interface UpdateDocumentsResultAction {
  readonly type: SectionContentResultActionTypes.UPDATE_DOCUMENTS;
  readonly documents: DocumentItemEntity[];
  readonly filteredDocuments: DocumentItemEntity[];
}

export interface ChangeFilterStateResultAction {
  readonly type: SectionContentResultActionTypes.CHANGE_FILTER_STATE;
  readonly filterState: FiltersState;
  readonly filteredDocuments: DocumentItemEntity[];
}
