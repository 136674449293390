import { Injectable } from '@angular/core';
import { CompetenciesAndSkillsDto } from '../../../@data/dto/competencies-and-skills.dto';

@Injectable()
export class CompetenciesAndSkillsState {
  readonly selectedEntity: string | null = null;
  readonly openedFolders: string[] = [];
  readonly openedCompetencies: string[] = [];
  readonly competenciesAndSkills: CompetenciesAndSkillsDto = {
    skills: [],
    competencies: [],
    folders: [],
  };
  readonly search: string = '';
  readonly isLoading: boolean = true;
  readonly error: CompetenciesAndSkillsError | null = null;
}

export enum CompetenciesAndSkillsError {
  INCORRECT_CREDENTIALS,
  CONNECTION_ERROR,
}
