import { NavTab } from "../../../core/components/nav-tabs/nav-tabs.component";
import { NewsRoutesPath } from "../../../routes/news-routes";
import { Tabs } from "../../main/presentation/state/main-state";

export class NewsConstants{
  public static NEWS:string = "Новости";
  public static NAV_TABS: NavTab[] = [
    {
      id: Tabs.NEWS,
      url: NewsRoutesPath.LIST,
      title: 'Главная'
    }
  ]
}
