<div class="group py-[12px] pl-[24px] pr-[8px] flex items-center w-full gap-[12px] min-h-[32px]"
>
  <button
    class="min-w-[24px] min-h-[24px] text-tt-icon-default"
    (click)="arrowClick()"
    *ngIf="section.documents.length != 0"
    [ngClass]="{
      '-rotate-90': section.isOpen,
      'rotate-90': !section.isOpen,
    }"
    #arrowButton
  >
    <svg-arrow class="min-w-[24px] min-h-[24px]"/>
  </button>

  <p class="font-normal text-tt-text-primary-without-alpha text-[13px] text-left leading-[18px] h-full w-full truncate">
    <span
      app-core-with-highlighting
      [text]="titleStrings"
    ></span>
    <span *ngIf="section.countSearchMatches != 0">(Встречено {{section.countSearchMatches}} раз)</span>
  </p>

  <div class="w-max max-h-[24px] text-tt-icon-default gap-[12px]"
    [ngClass]="{
      'flex ': isPopupOpen,
      'hidden group-hover:flex': !isPopupOpen
    }"
    *ngIf="canEdit"
  >
    <div class="relative"
      appClickOutside
      (clickOutside)="isPopupOpen = false"
    >
      <button
        class="w-[24px] h-[24px]"
        (click)="isPopupOpen = !isPopupOpen"
      >
        <svg-triple-dot class="min-w-[24px] min-h-[24px]"/>
      </button>

      <app-core-action-menu
        class="absolute top-[24px] right-0 z-50"
        *ngIf="isPopupOpen"
        [sections]="SpaceConstants.TREE_POPUP_MENU_SECTIONS"
        (elementClicked)="popupElementClicked($event)"
      />
    </div>

    <button
      class="w-[24px] h-[24px]"
      (click)="createClicked.emit(section.id)"
    >
      <svg-plus class="min-w-[24px] min-h-[24px]"/>
    </button>
  </div>

</div>

<div
  *ngIf="section.isOpen"
>
  <space-document-item
    *ngFor="let doc of section.documents"
    [document]="doc"
    [canEdit]="canEdit"
    [searchStr]="searchStr"
    [selectedId]="selectedDocumentId"
    (createClicked)="documentCreateClicked.emit($event)"
    (documentClicked)="documentClicked.emit($event)"
    (editClicked)="documentEditClicked.emit($event)"
    (deleteClicked)="documentDeleteClicked.emit($event)"
  />
</div>

<div class="h-[1px] w-full bg-tt-border-default"></div>
