import { Tabs } from './main-state';

export type MainAction =
  | ChangeItemAction
  | LogoutAction
  | SideBarAction
  | ChangeSubscriptionWindowVisibilityAction;

export enum MainActionTypes {
  SELECT_ITEM,
  LOGOUT,
  SHOW_SIDEBAR,
  CHANGE_SUBSCRIPTION_WINDOW_VISIBILITY
}

export interface ChangeSubscriptionWindowVisibilityAction {
  readonly type: MainActionTypes.CHANGE_SUBSCRIPTION_WINDOW_VISIBILITY;
  readonly value: boolean;
}

export interface ChangeItemAction {
  readonly type: MainActionTypes.SELECT_ITEM;
  readonly item: Tabs;
}

export interface LogoutAction {
  readonly type: MainActionTypes.LOGOUT;
}

export interface SideBarAction {
  readonly type: MainActionTypes.SHOW_SIDEBAR;
}
