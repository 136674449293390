import { Executor } from '../../../../../../core/mvi/store';
import { SectionSettingsState, NavItem } from './section-settings-state';
import {
  SectionSettingsAction,
  SectionSettingsActionTypes,
} from './section-settings-action';
import {
  SectionSettingsResultAction,
  SectionSettingsResultActionTypes,
} from './section-settings-result-action';
import { Injectable } from '@angular/core';

@Injectable()
export class SectionSettingsExecutor extends Executor<
  SectionSettingsState,
  SectionSettingsAction,
  SectionSettingsResultAction
> {
  constructor() {
    super();
  }

  execute(action: SectionSettingsAction) {
    switch (action.type) {
      case SectionSettingsActionTypes.SELECT_ITEM:
        this.reduce({
          type: SectionSettingsResultActionTypes.SELECT_ITEM,
          item: action.item,
        });
        break;
    }
  }
}
