<a
  href="https://www.teamtells.ru"
  class="z-10 w-11 h-11 rounded-full bg-tt-icon-background flex items-center justify-center text-tt-icon fixed right-3 top-3 sm:right-7 sm:top-7"
>
  <svg-x-mark class="text-tt-icon" />
</a>
<div
  class="bg-tt-bg-page min-h-[100dvh] min-w-[100dvw] flex items-center flex-col justify-center px-2"
>
  <ng-content />
</div>
