<div class="flex items-center justify-between gap-tt_l">
  <h2 class="text-tt-font-size_3xl text-tt-text-default font-medium">
    Профили компетенций
  </h2>
  <div class="flex flex-row gap-tt_l items-center">
    <app-core-icon-button variant="ghost">
      <svg-add-folder />
    </app-core-icon-button>
    <app-core-button>Добавить</app-core-button>
  </div>
</div>

<app-core-search-field className="my-tt_l" />

<app-core-skeleton
  className="rounded-tt-border-radius_m w-full h-tt-button-height_l"
  gap="8px"
  [amount]="20"
/>
