import { Component } from '@angular/core';
import { Store } from 'src/app/core/mvi/store';
import { filterBySearchStr } from 'src/app/core/utils/filter-by-search-str';
import {
  RatingScalesAction,
  RatingScalesActionTypes,
} from '../state/rating-scales-action';
import { RatingScalesExecutor } from '../state/rating-scales-executor';
import { RatingScalesReducer } from '../state/rating-scales-reducer';
import { RatingScalesResultAction } from '../state/rating-scales-result-action';
import { RatingScalesState } from '../state/rating-scales-state';

@Component({
  providers: [RatingScalesState, RatingScalesReducer, RatingScalesExecutor],
  selector: 'app-rating-scales',
  templateUrl: './rating-scales.component.html',
})
export class RatingScalesComponent extends Store<
  RatingScalesState,
  RatingScalesExecutor,
  RatingScalesAction,
  RatingScalesResultAction
> {
  constructor(
    state: RatingScalesState,
    executor: RatingScalesExecutor,
    reducer: RatingScalesReducer,
  ) {
    super(state, executor, reducer);
  }

  protected readonly RatingScalesActionTypes = RatingScalesActionTypes;

  ratingScaleTrackByFn(_: number, ratingScale: any) {
    return ratingScale.id;
  }

  filterBySearchStr = filterBySearchStr;
}
