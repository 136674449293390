import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  CompetenciesAndSkillsResultAction,
  CompetenciesAndSkillsResultActionTypes,
} from './competencies-and-skills-result-action';
import { CompetenciesAndSkillsState } from './competencies-and-skills-state';

@Injectable()
export class CompetenciesAndSkillsReducer
  implements
    Reducer<CompetenciesAndSkillsState, CompetenciesAndSkillsResultAction>
{
  reduce(
    state: CompetenciesAndSkillsState,
    action: CompetenciesAndSkillsResultAction,
  ): CompetenciesAndSkillsState {
    switch (action.type) {
      case CompetenciesAndSkillsResultActionTypes.OPEN_FOLDER:
        return {
          ...state,
          openedFolders: [...state.openedFolders, action.folder],
        };

      case CompetenciesAndSkillsResultActionTypes.CLOSE_FOLDER:
        return {
          ...state,
          openedFolders: state.openedFolders.filter(
            (folder) => folder !== action.folder,
          ),
        };

      case CompetenciesAndSkillsResultActionTypes.OPEN_COMPETENCY:
        return {
          ...state,
          openedCompetencies: [...state.openedCompetencies, action.competency],
        };

      case CompetenciesAndSkillsResultActionTypes.CLOSE_COMPETENCY:
        return {
          ...state,
          openedCompetencies: state.openedCompetencies.filter(
            (competency) => competency !== action.competency,
          ),
        };

      case CompetenciesAndSkillsResultActionTypes.UPDATE_COMPETENCIES_AND_SKILLS:
        return {
          ...state,
          competenciesAndSkills: action.competenciesAndSkills,
        };

      case CompetenciesAndSkillsResultActionTypes.CHANGE_SEARCH:
        return { ...state, search: action.search };

      case CompetenciesAndSkillsResultActionTypes.TOGGLE_SELECTED_ENTITY:
        if (
          state.selectedEntity !== null &&
          state.selectedEntity !== action.selectedEntity &&
          !action.selected
        ) {
          return state;
        }

        return {
          ...state,
          selectedEntity: action.selected ? action.selectedEntity : null,
        };

      case CompetenciesAndSkillsResultActionTypes.CHANGE_LOADING_STATUS:
        return { ...state, isLoading: action.isLoading };

      case CompetenciesAndSkillsResultActionTypes.CHANGE_ERROR_STATUS:
        return { ...state, error: action.error };
    }
  }
}
