import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-core-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent {
  @Input() className?: string | string[];
  @Input() placeholder: string = 'Поиск';
  @Input() height: 'm' | 'l' = 'l';
  @Input() size: 'l' | 'xl' = 'l'
  @Input() onSubmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  value: string = ''

  onSubmitValue(event: any) {
    if (!this.onSubmit) return;
    const value = event.target.value;
    this.onSubmit.emit(value);
  }

  onChangeValue(event: any) {
    if (!this.onChange) return;
    const value = event.target.value;
    this.onChange.emit(value);
  }
}
