import { SpacesState } from "./spaces-state";
import { SpacesAction, SpacesActionTypes } from "./spaces-action";
import { SpacesResultAction, SpacesResultActionTypes } from "./spaces-result-action";
import { Injectable } from "@angular/core";
import { Executor } from "src/app/core/mvi/store";
import { ToastsService } from "src/app/core/components/toast-alert/services/toast-alert.service";
import { SpaceSummeryEntity } from "../components/space-item/space-item.component";
import { Router } from "@angular/router";
import { MainRoutesPaths } from "src/app/routes/main-routes";
import { KnowledgeBasePaths } from "src/app/routes/knowledge-base-routes";
import { parseToHighlightedParts } from "src/app/core/utils/parse-to-highlighted-parts";
import { KnowledgeBaseService } from "../../../../data/knowledge-base-service";
import { KnowledgeBaseListSpaceDto } from "../../../../data/dto/knowledge-base-list-dto";

@Injectable()
export class SpacesExecutor extends Executor<
  SpacesState,
  SpacesAction,
  SpacesResultAction
> {
  constructor(
    private toastsService: ToastsService,
    private knowledgeBaseService: KnowledgeBaseService,
    private router: Router,
  ) {
    super();
  }

  execute(action: SpacesAction) {
    switch (action.type) {
      case SpacesActionTypes.CHANGE_VISIBLE_CREATE_SPACE_MODAL:
        this.reduce({
          type: SpacesResultActionTypes.CHANGE_VISIBLE_CREATE_SPACE_MODAL,
          value: action.value
        })
        break;
      case SpacesActionTypes.CREATE_SPACE:
        this.handleCreateSpace(action.spaceName)
        break
      case SpacesActionTypes.UPDATE_SPACES:
        this.handleUpdateSpaces()
        break
      case SpacesActionTypes.FILTER_SPACES:
        this.handleFilterSpaces(action.filterStr)
        break
    }
  }

  private handleFilterSpaces(filterStr: string){
    const filteredSpaces = this.getState().spaces.filter((space)=>
      {
        space.nameParts = parseToHighlightedParts(space.name, filterStr)
        return space.nameParts.length > 1
      })

    this.reduce({
      type: SpacesResultActionTypes.FILTER_SPACES,
      filterStr: filterStr,
      filteredSpaces: filteredSpaces
    })
  }

  private handleCreateSpace(name: string){
    this.reduce({
      type: SpacesResultActionTypes.CHANGE_IS_CREATING,
      value: true
    })
    this.knowledgeBaseService.createSpace(name)
    .subscribe((response)=>{
      this.reduce({
        type: SpacesResultActionTypes.CHANGE_IS_CREATING,
        value: false
      })
      this.router.navigateByUrl(`${MainRoutesPaths.KNOWLEDGE_BASE}/${KnowledgeBasePaths.SPACE}/${response.id}`)
    })
  }

  private handleUpdateSpaces(){
    this.reduce({
      type: SpacesResultActionTypes.CHANGE_IS_LOADING,
      value: true
    })

    this.knowledgeBaseService.knowledgeBaseList()
    .subscribe((knowledgeBaseList) => {
      this.reduce({
        type: SpacesResultActionTypes.UPDATE_SPACES,
        canCreate: knowledgeBaseList.canCreate,
        recentSpaces: this.mapToSpaceSummeryEntities(knowledgeBaseList.recent),
        spaces: this.mapToSpaceSummeryEntities(knowledgeBaseList.allItems)
      })
      this.reduce({
        type: SpacesResultActionTypes.CHANGE_IS_LOADING,
        value: false
      })
    })
  }

  private mapToSpaceSummeryEntities(
    spacesDto: KnowledgeBaseListSpaceDto[] | null
  ): SpaceSummeryEntity[]{
    if(spacesDto == null){
      return []
    }
    return spacesDto.map<SpaceSummeryEntity>((spaceDto) => {
      return {
        id: spaceDto.id,
        name: spaceDto.name,
        sectionsCount: spaceDto.sectionsCount,
        documentsCount: spaceDto.documentsCount,
        nameParts: parseToHighlightedParts(spaceDto.name, this.getState().filterString),
      }
    })
  }
}
