import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import {
  ActionElement,
  ActionElementStyle,
  ActionSection
} from "src/app/core/components/action-menu/action-menu.component";
import { EmployeeSummeryEntity } from "../../../../../domain/employee";
import { stringToRgb } from "src/app/core/utils/string-to-rgb";
import { EmployeesConstants } from "../../../../../common/employees-constants";
import { stringToPhoneNumber } from "../../../../../../../core/utils/string-to-phone-number";


@Component({
  selector: '[employeeTr]',
  templateUrl: './employee-tr.component.html',
  styleUrls: ['./employee-tr.component.scss'],
})
export class EmployeeTrComponent
implements OnChanges
{
  @Input() employee: EmployeeSummeryEntity ={
    id: -1,
    surname: '',
    name: '',
    icon: '',
    email: '',
    phoneNumber: '',
    nameParts: [],
    emailParts: []
  }
  @Input() canEdit: boolean = false
  @Input() canDelete: boolean = false
  @Input() windowWidth: number = 0

  @Output() deleteClicked = new EventEmitter()
  @Output() editClicked = new EventEmitter()

  isPopupVisible: boolean = false
  isAvatarUserCardVisible: boolean = false
  isNameUserCardVisible: boolean = false

  phoneNumber: string = ''
  avatarPlaceHolder: string = ''
  avatarBgColor?: string

  sections: ActionSection[] = []

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['employee']){
      this.phoneNumber = stringToPhoneNumber(this.employee.phoneNumber)

      this.avatarPlaceHolder = (this.employee.surname.charAt(0) + this.employee.name.charAt(0)).toUpperCase()

      this.avatarBgColor = stringToRgb(this.employee.email)
    }
    if(changes['canEdit'] || changes['canDelete']){
      const newActionElements: ActionElement[] = []
      if(this.canEdit){
        newActionElements.push(EmployeesConstants.EDIT_ACTION_MENU)
      }
      if(this.canDelete){
        newActionElements.push(EmployeesConstants.DELETE_ACTION_MENU)
      }

      this.sections = [
        {
          elements: newActionElements
        }
      ]
    }
  }

  onElementClick(id: string){
    switch(id){
      case 'edit':
        this.editClicked.emit()
        break;
      case 'delete':
        this.deleteClicked.emit()
        break;
    }
    this.isPopupVisible = false
  }

  tripleDotClick(){
    this.isPopupVisible = !this.isPopupVisible
  }

  popupClose() {
    this.isPopupVisible = false
  }

  closeCard(){
    this.isAvatarUserCardVisible = false
    this.isNameUserCardVisible = false
  }
}
