import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CreateRatingScaleDto } from '../dto/create-rating-scale.dto';
import { RatingScaleDto } from '../dto/rating-scale.dto';
import { RatingScalesDto } from '../dto/rating-scales.dto';
import { UpdateRatingScaleDto } from '../dto/update-rating-scale.dto';

@Injectable({
  providedIn: 'root',
})
export class RatingScaleService {
  constructor(private http: HttpClient) {}

  private baseUrl = `${environment.apiUrl}/performance-review/scales/`;

  createRatingScaleEvent$ = new Subject<void>();
  updateRatingScaleEvent$ = new Subject<RatingScaleDto>();

  getRatingScales(): Observable<RatingScalesDto> {
    return this.http.get<RatingScalesDto>(this.baseUrl);
  }

  createRatingScale(ratingScale: CreateRatingScaleDto): Observable<boolean> {
    return this.http.post(this.baseUrl, ratingScale).pipe(map(() => true));
  }

  getRatingScale(ratingSaleId: string): Observable<RatingScaleDto> {
    return this.http.get<RatingScaleDto>(`${this.baseUrl}${ratingSaleId}/`);
  }

  deleteRatingScale(ratingScaleId: string): Observable<boolean> {
    return this.http
      .delete(`${this.baseUrl}${ratingScaleId}`)
      .pipe(map(() => true));
  }

  updateRatingScale(ratingScale: UpdateRatingScaleDto): Observable<boolean> {
    const { id, ...updateRatingScale } = ratingScale;

    return this.http
      .put(`${this.baseUrl}${id}`, updateRatingScale)
      .pipe(map(() => true));
  }
}
