import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpaceItemComponent } from './presentation/components/space-item/space-item.component';
import { SpacesComponent } from './presentation/spaces.component';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { CreateSpaceModalComponent } from './presentation/components/create-space-modal/create-space-modal.component';
import { RouterLink } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SvgPlay, SvgPlus } from "../../../../core/components/svg-components/svg.components";

@NgModule({
  declarations: [
    SpaceItemComponent,
    SpacesComponent,
    CreateSpaceModalComponent
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    ComponentsModule,
    SvgPlus,
    SvgPlay,
    RouterLink,
    MatProgressSpinnerModule
  ]
})
export class SpacesModule {}
