import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface PageLayoutRoute {
  title: string;
  path: string;
  exact: boolean;
}

export interface PageLayoutFolderRoutes {
  id: string;
  title: string;
  opened: boolean;
  children: PageLayoutRoute[];
}

export function isPageLayoutRoute(
  routeOrRoutes: PageLayoutRoute | PageLayoutFolderRoutes,
): routeOrRoutes is PageLayoutRoute {
  return (routeOrRoutes as PageLayoutRoute).path !== undefined;
}

export function isPageLayoutFolderRoutes(
  routeOrRoutes: PageLayoutRoute | PageLayoutFolderRoutes,
): routeOrRoutes is PageLayoutFolderRoutes {
  return (routeOrRoutes as PageLayoutFolderRoutes).children !== undefined;
}

@Component({
  selector: 'app-core-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
})
export class PageLayoutComponent {
  @Input() title = 'Layout title';
  @Input() headerImage =
    'https://cdn.discordapp.com/attachments/1083850928161030224/1213168541738606692/4a5f4a713f6c5bd5d23d5321f15fcf16.jpg?ex=6606f33f&is=65f47e3f&hm=0f3be6f1552b3d9a2fde06bf57fcbae599e3187b1b8f14ed4b5909e048666267&';
  @Input() routes: (PageLayoutRoute | PageLayoutFolderRoutes)[] = [];

  @Output() onClickFolderRoutes = new EventEmitter<string>();

  isRoute = isPageLayoutRoute;
  isFolderRoutes = isPageLayoutFolderRoutes;

  routeTrackByFc(index: number, item: any) {
    return item.title + index.toString();
  }
}
