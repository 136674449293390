<div class="relative flex justify-center h-[48px]">
  <button class="group w-full flex justify-between items-center bg-tt-line-unaccented-hover rounded-tt-border-radius_s"
    (click)="blockClicked($event)"
  >
    <div class="flex gap-[8px] items-center">
      <div class="w-[48px] h-[48px] flex justify-center items-center">
        <svg-file *ngIf="fileType === '*'" class="text-tt-icon-secondary w-[24px] h-[24px]"/>
        <svg-file-downloader-image *ngIf="fileType === 'image/*'" class="text-tt-icon-secondary"/>
        <svg-file-downloader-video *ngIf="fileType === 'video/*'" class="text-tt-icon-secondary"/>
      </div>
      <p class="text-tt-text-placeholder" *ngIf="fileName.length === 0 && fileUrl.length === 0">{{title}}</p>
      <div *ngIf="fileName.length !== 0">
        <p class="text-tt-text-default text-left">{{fileName}}
          <br/>
          <span class="text-tt-text-secondary">{{fileSize}} - </span>
          <button>
            <span class="text-tt-text-link">Скачать</span>
          </button>
        </p>
      </div>
        <p class="text-tt-text-default" *ngIf="fileUrl.length !== 0">Ссылка на файл: <a class="text-tt-text-link" href="{{fileUrl}}">{{fileUrl}}</a></p>
      </div>

    <div class="relative"
      [ngClass]="{
        'block': isTripleDotPopupVisible,
        'hidden': !isTripleDotPopupVisible,
        'group-hover:block': !isReadOnly,
      }"
      *ngIf="!isReadOnly"
      #tripleDot
    >
      <button class="w-[48px] h-[48px] text-tt-icon-default flex justify-center items-center"
        (click)="openTripleDotPopup()"
      >
        <svg-triple-dot class="w-[24px] h-[24px]"/>
      </button>
      <app-core-action-menu
        appPopup
        popupOffsetXSide="right"
        *ngIf="isTripleDotPopupVisible"
        (popupClose)="isTripleDotPopupVisible = false"
        [sections]="POPUP_SECTIONS"
        (elementClicked)="popupClicked($event)"
      />
    </div>
  </button>

  <app-file-downloader-popup
    class="z-50 max-w-[450px] w-full"
    appPopup
    popupOffsetXSide="center"
    (popupClose)="isUploadPopupVisible = false"
    *ngIf="isUploadPopupVisible"
    [isVisible]="isUploadPopupVisible"
    (uploadClicked)="uploadClicked()"
    (urlConfirmClicked)="applyUrl($event)"
  />
</div>

<input type="file" class="hidden" [accept]=fileType #inputFile (change)="onSelectFile($event)">
