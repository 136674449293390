<div
  class="p-[8px] h-[60px] border-b-[1px] border-tt-separator-color flex items-center justify-between hover:bg-tt-hover-color"
>
  <div class="flex w-full gap-[16px] cursor-pointer" (click)="clicked.emit()">
    <img class="rounded-tt-border-radius_s w-10 h-10" src="{{ section.url }}" />
    <p class="my-auto text-tt-font-size_l text-tt-text-default">
      {{ section.title }}
    </p>
  </div>
  <div class="flex gap-[12px] relative">
    <svg-bookmark-fill
      *ngIf="section.isFavorite"
      class="cursor-pointer w-10 h-10"
      (click)="markClicked.emit()"
    />
    <svg-bookmark
      *ngIf="!section.isFavorite"
      class="fill-tt-icon-secondary cursor-pointer w-10 h-10"
      (click)="markClicked.emit()"
    />
    <svg-triple-dot
      class="text-tt-icon-secondary cursor-pointer p-[8px] min-w-10 min-h-10"
      (click)="popupVisibility = !popupVisibility"
      [ngClass]="{
        'bg-tt-hover-color rounded-tt-border-radius_m': popupVisibility
      }"
    />
    <app-core-action-menu
      class="absolute z-50 top-[40px] right-[0px]"
      *ngIf="popupVisibility"
      variant="standard"
      [sections]="popupMenuSections"
      (elementClicked)="popupClicked($event)"
    />
  </div>
</div>
