<div
  class="size-6 mt-[1px]"
  [ngClass]="{
    'me-[10px] ms-[-4px]': position === 'start',
    'ms-[10px] me-[-4px]': position === 'end',
    'mx-[10px]': position === 'center'
  }"
>
  <ng-content class="fill-tt-text-primary" />
</div>
