import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsMainComponent } from './presentation/settings-main.component';
import { ComponentsModule } from "../../../../core/components/components.module";
import {
  SpaceSettingsHome,
  SvgFile,
  SvgPerson,
  SvgTrash
} from "../../../../core/components/svg-components/svg.components";
import { SpaceModule } from "../../../knowledge-base/modules/space/space.module";

@NgModule({
  declarations: [SettingsMainComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    SpaceModule,
    SpaceSettingsHome,
    SvgPerson,
    SvgTrash,
    SvgFile
  ]
})
export class SettingsMainModule {}
