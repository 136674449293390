<div
  class="
    order-bg-gradient p-[48px] w-full border-[1px] border-tt-border-default rounded-[24px]
    flex flex-col gap-[48px]
  "
>
  <p class="font-medium text-[32px] leading-[48px] text-tt-text-default">Рассчитать стоимость</p>

  <div class="rounded-[20px] border-[1px] border-tt-icon-secondary w-max main-text">
    <div class="relative w-max">
      <button
        class="rounded-[20px] px-[24px] py-[11px]"
        (click)="changeSubscriptionType('year')"
        [ngClass]="{
          'invisible': subscriptionType == 'year'
        }"
      >
        <p>Ежегодная оплата<span class="text-tt-text-secondary"> 2 месяца экономии 🔥</span></p>
      </button>
      <button
        class="rounded-[20px] px-[24px] py-[11px] border-[2px] border-tt-text-default absolute z-10 -left-[2px] -top-[2px]"
        (click)="changeSubscriptionType('year')"
        *ngIf="subscriptionType == 'year'"
      >
        <p>Ежегодная оплата<span class="text-tt-text-secondary"> 2 месяца экономии 🔥</span></p>
      </button>

      <button
        class="rounded-[20px] px-[24px] py-[11px]"
        (click)="changeSubscriptionType('month')"
        [ngClass]="{
          'invisible': subscriptionType == 'month'
        }"
      >
        <p>Ежемесячная оплата</p>
      </button>
      <button
        class="rounded-[20px] px-[24px] py-[11px] border-[2px] border-tt-text-default absolute z-10 -right-[2px] -top-[2px]"
        (click)="changeSubscriptionType('month')"
        *ngIf="subscriptionType == 'month'"
      >
        <p>Ежемесячная оплата</p>
      </button>
    </div>


  </div>

  <div class="w-full">
    <div class="py-[35px]">
      <div class="h-[5px] w-full flex items-center relative"
        #sliderBar
      >
        <div class="w-full h-full slider-bg rounded-[4px] cursor-pointer"
          (click)="sliderClick($event)"
        ></div>
        <div class="absolute w-[24px] h-[24px] rounded-[12px] border-[1px] z-10 border-tt-border-default bg-white cursor-pointer"
          (mousedown)="sliderMove($event)"
          #slider
        ></div>
        <div class="absolute h-full w-[3px] left-[8%] flex justify-center"
          #sliderSeparator10
        >
          <div class="h-full w-full bg-white cursor-pointer"
            (click)="separatorClick('10')"
          ></div>
          <p class="absolute main-text -top-[29px]">10</p>
          <p class="absolute secondary-text top-[17px] left-[10px]">
            <span *ngIf="subscriptionType == 'year'">{{SettingsSubscriptionConstants.SUBSCRIPTION_YEAR_PRICES.get('10-100')}}</span>
            <span *ngIf="subscriptionType == 'month'">{{SettingsSubscriptionConstants.SUBSCRIPTION_MONTH_PRICES.get('10-100')}}</span>₽
          </p>
        </div>
        <div class="absolute h-full w-[3px] left-[26%] flex justify-center"
          #sliderSeparator100
        >
          <div class="h-full w-full bg-white cursor-pointer"
            (click)="separatorClick('100')"
          ></div>
          <p class="absolute main-text -top-[29px]">100</p>
          <p class="absolute secondary-text top-[17px] left-[10px]">
            <span *ngIf="subscriptionType == 'year'">{{SettingsSubscriptionConstants.SUBSCRIPTION_YEAR_PRICES.get('100-200')}}</span>
            <span *ngIf="subscriptionType == 'month'">{{SettingsSubscriptionConstants.SUBSCRIPTION_MONTH_PRICES.get('100-200')}}</span>₽
          </p>
        </div>
        <div class="absolute h-full w-[3px] left-[44%] flex justify-center"
          #sliderSeparator200
        >
          <div class="h-full w-full bg-white cursor-pointer"
            (click)="separatorClick('200')"
          ></div>
          <p class="absolute main-text -top-[29px]">200</p>
          <p class="absolute secondary-text top-[17px] left-[10px]">
            <span *ngIf="subscriptionType == 'year'">{{SettingsSubscriptionConstants.SUBSCRIPTION_YEAR_PRICES.get('200-300')}}</span>
            <span *ngIf="subscriptionType == 'month'">{{SettingsSubscriptionConstants.SUBSCRIPTION_MONTH_PRICES.get('200-300')}}</span>₽
          </p>
        </div>
        <div class="absolute h-full w-[3px] left-[62%] flex justify-center"
          #sliderSeparator300
        >
          <div class="h-full w-full bg-white cursor-pointer"
            (click)="separatorClick('300')"
          ></div>
          <p class="absolute main-text -top-[29px]">300</p>
          <p class="absolute secondary-text top-[17px] left-[10px]">
            <span *ngIf="subscriptionType == 'year'">{{SettingsSubscriptionConstants.SUBSCRIPTION_YEAR_PRICES.get('300-400')}}</span>
            <span *ngIf="subscriptionType == 'month'">{{SettingsSubscriptionConstants.SUBSCRIPTION_MONTH_PRICES.get('300-400')}}</span>₽
          </p>
        </div>
        <div class="absolute h-full w-[3px] left-[80%] flex justify-center"
          #sliderSeparator400
        >
          <div class="h-full w-full bg-white cursor-pointer"
            (click)="separatorClick('400')"
          ></div>
          <p class="absolute main-text -top-[29px]">400</p>
          <p class="absolute secondary-text top-[17px] left-[10px]">
            <span *ngIf="subscriptionType == 'year'">{{SettingsSubscriptionConstants.SUBSCRIPTION_YEAR_PRICES.get('400-1000')}}</span>
            <span *ngIf="subscriptionType == 'month'">{{SettingsSubscriptionConstants.SUBSCRIPTION_MONTH_PRICES.get('400-1000')}}</span>₽
          </p>
        </div>
        <p class="absolute main-text -top-[29px] right-0">1000</p>
      </div>

    </div>

    <p class="secondary-text mt-[16px]"> * цена за одного пользователя</p>

  </div>

  <div>
    <p class="main-text">
      Пользователи
    </p>
    <div class="mt-[16px] flex gap-[16px]">
      <app-core-input-field
        class="max-w-[213px] max-h-[40px]"
        variant="standard"
        mask="0000"
        [defaultValue]="'' + sliderValue"
        [value]="'' + sliderValue"
        (onChange)="onInputChange($event)"
        (onUnFocused)="onInputChange($event, true)"
      />
      <div class="max-w-[194px] pt-[16px]">
        <p
          class="font-light text-[19px] leading-[24px] text-tt-text-default"
        >
          <span *ngIf="subscriptionPrice != 0">{{numberFormat.format(subscriptionPrice)}}₽ в месяц</span>
          <span *ngIf="subscriptionPrice == 0">Бесплатно</span>
        </p>
        <p class="secondary-text"
          *ngIf="onePersonPrice != 0"
        >В среднем за пользователя: {{numberFormat.format(onePersonPrice)}}₽ в месяц
        </p>
      </div>
    </div>

    <div class="h-[1px] mt-[24px] w-full bg-tt-icon-secondary"></div>

    <div class="mt-[24px] flex justify-between items-center">
      <div>
        <p class="font-semibold text-main">Итого:</p>
        <p class="mt-[16px] font-light text-[29px] leading-[35px] text-tt-text-default">
          <span *ngIf="subscriptionPrice != 0">{{numberFormat.format(subscriptionType === 'year' ? subscriptionPrice * 12: subscriptionPrice)}}₽ в {{subscriptionType === 'year' ? 'год': 'месяц'}}</span>
          <span *ngIf="subscriptionPrice == 0">Бесплатно</span>
        </p>
      </div>
      <div class="flex gap-[16px]"
        *ngIf="subscriptionPrice != 0"
      >
        <button class="px-[24px] py-[11px] rounded-[20px] border-[1px] border-tt-icon-secondary"
          (click)="isInvoicingModalVisible = !isInvoicingModalVisible"
        >
          <p class="main-text">Запросить счет</p>
        </button>
        <button class="main-text text-white px-[24px] py-[11px] rounded-[20px] bg-tt-text-default">
          Перейти к оплате
        </button>
      </div>
    </div>
  </div>
</div>

<app-invoicing-modal
  *ngIf="isInvoicingModalVisible"
  [countUsers]="sliderValue"
  [periodType]="subscriptionType"
  (close)="isInvoicingModalVisible = false"
/>
