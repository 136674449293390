import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  SvgBorderedArrow,
  SvgFolder,
} from 'src/app/core/components/svg-components/svg.components';
import { FolderDto } from '../../../../@data/dto/folder.dto';
import { CompetencyItemComponent } from '../competency-item/competency-item.component';
import { SkillItemComponent } from '../skill-item/skill-item.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    SkillItemComponent,
    CompetencyItemComponent,
    SvgFolder,
    SvgBorderedArrow,
  ],
  selector: 'app-folder-item',
  templateUrl: './folder-item.component.html',
})
export class FolderItemComponent {
  @Input() depth: number = 0;
  @Input() folder!: FolderDto;
  @Input() opened = false;
  @Input() openedFolders: string[] = [];
  @Input() openedCompetencies: string[] = [];

  @Output() onOpenFolder = new EventEmitter<string>();
  @Output() onOpenCompetency = new EventEmitter<string>();
  @Output() onCloseFolder = new EventEmitter<string>();
  @Output() onCloseCompetency = new EventEmitter<string>();

  onToggle() {
    if (this.opened) {
      this.onCloseFolder.emit(this.folder.id);
    } else {
      this.onOpenFolder.emit(this.folder.id);
    }
  }

  @Output() onSelectFolder = new EventEmitter<string>();
  @Output() onSelectCompetency = new EventEmitter<string>();
  @Output() onSelectSkill = new EventEmitter<string>();
}
