import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from "@angular/core";
import { Store } from "src/app/core/mvi/store";
import { CreateEmployeeModalState } from "./state/create-employee-modal-state";
import { CreateEmployeeModalExecutor } from "./state/create-employee-modal-executor";
import { CreateEmployeeModalAction, CreateEmployeeModalActionTypes } from "./state/create-employee-modal-action";
import { CreateEmployeeModalResultAction } from "./state/create-employee-modal-result-action";
import { CreateEmployeeModalReducer } from "./state/create-employee-modal-reducer";
import { InputFieldComponent } from "../../../../../../../core/components/fields/input-field/input-field.component";
import { EmployeeDeleteProps } from "../../../../../domain/employee-delete-props";

@Component({
  selector: 'employees-create-employee-modal',
  templateUrl: './create-employee-modal.component.html',
  styleUrls: ['./create-employee-modal.component.scss'],
  providers: [
    CreateEmployeeModalState,
    CreateEmployeeModalExecutor,
    CreateEmployeeModalReducer
  ]
})
export class CreateEmployeeModalComponent extends Store<
  CreateEmployeeModalState,
  CreateEmployeeModalExecutor,
  CreateEmployeeModalAction,
  CreateEmployeeModalResultAction
  > implements OnChanges, AfterViewInit
{

  @Input() isVisible: boolean = false
  @Input() editEmployeeId: number = -1
  @Input() canDelete: boolean = false
  @Output() closeClicked = new EventEmitter()
  @Output() deleteClicked = new EventEmitter<EmployeeDeleteProps>()

  @ViewChildren('employeeInput') inputEls?: QueryList<InputFieldComponent>

  protected readonly CreateEmployeeModalActionTypes = CreateEmployeeModalActionTypes

  constructor(
    state: CreateEmployeeModalState,
    executor: CreateEmployeeModalExecutor,
    reducer: CreateEmployeeModalReducer,
  ) {
    super(state, executor, reducer);
  }

  ngAfterViewInit(): void {
    this.performAction({
      type: CreateEmployeeModalActionTypes.INIT_VIEW,
      inputs: this.inputEls
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['editEmployeeId']){
      if(this.editEmployeeId != -1)
      {
        this.performAction({
          type: CreateEmployeeModalActionTypes.LOAD_EMPLOYEE,
          id: this.editEmployeeId
        })
      }
    }
  }

  close(){
    this.performAction({
      type: CreateEmployeeModalActionTypes.INIT
    })
    this.closeClicked.emit()
  }

  onDelete(){
    this.deleteClicked.emit({
      id: this.state.loadedEmployeeId,
      name: this.state.loadedEmployeeName
    })
  }
}
