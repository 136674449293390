import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { GradeSettingsComponent } from './presentation/view/grade-settings.component';

@NgModule({
  declarations: [GradeSettingsComponent],
  exports: [GradeSettingsComponent],
  imports: [CommonModule, ComponentsModule],
})
export class GradeSettingsModule {}
