import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActionElementStyle, ActionSection } from "../../action-menu/action-menu.component";

const POPUP_SECTIONS: ActionSection[] = [
  {
    elements: [
      {
        id: 'change',
        name: 'Изменить',
        icon: 'assets/popup-pencil.svg'
      },
      {
        id: 'delete',
        name: 'Удалить',
        icon: 'assets/trash-red.svg',
        type: ActionElementStyle.DANGER
      }
    ]
  }
]

@Component({
  selector: 'app-video-instruments',
  templateUrl: './video-instruments.component.html',
})
export class VideoInstrumentsComponent {
  @Input() isReadOnly: boolean = true;
  @Output() popupVisibilityChange: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() changeClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();

  popupVisibility: boolean = false

  protected readonly POPUP_SECTIONS = POPUP_SECTIONS;

  popupClicked(id: string){
    switch (id){
      case 'change':
        this.changeClicked.emit()
        break;
      case 'delete':
        this.deleteClicked.emit()
        break;
    }
    this.changePopupVisibility(false)
  }

  changePopupVisibility(value?: boolean){
    if(value === undefined){
      this.popupVisibility = !this.popupVisibility
    }
    else {
      this.popupVisibility = value
    }
    this.popupVisibilityChange.emit(this.popupVisibility)
  }
}
