import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-core-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent {
  @Input() className: string | string[] = [];
  @Input() gap = '16px';
  @Input() amount = 1;

  trackByFn(index: number, _: any) {
    return index;
  }
}
