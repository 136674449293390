import { SpaceState } from "./space-state";
import { SpaceAction, SpaceActionTypes } from "./space-action";
import { SpaceResultAction, SpaceResultActionTypes } from "./space-result-action";
import { Injectable } from "@angular/core";
import { Executor } from "src/app/core/mvi/store";
import { SpaceNavItems } from "../../domain/nav-items";
import { DocumentSummaryEntity } from "../../domain/document-summery-entity";
import { SectionSummaryEntity } from "../../domain/section-summery-entity";
import { ActivatedRoute, Router } from "@angular/router";
import { KnowledgeBaseService } from "../../../../data/knowledge-base-service";
import { SpaceDocumentDto, SpaceSectionDto } from "../../../../data/dto/space-content-dto";
import { SpaceConstants } from "../common/space-constants";
import { SpaceDtoPermissions } from "../../../../data/dto/space-dto";
import { NavTab } from "src/app/core/components/nav-tabs/nav-tabs.component";
import { SpaceNavigator } from "../navigator/space-navigator";
import { dateToLastTimeString } from "../../../../../../core/utils/dateToLastTimeString";
import { dateToDMonthYString } from "../../../../../../core/utils/dateToDMonthYString";
import { MovingDocumentProps } from "../components/document-item/document-item.component";
import { ToastState } from "../../../../../../core/components/toast-alert/toast-alert.component";
import { ToastsService } from "../../../../../../core/components/toast-alert/services/toast-alert.service";
import { MainRoutesPaths } from "../../../../../../routes/main-routes";

@Injectable()
export class SpaceExecutor extends Executor<
  SpaceState,
  SpaceAction,
  SpaceResultAction
> {
  constructor(
    private knowledgeBaseService: KnowledgeBaseService,
    private route: ActivatedRoute,
    private spaceNavigator: SpaceNavigator,
    private router: Router,
    private toastsService: ToastsService,
  ) {
    super();
  }

  execute(action: SpaceAction) {
    switch (action.type) {
      case SpaceActionTypes.CHANGE_NAV_ITEM:
        this.handleChangeNavItem(action.id)
        break;
      case SpaceActionTypes.FILTER_CONTENT:
        this.reduce({
          type: SpaceResultActionTypes.FILTER_CONTENT,
          searchStr: action.value,
          filteredDocuments: this.filterDocuments(this.getState().documents.slice(), action.value.toLocaleLowerCase()),
          filteredSections: this.filterSections(this.getState().sections.slice(), action.value.toLocaleLowerCase())
        })
        break;
      case SpaceActionTypes.UPDATE_SPACE:
        this.handleUpdateSpace()
        break
      case SpaceActionTypes.UPDATE_CONTENT:
        this.handleUpdateContent()
        break
      case SpaceActionTypes.UPDATE_SPACE_TITLE:
        this.reduce({
          type: SpaceResultActionTypes.UPDATE_SPACE_TITLE,
          title: action.title
        })
        break
      case SpaceActionTypes.CREATE_DOCUMENT:
        this.handleCreateDocument(action.sectionId, action.parentId)
        break
      case SpaceActionTypes.MOVE_DOCUMENT:
        this.handleMoveDocument(action.movingProps)
        break
      case SpaceActionTypes.MOVE_TO_DOCUMENT_PAGE:
        this.spaceNavigator.openEditDocument(action.id)
        break
      case SpaceActionTypes.OPEN_DOCUMENT:
        this.handleOpenDocument(action.documentId)
        break
      case SpaceActionTypes.CHANGE_ALERT_NAME:
        this.reduce({
          type: SpaceResultActionTypes.CHANGE_ALERT_NAME,
          value: action.value
        })
        break
      case SpaceActionTypes.SAVE_DOCUMENT_NAME:
        this.handleSaveDocument()
        break
      case SpaceActionTypes.CHANGE_RENAME_MODAL_VISIBILITY:
        this.reduce({
          type: SpaceResultActionTypes.CHANGE_RENAME_MODAL_VISIBILITY,
          value: action.value,
          id: action.id,
          name: action.name,
        })
        break
      case SpaceActionTypes.OPEN_DELETE_DOCUMENT_MODAL:
        this.reduce({
          type: SpaceResultActionTypes.CHANGE_IS_OPEN_DELETE_DOCUMENT_MODAL,
          id: action.id,
          name: action.name == '' ? SpaceConstants.EMPTY_DOCUMENT_NAME : action.name,
          value: true
        })
        break
      case SpaceActionTypes.CLOSE_DELETE_DOCUMENT_MODAL:
        this.reduce({
          type: SpaceResultActionTypes.CHANGE_IS_OPEN_DELETE_DOCUMENT_MODAL,
          value: false
        })
        break
      case SpaceActionTypes.DELETE_DOCUMENT:
        this.handleDeleteDocument()
        break;
      case SpaceActionTypes.DOCUMENT_START_LOAD:
        this.reduce({
          type: SpaceResultActionTypes.DOCUMENT_START_LOAD
        })
        break
      case SpaceActionTypes.DOCUMENT_END_LOAD:
        const lastTimeString = dateToLastTimeString(new Date(action.updatedAt * 1000))
        this.reduce({
          type: SpaceResultActionTypes.DOCUMENT_END_LOAD,
          updatedAt: lastTimeString == '' ? dateToDMonthYString(new Date(action.updatedAt * 1000)) + ' года' : lastTimeString
        })
        break
      case SpaceActionTypes.CHANGE_DOCUMENT_LIST_VISIBLE:
        this.reduce({
          type: SpaceResultActionTypes.CHANGE_DOCUMENT_LIST_VISIBLE,
          value: action.value
        })
        break
    }
  }

  private handleSaveDocument(){
    const state = this.getState()
    const saveName = state.alertDocumentName ? state.alertDocumentName : ''
    const saveId = state.alertDocumentId
    if(saveId)
    {
      this.reduce({
        type: SpaceResultActionTypes.CHANGE_ALERT_IS_LOADING,
        value: true
      })
      this.knowledgeBaseService.updateDocument(
        saveId,
        {
          name: saveName
        }
      ).subscribe({
        next: ()=>{
          let newSections = state.sections
          newSections.forEach(
            (section)=>{
            this.changeDocumentById(
              saveId,
              section.documents,
              (doc)=>{
                doc.name = saveName
              }
            )
          })

          let newDocuments = this.changeDocumentById(
            saveId,
            state.documents,
            (doc)=>{
              doc.name = saveName
            }
          )

          this.reduce({
            type: SpaceResultActionTypes.UPDATE_CONTENT,
            documents: newDocuments,
            sections: newSections,
          })

          this.execute({
            type: SpaceActionTypes.FILTER_CONTENT,
            value: state.searchFieldValue
          })


          const selectedDocument = state.selectedDocument
          if(saveId == selectedDocument.id){
            this.execute({
              type: SpaceActionTypes.OPEN_DOCUMENT,
              documentId: saveId
            })
          }

          this.reduce({
            type: SpaceResultActionTypes.CHANGE_ALERT_IS_LOADING,
            value: false
          })

          this.reduce({
            type: SpaceResultActionTypes.CHANGE_RENAME_MODAL_VISIBILITY,
            value: false
          })
        },
        error: () =>{
          this.reduce({
            type: SpaceResultActionTypes.CHANGE_RENAME_MODAL_VISIBILITY,
            value: false
          })
          this.reduce({
            type: SpaceResultActionTypes.CHANGE_ALERT_IS_LOADING,
            value: false
          })
        }
      })
    }

  }

  private handleMoveDocument(props: MovingDocumentProps){
    this.reduce({
      type: SpaceResultActionTypes.CHANGE_IS_CONTENT_LOADING,
      value: true
    })
    this.knowledgeBaseService
      .moveDocument(
        props.document.id,
        this.getState().id,
        props.moveToDocumentId,
        props.nextDocumentId,
      ).subscribe(
      {
        next: () => {
          let [sections, documents] =
            this.deleteDocument(
              props.document.id,
              this.getState().sections,
              this.getState().documents
            );

          [sections, documents] =
            this.createDocument(
              { ...props.document, nextDocumentId: props.nextDocumentId, parentId: props.moveToDocumentId},
              sections,
              documents,
              undefined,
              props.moveToDocumentId
            );

          this.reduce({
            type: SpaceResultActionTypes.UPDATE_CONTENT,
            documents: documents,
            sections: sections,
          })

          this.execute({
            type: SpaceActionTypes.FILTER_CONTENT,
            value: this.getState().searchFieldValue
          })

          this.reduce({
            type: SpaceResultActionTypes.CHANGE_IS_CONTENT_LOADING,
            value: false
          })
        },
        error: () =>{
          this.reduce({
            type: SpaceResultActionTypes.CHANGE_IS_CONTENT_LOADING,
            value: false
          })
        }
      })
  }

  private handleChangeNavItem(id: string){
    let navItem: SpaceNavItems = SpaceNavItems.MAIN

    if(this.getState().navTabs.find((tab) => tab.url === id)){
      switch (id){
        case SpaceNavItems.SETTINGS:
          navItem = SpaceNavItems.SETTINGS
          break
      }
    }

    this.reduce({
      type: SpaceResultActionTypes.CHANGE_NAV_ITEM,
      navItem: navItem
    })
  }

  private handleOpenDocument(documentId: string){
    this.router.navigate([], {
      queryParams: { documentId: documentId},
      queryParamsHandling: 'merge'
    });

    let document = this.findDocument(documentId, this.getState().documents)

    if(!document)
    {
      this.getState().sections.forEach((section)=>{
        const findDoc = this.findDocument(documentId, section.documents)
        if(findDoc)
        {
          document = findDoc
        }
      })
    }

    if(document){
      let documents = this.getState().documents.map((document)=>{
        this.openToDocument(documentId, document)
        return document
      })

      let sections = this.getState().sections.map((section)=>{
        section.documents.forEach((document)=>{
          if(this.openToDocument(documentId, document)){
            section.isOpen = true
          }
        })
        return section
      })

      this.reduce({
        type: SpaceResultActionTypes.OPEN_DOCUMENT,
        document: document,
        documents: documents,
        sections: sections,
      })

      this.execute({
        type: SpaceActionTypes.FILTER_CONTENT,
        value: this.getState().searchFieldValue
      })
    }
  }

  private openToDocument(id: string, document: DocumentSummaryEntity): boolean{
    let result = document.documents.findIndex((doc) => {return doc.id == id}) != -1

    if(!result)
    {
      document.documents.forEach((doc)=>{
        if(this.openToDocument(id, doc))
        {
          result = true
        }
      })
    }

    if(result){
      document.isOpen = true
    }

    return result
  }

  private handleDeleteDocument(){
    const id = this.getState().alertDocumentId
    if(id){
      this.reduce({
        type: SpaceResultActionTypes.CHANGE_ALERT_IS_LOADING,
        value: true
      })
      this.knowledgeBaseService.deleteDocument(id).subscribe({
        next: ()=>
        {
          this.toastsService.createToast({
            title: 'Документ удален',
            description: '',
            state: ToastState.SUCCESS
          })

          const [sections, documents] = this.deleteDocument(
            id,
            this.getState().sections,
            this.getState().documents
          )

          this.reduce({
            type: SpaceResultActionTypes.INIT_SELECTED_DOCUMENT
          })

          this.reduce({
            type: SpaceResultActionTypes.UPDATE_CONTENT,
            documents: documents,
            sections: sections
          })

          this.reduce({
            type: SpaceResultActionTypes.CHANGE_IS_OPEN_DELETE_DOCUMENT_MODAL,
            value: false
          })

          this.execute({
            type: SpaceActionTypes.FILTER_CONTENT,
            value: this.getState().searchFieldValue
          })

          this.reduce({
            type: SpaceResultActionTypes.CHANGE_ALERT_IS_LOADING,
            value: false
          })
        },
        error: () =>{
          this.reduce({
            type: SpaceResultActionTypes.CHANGE_ALERT_IS_LOADING,
            value: false
          })
          this.reduce({
            type: SpaceResultActionTypes.CHANGE_IS_OPEN_DELETE_DOCUMENT_MODAL,
            value: false
          })
        }
      })
    }
  }

  private handleUpdateSpace(){
    const id = this.route.snapshot.paramMap.get('spaceId')
    if(id != null)
    {
      this.reduce({
        type: SpaceResultActionTypes.CHANGE_IS_SPACE_LOADING,
        value: true
      })

      this.knowledgeBaseService.getSpace(id)
      .subscribe({
        next :(spaceDto)=>{
          this.reduce({
            id: spaceDto.id,
            type: SpaceResultActionTypes.UPDATE_SPACE,
            name: spaceDto.name,
            permissions: spaceDto.permissions,
            navTabs: this.getTabsByPermissions(spaceDto.permissions)
          })
          this.reduce({
            type: SpaceResultActionTypes.CHANGE_IS_SPACE_LOADING,
            value: false
          })

          this.route.queryParamMap.subscribe((params) => {
            const nav = params.get('nav')
            if(nav){
              this.execute({
                type: SpaceActionTypes.CHANGE_NAV_ITEM,
                id: nav
              })
            }
            else{
              this.execute({
                type: SpaceActionTypes.CHANGE_NAV_ITEM,
                id: SpaceNavItems.MAIN
              })
            }
          })

          this.execute({
            type: SpaceActionTypes.UPDATE_CONTENT
          })
        },
        error: () =>{
          this.router.navigateByUrl(`/${MainRoutesPaths.KNOWLEDGE_BASE}`)
        }
      })
    }
  }

  private handleUpdateContent(){
    this.reduce({
      type: SpaceResultActionTypes.CHANGE_IS_CONTENT_LOADING,
      value: true
    })
    this.knowledgeBaseService.getSpaceContent(this.getState().id)
    .subscribe({
      next: (spaceContentDto) => {
        this.reduce({
          type: SpaceResultActionTypes.UPDATE_CONTENT,
          documents: this.mapToDocumentSummaryEntities(spaceContentDto.documents),
          sections: this.mapToSectionSummaryEntities(spaceContentDto.sections),
        })
        this.execute({
          type: SpaceActionTypes.FILTER_CONTENT,
          value: this.getState().searchFieldValue
        })

        const openDocumentId = this.route.snapshot.queryParamMap.get('documentId')
        if (openDocumentId != null) {
          this.execute({
            type: SpaceActionTypes.OPEN_DOCUMENT,
            documentId: openDocumentId,
          })
        }

        this.reduce({
          type: SpaceResultActionTypes.CHANGE_IS_CONTENT_LOADING,
          value: false
        })
      },
      error: () => {
        this.reduce({
          type: SpaceResultActionTypes.CHANGE_IS_CONTENT_LOADING,
          value: false
        })
        this.router.navigateByUrl(`/${MainRoutesPaths.KNOWLEDGE_BASE}`)
      }
    })
  }

  private handleCreateDocument(sectionId?: string, parentId?: string){
    this.reduce({
      type: SpaceResultActionTypes.CHANGE_IS_CONTENT_LOADING,
      value: true
    })

    this.knowledgeBaseService.createDocument({
      knowledgeBaseId: this.getState().id,
      parentId: parentId,
      sectionId: sectionId
    }).subscribe({
      next: (response)=>{
        const newDocument = {
          id: response.id,
          name: '',
          isOpen: false,
          countSearchMatches: 0,
          longreadId: response.longreadID,
          documents: []
        };

        const [sections, documents] = this.createDocument(newDocument, this.getState().sections, this.getState().documents, sectionId, parentId)

        this.reduce({
          type: SpaceResultActionTypes.UPDATE_CONTENT,
          documents: documents,
          sections: sections,
        })

        this.execute({
          type: SpaceActionTypes.FILTER_CONTENT,
          value: this.getState().searchFieldValue
        })

        this.reduce({
          type: SpaceResultActionTypes.CHANGE_IS_CONTENT_LOADING,
          value: false
        })

        this.execute({
          type: SpaceActionTypes.OPEN_DOCUMENT,
          documentId: newDocument.id
        })
      },
      error: () =>{
        this.reduce({
          type: SpaceResultActionTypes.CHANGE_IS_CONTENT_LOADING,
          value: false
        })
      }
    })
  }

  changeDocumentById(
    id: string,
    documents: DocumentSummaryEntity[],
    changeFn:(document: DocumentSummaryEntity) => void,
    changeParent: boolean = false,
  ) : DocumentSummaryEntity[]{
    if(changeParent)
    {
      documents = documents.map((document)=>{
        if(document.documents.findIndex((doc)=>{return doc.id == id}) != -1){
          changeFn(document)
        }
        else {
          document.documents = this.changeDocumentById(id, document.documents, changeFn, changeParent)
        }
        return document
      })
    }
    else{
      documents = documents.map((document)=>{
        if(document.id == id)
        {
          changeFn(document)
        }
        else
        {
          document.documents = this.changeDocumentById(id, document.documents, changeFn)
        }
        return document
      })
    }

    return documents
  }

  filterSections(sections: SectionSummaryEntity[], filterStr: string): SectionSummaryEntity[]
  {
    return sections.filter((section) => {
      section.countSearchMatches = 0
      if (filterStr != '') {
        if (section.name.toLocaleLowerCase().includes(filterStr)) {
          section.countSearchMatches = 1
        }
      }
      const childFilteredDocuments = this.filterDocuments(section.documents, filterStr)
      if (childFilteredDocuments.length != 0) {
        childFilteredDocuments.forEach((childDocumentsDocument) => {
          section.countSearchMatches += childDocumentsDocument.countSearchMatches
        })
        section.documents = childFilteredDocuments.slice()
      }
      return section.countSearchMatches > 0 || filterStr == ''
    })
  }

  filterDocuments(documents: DocumentSummaryEntity[], filterStr: string): DocumentSummaryEntity[]
  {
    return documents.filter((document) => {
      document.countSearchMatches = 0
      if (filterStr != '') {
        const viewName = document.name == '' ? SpaceConstants.EMPTY_DOCUMENT_NAME : document.name
        if (viewName.toLocaleLowerCase().includes(filterStr)) {
          document.countSearchMatches = 1
        }
      }
      const childFilteredDocuments = this.filterDocuments(document.documents, filterStr)
      if (childFilteredDocuments.length != 0) {
        childFilteredDocuments.forEach((childDocumentsDocument) => {
          document.countSearchMatches += childDocumentsDocument.countSearchMatches
        })
      }

      return document.countSearchMatches > 0 || filterStr == ''
    })
  }

  mapToDocumentSummaryEntities(
    documentsDto: SpaceDocumentDto[] | null,
    parentId: string | undefined = undefined
  ): DocumentSummaryEntity[]{
    if(documentsDto == null){
      return []
    }
    return documentsDto.map<DocumentSummaryEntity>((documentDto, index)=>{
      return {
        id: documentDto.id,
        name: documentDto.name,
        documents: this.mapToDocumentSummaryEntities(documentDto.documents, documentDto.id),
        parentId: parentId,
        nextDocumentId: documentsDto[index + 1]?.id,
        isOpen: false,
        longreadId: documentDto.longreadID,
        countSearchMatches: 0
      }
    })
  }

  mapToSectionSummaryEntities(
    sectionsDto: SpaceSectionDto[] | null
  ): SectionSummaryEntity[]{
    if(sectionsDto == null){
      return []
    }
    return sectionsDto.map<SectionSummaryEntity>((documentDto)=>{
      return {
        id: documentDto.id,
        name: documentDto.name,
        documents: this.mapToDocumentSummaryEntities(documentDto.documents),
        isOpen: false,
        countSearchMatches: 0
      }
    })
  }

  getTabsByPermissions(permissions: SpaceDtoPermissions): NavTab[]{
    let tabs: NavTab[] = [SpaceConstants.MAIN_NAV_TAB]

    if(permissions.settings)
    {
      tabs.push(SpaceConstants.SETTINGS_NAV_TAB)
    }

    return tabs
  }

  createDocument(
    document: DocumentSummaryEntity,
    sections: SectionSummaryEntity[],
    documents: DocumentSummaryEntity[],
    sectionId?: string,
    parentId?: string,
  ): [SectionSummaryEntity[], DocumentSummaryEntity[]] {

    const pushDocument = (documents: DocumentSummaryEntity[]): DocumentSummaryEntity[] => {
      let documentsCopy = documents.slice()

      const prevDocumentIndex = documents.findIndex((doc) => doc.nextDocumentId === document.nextDocumentId) + 1

      if(prevDocumentIndex != documentsCopy.length)
      {
        documentsCopy.splice(
          prevDocumentIndex,
          0,
          document
        )
      }
      else {
        documentsCopy.push(document)
      }

      documentsCopy = documentsCopy.map<DocumentSummaryEntity>((doc)=>{
        return { ...doc, nextDocumentId: doc.id != document.id ? (doc.nextDocumentId == document.nextDocumentId ? document.id : doc.nextDocumentId) : doc.nextDocumentId }
      })

      return documentsCopy
    }

    if(parentId)
    {
      sections.forEach((section)=>{
        this.changeDocumentById(
          parentId,
          section.documents,
          (doc)=>{
            doc.documents = pushDocument(doc.documents)
          }
        )
      })
      documents = this.changeDocumentById(
        parentId,
        documents,
        (doc)=>{
          doc.documents = pushDocument(doc.documents)
        }
      )
    }
    else if(sectionId)
    {
      sections = sections.map((section)=>{
        if(section.id == sectionId)
        {
          section.documents = pushDocument(section.documents)
        }
        return section
      })
    }
    else{
      documents = pushDocument(documents)
    }

    return [sections, documents]
  }

  deleteDocument(deleteId: string, sections: SectionSummaryEntity[], documents: DocumentSummaryEntity[]): [SectionSummaryEntity[], DocumentSummaryEntity[]]{
    let deleteIndex = documents.findIndex((document)=> {return document.id == deleteId})

    const deleteFn = (docs: DocumentSummaryEntity[]): DocumentSummaryEntity[] => {
      const deleteDoc = docs.find((document)=>{return document.id == deleteId})

      if(deleteDoc)
      {
        return docs.filter((document)=> {
          if(document.id == deleteDoc.id){
            return false
          }
          if(document.nextDocumentId == deleteDoc.id)
          {
            document.nextDocumentId = deleteDoc.nextDocumentId
          }
          return document.id != deleteDoc.id
        })
      }
      return docs
    }

    if(deleteIndex != -1){
      documents = deleteFn(documents)
    }
    else {
      sections.map((section)=>{
        deleteIndex = section.documents.findIndex((document)=> {return document.id == deleteId})
        if(deleteIndex != -1){
          section.documents.splice(deleteIndex, 1)
        }
        else{
          section.documents = this.changeDocumentById(
            deleteId,
            section.documents,
            (doc) => doc.documents = deleteFn(doc.documents),
            true
          )
        }
        return section
      })

      documents = this.changeDocumentById(
        deleteId,
        documents,
        (doc) => doc.documents = deleteFn(doc.documents),
        true
      )
    }
    return [sections, documents]
  }

  findDocument(documentId: string, documents: DocumentSummaryEntity[]): DocumentSummaryEntity | undefined{
    let result = documents.find((document)=>{return document.id == documentId})

    if(!result){
      documents.forEach((document)=>{
        const findDoc = this.findDocument(documentId, document.documents)
        if(findDoc)
        {
          result = findDoc
        }
      })
    }

    return result
  }
}
