import {
  AutocompleteItem
} from "../../../../../core/components/fields/autocomplete-field/autocomplete-field.component";

export class SectionEditEntity {
  constructor(
    readonly id: number,
    readonly title: string,
    readonly owner: AutocompleteItem,
    readonly privacy: AutocompleteItem,
    readonly icon: string,
  ) {}
}
