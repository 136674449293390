<div class="relative" [ngClass]="styles">
  <div class="mt-[4px] inline-block">
    <p #text class="absolute h-full line-clamp-1  z-1 top-0 left-0 inline py-[2px] px-[8px] rounded-tt-border-radius_s border-[#FFFFFF] border-[1px] hover:border-tt-text-primary"
      [ngClass]="{
        'visible-hidden' : isEditable
      }"
       (click)="showInput()"
    >
      {{value}}
    </p>
  </div>
  <input #input class="py-[2px] px-[8px] rounded-tt-border-radius_s border-[1px] border-tt-text-primary"
    [(ngModel)]="value"
    (input)="inputResizeText($event)"
    [ngClass]="{
      'visible-hidden' : !isEditable
    }"
    (keyup.enter)="changeValue()"
  >
</div>
