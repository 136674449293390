import { Injectable } from '@angular/core';
import { DocumentState } from './document-state';
import {
  DocumentResultAction,
  DocumentResultActionTypes,
} from './document-result-action';
import { clone } from 'cloneable-ts';
import { Reducer } from "../../../../../core/mvi/store";

@Injectable()
export class DocumentReducer
  implements Reducer<DocumentState, DocumentResultAction>
{
  reduce(state: DocumentState, action: DocumentResultAction): DocumentState {
    switch (action.type) {
      case DocumentResultActionTypes.CHANGE_TITLE:
        return {...state, title: action.title}
      case DocumentResultActionTypes.INIT_DOCUMENT:
        return {...state, knowledgeBaseID: action.knowledgeBaseID, longreadId: action.longreadId, title: action.name, id: action.id, initTitle: action.name}
      case DocumentResultActionTypes.INIT_CHILDREN:
        return {...state, editorComponent: action.editor}
      case DocumentResultActionTypes.CHANGE_SAVE_SPACE_MODAL_VISIBILITY:
        return {...state, saveModalVisible: action.visibilityValue, isLoadingSaveModal: action.isLoadingSpaceModal}
      case DocumentResultActionTypes.CHANGE_IS_LOADING:
        return {...state, isLoading: action.value}
      case DocumentResultActionTypes.CHANGE_BACK_SPACE_MODAL_VISIBILITY:
        return {...state, backModalVisible: action.visibility, isLoadingBackModal: action.isLoading !== undefined ? action.isLoading : state.isLoadingBackModal}
      case DocumentResultActionTypes.CHANGE_AFK_MODAL_VISIBILITY:
        return {...state, afkModalVisible: action.visibility}
      case DocumentResultActionTypes.SAVE_SESSION:
        return {...state, sessionId: action.sessionId, pingIntervalId: action.pingIntervalId}
      case DocumentResultActionTypes.CHANGE_ALREADY_EDIT_MODAL_VISIBILITY:
        return {...state, alreadyEditModalVisible: action.visibility, knowledgeBaseID: action.knowledgeBaseId, id: action.documentId}
    }
  }
}
