export class Account {
  constructor(
    readonly jwtToken: string,
    readonly userId: number,
  ) {}

}

export class User {
  constructor(
    readonly id: number,
    readonly accountId: number,
  ) {}
}

export class Company {
  constructor(readonly id: number) {}
}
