import { Injectable } from '@angular/core';
import { MessengerSideBarService } from '../domain/sidebar-service';
import { Observable, Subject } from 'rxjs';
import { MessengerSideBarDataService } from './sidebar-data-service';

@Injectable({
  providedIn: 'root',
})
export class MessengerSideBarServiceImpl implements MessengerSideBarService {
  menuState = new Subject<boolean>();
  constructor(
    private messengerSideBarDataService: MessengerSideBarDataService,
  ) {
    this.fetchMenuData();
  }

  getMenuData(): Observable<boolean> {
    return this.menuState.asObservable();
  }

  fetchMenuData(): void {
    this.menuState.next(this.messengerSideBarDataService.sideBar);
  }

  openMenu(): void {
    this.messengerSideBarDataService.sideBar = true;
    this.fetchMenuData();
  }

  closeMenu(): void {
    this.messengerSideBarDataService.sideBar = false;
    this.fetchMenuData();
  }
}
