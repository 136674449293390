<app-core-main-content-card>
  <div class="pb-[20px] flex">
    <img
      class="w-[64px] h-[64px] rounded-[40px] text-center"
      src="../../../../../../../assets/empty_employee_icon.svg"
      alt=""
    />
    <div class="ml-[24px] w-fit justify-start">
      <p class="text-center text-[24px] font-[500] w-fit">Воронин дмитрий</p>
      <div class="flex">
        <p
          class="underline text-tt-text-secondary text-[18px] font-normal text-left"
        >
          Voronindim&#64;mail.ru
        </p>
        <p class="text-[18px] pl-[12px] text-tt-text-secondary">
          +7 (902) 888-11-54
        </p>
      </div>
    </div>
  </div>
  <div class="mt-6 flex flex-col gap-4">
    <p class="text-[20px] not-italic leading-normal text-center w-min">
      Работа
    </p>
    <app-underlined-list [prop]="workProp"></app-underlined-list>
    <p class="text-[20px] not-italic leading-normal text-center w-min">
      Личное
    </p>
    <app-underlined-list [prop]="personalProp"></app-underlined-list>
  </div>
</app-core-main-content-card>
