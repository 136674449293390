export type InvoicingModalAction =
  | ChangeOrganisationNameAction
  | ChangeLegalAddressAction
  | ChangeINNAction
  | ChangeKPPAction
  | ChangePhoneNumberAction
  | ToggleSoloProprietorAction
  | InitOnChangesAction
  | OpenInvoicePaymentModalAction

export enum InvoicingModalActionTypes {
  CHANGE_ORGANISATION_NAME,
  CHANGE_LEGAL_ADDRESS,
  TOGGLE_I_SOLE_PROPRIETOR,
  CHANGE_INN,
  CHANGE_KPP,
  CHANGE_PHONE_NUMBER,
  INIT_ON_CHANGES,
  OPEN_INVOICE_PAYMENT_MODAL
}

export interface OpenInvoicePaymentModalAction{
  readonly type: InvoicingModalActionTypes.OPEN_INVOICE_PAYMENT_MODAL,
}

export interface InitOnChangesAction{
  readonly type: InvoicingModalActionTypes.INIT_ON_CHANGES,
  readonly countUsers: number
  readonly periodType: 'month' | 'year'
}

export interface ChangeOrganisationNameAction{
  readonly type: InvoicingModalActionTypes.CHANGE_ORGANISATION_NAME,
  readonly value: string
}

export interface ChangeLegalAddressAction {
  readonly type: InvoicingModalActionTypes.CHANGE_LEGAL_ADDRESS;
  readonly value: string
}

export interface ChangeINNAction {
  readonly type: InvoicingModalActionTypes.CHANGE_INN;
  readonly value: string
}

export interface ChangeKPPAction {
  readonly type: InvoicingModalActionTypes.CHANGE_KPP;
  readonly value: string
}

export interface ChangePhoneNumberAction {
  readonly type: InvoicingModalActionTypes.CHANGE_PHONE_NUMBER;
  readonly value: string
}

export interface ToggleSoloProprietorAction {
  readonly type: InvoicingModalActionTypes.TOGGLE_I_SOLE_PROPRIETOR;
}
