import { Reducer } from 'src/app/core/mvi/store';
import { InvoicingModalState } from "./invoicing-modal-state";
import {
  InvoicingModalResultAction,
  InvoicingModalResultActionTypes,
} from './invoicing-modal-result-action';
import { Injectable } from '@angular/core';

@Injectable()
export class InvoicingModalReducer
  implements Reducer<InvoicingModalState, InvoicingModalResultAction>
{
  reduce(
    state: InvoicingModalState,
    action: InvoicingModalResultAction,
  ): InvoicingModalState {
    switch (action.type) {
      case InvoicingModalResultActionTypes.CHANGE_INN:
        return {...state, INN: action.value}
      case InvoicingModalResultActionTypes.CHANGE_KPP:
        return {...state, KPP: action.value}
      case InvoicingModalResultActionTypes.CHANGE_LEGAL_ADDRESS:
        return {...state, legalAddress: action.value}
      case InvoicingModalResultActionTypes.CHANGE_ORGANISATION_NAME:
        return {...state, organisationName: action.value}
      case InvoicingModalResultActionTypes.CHANGE_PHONE_NUMBER:
        return {...state, phoneNumber: action.value}
      case InvoicingModalResultActionTypes.TOGGLE_I_SOLE_PROPRIETOR:
        return {...state, isISoloProprietor: action.value}
      case InvoicingModalResultActionTypes.INIT_ON_CHANGES:
        return {...state, countUsers: action.countUsers, periodType: action.periodType}
      case InvoicingModalResultActionTypes.OPEN_INVOICE_PAYMENT_MODAL:
        return {...state, isInvoicePaymentModalVisible: true}
      case InvoicingModalResultActionTypes.VALIDATE_FIELDS:
        return {...state,
          organisationName: action.organisationName,
          legalAddress: action.legalAddress,
          phoneNumber: action.phoneNumber,
          KPP: action.KPP,
          INN: action.INN,
        }
    }
  }
}
