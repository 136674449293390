import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Subscription, finalize } from 'rxjs';
import { ToastsService } from 'src/app/core/components/toast-alert/services/toast-alert.service';
import { ToastState } from 'src/app/core/components/toast-alert/toast-alert.component';
import { Executor } from 'src/app/core/mvi/store';
import { Validator } from 'src/app/core/validators/validator';
import { CompetenciesAndSkillsService } from '../../../@data/services/competencies-and-skills.service';
import { RatingScaleService } from '../../../@data/services/rating-scale.service';
import {
  CreateSkillAction,
  CreateSkillActionTypes,
} from './create-skill-action';
import {
  CreateSkillResultAction,
  CreateSkillResultActionTypes,
} from './create-skill-result-action';
import { CreateSkillError, CreateSkillState } from './create-skill-state';

@Injectable()
export class CreateSkillExecutor extends Executor<
  CreateSkillState,
  CreateSkillAction,
  CreateSkillResultAction
> {
  constructor(
    @Inject('CreateSkillNameValidator')
    private nameValidator: Validator,

    @Inject('CreateSkillLevelDescriptionValidator')
    private levelDescriptionValidator: Validator,

    private toastsService: ToastsService,
    private ratingScalesService: RatingScaleService,
    private competenciesAndSkillsService: CompetenciesAndSkillsService,
  ) {
    super();
  }

  private subscription: Subscription = new Subscription();

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  execute(action: CreateSkillAction) {
    switch (action.type) {
      case CreateSkillActionTypes.OPEN_MODAL:
        {
          const state = this.getState();
          if (state.ratingScalesIsLoading) {
            this.subscription.add(
              this.ratingScalesService.getRatingScales().subscribe({
                next: (ratingScales) => {
                  this.reduce({
                    type: CreateSkillResultActionTypes.CHANGE_RATING_SCALES,
                    ratingScales: ratingScales.scales.map((ratingScale) => ({
                      ...ratingScale,
                      levels: ratingScale.levels.map((level) => ({
                        ...level,
                        descriptionError: null,
                        description: '',
                      })),
                    })),
                  });
                },
                error: (error: HttpErrorResponse) => {
                  this.reduce({
                    type: CreateSkillResultActionTypes.CHANGE_ERROR_STATUS,
                    error: (() => {
                      switch (error.status) {
                        case HttpStatusCode.Unauthorized:
                          return CreateSkillError.INCORRECT_CREDENTIALS;
                        default:
                          return CreateSkillError.CONNECTION_ERROR;
                      }
                    })(),
                  });
                },
                complete: () => {
                  this.reduce({
                    type: CreateSkillResultActionTypes.CHANGE_RATING_SCALES_IS_LOADING_STATUS,
                    ratingScalesIsLoading: false,
                  });
                },
              }),
            );
          }

          this.reduce({ type: CreateSkillResultActionTypes.OPEN_MODAL });
        }
        break;

      case CreateSkillActionTypes.CLOSE_MODAL:
        this.reduce({ type: CreateSkillResultActionTypes.CLOSE_MODAL });
        break;

      case CreateSkillActionTypes.CHANGE_NAME:
        this.reduce({
          type: CreateSkillResultActionTypes.CHANGE_NAME,
          name: action.name,
        });
        break;

      case CreateSkillActionTypes.CHANGE_DESCRIPTION:
        this.reduce({
          type: CreateSkillResultActionTypes.CHANGE_DESCRIPTION,
          description: action.description,
        });
        break;

      case CreateSkillActionTypes.CHANGE_LEVEL_DESCRIPTION:
        this.reduce({
          type: CreateSkillResultActionTypes.CHANGE_LEVEL_DESCRIPTION,
          levelDescription: action.levelDescription,
          levelId: action.levelId,
        });
        break;

      case CreateSkillActionTypes.CHANGE_RATING_SCALE:
        if (action.ratingScaleId === null) {
          this.reduce({
            type: CreateSkillResultActionTypes.UNSELECT_RATING_SCALE,
          });
        } else {
          const ratingScale = this.getState().ratingScales.find(
            (ratingScale) => ratingScale.id === action.ratingScaleId,
          );
          if (ratingScale) {
            this.reduce({
              type: CreateSkillResultActionTypes.SELECT_RATING_SCALE,
              ratingScale,
            });
          }
        }
        break;

      case CreateSkillActionTypes.CHANGE_CURRENT_STEP:
        {
          if (action.step === 2) {
            const state = this.getState();
            const nameError = this.nameValidator.validate(state.name);
            const selectedRatingScaleError = !state.selectedRatingScale
              ? 'Выберете оценочную шкалу'
              : null;

            if (nameError || selectedRatingScaleError) {
              this.reduce({
                type: CreateSkillResultActionTypes.VALIDATION_ERROR,
                nameError: nameError,
                selectedRatingScaleError,
              });
              return;
            }
          }

          this.reduce({
            type: CreateSkillResultActionTypes.CHANGE_CURRENT_STEP,
            step: action.step,
          });
        }
        break;

      case CreateSkillActionTypes.SAVE_SKILL:
        {
          const state = this.getState();

          if (!state.selectedRatingScale) return;

          let hasError = false;

          for (const level of state.selectedRatingScale.levels) {
            const levelDescriptionError =
              this.levelDescriptionValidator.validate(level.description);

            if (levelDescriptionError) {
              hasError = true;
              this.reduce({
                type: CreateSkillResultActionTypes.CHANGE_LEVEL_DESCRIPTION_ERROR_STATUS,
                levelId: level.id,
                levelDescriptionError,
              });
            }
          }

          if (!hasError) {
            this.reduce({
              type: CreateSkillResultActionTypes.CHANGE_LOADING_STATUS,
              isLoading: true,
            });

            this.competenciesAndSkillsService
              .createSkill({
                scaleId: state.selectedRatingScale?.id,
                name: state.name,
                description: state.description,
                levelsDescriptions: state.selectedRatingScale.levels.map(
                  (level) => ({
                    levelId: level.id,
                    description: level.description,
                  }),
                ),
              })
              .pipe(
                finalize(() => {
                  this.reduce({
                    type: CreateSkillResultActionTypes.CHANGE_LOADING_STATUS,
                    isLoading: false,
                  });
                  this.competenciesAndSkillsService.createSkillEvent$.next();
                }),
              )
              .subscribe({
                next: () => {
                  this.toastsService.createToast({
                    title: 'Навык успешно создан',
                    description: '',
                    state: ToastState.SUCCESS,
                  });
                  this.reduce({
                    type: CreateSkillResultActionTypes.RESET_STATE,
                  });
                },
                error: (error: HttpErrorResponse) => {
                  this.reduce({
                    type: CreateSkillResultActionTypes.CHANGE_ERROR_STATUS,
                    error: (() => {
                      this.toastsService.createToast({
                        title: 'Не удалось создать навык',
                        description: 'Код ошибки: ' + error.status,
                        state: ToastState.ERROR,
                      });

                      switch (error.status) {
                        case HttpStatusCode.Unauthorized:
                          return CreateSkillError.INCORRECT_CREDENTIALS;
                        default:
                          return CreateSkillError.CONNECTION_ERROR;
                      }
                    })(),
                  });
                },
              });
          }
        }
        break;
    }
  }
}
