import { HttpErrorResponse } from "@angular/common/http";

export enum ResponseStatus {
  SUCCESS,
  UNAUTHORIZED,
  NOT_FOUND,
  FORBIDDEN,
  SERVER_ERROR,
  UNKNOWN,
}

export function errorToResponseStatus(err: any): ResponseStatus {
  if (err instanceof HttpErrorResponse) {
    switch (err.status) {
      case 401:
        return ResponseStatus.UNAUTHORIZED;
      case 403:
        return ResponseStatus.FORBIDDEN;
      case 404:
        return ResponseStatus.NOT_FOUND;
      case 500:
        return ResponseStatus.SERVER_ERROR;
      default:
        return ResponseStatus.UNKNOWN;
    }
  } else {
    return ResponseStatus.UNKNOWN;
  }
}
