import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NavTab, NavTabType } from "../nav-tabs/nav-tabs.component";
import { QueryParamsHandling } from "@angular/router";

@Component({
  selector: 'app-core-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() title: string = '';

  @Input() navTabs: NavTab[] = []
  @Input() navTabType: NavTabType = 'click'
  @Input() queryParamsHandling?: QueryParamsHandling
  @Input() borderBottom: boolean = true;
  @Input() selectedTabId: string = ''

  @Input() textSize: '5xl' | '6xl' = '6xl'

  @Output() tabClicked = new EventEmitter<string>();
}
