import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { convertToBytes } from "../../utils/convert-to-bytes";
import { ActionElementStyle, ActionSection } from "../action-menu/action-menu.component";

const POPUP_SECTIONS: ActionSection[] = [
  {
    elements: [
      {
        id: 'change',
        name: 'Изменить',
        icon: 'assets/popup-pencil.svg'
      },
      {
        id: 'delete',
        name: 'Удалить',
        icon: 'assets/trash-red.svg',
        type: ActionElementStyle.DANGER
      }
    ]
  }
]

@Component({
  selector: 'app-file-downloader',
  templateUrl: './file-downloader.component.html',
  styleUrls: ['./file-downloader.component.scss']
})
export class FileDownloaderComponent{
  @Input() title: string = 'Добавить файл'
  @Input() fileUrl: string = ''
  @Input() isReadOnly: boolean = false
  @Input() canPasteUrl: boolean = true
  @Input() canUpload: boolean = true
  @Input() fileType: 'image/*' | 'video/*' | '*' = '*'

  @Output() fileUpload = new EventEmitter<string>()
  @Output() urlChanged = new EventEmitter<string>()
  @Output() deleteClicked = new EventEmitter<string>()

  @ViewChild('inputFile') fileInput!: ElementRef
  @ViewChild('tripleDot') tripleDotEl!: ElementRef

  inputUrl: string = ''
  fileName: string = ''
  fileData: string = ''
  fileSize: string = ''

  isUploadPopupVisible: boolean = false

  isTripleDotPopupVisible: boolean = false
  protected readonly POPUP_SECTIONS = POPUP_SECTIONS;

  constructor(private el: ElementRef) {
  }

  applyUrl(url: string){
    if(url != '')
    {
      this.initFile()
      this.fileUrl = url
      this.fileUpload.emit(this.fileUrl)
      this.isUploadPopupVisible = false
    }
  }

  uploadClicked(){
    this.fileInput.nativeElement.click()
  }

  onSelectFile(event: any){
    const file = event.target.files[0];
    this.uploadFile(file)
  }

  uploadFile(file: File)
  {
    const reader = new FileReader();
    reader.readAsDataURL(file)

    reader.onload = (event) => {
      if(this.fileUpload.observed){
        this.fileUpload.emit(<string>event.target!.result)
      }

      this.initFile()
      this.fileData = <string>event.target!.result
      this.fileName = file.name
      this.fileSize = convertToBytes(file.size, {decimals: 1})
      this.isUploadPopupVisible = false
    }
  }

  initFile(){
    this.fileUrl = ''
    this.fileName = ''
    this.fileData = ''
    this.fileSize = ''
  }

  popupClicked(id: string){
    switch (id){
      case 'change':
        this.isUploadPopupVisible = this.isReadOnly && !this.isUploadPopupVisible
        break;
      case 'delete':
        if(this.deleteClicked.observed){
          this.deleteClicked.emit()
        }
        else {
          this.initFile()
        }
        break;
    }
    this.isTripleDotPopupVisible = false
  }

  blockClicked(event: MouseEvent){
    if(!this.tripleDotEl || !this.tripleDotEl.nativeElement.contains(event.target)){
      this.isUploadPopupVisible = !this.isReadOnly && !this.isUploadPopupVisible
    }
  }

  openTripleDotPopup(){
    this.isUploadPopupVisible = false
    this.isTripleDotPopupVisible = !this.isTripleDotPopupVisible
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (this.isTripleDotPopupVisible && !this.tripleDotEl.nativeElement.contains(event.target)) {
      this.isTripleDotPopupVisible = false
    }
    if (this.isUploadPopupVisible && !this.el.nativeElement.contains(event.target)){
      this.isUploadPopupVisible = false
    }
  }
}
