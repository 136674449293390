import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { EmployeeService } from 'src/app/features/employees/data/employee-service';
import { EmployeesComponent } from './presentation/employees.component';
import { EmployeesListModule } from './modules/employees-list/employees-list.module';
import { EmployeesRolesModule } from './modules/employees-roles/employees-roles.module';

@NgModule({
  declarations: [
    EmployeesComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterOutlet,
    EmployeesListModule,
    EmployeesRolesModule,
  ],
  providers: [
    EmployeeService,
  ],
})
export class EmployeesModule {
}
