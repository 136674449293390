<div class="px-[16px] pt-[12px] flex flex-col gap-[6px]">

  <div class="h-[44px]">
    <nav class="h-full">
      <ul class="h-full flex flex-row gap-4 border-b-[1px] border-tt-separator-color">
        <li class="h-full">
          <button
            class="nav-item"
            [ngClass]="{
              'nav-item__selected': state.selectedItem == NavItem.MAIN
            }"
            (click)="
              performAction({
                type: SectionSettingsActionTypes.SELECT_ITEM,
                item: NavItem.MAIN
              })
            "
          >
            Основное
          </button>
        </li>
        <li class="h-full hidden">
          <button
            class="nav-item"
            [ngClass]="{
              'nav-item__selected': state.selectedItem == NavItem.ACCESS
            }"
            (click)="
              performAction({
                type: SectionSettingsActionTypes.SELECT_ITEM,
                item: NavItem.ACCESS
              })
            "
          >
            Доступ
          </button>
        </li>
      </ul>
    </nav>
  </div>
  <div class="">
    <section-settings-main *ngIf="state.selectedItem == NavItem.MAIN" />
    <section-settings-access *ngIf="state.selectedItem == NavItem.ACCESS" />
  </div>
</div>
