<div class="pt-[16px] px-[16px] flex flex-col gap-[16px]">

  <div class="flex gap-[16px] content-height">
    <div
      class="
        flex flex-col gap-[8px]
        max-w-[406px] w-full pt-[8px]
        hover:resize-x"
    >

      <div class="shrink-0 px-[4px]">
        <div class="flex flex-col gap-[8px]">
          <section-menu-box [isSelect]="state.navItem == NavigationItem.SETTINGS"
            (click)="performAction({
                type: SectionActionTypes.OPEN_SETTINGS
              })"
          >
            <div class="flex items-center gap-[8px]">
              <svg-gear-shape
                class="p-[2px] document-icon"
              ></svg-gear-shape>
              <div
                class="font-unselected leading-[18px]"
                [ngClass]="{
              'font-selected': state.navItem == NavigationItem.SETTINGS
            }"
              >
                Настройки раздела
              </div>
            </div>

          </section-menu-box>

          <div class="h-[1px] w-full bg-tt-unaccented-separator-color"></div>

        </div>
      </div>

      <div class="overflow-y-scroll h-full tiny-scrollbar">
        <section-menu-box
          [isSelect]="state.navItem == NavigationItem.ALL_PAGES"
          (click)="performAction({
                type: SectionActionTypes.OPEN_CONTENT
              })"
        >
          <div class="flex justify-between">
            <div class="flex items-center gap-[8px]">
              <svg-all-pages class="document-icon"></svg-all-pages>
              <div
                class="font-unselected leading-[18px]"
                [ngClass]="{
                    'font-selected': state.navItem == NavigationItem.ALL_PAGES
                  }"
              >
                Все страницы
              </div>
            </div>
            <svg-plus
              class="document-icon"
              (click)="
                performAction({
                  type: SectionActionTypes.CREATE_DOCUMENT,
                  documentId: '-1'
                })
              "
            ></svg-plus>
          </div>
        </section-menu-box>

        <div *ngFor="let document of state.documents">
          <app-document-item
            [document]="document"
            [openDocuments]="state.openDocuments"
            [idActiveDocument]="state.idActiveDocument"
            (openDocumentClicked)="
              this.performAction({
                type: SectionActionTypes.OPEN_DOCUMENT,
                documentId: $event
              })
            "
            (arrowClicked)="
              this.performAction({
                type: SectionActionTypes.CHANGE_DOCUMENT_OPEN_STATE,
                documentId: $event
              })
            "
            (createDocumentClicked)="
              this.performAction({
                type: SectionActionTypes.CREATE_DOCUMENT,
                documentId: $event
              })
            "
            (linkDocumentClicked)="
              this.performAction({
                type: SectionActionTypes.COPY_DOCUMENT_LINK,
                documentId: $event
              })"
            (editDocumentClicked)="
              this.performAction({
                type: SectionActionTypes.EDIT_DOCUMENT,
                documentId: $event
              })"
            (deleteDocumentClicked)="
              performAction({
                type: SectionActionTypes.DELETE_DOCUMENT,
                documentId: $event
              })"
          />
        </div>
      </div>
    </div>
    <div class="w-full content-height bg-white rounded-t-tt-border-radius_l">
      <router-outlet></router-outlet>
    </div>
  </div>

</div>
