import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, of } from "rxjs";
import { EnvironmentDto } from "./dto/environment-dto";
import { ToastsService } from "../../components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../components/toast-alert/toast-alert.component";

@Injectable({providedIn: 'root'})

export class CompanyService {

  private readonly apiUrl: string = `${environment.apiUrl}`;

  constructor(private httpClient: HttpClient, private toastService: ToastsService) { }

  getEnvironment(): Observable<EnvironmentDto> {
    return this.httpClient.get<EnvironmentDto>(`${this.apiUrl}/environment`);
  }

  updateCompany(name: string): Observable<null> {
    const body ={
      name: name,
    }
    return this.httpClient.post<null>(`${this.apiUrl}/company_new/company/update`, body )
  }

  deleteCompany(id: string): Observable<null> {
    this.toastService.createToast({
      title: 'Porta mundo daemoni inaccessibilis est',
      description: 'Sis felix societas tua non auferetur',
      state: ToastState.ERROR
    })
    console.log(`${this.apiUrl}/company_new/company/delete`)
    return of()
  }
}
