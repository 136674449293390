import { Executor, Reducer } from "../../../../../../core/mvi/store";
import { FilterParams, SectionContentState, SortParams } from "./section-content-state";
import { SectionContentAction, SectionContentActionTypes } from "./section-content-action";
import { SectionContentResultAction, SectionContentResultActionTypes } from "./section-content-result-action";
import { Injectable } from "@angular/core";
import { SectionContentService } from "../../domain/section-content-service";
import { ActivatedRoute } from "@angular/router";
import { Constants } from "../common/constants";
import { DocumentItemEntity } from "../../domain/document-item-entity";
import { clone } from "cloneable-ts";
import { SectionContentNavigator } from "../../navigation/section-content.navigator";
import { SectionsResultActionTypes } from "../../../../sections/impl/presentation/state/sections-result-action";
import { ResponseStatus } from "../../../../../../core/utils/response-status";
import { ToastState } from "../../../../../../core/components/toast-alert/toast-alert.component";
import { ToastsService } from "../../../../../../core/components/toast-alert/services/toast-alert.service";

@Injectable()
export class SectionContentExecutor extends Executor<
  SectionContentState,
  SectionContentAction,
  SectionContentResultAction
> {
  private sectionId!: number;
  constructor(
    private sectionContentService: SectionContentService,
    private route: ActivatedRoute,
    private navigator: SectionContentNavigator,
    private toastsService: ToastsService
  ) {
    super();
  }

  override init(
    reducer: Reducer<SectionContentState, SectionContentResultAction>,
    getState: () => SectionContentState,
    onReduced: (state: SectionContentState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    this.sectionContentService.getDocuments().subscribe((documents) => {
      const filterState = this.getState().filterState;
      this.reduce({
        type: SectionContentResultActionTypes.UPDATE_DOCUMENTS,
        documents: documents,
        filteredDocuments: this.calculateFilteredDocuments(
          documents,
          filterState.filterParam,
          filterState.filter,
          filterState.sortParam.id,
        ),
      });
    });
    this.route.parent?.paramMap.subscribe((params: any) => {
      this.sectionId = +params.get('sectionId');
      if (this.sectionId) {
        this.sectionContentService.fetchDocuments(this.sectionId);
      }
    });
  }

  execute(action: SectionContentAction) {
    switch (action.type) {
      case SectionContentActionTypes.CHANGE_FILTER_ITEM:
        this.handleChangeFilterItem(action.param);
        break;
      case SectionContentActionTypes.ADD_DOCUMENT:
        this.handleAddDocument();
        break;
      case SectionContentActionTypes.CHANGE_SEARCH_FIELD:
        this.handleChangeSearchField(action.value);
        break;
      case SectionContentActionTypes.CHANGE_SORT_ITEM:
        this.handleChangeSortItem(action.id);
        break;
      case SectionContentActionTypes.FAVORITE_DOCUMENT:
        this.handleFavoriteDocument(action.documentId);
        break;
      case SectionContentActionTypes.DELETE_DOCUMENT:
        this.handleDeleteDocument(action.documentId);
        break;
      case SectionContentActionTypes.EDIT_DOCUMENT:
        this.navigator.navigateToDocument(action.documentId);
        break;
      case SectionContentActionTypes.LINK_DOCUMENT:
        console.log(this.navigator.getDocumentLink(action.documentId));
        break;
      case SectionContentActionTypes.MOVE_TO_DOCUMENT_EDITOR:
        this.navigator.navigateToDocumentEditor(this.sectionId.toString(), action.documentId)
    }
  }

  handleFavoriteDocument(documentId: string) {
    const state = this.getState();

    let isFavoriteSection: boolean | null = null;
    const prevDocuments = state.documents;
    const prevFilteredDocuments = state.filteredDocuments;

    const documents = state.documents.map((document) => {
      if (documentId == document.id) {
        isFavoriteSection = !document.isFavorite;
        return clone(document, { isFavorite: isFavoriteSection });
      } else {
        return document;
      }
    });

    const filteredDocuments = state.filteredDocuments.map((document) => {
      if (documentId == document.id) {
        isFavoriteSection = !document.isFavorite;
        return clone(document, { isFavorite: isFavoriteSection });
      } else {
        return document;
      }
    });

    this.reduce({
      type: SectionContentResultActionTypes.CHANGE_DOCUMENT_MARK,
      documents: documents,
      filteredDocuments: filteredDocuments,
    });

    if (isFavoriteSection != null) {
      this.sectionContentService
        .updateDocumentFavorite(documentId, isFavoriteSection)
        .subscribe((status) => {
          if (status != ResponseStatus.SUCCESS) {
            this.toastsService.createToast({
              title: 'Возникла ошибка при добавлении в отмеченные.',
              description: 'Попробуйте позднее.',
              state: ToastState.ERROR,
            });
            this.reduce({
              type: SectionContentResultActionTypes.CHANGE_DOCUMENT_MARK,
              documents: prevDocuments,
              filteredDocuments: prevFilteredDocuments,
            });
          }
        });
    }
  }

  handleDeleteDocument(documentId: string) {
    this.sectionContentService.deleteDocument(documentId).subscribe(() => {
      if (this.sectionId != undefined) {
        this.sectionContentService.fetchDocuments(this.sectionId);
      }
    });
  }

  handleChangeSortItem(sortParamId: string) {
    const state = this.getState();
    this.reduce({
      type: SectionContentResultActionTypes.CHANGE_FILTER_STATE,
      filterState: clone(state.filterState, {
        sortParam: Constants.SORT_POPUP_SECTION[0].elements.find((elem) => {
          return elem.id == sortParamId;
        }),
      }),
      filteredDocuments: this.calculateFilteredDocuments(
        state.documents,
        state.filterState.filterParam,
        state.filterState.filter,
        sortParamId,
      ),
    });
  }

  handleChangeSearchField(value: string) {
    const state = this.getState();
    this.reduce({
      type: SectionContentResultActionTypes.CHANGE_FILTER_STATE,
      filterState: clone(state.filterState, { filter: value }),
      filteredDocuments: this.calculateFilteredDocuments(
        state.documents,
        state.filterState.filterParam,
        value,
        state.filterState.sortParam.id,
      ),
    });
  }

  handleChangeFilterItem(param: FilterParams) {
    const state = this.getState();
    this.reduce({
      type: SectionContentResultActionTypes.CHANGE_FILTER_ITEM,
      items: state.filterItems.map((item) => {
        item.isActive = item.id == param.valueOf();
        return item;
      }),
    });
    this.reduce({
      type: SectionContentResultActionTypes.CHANGE_FILTER_STATE,
      filterState: clone(state.filterState, { filterParam: param }),
      filteredDocuments: this.calculateFilteredDocuments(
        state.documents,
        param,
        state.filterState.filter,
        state.filterState.sortParam.id,
      ),
    });
  }

  handleAddDocument() {
    if (this.sectionId != undefined) {
      this.sectionContentService
        .createDocument(this.sectionId, Constants.BASE_NEW_DOC_NAME, null)
        .subscribe((documentId) => {
          console.log(documentId);
        });
    }
  }

  calculateFilteredDocuments(
    documents: Array<DocumentItemEntity>,
    filterParam: FilterParams,
    filter: string,
    sortParamId: string,
  ) {
    return documents
      .filter((document) => {
        let showWithFavorite = true;
        if (filterParam == FilterParams.MARKED) {
          showWithFavorite = document.isFavorite;
        }
        return (
          document.title.toLowerCase().includes(filter.toLowerCase()) &&
          showWithFavorite
        );
      })
      .sort((sectionA, sectionB): number => {
        const titleA = sectionA.title.toLowerCase();
        const titleB = sectionB.title.toLowerCase();
        switch (sortParamId) {
          case SortParams.ALPHABET_REVERT:
            return titleA < titleB ? 1 : -1;
          default:
            return titleA < titleB ? -1 : 1;
        }
      });
  }
}
