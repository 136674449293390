<ul class="space-y-4">
  <li *ngFor="let elem of prop" class="">
    <div class="pb-4 border-b border-tt-hover-color">
      <div class="flex gap-4 items-center">
        <img [src]="elem.image" alt="" />
        <p
          class="text-[16px] font-[400] text-center not-italic leading-normal text-tt-text-secondary"
        >
          {{ elem.first }}:
        </p>
        <p class="text-[16px] font-[400] text-center not-italic leading-normal">
          {{ elem.second }}
        </p>
      </div>
    </div>
  </li>
</ul>
