<div [ngClass]="className">
  <div class="w-full flex justify-between mb-[8px] text-tt-font-size_l leading-[18px] text-tt-text-primary">
    <label
      *ngIf="variant === 'standard' && label"
      class="inline-block"
    >
      {{ label }}
      <span class="text-[#F53232]" *ngIf="required">*</span>
    </label>

    <label
      *ngIf="charsCounterMax != 0 && focused"
      class="inline-block"
    >
      {{ countChars }} / {{ charsCounterMax }}
    </label>
  </div>

  <div
    class="relative w-full"
    [ngClass]="{
      'min-h-tt-height-field_xl': variant === 'outlined' && height === undefined,
      'min-h-tt-height-field_m': variant === 'standard' && height === undefined
    }"
    [style.height]="height + 'px'"
  >

    <textarea
      #textarea
      [ngModel]="localValue"
      (ngModelChange)="onChangeValue($event)"
      (focus)="onFocus()"
      (blur)="onUnFocus()"
      [readonly]="readonly"
      [disabled]="readonly"
      [maxLength]="charsCounterMax > 0 ? charsCounterMax : undefined"
      [ngClass]="{
        'bg-tt-bg-failure outline-tt-border-failure ring-tt-border-failure':
          error,
        'bg-tt-field-background-color': !error,
        'px-tt_l pt-[22px] pb-3 tracking-wider': variant === 'outlined',
        'p-[16px]': variant === 'standard',
        'overflow-y-scroll textarea-scrollbar': !isResizable,
        'overflow-hidden min-h-[112px]': isResizable,
      }"
      [style.height]="height + 'px'"
      class="resize-none  rounded-tt-border-radius_m
        peer w-full
        text-tt-font-size_l leading-[18px] font-normal text-tt-text-default
        outline outline-1 outline-tt-border-default
        ring-tt-primary focus:ring-2 focus:outline-none"
      [placeholder]="variant === 'outlined' ? ' ' : placeholder"
    ></textarea>
    <label
      *ngIf="variant === 'outlined' && placeholder"
      class="pointer-events-none absolute left-[16px] top-[8px] flex h-full w-full select-none text-tt-font-size_s tracking-wider font-normal leading-none text-tt-icon-field transition-all peer-placeholder-shown:text-tt-font-size_l peer-placeholder-shown:top-[16px] peer-focus:text-tt-font-size_s peer-focus:leading-none peer-focus:top-[10px]"
    >
      {{ placeholder }}
      <span class="text-[#F53232]" *ngIf="required">*</span>
    </label>
  </div>
  <app-field-error-message [error]="error" />
</div>
