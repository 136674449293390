import { SectionPrivacy } from '../../../../../../sections/impl/domain/section-summary-entity';
import { SectionEditEntity } from '../../../../domain/section-edit-entity';
import {
  AutocompleteItem
} from "../../../../../../../../core/components/fields/autocomplete-field/autocomplete-field.component";

export class Constants {
  public static PRIVACY_FIELD_VALUES: AutocompleteItem[] = [
    {
      id: SectionPrivacy.PUBLIC,
      name: 'Открытый',
      description: 'Доступен всем сотрудникам компании',
    },
    {
      id: SectionPrivacy.PRIVATE,
      name: 'Закрытый',
      description:
        'Недоступен большинству, доступ настраивается для каждого сотрудника/отдела отдельно, через настройки раздела.',
    },
  ];

  public static OWNER_FIELD_VALUES: AutocompleteItem[] = [
    {
      id: '1',
      name: 'Воронин Дмитрий',
      image: {
        url: 'https://cdn.betterttv.net/emote/5b35cae2f3a33e2b6f0058ef/3x.gif',
        placeholder: '',
      },
    },
    {
      id: '2',
      name: 'Алексей Минай',
      image: {
        url: 'https://cdn.betterttv.net/emote/5b35cae2f3a33e2b6f0058ef/3x.gif',
        placeholder: '',
      },
    },
  ];

  public static MOCK_ICON_URLS: string[] = [
    'https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png',
    'favicon.ico',
    'favicon.ico',
    'favicon.ico',
    'favicon.ico',
    'favicon.ico',
    'assets/link-icon.svg',
    'assets/arrow.svg',
    'assets/doc-icon.svg',
    'favicon.ico',
    'favicon.ico',
    'favicon.ico',
    'favicon.ico',
    'favicon.ico',
    'favicon.ico',
    'favicon.ico',
  ];

  public static INIT_EDIT_SECTION: SectionEditEntity = {
    id: 0,
    title: 'Название',
    owner: this.OWNER_FIELD_VALUES[0],
    privacy: this.PRIVACY_FIELD_VALUES[0],
    icon: this.MOCK_ICON_URLS[0],
  };

  public static EMPTY_TITLE_ERROR = 'Название не должно быть пустым';
}
