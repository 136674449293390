<div
  class="w-full h-full relative"
  (dragenter)="dragEnter($event)"
  (dragover)="dragOver($event)"
  (dragleave)="dragLeave($event)"
  (drop)="drop($event)"
  #dropEl
>
  <div
    class="drop-line drop-line-top-offset"
    *ngIf="isDragOver"
  ></div>
</div>

