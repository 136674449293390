export type SectionEditorAction = SwitchFullScreenAction
  | MoveToEditDocumentAction
  | CloseAlreadyOpenModalAction

export enum SectionEditorActionTypes {
  SWITCH_FULL_SCREEN,
  MOVE_TO_EDIT_DOCUMENT,
  CLOSE_ALREADY_OPEN_MODAL
}
export interface CloseAlreadyOpenModalAction {
  readonly type: SectionEditorActionTypes.CLOSE_ALREADY_OPEN_MODAL;
}

export interface SwitchFullScreenAction {
  readonly type: SectionEditorActionTypes.SWITCH_FULL_SCREEN;
  readonly value: boolean
}

export interface MoveToEditDocumentAction {
  readonly type: SectionEditorActionTypes.MOVE_TO_EDIT_DOCUMENT;
}
