<button
  type="button"
  (click)="onClickOption()"
  [disabled]="loading"
  class="relative px-tt_l truncate w-full text-left h-tt-button-height_m flex items-center text-tt-font-size_l text-tt-text-default cursor-pointer duration-75 hover:bg-tt-hover-color"
  [ngClass]="className"
>
  <div
    *ngIf="loading"
    class="bg-tt-hover-color absolute left-0 top-0 w-full h-full flex justify-center items-center z-10 text-tt-primary"
  >
    <div class="animate-spin">
      <svg-loading-icon />
    </div>
  </div>
  <ng-content />
</button>
<div *ngIf="divider" class="w-full h-[1px] bg-tt-separator-color"></div>
