import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionComponent } from './presentation/view/section.component';
import { SectionService } from './domain/section-service';
import { SectionServiceImpl } from './data/section-service-impl';
import { SectionsModule } from '../../sections/impl/sections.module';
import {
  SvgAllPages,
  SvgBorderedArrow,
  SvgGearShape, SvgLink,
  SvgPlus,
  SvgSquare
} from "../../../../core/components/svg-components/svg.components";
import { RouterOutlet } from '@angular/router';
import { ComponentsModule } from '../../../../core/components/components.module';
import { SectionMenuBoxComponent } from './presentation/components/section-menu-box/section-menu-box.component';

@NgModule({
  declarations: [SectionComponent, SectionMenuBoxComponent],
  imports: [
    CommonModule,
    SectionsModule,
    SvgGearShape,
    SvgSquare,
    SvgBorderedArrow,
    SvgPlus,
    RouterOutlet,
    SvgAllPages,
    ComponentsModule,
    SvgLink
  ]
})
export class SectionModule {}
