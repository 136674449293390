const Resizer = ({ position }: any) => (
  <div
    contentEditable={false}
    className={`yoopta-media-resizer-block`}
    style={{
      top: 0,
      left: position === 'left' ? 0 : undefined,
      right: position !== 'left' ? 0 : undefined,
    }}
  >
    <div className="yoopta-media-resizer " />
  </div>
);

export { Resizer };
