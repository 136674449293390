<td class="pl-[16px]">
  <div class="flex h-full gap-[16px] items-center">
    <button
      class="shrink-0"
      (click)="isAvatarUserCardVisible = !isAvatarUserCardVisible"
    >
      <img
        *ngIf="employee.icon"
        class="w-[40px] h-[40px] rounded-[20px]"
        src="{{employee.icon}}"
      >
      <app-core-avatar
        *ngIf="!employee.icon"
        size="l"
        variant="primary"
        placeholder="{{avatarPlaceHolder}}"
        bgRGB="{{avatarBgColor}}"
      />
    </button>

    <button
      (click)="isNameUserCardVisible = !isNameUserCardVisible"
      class="flex-grow truncate text-left text-[14px] leading-[18px] font-normal"
      app-core-with-highlighting
      [text]="employee.nameParts"
    >
    </button>

    <app-user-card
      *ngIf="isNameUserCardVisible || isAvatarUserCardVisible"
      [employee]="employee"
      [canEdit]="canEdit"
      (editClicked)="editClicked.emit()"
      (deleteClicked)="deleteClicked.emit()"
      appPopup
      popupOffsetXSide="left"
      [parentOffset]="{x: isNameUserCardVisible ? 56 : 0, y:  isNameUserCardVisible ? -12 : 0}"
      (popupClose)="closeCard()"
    />
  </div>
</td>

<td
  *ngIf="windowWidth >= 768"
  class="
    text-[13px] leading-[16px] text-tt-text-secondary truncate
  "
  app-core-with-highlighting
  [text]="employee.emailParts"
>
</td>

<td
  *ngIf="windowWidth >= 1024"
  class="
    text-[13px] leading-[16px] text-tt-text-secondary truncate"
>
  {{phoneNumber}}
</td>

<td class="pr-[16px]"
>
  <div
    *ngIf="sections[0] ? sections[0].elements.length > 0 : false"
    class="w-full h-full flex items-center justify-end group-hover:visible"
    [ngClass]="{
      'invisible': !isPopupVisible
    }"
  >
    <button class="p-[4px] text-tt-icon-default group/tripledot"
      (click)="tripleDotClick()"
    >
      <div class="size-[40px] flex items-center justify-center rounded-[20px] hover:bg-tt-button-hover">
        <svg-triple-dot />
      </div>
    </button>

    <app-core-action-menu
      *ngIf="isPopupVisible"
      [sections]="sections"
      (elementClicked)="onElementClick($event)"
      appPopup
      [parentOffset]="{x: -4, y: -44}"
      (popupClose)="popupClose()"
    />
  </div>
</td>

