import { RatingScaleEntity } from './create-skill-state';

export type CreateSkillAction =
  | OpenModalAction
  | CloseModalAction
  | ChangeNameAction
  | ChangeDescriptionAction
  | SaveSkillAction
  | ChangeCurrentStepAction
  | ChangeRatingScaleAction
  | ChangeLevelDescriptionAction;

export enum CreateSkillActionTypes {
  OPEN_MODAL,
  CLOSE_MODAL,
  CHANGE_NAME,
  CHANGE_DESCRIPTION,
  CHANGE_LEVEL_DESCRIPTION,
  CHANGE_CURRENT_STEP,
  CHANGE_RATING_SCALE,
  SAVE_SKILL,
}

export interface ChangeRatingScaleAction {
  readonly type: CreateSkillActionTypes.CHANGE_RATING_SCALE;
  readonly ratingScaleId: string | null;
}

export interface ChangeCurrentStepAction {
  readonly type: CreateSkillActionTypes.CHANGE_CURRENT_STEP;
  readonly step: 1 | 2;
}

export interface ChangeLevelDescriptionAction {
  readonly type: CreateSkillActionTypes.CHANGE_LEVEL_DESCRIPTION;
  readonly levelId: string;
  readonly levelDescription: string;
}

export interface OpenModalAction {
  readonly type: CreateSkillActionTypes.OPEN_MODAL;
  readonly defaultValue?: {
    name: string;
    description: string;
    ratingScale: RatingScaleEntity;
  };
}

export interface CloseModalAction {
  readonly type: CreateSkillActionTypes.CLOSE_MODAL;
}

export interface ChangeNameAction {
  readonly type: CreateSkillActionTypes.CHANGE_NAME;
  readonly name: string;
}

export interface ChangeDescriptionAction {
  readonly type: CreateSkillActionTypes.CHANGE_DESCRIPTION;
  readonly description: string;
}

export interface SaveSkillAction {
  readonly type: CreateSkillActionTypes.SAVE_SKILL;
}
