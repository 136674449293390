import {
  useBlockData,
  PluginElementRenderProps,
  useYooptaEditor,
  useYooptaPluginOptions,
  useBlockSelected,
  useYooptaReadOnly,
  Elements,
} from '@teamtells/editor';
import { Resizable, ResizableProps } from 're-resizable';
import { useEffect, useMemo, useState } from 'react';
import { Resizer } from "../../../components/resizer";
import { ImageComponent } from "../components/image";
import { ImagePluginOptions } from "../types/image-plugin-options";
import { Placeholder } from "../components/image-placeholder";
import { ImageBlockOptions } from "../components/image-block-options";

const MyImageRender = ({ extendRender, ...props }: PluginElementRenderProps) => {
  const { element, blockId, children, attributes } = props;
  const { src, alt, srcSet, bgColor, fit, sizes: propSizes } = element.props || {};
  const blockData = useBlockData(blockId);
  const editor = useYooptaEditor();
  const isReadOnly = useYooptaReadOnly();

  const pluginOptions = useYooptaPluginOptions<ImagePluginOptions>('Image');

  const [sizes, setSizes] = useState({
    width: propSizes?.width || 650,
    height: propSizes?.height || 440,
  });

  useEffect(
    () =>
      setSizes({
        width: propSizes?.width || 650,
        height: propSizes?.height || 440,
      }),
    [element.props],
  );

  const blockSelected = useBlockSelected({ blockId });

  const resizeProps: ResizableProps = useMemo(
    () => ({
      size: { width: !isReadOnly ? sizes.width : undefined, height: sizes.height },
      maxWidth: !isReadOnly ? (pluginOptions?.maxSizes?.maxWidth || 800) : undefined,
      maxHeight: pluginOptions?.maxSizes?.maxHeight || 720,
      lockAspectRatio: true,
      resizeRatio: 2,
      enable: {
        left: !isReadOnly,
        right: !isReadOnly,
      },
      handleStyles: {
        left: { left: 0 },
        right: { right: 0 },
      },
      onResize: (e, direction, ref) => {
        if (isReadOnly) return;
        setSizes({ width: ref.offsetWidth, height: ref.offsetHeight });
      },
      onResizeStop: (e, direction, ref) => {
        if (isReadOnly) return;
        Elements.updateElement(editor, blockId, {
          type: 'image',
          props: {
            sizes: { width: ref.offsetWidth, height: ref.offsetHeight },
          },
        });
      },
      handleComponent: {
        left: isReadOnly ? <></> : <Resizer position="left" />,
        right: isReadOnly ? <></> : <Resizer position="right" />,
      },
    }),
    [sizes.width, sizes.height],
  );

  if (!src) {
    if (isReadOnly) return <></>;

    return (
      <Placeholder attributes={attributes} blockId={blockId}>
        {children}
      </Placeholder>
    );
  }

  const currentAlign = blockData?.meta?.align || 'center';
  const alignClass = `yoopta-align-${currentAlign}`;

  return (
    <div
      contentEditable={false}
      draggable={false}
      className={`mt-4 relative flex ${alignClass} yoopta-image`}
    >
      <Resizable {...resizeProps} className="yoopta-resizer">
        {blockSelected && (
          <div className="yoopta-selected-block" />
        )}
          <ImageComponent
            src={src}
            alt={alt}
            srcSet={srcSet}
            fit={fit}
            width={sizes?.width}
            bgColor={bgColor}
            height={sizes?.height}
            attributes={attributes}
          >
            {children}
          </ImageComponent>
        {!isReadOnly && <ImageBlockOptions block={blockData} editor={editor} props={element.props} />}
      </Resizable>
    </div>
  );
};

export { MyImageRender };
