import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";

const EMPTY_IMAGE_URL = 'assets/empty-image.svg'

@Component({
  selector: 'app-core-input-icon',
  templateUrl: './input-icon.component.html',
  styleUrls: ['../input-image/input-image.component.scss'],
  host: { class: 'relative' }
})
export class InputIconComponent implements OnChanges, AfterViewInit{
  @Input() currentImageUrl: string = EMPTY_IMAGE_URL
  @Input() initUrl: string = EMPTY_IMAGE_URL
  @Input() windowIcons: string[] = []
  @Input() isReadOnly: boolean = false

  @Output() imageChanged = new EventEmitter<string>()

  @ViewChild('inputImage') inputImageRef!: ElementRef;
  @ViewChild('iconsWindow') iconsWindowRef!: ElementRef;

  fileName = ''
  filesHovered: boolean = false

  isIconsWindowVisible: boolean = true
  iconsWindowOffset = {
    top: 0,
    left: 0,
  }
  iconsWindowHeight: number = 0;

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.iconsWindowHeight = this.iconsWindowRef.nativeElement.offsetHeight
    this.isIconsWindowVisible = false
  }


  ngOnChanges(changes: SimpleChanges) {
    if(changes['initUrl']){
      if(this.initUrl == '')
      {
        this.initUrl = EMPTY_IMAGE_URL
      }
      else
      {
        this.currentImageUrl = this.initUrl
      }
    }
    if(changes['currentImageUrl'])
    {
      if(this.currentImageUrl == '') {
        this.currentImageUrl = EMPTY_IMAGE_URL
      }
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: any) {
    if(!this.isReadOnly)
    {
      event.preventDefault()

      let transfer = event.dataTransfer;
      this.imageChange(transfer.files[0]);

      this.filesHovered = false
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: any) {
    event.preventDefault()
    if(!this.isReadOnly) {
      this.filesHovered = true
    }
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: any) {
    event.preventDefault()
    this.filesHovered = false
  }

  @HostListener('document:click', ['$event'])
  onClickOutsideWindow(event: Event) {
    if (this.isIconsWindowVisible && !this.el.nativeElement.contains(event.target)) {
      this.isIconsWindowVisible = false;
    }
  }

  imageChange(file: File)
  {
    const reader = new FileReader();
    reader.readAsDataURL(file)
    this.fileName = file.name

    reader.onload = (event) => {
      console.log('reader', event.target!.result)
      this.iconSelected(<string>event.target!.result)
    }
  }

  showImageWindow(event: MouseEvent){
    this.isIconsWindowVisible = true
    const rect = this.el.nativeElement.getBoundingClientRect();
    const topOverflowOffset = window.innerHeight - event.clientY - this.iconsWindowHeight
    this.iconsWindowOffset = {
      top: topOverflowOffset >= 0 ?
        event.clientY - rect.top : event.clientY - rect.top + topOverflowOffset,
      left: event.clientX - rect.left,
    }
  }

  iconSelected(iconUrl:string)
  {
    this.isIconsWindowVisible = false
    this.currentImageUrl = iconUrl
    this.imageChanged.emit(iconUrl)
  }

  downloadClick(){
    if(!this.isReadOnly)
    {
      this.inputImageRef.nativeElement.click()
    }
  }

  onSelectFile(event: any){
    const file = event.target.files[0];
    this.imageChange(file)
  }

  cancelImage(){
    this.fileName = ''
    this.currentImageUrl = this.initUrl
    this.imageChanged.emit(this.initUrl)
  }
}
