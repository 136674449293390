import { NavTab } from "src/app/core/components/nav-tabs/nav-tabs.component";
import { StepViewState } from "../state/create-role-modal-state";
import { PermissionsDto } from "../../../../../../../core/services/role-service/dto/create-role-dto";

export enum CreateRoleModalNavItems {
  MAIN = 'main',
  ACCESSES = 'accesses',
  //USERS = 'users'
}

export enum CreateRoleModalClueTexts {
  MAIN = 'Заполните название, описание роли, а также укажите возможности для пользователей с этой ролью.',
  ACCESSES = 'Доступ помогает точечно настроить возможности пользователей в системе.',
 // USERS = 'Добавьте пользователей, чтобы они получили необходимый доступ к разделам системы для выполнения своих обязанностей'
}

export enum CreateRoleModalClueTitles {
  MAIN = 'Создание роли',
  ACCESSES = 'Укажите доступы',
  //USERS = 'Укажите пользователей'
}

export class CreateRoleConstants {
  public static readonly INIT_STEP_STATE: StepViewState = {
    stepNumber: 1,
    selectedNavItem: CreateRoleModalNavItems.MAIN,
    clueText: CreateRoleModalClueTexts.MAIN,
    clueTitle: CreateRoleModalClueTitles.MAIN,
    isShowErrorMain: false,
    isShowErrorContacts: false,
  }

  public static readonly NAV_ITEMS: NavTab[] = [
      {
        id: '',
        url: CreateRoleModalNavItems.MAIN,
        title: 'Основное'
      },
      {
        id: '',
        url: CreateRoleModalNavItems.ACCESSES,
        title: 'Доступы'
      },
      // {
      //   id: '',
      //   url: CreateRoleModalNavItems.USERS,
      //   title: 'Пользователи'
      // },
  ]

  public static readonly INIT_PERMISSIONS: PermissionsDto = {
    knowledgeBase: {
      viewKnowledgeBase: true,
      createKnowledgeBase: false,
      editKnowledgeBase: false,
      deleteKnowledgeBase: false
    },
    company: {
      viewEmployee: true,
      createEmployee: false,
      editEmployee: false,
      deleteEmployee: false
    },
    settings: {
      billing: false,
      main: false,
    },
    role: {
      viewRole: true,
      createRole: false,
      editRole: false,
      deleteRole: false
    }
  }
}
