import { Injectable } from '@angular/core';
import { clone } from 'cloneable-ts';
import { Reducer } from 'src/app/core/mvi/store';
import {
  SectionSettingsMainResultAction,
  SectionSettingsResultActionTypes,
} from './section-settings-main-result-action';
import { SectionSettingsMainState } from './section-settings-main-state';
import { SectionEditEntity } from '../../../../domain/section-edit-entity';

@Injectable()
export class SectionSettingsMainReducer
  implements Reducer<SectionSettingsMainState, SectionSettingsMainResultAction>
{
  reduce(
    state: SectionSettingsMainState,
    action: SectionSettingsMainResultAction,
  ): SectionSettingsMainState {
    switch (action.type) {
      case SectionSettingsResultActionTypes.UPDATE_SECTION:
        return clone(state, {
          baseSectionData: action.section,
          currentSectionData: action.section,
          saveButtonVisible: false,
        });
      case SectionSettingsResultActionTypes.CHANGE_CURRENT_SECTION:
        return clone(state, {
          currentSectionData: action.section,
          saveButtonVisible: !this.isEqual(
            state.baseSectionData,
            action.section,
          ),
          titleError: action.titleError,
        });
      case SectionSettingsResultActionTypes.UPDATE_ICONS:
        return clone(state, {
          iconUrls: action.iconUrls
        })
    }
  }
  isEqual(sectionA: SectionEditEntity, sectionB: SectionEditEntity): boolean {
    return (
      sectionA.title == sectionB.title &&
      sectionA.privacy.id == sectionB.privacy.id &&
      sectionA.owner.id == sectionB.owner.id &&
      sectionA.icon == sectionB.icon
    );
  }
}
