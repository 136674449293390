<div [ngClass]="className">
  <label
    *ngIf="variant === 'standard' && label"
    class="inline-block mb-1 text-tt-font-size_l text-tt-text-secondary"
  >
    {{ label }}
    <span class="text-[#F53232]" *ngIf="required">*</span>
  </label>
  <div
    class="relative w-full"
    [ngClass]="{
      'h-tt-height-field_m': variant === 'standard'
    }"
  >
    <input
      [(ngModel)]="searchString"
      (input)="onChangeValue($event)"
      (click)="onOpen()"
      (focus)="onFocus()"
      (blur)="onUnFocus()"
      [placeholder]="placeholder"
      [ngClass]="{
        'ring-tt-border-failure outline-tt-border-failure bg-tt-bg-failure':
          error,
        'ring-2': opened,
        'bg-tt-field-background-color': !error,
        'px-3': variant === 'standard',
        'rounded-b-none': opened && localItems.length > 0
      }"
      class="rounded-tt-border-radius_m text-tt-font-size_l font-normal text-tt-text-default h-full w-full outline outline-1 outline-tt-border-default ring-tt-primary focus:ring-2 focus:outline-none"
    />
    <div class="absolute right-4 top-2 flex items-center gap-[6px]">
      <button
        *ngIf="this.onUnselect.observed && selectedItem"
        (click)="onUnselectItem($event, selectedItem!.id)"
        type="button"
        class="flex justify-center items-center h-[24px] w-[24px] hover:bg-tt-bg-default rounded-full transition"
      >
        <svg-x-mark class="text-tt-icon-default" />
      </button>
      <button
        type="button"
        class="flex justify-center items-center h-[24px] w-[24px] hover:bg-tt-bg-default rounded-full transition"
        [class.rotate-180]="focused || opened"
      >
        <svg-bordered-arrow class="text-tt-icon-default" />
      </button>
    </div>
    <div
      [class.hidden]="!opened || localItems.length == 0"
      class="pb-2 z-50 absolute w-full"
    >
      <div
        class="w-full max-h-[280px] overflow-y-auto hidden-scrollbar bg-tt-bg-default ring-2 outline outline-1 outline-tt-border-default ring-tt-primary rounded-tt-border-radius_m"
        [ngClass]="{
          'top-[3px] absolute rounded-t-none': variant === 'standard'
        }"
      >
        <div class="overflow-hidden w-full text-start">
          <button
            type="button"
            *ngFor="let item of localItems; trackBy: trackItemByFn"
            (click)="onSelectItem(item)"
            class="flex items-center justify-between gap-3 text-tt-font-size_l font-normal text-tt-text-default cursor-pointer py-2 min-h-[40px] px-3 w-full bg-tt-bg-default hover:bg-tt-hover-color transition"
          >
            <div
              [class.items-center]="!item.description"
              class="flex flex-row gap-3"
            >
              <div *ngIf="item.image">
                <app-core-avatar
                  size="l"
                  [url]="item.image.url"
                  [placeholder]="item.image.placeholder"
                />
              </div>
              <div class="flex flex-col items-start">
                <span
                  class="text-start text-tt-font-size_l text-tt-text-default"
                >
                  {{ item.name }}
                </span>
                <span
                  *ngIf="item.description"
                  class="text-start text-tt-font-size_m text-tt-text-secondary"
                >
                  {{ item.description }}
                </span>
              </div>
            </div>
            <div class="w-[24px] h-[24px]">
              <div *ngIf="selectedItem && item.id === selectedItem.id">
                <svg-checkmark class="text-tt-primary" />
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-field-error-message [error]="error" />
</div>
