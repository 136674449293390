import { NgModule } from '@angular/core';
import { SectionSettingsComponent } from './presentation/view/section-settings.component';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../../../core/components/components.module';
import { SectionSettingsMainComponent } from './presentation/components/section-settings-main/section-settings-main.component';
import { SectionSettingsAccessComponent } from './presentation/components/section-settings-access/section-settings-access.component';
import { SvgEdit } from '../../../../core/components/svg-components/svg.components';
import { SectionsModule } from '../../sections/impl/sections.module';

@NgModule({
  declarations: [
    SectionSettingsComponent,
    SectionSettingsMainComponent,
    SectionSettingsAccessComponent,
  ],
  imports: [CommonModule, ComponentsModule, SvgEdit, SectionsModule],
})
export class SectionSettingsModule {}
