<app-auth-layout>
  <section
    class="relative bg-tt-bg-default rounded-tt-border-radius_l sm:rounded-tt-border-radius_4xl mt-4 mb-16 p-tt_2xl sm:p-tt_8xl w-full max-w-tt-auth-container"
    (keyup.enter)="performAction({ type: NewPasswordActionTypes.NEW })"
  >
    <h1
      class="text-tt-font-size_3xl sm:text-tt-font-size_5xl mb-7 block text-center"
    >
      Установите пароль
    </h1>

    <span class="block my-3 leading-6">
      Укажите пароль для дальнейшего входа в систему
    </span>

    <app-core-input-field
      className="mt-tt_xl"
      placeholder="Пароль"
      type="password"
      [error]="state.passwordError.toString()"
      (onChange)="
        performAction({
          type: NewPasswordActionTypes.CHANGE_PASSWORD,
          password: $event
        })
      "
    />

    <app-core-button
      className="w-full mt-7"
      size="xl"
      [loading]="state.isLoading"
      (click)="performAction({ type: NewPasswordActionTypes.NEW })"
    >
      Сохранить
    </app-core-button>

    <div
      class="absolute left-0 bottom-[-44px] w-full flex justify-center items-center mt-[20px]"
    >
      <a class="text-tt-text-secondary" href="https://teamtells.ru">
        teamtells.ru
      </a>
    </div>
  </section>
</app-auth-layout>
