import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output, Renderer2,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { SpaceConstants } from "../../common/space-constants";
import { DocumentSummaryEntity } from "../../../domain/document-summery-entity";
import { parseToHighlightedParts } from "src/app/core/utils/parse-to-highlighted-parts";
import {
  HighlightedPartsStr
} from "../../../../../../../core/components/text-with-highlighting/text-with-highlighting.component";
import { DocumentsDragAndDropService } from "../../../services/documents-drag-and-drop.service";

@Component({
  selector: 'space-document-item',
  templateUrl: './document-item.component.html',
  styleUrls: ['../../common/space.component.scss', './document-item.component.scss']
})
export class DocumentItemComponent implements OnChanges{
  @Input() document: DocumentSummaryEntity = {
    id: '-1',
    name: '',
    isOpen: false,
    countSearchMatches: 0,
    longreadId: '',
    documents: []
  }
  @Input() selectedId: string = ''
  @Input() canEdit: boolean = true
  @Input() searchStr: string = ''
  @Input() depth: number = 0
  @Input() canDrop: boolean = true

  @Output() documentClicked = new EventEmitter<string>()
  @Output() createClicked = new EventEmitter<string>()
  @Output() deleteClicked = new EventEmitter<{id: string, name: string}>()
  @Output() renameClicked = new EventEmitter<{id: string, name: string}>()
  @Output() editClicked = new EventEmitter<string>()
  @Output() moveTo = new EventEmitter<MovingDocumentProps>()
  @Output() onStartDrag = new EventEmitter()
  @Output() onEndDrag = new EventEmitter()

  @ViewChild('arrowButton') arrowButton?: ElementRef
  @ViewChild('tripleDotButton') tripleDotButton?: ElementRef
  @ViewChild('plusButton') plusButton?: ElementRef
  @ViewChild('draggableDoc') draggableDocEl?: ElementRef
  @ViewChild('dragContainerDoc') dragContainerDocEl?: ElementRef

  isDragging: boolean = false
  isChildDragging: boolean = false
  isDragOver: boolean = false
  isDragOverTopBox: boolean = false
  isDragOverCenterBox: boolean = false
  isDragOverBottomBox: boolean = false
  dragLineOffset: number = 5

  isSelected: boolean = false
  isPopupOpen: boolean = false
  titleStrings: HighlightedPartsStr[] = []

  leftOffset: number = SpaceConstants.BASE_DOCUMENT_LEFT_OFFSET
  rightOffset: number = SpaceConstants.BASE_DOCUMENT_RIGHT_OFFSET

  protected readonly SpaceConstants = SpaceConstants

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private dragAndDropService: DocumentsDragAndDropService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['document'])
    {
      this.leftOffset = SpaceConstants.BASE_DOCUMENT_LEFT_OFFSET + SpaceConstants.BASE_DOCUMENT_DEPTH_STEP * this.depth
      this.isSelected = this.document.id == this.selectedId
      this.titleStrings = parseToHighlightedParts(
        this.document.name == '' ? SpaceConstants.EMPTY_DOCUMENT_NAME : this.document.name,
        this.searchStr
      )
    }
    if(changes['selectedId'])
    {
      this.isSelected = this.document.id == this.selectedId
    }
    if(changes['searchStr']){
      this.titleStrings = parseToHighlightedParts(
        this.document.name == '' ? SpaceConstants.EMPTY_DOCUMENT_NAME : this.document.name,
        this.searchStr
      )
    }
  }

  childStartDrag(){
    this.isChildDragging = true
    this.onStartDrag.emit()
  }

  childEndDrag(){
    this.isChildDragging = false
    this.onEndDrag.emit()
  }

  dragStart(event: DragEvent){
    if(this.canDrop && !this.isChildDragging){
      this.isDragging = true
      this.canDrop = false
      this.isPopupOpen = false
      this.onStartDrag.emit()

      this.dragAndDropService.setDragDocument(this.document)
    }
  }

  dragEnter(event: DragEvent){
    if(this.canDrop) {
      event.preventDefault()
      this.isDragOver = true
    }
  }

  dragLeave(event: DragEvent){
    if(this.canDrop) {
      event.preventDefault()
      if (!this.dragContainerDocEl?.nativeElement.contains(event.relatedTarget)) {
        this.isDragOver = false
        this.isDragOverTopBox = false
        this.isDragOverBottomBox = false
        this.isDragOverCenterBox = false
      }
    }
  }

  dragEnd(event: DragEvent){
    this.isDragging = false
    this.onEndDrag.emit()
    this.canDrop = true
    this.dragAndDropService.setDragDocument(undefined)
  }

  dragOver(event: DragEvent){
    if(this.canDrop) {
      event.preventDefault()
      const containerRect = this.dragContainerDocEl?.nativeElement.getBoundingClientRect() as DOMRect
      const height = containerRect.height

      this.isDragOverTopBox = false
      this.isDragOverBottomBox = false
      this.isDragOverCenterBox = false

      //insert to top
      if (event.offsetY <= this.dragLineOffset) {
        this.isDragOverTopBox = true
      }
      //insert to bottom
      if (height - event.offsetY <= this.dragLineOffset) {
        this.isDragOverBottomBox = true
      }
      //insert to document
      if (event.offsetY > this.dragLineOffset && height - event.offsetY > this.dragLineOffset) {
        this.isDragOverCenterBox = true
      }
    }
  }

  dragDrop(event: DragEvent){
    if(this.canDrop) {
      this.isDragOver = false
      this.isDragOverTopBox = false
      this.isDragOverBottomBox = false
      this.isDragOverCenterBox = false

      const movingDocument=  this.dragAndDropService.getDragDocument()

      if(movingDocument)
      {
        const height = this.dragContainerDocEl?.nativeElement.offsetHeight

        //insert to top
        if (event.offsetY <= this.dragLineOffset
          && movingDocument.nextDocumentId != this.document.id
        ) {
          this.moveTo.emit(
            {
              document: movingDocument,
              moveToDocumentId: this.document.parentId,
              nextDocumentId: this.document.id
            }
          )
        }
        //insert to bottom
        if (height - event.offsetY <= this.dragLineOffset
          && movingDocument.id != this.document.nextDocumentId
        ) {
          this.moveTo.emit(
            {
              document: movingDocument,
              moveToDocumentId: this.document.parentId,
              nextDocumentId: this.document.nextDocumentId
            }
          )
        }
        //insert to document
        if (event.offsetY > this.dragLineOffset && height - event.offsetY > this.dragLineOffset) {
          this.moveTo.emit(
            {
              document: movingDocument,
              moveToDocumentId: this.document.id
            }
          )
        }
      }
    }

  }

  arrowClick(){
    this.document.isOpen = !this.document.isOpen
  }


  documentClick(event: MouseEvent){
    if(
      (!this.arrowButton || !this.arrowButton.nativeElement.contains(event.target)) &&
      (!this.tripleDotButton || !this.tripleDotButton.nativeElement.contains(event.target)) &&
      (!this.plusButton || !this.plusButton.nativeElement.contains(event.target))
    )
    {
      this.documentClicked.emit(this.document.id)
    }
  }

  popupElementClicked(id: string){
    switch(id){
      case 'rename':
        this.renameClicked.emit({
          id: this.document.id,
          name: this.document.name
        })
        break
      case 'edit':
        this.editClicked.emit(this.document.id)
        break;
      case 'delete':
        this.deleteClicked.emit({ id: this.document.id, name: this.document.name})
        break
    }
    this.isPopupOpen = false
  }
}

export type MovingDocumentProps = {
  document: DocumentSummaryEntity,
  moveToDocumentId?: string,
  nextDocumentId?: string
}
