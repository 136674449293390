import { Injectable } from '@angular/core';
import { Reducer } from 'src/app/core/mvi/store';
import {
  RatingScalesResultAction,
  RatingScalesResultActionTypes,
} from './rating-scales-result-action';
import { RatingScalesState } from './rating-scales-state';

@Injectable()
export class RatingScalesReducer
  implements Reducer<RatingScalesState, RatingScalesResultAction>
{
  reduce(
    state: RatingScalesState,
    action: RatingScalesResultAction,
  ): RatingScalesState {
    switch (action.type) {
      case RatingScalesResultActionTypes.UPDATE_RATING_SCALE:
        return {
          ...state,
          ratingScales: state.ratingScales.map((ratingScale) => {
            if (ratingScale.id === action.ratingScale.id) {
              return action.ratingScale;
            }
            return ratingScale;
          }),
        };

      case RatingScalesResultActionTypes.ADD_RATING_SCALE:
        return {
          ...state,
          ratingScales: [...state.ratingScales, action.ratingScale],
        };

      case RatingScalesResultActionTypes.UPDATE_RATING_SCALES:
        return { ...state, ratingScales: action.ratingScales };

      case RatingScalesResultActionTypes.CHANGE_SEARCH:
        return { ...state, search: action.search };

      case RatingScalesResultActionTypes.TOGGLE_SELECTED_RATING_SCALE: {
        if (
          state.selectedRatingScale !== null &&
          state.selectedRatingScale !== action.selectedRatingScale &&
          !action.selected
        ) {
          return state;
        }

        return {
          ...state,
          selectedRatingScale: action.selected
            ? action.selectedRatingScale
            : null,
        };
      }

      case RatingScalesResultActionTypes.CHANGE_LOADING_STATUS:
        return { ...state, isLoading: action.isLoading };

      case RatingScalesResultActionTypes.CHANGE_ERROR_STATUS:
        return { ...state, error: action.error };

      case RatingScalesResultActionTypes.CHANGE_LOADING_DELETE_STATUS:
        return { ...state, isLoadingDelete: action.isLoadingDelete };

      case RatingScalesResultActionTypes.CHANGE_ERROR_DELETE_STATUS:
        return { ...state, errorDelete: action.errorDelete };
    }
  }
}
