<app-auth-layout>
  <section
    class="relative bg-tt-bg-default rounded-tt-border-radius_l
      sm:rounded-tt-border-radius_4xl mt-4 mb-16 p-[44px] w-full
      border-[1px] border-tt-border-default max-w-[872px]"
  >
    <div
      *ngIf="state.status === 'idle'"
      class="flex flex-col gap-[24px]"
    >
      <h2
        class="text-tt-font-size_3xl font-medium sm:text-tt-font-size_5xl block text-center"
      >
        Регистрация в <span class="text-tt-primary">TeamTells</span>
      </h2>

      <div class="flex w-full gap-[20px]">
        <app-core-input-field
          class="w-full"
          placeholder="Фамилия"
          [required]="true"
          [error]="state.nameError"
          (onChange)="
            performAction({
              type: RegistrationActionTypes.CHANGE_SURNAME,
              surname: $event
            })
          "
        />

        <app-core-input-field
          class="w-full"
          placeholder="Имя"
          [required]="true"
          [error]="state.nameError"
          (onChange)="
            performAction({
              type: RegistrationActionTypes.CHANGE_NAME,
              name: $event
            })
          "
        />
      </div>

      <app-core-input-field
        placeholder="Отчество"
        [error]="state.nameError"
        (onChange)="
          performAction({
            type: RegistrationActionTypes.CHANGE_PATRONYMIC,
            patronymic: $event
          })
        "
      />

      <app-core-dropdown-field
        placeholder="Из какой отрасли ваша компания?"
        [required]="true"
        [error]="state.specializingError"
        [items]="state.specializations"
        [selectedItem]="state.selectedSpecializing"
        (onSelect)="
          performAction({
            type: RegistrationActionTypes.CHANGE_SPECIALIZING,
            specializingId: $event
          })
        "
      />
      <app-core-dropdown-field
        className="max-w-none"
        placeholder="Сколько человек работает в компании?"
        [required]="true"
        [error]="state.staffSizeError"
        [items]="state.possibleStaffSize"
        [selectedItem]="state.selectedStaffSize"
        (onSelect)="
          performAction({
            type: RegistrationActionTypes.CHANGE_STAFF_SIZE,
            staffSizeId: $event
          })
        "
      />
      <div class="flex flex-col sm:flex-row gap-tt_xl">
        <app-core-input-field
          class="flex-1"
          placeholder="Электронная почта"
          [required]="true"
          [error]="state.emailError"
          (onChange)="
            performAction({
              type: RegistrationActionTypes.CHANGE_EMAIL,
              email: $event
            })
          "
        />
        <app-core-input-field
          class="flex-1"
          [error]="state.phoneNumberError"
          prefix="+"
          placeholder="Мобильный телефон"
          [required]="true"
          mask="0 (000) 000-00-00||00 (000) 000-00-00||000 (000) 000-00-00"
          (onChange)="
            performAction({
              type: RegistrationActionTypes.CHANGE_PHONE_NUMBER,
              phoneNumber: $event
            })
          "
        />
      </div>
      <div class="flex flex-col sm:flex-row gap-4 items-center">
        <div>
          <p class="text-tt-text-secondary text-sm leading-6">
            Заполняя форму, я принимаю
            <a href="#" class="text-tt-secondary"
              >условия передачи информации</a
            >
            и даю
            <a href="#" class="text-tt-secondary">
              согласие на получение информации о продуктах компании и партнеров
            </a>
          </p>
        </div>
        <app-core-button
          size="xl"
          (onClick)="performAction({ type: RegistrationActionTypes.CREATE })"
        >
          Создать аккаунт
        </app-core-button>
      </div>
    </div>

    <div
      *ngIf="
        state.status === 'create_loading' ||
        state.status === 'success' ||
        state.status === 'create_error' ||
        state.status === 'get_info_error' ||
        state.status === 'get_info_loading' ||
        state.status === 'already_exist_error'
      "
    >
      <div
        *ngIf="state.status === 'create_error'"
        class="flex w-full items-center flex-col text-center"
      >
        <img
          class="w-[96px] h-[96px]"
          src="/assets/registration-error.svg"
          alt="Registration Success"
        />
        <h2 class="text-2xl text-center inline-block mt-6">
          Возникла ошибка
        </h2>
        <span class="text-center mt-6">Приносим свои изменения за предоставленные неудобства.<br/> Попробуйте создать аккаунт позднее</span>
      </div>
      <div
        *ngIf="state.status === 'success'"
        class="flex items-center flex-col"
      >
        <img
          class="w-[96px] h-[96px]"
          src="/assets/registration-success.svg"
          alt="Registration Success"
        />
        <h2 class="text-xl sm:text-2xl text-center inline-block mt-[40px]">
          Аккаунт успешно создан
        </h2>
        <span class="text-center mt-6">
          Данные аккаунта отправили на email, который<br/> вы указали при регистрации
        </span>
      </div>
      <div
        *ngIf="state.status === 'create_loading'"
        class="flex items-center flex-col"
      >
        <svg-loading class="animate-spin text-tt-primary w-24 h-24" />

        <h2 class="text-xl sm:text-2xl text-center inline-block mt-10 mb-6">
          Подождите, создаем ваш аккаунт
        </h2>
        <span class="text-center">
          Данные аккаунта отправили на email, который <br/>вы указали при регистрации
        </span>
      </div>
      <div
        *ngIf="state.status === 'already_exist_error'"
        class="flex w-full items-center flex-col text-center"
      >
        <img
          class="w-[96px] h-[96px]"
          src="/assets/registration-error.svg"
          alt="Registration Success"
        />
        <h2 class="text-2xl text-center inline-block mt-6">
          Возникла ошибка
        </h2>
        <span class="text-center mt-6">Указанный адрес электронной почты
          <span class="text-tt-text-link">example@example.com</span>
          уже существует в системе TeamTells. Пожалуйста, используйте другой адрес электронной почты для создания аккаунта.
        </span>
      </div>

      <a
        href="/login"
        class="mt-6 bg-tt-primary h-[56px] px-[32px] w-max mx-auto
          text-tt-text-contrast rounded-tt-border-radius_m
          flex items-center"
        *ngIf="state.status != 'create_loading'"
      >
        На главную
      </a>
    </div>

    <div
      class="absolute left-0 bottom-[-44px] w-full flex justify-between items-center mt-tt_xl"
      [ngClass]="{
        'justify-between': state.status == 'idle',
        'justify-center': state.status != 'idle'
      }"
    >
      <a class="text-tt-text-secondary" href="https://teamtells.ru">
        teamtells.ru
      </a>
      <a
        *ngIf="state.status == 'idle'"
        class="text-sm font-medium flex items-center"
        href="/login"
      >
        Войти в учетную запись
        <svg-arrow-right class="h-3 w-2 ms-2" />
      </a>
    </div>
  </section>
</app-auth-layout>
