<div
  class="rating-scale flex justify-between items-center h-tt-button-height_l py-tt_extra-s w-full rounded-tt-border-radius_m px-tt_s hover:bg-tt-hover-color transition-colors duration-75"
  [class.rating-scale__active]="selected"
>
  <div class="flex flex-row items-center gap-tt_s">
    <svg-office-building class="text-tt-icon-default" />
    <span class="text-tt-font-size_l text-tt-text-default">
      {{ this.ratingScale.name }}
    </span>
  </div>
  <app-core-popup-menu
    class="rating-scale-menu"
    [leftOffset]="-148"
    [topOffset]="16"
    (onToggle)="onToggleMenu.emit($event)"
  >
    <app-core-popup-menu-button>
      <button type="button">
        <svg-triple-dot class="text-tt-icon-default" />
      </button>
    </app-core-popup-menu-button>
    <app-core-popup-menu-content width="180px">
      <app-create-rating-scale [ratingScale]="ratingScale">
        <app-core-popup-menu-option>
          <svg-edit-pencil class="text-tt-icon-default me-tt_sm" />
          Редактировать
        </app-core-popup-menu-option>
      </app-create-rating-scale>
      <app-core-popup-menu-option
        (onClick)="onDeleteRatingScale.emit()"
        [loading]="deleteLoading"
      >
        <svg-trash-red class="text-tt-icon-failure me-tt_s" />
        <span class="text-tt-text-failure">Удалить</span>
      </app-core-popup-menu-option>
    </app-core-popup-menu-content>
  </app-core-popup-menu>
</div>
