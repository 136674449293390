import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { PlatformLocation } from "@angular/common";
import { ToastState } from "../../../../../core/components/toast-alert/toast-alert.component";
import { ToastsService } from "../../../../../core/components/toast-alert/services/toast-alert.service";

@Injectable({
  providedIn: 'root',
})
export class SectionEditorNavigator {
  baseUrl: string;

  constructor(
    private platform: PlatformLocation
  ) {
    this.baseUrl = platform.port ? 'http://' + platform.hostname + ':' + platform.port : 'https://' + platform.hostname
  }

  openEditDocument(documentId: string) {
    window.open(`${this.baseUrl}/document/${documentId}`, '_blank');
  }
}
