import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SvgSkill } from 'src/app/core/components/svg-components/svg.components';
import { SkillDto } from '../../../../@data/dto/skill.dto';

@Component({
  standalone: true,
  imports: [CommonModule, SvgSkill],
  selector: 'app-skill-item',
  templateUrl: './skill-item.component.html',
})
export class SkillItemComponent {
  @Input() depth: number = 0;
  @Input() skill!: SkillDto;

  @Output() onSelectSkill = new EventEmitter<string>();
}
