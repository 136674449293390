import { SettingsMainNavItems } from "./settings-main-state"

export type SettingsMainAction =
  | ChangeOrganisationTitleAction
  | SaveTitleAction
  | CancelTitleAction
  | ChangeNavItemAction
  | ChangeLanguageAction
  | DeleteAction
  | ChangeDeleteAlertModalVisibilityAction

export enum SettingsMainActionTypes {
  DELETE,
  CHANGE_ORGANISATION_NAME,
  SAVE_TITLE,
  CANCEL_TITLE,
  CHANGE_NAV_ITEM,
  CHANGE_LANGUAGE,
  CHANGE_DELETE_ALERT_MODAL_VISIBILITY
}

export interface ChangeDeleteAlertModalVisibilityAction{
  readonly type: SettingsMainActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY
  readonly value: boolean
}

export interface DeleteAction{
  readonly type: SettingsMainActionTypes.DELETE
}

export interface ChangeLanguageAction {
  readonly type: SettingsMainActionTypes.CHANGE_LANGUAGE,
  readonly id: string
}

export interface ChangeNavItemAction {
  readonly type: SettingsMainActionTypes.CHANGE_NAV_ITEM,
  readonly navItem: SettingsMainNavItems
}

export interface CancelTitleAction {
  readonly type: SettingsMainActionTypes.CANCEL_TITLE
}

export interface SaveTitleAction {
  readonly type: SettingsMainActionTypes.SAVE_TITLE
}

export interface ChangeOrganisationTitleAction {
  readonly type: SettingsMainActionTypes.CHANGE_ORGANISATION_NAME
  readonly value: string
}
