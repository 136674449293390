<button class="px-[20px] py-[12px] flex items-center gap-[8px] w-full"
  [ngClass]="{
    'bg-tt-menu-navigation-item-selected': isSelected,
    'hover:bg-tt-menu-navigation-item-hover': !isSelected
  }"
  (click)="onClick.emit()"
>
  <div class="w-[36px] h-[36px] flex justify-center items-center rounded-[18px] "
    [ngClass]="{
      'bg-tt-menu-navigation-item-selected-title text-white': isSelected,
      'text-tt-menu-navigation-item-icon-default-color border-[1px] border-tt-menu-navigation-item-icon-border': !isSelected
    }"
  >
    <ng-content></ng-content>
  </div>

  <div
    class="font-normal text-tt-font-size_l"
    [ngClass]="{
      'text-tt-menu-navigation-item-selected-title': isSelected,
      'text-tt-menu-navigation-item-default-title': !isSelected
    }"
  >
    <ng-content select="p"></ng-content>
  </div>
</button>
