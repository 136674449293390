import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { SpacesState } from './state/spaces-state';
import { SpacesExecutor } from './state/spaces-executor';
import { SpacesAction, SpacesActionTypes } from './state/spaces-action';
import { SpacesResultAction } from './state/spaces-result-action';
import { SpacesReducer } from './state/spaces-reducer';
import { Store } from 'src/app/core/mvi/store';
import { HeaderService } from 'src/app/features/main/data/header-service';
import { SpacesConstants } from './common/spaces-constants';
import { KnowledgeBaseConstants } from '../../../common/knowledge-base-constants';
import { MainRoutesPaths } from 'src/app/routes/main-routes';


@Component({
  selector: 'app-spaces-component',
  templateUrl: './spaces.component.html',
  styleUrls: [
    './common/spaces.component.scss',
    '../../../../main/presentation/main.component.scss',
  ],
  providers: [
    SpacesState,
    SpacesExecutor,
    SpacesReducer,
  ]
})
export class SpacesComponent
extends Store<
  SpacesState,
  SpacesExecutor,
  SpacesAction,
  SpacesResultAction
> implements AfterViewInit
{
  @ViewChild('scrollable', { read: ElementRef }) scrollableEl?: ElementRef
  @ViewChild('spaces', { read: ElementRef }) spacesEl?: ElementRef

  spacesWidth: string = '100%'

  protected readonly SpacesActionTypes = SpacesActionTypes
  protected readonly SpacesConstants = SpacesConstants

  constructor(
    state: SpacesState,
    executor: SpacesExecutor,
    reducer: SpacesReducer,
    private headerService: HeaderService
  ) {
    headerService.ChangeStyle('header-bg-gradient')
    headerService.ChangeBreadCrumbs([])
    super(state, executor, reducer);
    this.performAction({
      type: SpacesActionTypes.UPDATE_SPACES
    })
  }

  ngAfterViewInit(): void {
    this.onResize()
  }

  onScroll(){
    if(this.scrollableEl){
      if(this.scrollableEl.nativeElement.scrollTop > 0){
        this.headerService.ChangeStyle('header-bg-gradient')
      }
      else{
        this.headerService.ChangeStyle('header-bg-gradient')
      }
      if(this.scrollableEl.nativeElement.scrollTop > 50){
        this.headerService.ChangeBreadCrumbs([
          {
            name: KnowledgeBaseConstants.KNOWLEDGE_BASE,
            href: '/' + MainRoutesPaths.KNOWLEDGE_BASE
          }
        ])
      }
      else{
        this.headerService.ChangeBreadCrumbs([])
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const spacesW = this.spacesEl?.nativeElement.offsetWidth
    const countSpacesInLine = Math.floor((spacesW + SpacesConstants.SPACES_GAP) / (SpacesConstants.SPACES_MAX_W + SpacesConstants.SPACES_GAP))
    this.spacesWidth = countSpacesInLine * SpacesConstants.SPACES_MAX_W + (countSpacesInLine - 1) * SpacesConstants.SPACES_GAP + 'px'
  }
}
