import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RatingScaleDto } from '../../../@data/dto/rating-scale.dto';

@Component({
  selector: 'app-rating-scale-item',
  templateUrl: './rating-scale-item.component.html',
  styleUrls: ['./rating-scale-item.component.scss'],
})
export class RatingScaleItemComponent {
  @Input() selected = false;
  @Input() ratingScale!: RatingScaleDto;
  @Input() deleteLoading = false;

  @Output() onToggleMenu = new EventEmitter<boolean>();
  @Output() onDeleteRatingScale = new EventEmitter<void>();
}
