import { Component, OnInit } from '@angular/core';
import { Store } from '../../../../../../core/mvi/store';
import { Constants } from '../common/сonstants';
import { SectionsAction, SectionsActionTypes } from '../state/sections-action';
import { SectionsExecutor } from '../state/sections-executor';
import { SectionsReducer } from '../state/sections-reducer';
import { SectionsResultAction } from '../state/sections-result-action';
import { SectionsState } from '../state/sections-state';
import { SectionsService } from '../../domain/sections-service';
import { SectionsServiceImpl } from '../../data/sections-service-impl';
import { DocumentationService } from "../../../../data/documentation-service";
import {
  SectionSettingsMainActionTypes
} from "../../../../section-settings/impl/presentation/components/section-settings-main/state/section-settings-main-action";

@Component({
  selector: 'app-presentation',
  templateUrl: './sections.component.html',
  styleUrls: [
    './sections.component.scss',
    '../../../../section/impl/presentation/common/section.component.scss',
  ],
  providers: [
    SectionsState,
    SectionsExecutor,
    SectionsReducer,
    {
      provide: SectionsService,
      useClass: SectionsServiceImpl,
    },
    DocumentationService
  ],
})
export class SectionsComponent
  extends Store<
    SectionsState,
    SectionsExecutor,
    SectionsAction,
    SectionsResultAction
  >
  implements OnInit
{
  constructor(
    state: SectionsState,
    executor: SectionsExecutor,
    reducer: SectionsReducer,
  ) {
    super(state, executor, reducer);
  }

  ngOnInit(): void {
    this.performAction({ type: SectionsActionTypes.UPDATE_SECTIONS_LIST });
  }

  protected readonly SectionsActionTypes = SectionsActionTypes;
  protected readonly Constants = Constants;
  protected readonly SectionSettingsMainActionTypes = SectionSettingsMainActionTypes;
}
