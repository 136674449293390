import { Reducer } from 'src/app/core/mvi/store';
import {
  EmployeesListResultAction,
  EmployeesListResultActionTypes,
} from './employees-list-result-action';
import { Injectable } from '@angular/core';
import { EmployeesListState } from './employees-list-state';
import {
  SpaceSummeryEntity
} from "../../../../../knowledge-base/modules/spaces/presentation/components/space-item/space-item.component";
import { EmployeeSummeryEntity } from "../../../../domain/employee";
@Injectable()
export class EmployeesListReducer
  implements Reducer<EmployeesListState, EmployeesListResultAction>
{
  reduce(
    state: EmployeesListState,
    action: EmployeesListResultAction,
  ): EmployeesListState {
    switch (action.type) {
      case EmployeesListResultActionTypes.CHANGE_CREATE_EMPLOYEE_MODAL_VISIBLE:
        return {...state,
          isCreateEmployeeModalVisible: action.value,
          employeeEditId: !action.value ? -1 : state.employeeEditId
        }
      case EmployeesListResultActionTypes.UPDATE_EMPLOYEES_LIST:
        return {...state,
          canCreate: action.canCreate,
          canEdit: action.canEdit,
          canDelete: action.canDelete,
          ownerId: action.ownerId,
          employees: this.sortEmployees(action.employees),
          filteredEmployees: this.sortEmployees(action.filteredEmployees)
        }
      case EmployeesListResultActionTypes.EDIT_EMPLOYEE:
        return {...state, employeeEditId: action.id, isCreateEmployeeModalVisible: true}
      case EmployeesListResultActionTypes.CHANGE_IS_LOADING:
        return {...state, isLoading: action.value}
      case EmployeesListResultActionTypes.CHANGE_SEARCH_FIELD:
        return {...state,
          searchFieldValue: action.value,
          filteredEmployees: this.sortEmployees(action.filteredEmployees)
        }
      case EmployeesListResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY:
        return {...state,
          isDeleteAlertVisible: action.value,
          employeeDeleteProps: action.deleteProps ? action.deleteProps : null,
        }
      case EmployeesListResultActionTypes.CHANGE_IS_DELETING:
        return {...state, isDeleting: action.value}
    }
  }
  sortEmployees(spaces: EmployeeSummeryEntity[]): EmployeeSummeryEntity[] {
    return spaces.sort((a, b) => {
      return (a.surname + a.name + (a.patronymic ? a.patronymic: '')).toLowerCase() > (b.surname + b.name + (b.patronymic ? b.patronymic: '')).toLowerCase() ? 1 : -1;
    })
  }
}
