import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LevelEntity } from '../../presentation/state/create-rating-scale-state';

@Component({
  selector: 'app-level-field',
  templateUrl: './level-field.component.html',
})
export class LevelFieldComponent {
  @Input() level!: LevelEntity;

  @Output() onChangeName: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChangeValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() onRemove: EventEmitter<void> = new EventEmitter<void>();

  onRemoveLevel(event: MouseEvent) {
    event.stopPropagation();
    this.onRemove.emit();
  }
}
