
export const stringToRgb = (str: string) => {
  if(str != '')
  {
    let hash = 0;
    str.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let result: number = Math.abs(hash) % colorsRGB.length

    return colorsRGB[result]
  }
  return '129, 129, 129'
}

const colorsRGB = [
  '0, 87, 155',
  '3, 137, 123',
  '186, 104, 200',
  '20, 203, 168',
  '236, 102, 35'
]
