import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-path-line',
  templateUrl: './path-line.component.html',
})
export class PathLineComponent {
  @Input() pathEntities: PathEntity[] = [];
  @Input() styles: string | string[] = ''
  @Input() isClickable: boolean = true
  @Output() elementClick = new EventEmitter<string>();

  pathClicked(id:string)
  {
    if(this.isClickable)
    {
      this.elementClick.emit(id)
    }
  }
}

export type PathEntity = {
  id: string;
  text: string;
};
