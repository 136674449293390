import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from 'src/app/core/components/components.module';
import {
  SvgAddFolder,
  SvgCompetency,
  SvgSkill,
} from 'src/app/core/components/svg-components/svg.components';
import { TreeOfCompetenciesAndSkillsComponent } from '../@components/tree-of-competencies-and-skills/tree-of-competencies-and-skills.component';
import { CreateSkillModule } from '../create-skill/create-skill.module';
import { CompetenciesAndSkillsExecutor } from './presentation/state/competencies-and-skills-executor';
import { CompetenciesAndSkillsReducer } from './presentation/state/competencies-and-skills-reducer';
import { CompetenciesAndSkillsState } from './presentation/state/competencies-and-skills-state';
import { CompetenciesAndSkillsComponent } from './presentation/view/competencies-and-skills.component';

@NgModule({
  providers: [
    CompetenciesAndSkillsState,
    CompetenciesAndSkillsReducer,
    CompetenciesAndSkillsExecutor,
  ],
  declarations: [CompetenciesAndSkillsComponent],
  exports: [CompetenciesAndSkillsComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    CreateSkillModule,
    TreeOfCompetenciesAndSkillsComponent,
    SvgAddFolder,
    SvgCompetency,
    SvgSkill,
  ],
})
export class CompetenciesAndSkillsModule {}
