<div class="absolute right-[20px] top-[60px] z-50">
  <div
    class="flex flex-col mb-[20px]"
    *ngFor="let toast of toasts; let i = index"
  >
    <div *ngIf="i <= 4">
      <div
        class="flex gap-[16px] px-[16px] py-[12px] rounded-[8px] bg-tt-toast-background max-w-[320px]"
      >
        <svg-success *ngIf="isSuccess(toast.state)" class="min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px] text-tt-success-icon" />
        <svg-error *ngIf="isError(toast.state)"  class="min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px] text-tt-error-icon" />

        <div class="text-tt-toast-text flex-grow mt-[2px]">
          <p class="text-tt-font-size_l">{{ toast.title }}</p>
          <p *ngIf="toast.description != ''" class="text-tt-font-size_m mt-[10px]">{{ toast.description }}</p>
        </div>

      </div>
    </div>
  </div>
</div>
