<div (click)="performAction({ type: CreateSkillActionTypes.OPEN_MODAL })">
  <ng-content />
</div>

<app-core-modal
  [open]="state.isOpen"
  className="h-[700px] w-full max-w-[900px] flex flex-row"
>
  <app-core-clue
    class="flex-shrink-0 py-tt_l pl-tt_l"
    title="Создайте навык"
    [step]="{ current: state.currentStep, total: 2 }"
  >
    <div class="flex flex-col gap-tt_m">
      <span>
        Базовое знания или умение необходимое для выполнения профессиональных
        задач
      </span>
      <span>Как назвать? - Чем проще, тем лучше</span>
      <div class="flex flex-row gap-tt_m items-center">
        <span class="text-tt-icon-success"><svg-checkmark-circle /></span>
        <span>Дисциплина</span>
      </div>
      <div class="flex flex-row gap-tt_m items-center">
        <span class="text-tt-icon-failure"><svg-xmark-circle /></span>
        <span>Правила поведения личности</span>
      </div>
    </div>
  </app-core-clue>

  <app-core-modal-content
    *ngIf="state.currentStep === 1"
    class="flex-grow"
    [footer]="true"
  >
    <div class="flex flex-col gap-tt_l">
      <app-core-input-field
        label="Название"
        variant="standard"
        [required]="true"
        [defaultValue]="state.name"
        [error]="state.nameError"
        (onChange)="
          performAction({
            type: CreateSkillActionTypes.CHANGE_NAME,
            name: $event
          })
        "
      />
      <app-core-autocomplete-field
        label="Шкала"
        placeholder="Выберете шкалу оценки"
        variant="standard"
        [required]="true"
        [error]="state.selectedRatingScaleError"
        [items]="getRatingScalesItems()"
        [selectedItem]="getSelectedRatingScale()"
        (onUnselect)="
          performAction({
            type: CreateSkillActionTypes.CHANGE_RATING_SCALE,
            ratingScaleId: null
          })
        "
        (onSelect)="
          performAction({
            type: CreateSkillActionTypes.CHANGE_RATING_SCALE,
            ratingScaleId: $event
          })
        "
      />
      <app-core-textarea-field
        label="Описание"
        placeholder="Добавьте описание"
        variant="standard"
        [defaultValue]="state.description"
        (onChange)="
          performAction({
            type: CreateSkillActionTypes.CHANGE_DESCRIPTION,
            description: $event
          })
        "
      />
    </div>
    <app-core-modal-content-footer>
      <app-core-button
        (onClick)="performAction({ type: CreateSkillActionTypes.CLOSE_MODAL })"
        variant="soft"
      >
        Отмена
      </app-core-button>
      <app-core-button
        (onClick)="
          performAction({
            type: CreateSkillActionTypes.CHANGE_CURRENT_STEP,
            step: 2
          })
        "
      >
        Продолжить
      </app-core-button>
    </app-core-modal-content-footer>
  </app-core-modal-content>

  <app-core-modal-content
    *ngIf="state.currentStep === 2"
    class="flex-grow"
    [footer]="true"
  >
    <div class="flex flex-col gap-tt_m" *ngIf="state.selectedRatingScale">
      <app-core-textarea-field
        *ngFor="
          let level of state.selectedRatingScale.levels;
          trackBy: levelTrackByFn
        "
        variant="standard"
        placeholder="Добавьте описание"
        [label]="level.name"
        [defaultValue]="level.description"
        [error]="level.descriptionError"
        (onChange)="
          performAction({
            type: CreateSkillActionTypes.CHANGE_LEVEL_DESCRIPTION,
            levelId: level.id,
            levelDescription: $event
          })
        "
      />
    </div>
    <app-core-modal-content-footer>
      <app-core-button
        [disabled]="this.state.isLoading"
        (onClick)="
          performAction({
            type: CreateSkillActionTypes.CHANGE_CURRENT_STEP,
            step: 1
          })
        "
        variant="soft"
      >
        Назад
      </app-core-button>
      <app-core-button
        [loading]="this.state.isLoading"
        (onClick)="performAction({ type: CreateSkillActionTypes.SAVE_SKILL })"
      >
        Сохранить
      </app-core-button>
    </app-core-modal-content-footer>
  </app-core-modal-content>
</app-core-modal>
