<div class="flex w-full justify-center py-[44px]">

  <div class="max-w-[1256px] w-full rounded-tt-border-radius_m overflow-hidden border-[1px] border-tt-border-default bg-tt-light-blue">
    <div class="w-full flex px-[16px] py-[10px] gap-[16px]">
      <app-core-search-field class="w-full"
        placeholder="Поиск по ФИО или email"
        (onChange)="performAction({
          type: EmployeesActionTypes.CHANGE_SEARCH_FIELD,
          value: $event
        })"
      />
      <app-core-button
        *ngIf="state.canCreate"
        (onClick)="performAction({
          type: EmployeesActionTypes.CHANGE_CREATE_EMPLOYEE_MODAL_VISIBLE,
          value: true
        })"
      >
        Новый сотрудник
      </app-core-button>
    </div>
    <table class="w-full table-fixed">
      <colgroup
        *ngIf="windowWidth < 768"
      >
        <col span="1" style="width: calc(100% - 80px);">
        <col span="1" style="width: 80px;">
      </colgroup>
      <colgroup
        *ngIf="windowWidth >= 768 && windowWidth < 1024"
      >
        <col span="1" style="width: 70%;">
        <col span="1" style="width: 30%;">
        <col span="1" style="width: 80px;">
      </colgroup>
      <colgroup
        *ngIf="windowWidth >= 1024"
      >
        <col span="1" style="width: 50%;">
        <col span="1" style="width: 30%;">
        <col span="1" style="width: 20%;">
        <col span="1" style="width: 80px;">
      </colgroup>
      <thead class="bg-tt-light-blue h-[33px]">
        <tr>
          <th class="pl-[72px] table-column-header-text pr-[16px] truncate">Имя пользователя</th>
          <th
            class="table-column-header-text pr-[16px] truncate"
            *ngIf="windowWidth >= 768"
          >Email</th>
          <th
            class="table-column-header-text pr-[16px] truncate"
            *ngIf="windowWidth >= 1024"
          >Номер телефона</th>
          <th class="table-column-header-text text-right pr-[20px] truncate">
            Действия
          </th>
        </tr>
      </thead>
      <tbody class="bg-white"
        *ngIf="!state.isLoading"
      >
        <tr
          class="group h-[60px] items-center hover:bg-tt-light-blue"
          *ngFor="let employee of state.filteredEmployees"
          employeeTr
          [canEdit]="state.canEdit"
          [canDelete]="state.canDelete && employee.id != state.ownerId"
          [windowWidth]="windowWidth"
          [employee]="employee"
          (editClicked)="performAction({
            type: EmployeesActionTypes.EDIT_EMPLOYEE,
            id: employee.id
          })"
          (deleteClicked)="performAction({
            type: EmployeesActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
            value: true,
            deleteProps: {id: employee.id, name: employee.name}
          })"
        >
        </tr>
      </tbody>
    </table>
    <div class="w-full flex justify-center p-[24px] bg-white"
      *ngIf="state.isLoading"
    >
      <mat-spinner class="w-[48px] h-[48px]"></mat-spinner>
    </div>
    <app-core-image-stub
      class="bg-white"
      *ngIf="!state.isLoading && state.filteredEmployees.length == 0"
      [imageSrc]="''"
      [title]="'Не нашли совпадений'"
      [description]="'Попробуйте изменить параметры поиска'"
    />
  </div>
</div>

<employees-create-employee-modal
  *ngIf="state.isCreateEmployeeModalVisible"
  [isVisible]="state.isCreateEmployeeModalVisible"
  [editEmployeeId]="state.employeeEditId"
  [canDelete]="state.canDelete && state.employeeEditId != state.ownerId"
  (closeClicked)="performAction({
    type: EmployeesActionTypes.CHANGE_CREATE_EMPLOYEE_MODAL_VISIBLE,
    value: false
  })"
  (deleteClicked)="performAction({
    type: EmployeesActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
    value: true,
    deleteProps: $event
  })"
/>

<app-core-alert-modal
  *ngIf="state.isDeleteAlertVisible"
  [title]="'Удалить сотрудника'"
  [description]="'После удаления сотрудника он удаляется навсегда. Сотрудник не может быть востановлен.'"
  type="warning"
  [cancelButtonText]="'Отменить'"
  [confirmButtonText]="'Удалить'"
  (onConfirmClicked)="performAction({
    type: EmployeesListActionTypes.DELETE_EMPLOYEE
  })"
  (onCancelClicked)="performAction({
    type: EmployeesListActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
    value: false
  })"
>
  <p>Сотрудник “<span class="font-medium">{{state.employeeDeleteProps?.name}}</span>” будет удален. Вы уверены, что хотите удалить этого сотрудника?</p>

</app-core-alert-modal>
