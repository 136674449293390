import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { SvgXMark } from 'src/app/core/components/svg-components/svg.components';
import { EmptyRule, IntRule, MinIntRule } from 'src/app/core/validators/rule';
import { Validator } from 'src/app/core/validators/validator';
import { LevelFieldComponent } from './components/level-field/level-field.component';
import { CreateRatingScaleComponent } from './presentation/view/create-rating-scale.component';

@NgModule({
  declarations: [CreateRatingScaleComponent, LevelFieldComponent],
  exports: [CreateRatingScaleComponent],
  providers: [
    {
      provide: 'CreateRatingScaleNameValidator',
      useExisting: Validator,
      useFactory: CrateRatingScaleModule.nameValidatorFactory,
    },
    {
      provide: 'CreateRatingScaleLevelDescriptionValidator',
      useExisting: Validator,
      useFactory: CrateRatingScaleModule.levelDescriptionValidatorFactory,
    },
    {
      provide: 'CreateRatingScaleLevelPointsValidator',
      useExisting: Validator,
      useFactory: CrateRatingScaleModule.levelPointsValidatorFactory,
    },
  ],
  imports: [CommonModule, ComponentsModule, SvgXMark],
})
export class CrateRatingScaleModule {
  public static nameValidatorFactory = () =>
    new Validator([new EmptyRule('Введите название оценочной шкалы')]);

  public static levelDescriptionValidatorFactory = () =>
    new Validator([new EmptyRule('Введите описание уровня')]);

  public static levelPointsValidatorFactory = () =>
    new Validator([
      new IntRule('Введите целое число'),
      new MinIntRule('Только положительные числа', 0),
    ]);
}
