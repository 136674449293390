import { Injectable } from '@angular/core';
import {
  SectionPrivacy,
  SectionSummaryEntity,
} from '../domain/section-summary-entity';
import { SectionsService } from '../domain/sections-service';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SectionsResponseJson } from './json/sections-response-json';
import { environment } from '../../../../../../environments/environment';
import { errorToResponseStatus, ResponseStatus } from "../../../../../core/utils/response-status";
import { CreateSectionResponse } from '../domain/create-section-response';
import { Constants } from '../presentation/common/сonstants';
import { SectionCreateResponceJson } from "./json/section-create-responce-json";

@Injectable()
export class SectionsServiceImpl implements SectionsService {
  private mutableSections: BehaviorSubject<Array<SectionSummaryEntity>> =
    new BehaviorSubject<Array<SectionSummaryEntity>>([]);

  sections: Observable<Array<SectionSummaryEntity>> =
    this.mutableSections.asObservable();

  constructor(private http: HttpClient) {}

  fetchSections() {
    this.http
      .get<SectionsResponseJson>(`${environment.apiUrl}/documentation/sections`)
      .pipe(
        map((sectionResponse) => {
          const listSection: Array<SectionSummaryEntity> =
            sectionResponse.sections
              .map((section) => {
                return new SectionSummaryEntity(
                  section.id,
                  section.title,
                  section.thumbnailUrl,
                  section.isFavorite,
                );
              })
              .sort((sectionA, sectionB) => {
                if (sectionA.title > sectionB.title) {
                  return 1;
                }

                if (sectionA.title < sectionB.title) {
                  return -1;
                }

                return 0;
              });
          return listSection;
        }),
      )
      .subscribe((sections) => {
        this.mutableSections.next(sections);
      });
  }

  createSection(
    section: SectionSummaryEntity,
    sectionVisibility: SectionPrivacy,
  ): Observable<CreateSectionResponse> {
    const body = {
      title: section.title,
      thumbnailUrl: section.url,
      sectionVisibility: sectionVisibility,
    };

    return this.http
      .post<SectionCreateResponceJson>(`${environment.apiUrl}/documentation/section/create`, body)
      .pipe(
        map((value) => {
          return new CreateSectionResponse(ResponseStatus.SUCCESS, value.sectionId);
        }),
        catchError((err) => {
          return of(
            new CreateSectionResponse(
              errorToResponseStatus(err),
              Constants.NO_ID,
            ),
          );
        }),
      );
  }

  updateIsFavoriteSection(
    sectionId: number,
    isFavorite: boolean,
  ): Observable<ResponseStatus> {
    const body = {
      isFavorite: isFavorite,
      sectionId: sectionId,
    };

    return this.http
      .post<any>(
        `${environment.apiUrl}/documentation/section/updateFavorite`,
        body,
      )
      .pipe(
        map(() => {
          return ResponseStatus.SUCCESS;
        }),
        catchError((err) => {
          return of(errorToResponseStatus(err));
        }),
      );
  }

  deleteSection(sectionId: number) {
    this.http
      .delete(`${environment.apiUrl}/documentation/section/${sectionId}`)
      .subscribe((_) => {
        this.fetchSections();
      });
  }

  getIcons(): Observable<string[]> {
    this.http
      .get<string[]>(`${environment.apiUrl}/documentation/sections/icons`)
      .subscribe((iconUrls) => {
        return iconUrls;
      });
    return of([]);
  }
}
