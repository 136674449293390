import { Executor, Reducer } from '../../../../../../../../core/mvi/store';
import { SectionSettingsMainState } from './section-settings-main-state';
import {
  SectionSettingsMainAction,
  SectionSettingsMainActionTypes,
} from './section-settings-main-action';
import {
  SectionSettingsMainResultAction,
  SectionSettingsResultActionTypes,
} from './section-settings-main-result-action';
import { Injectable } from '@angular/core';
import { clone } from 'cloneable-ts';
import { Constants } from '../common/constants';
import { SectionSettingsService } from '../../../../domain/section-settings-service';
import { ActivatedRoute } from '@angular/router';
import { DocumentationService } from "../../../../../../data/documentation-service";

@Injectable()
export class SectionSettingsMainExecutor extends Executor<
  SectionSettingsMainState,
  SectionSettingsMainAction,
  SectionSettingsMainResultAction
> {
  constructor(
    private sectionSettingsService: SectionSettingsService,
    private documentationService: DocumentationService,
    private route: ActivatedRoute,
  ) {
    super();
  }

  override init(
    reducer: Reducer<SectionSettingsMainState, SectionSettingsMainResultAction>,
    getState: () => SectionSettingsMainState,
    onReduced: (state: SectionSettingsMainState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    this.sectionSettingsService.getSection().subscribe((section) => {
      this.reduce({
        type: SectionSettingsResultActionTypes.UPDATE_SECTION,
        section: section,
      });
    });
    this.documentationService.getIcons().subscribe((icons)=>{
      this.reduce({
        type: SectionSettingsResultActionTypes.UPDATE_ICONS,
        iconUrls: icons
      });
    })

    this.route.parent?.paramMap.subscribe((params: any) => {
      const sectionId = +params.get('sectionId');
      this.sectionSettingsService.fetchSection(sectionId)
    });
  }

  execute(action: SectionSettingsMainAction) {
    switch (action.type) {
      case SectionSettingsMainActionTypes.CHANGE_TITLE:
        this.reduce({
          type: SectionSettingsResultActionTypes.CHANGE_CURRENT_SECTION,
          section: clone(this.getState().currentSectionData, {
            title: action.title,
          }),
          titleError:
            action.title.length != 0 ? '' : Constants.EMPTY_TITLE_ERROR,
        });
        break;
      case SectionSettingsMainActionTypes.CHANGE_ICON:
        this.reduce({
          type: SectionSettingsResultActionTypes.CHANGE_CURRENT_SECTION,
          section: clone(this.getState().currentSectionData, {
            icon: action.iconUrl,
          }),
          titleError: '',
        });
        break;
      case SectionSettingsMainActionTypes.CHANGE_OWNER:
        this.reduce({
          type: SectionSettingsResultActionTypes.CHANGE_CURRENT_SECTION,
          section: clone(this.getState().currentSectionData, {
            owner: Constants.OWNER_FIELD_VALUES.find((val) => {
              return val.id == action.ownerId;
            }),
          }),
          titleError: '',
        });
        break;
      case SectionSettingsMainActionTypes.CHANGE_PRIVACY:
        this.reduce({
          type: SectionSettingsResultActionTypes.CHANGE_CURRENT_SECTION,
          section: clone(this.getState().currentSectionData, {
            privacy: Constants.PRIVACY_FIELD_VALUES.find((val) => {
              return val.id == action.privacyId;
            }),
          }),
          titleError: '',
        });
        break;
      case SectionSettingsMainActionTypes.SAVE_CHANGES:
        this.sectionSettingsService
          .updateSection(this.getState().currentSectionData)
          .subscribe(() => {
            if (this.getState().currentSectionData.id) {
              this.sectionSettingsService.fetchSection(this.getState().currentSectionData.id);
            }
          });
        break;
      case SectionSettingsMainActionTypes.DROP_CHANGES:
        this.reduce({
          type: SectionSettingsResultActionTypes.CHANGE_CURRENT_SECTION,
          section: clone(this.getState().baseSectionData),
          titleError: '',
        });
        break;
    }
  }
}
