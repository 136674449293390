import { FilterParams } from './sections-state';

export type SectionsAction =
  | ChangeFilterParamsAction
  | UpdateSectionListAction
  | ChangeCreateSectionModalVisibilityAction
  | ChangeSectionMarkAction
  | MoveToSectionAction
  | CreateSectionAction
  | ChangeSearchFieldAction
  | ChangeCreateSectionTitleAction
  | ChangeCreateSectionIconUrlAction
  | ChangeCreateSectionPrivacyAction
  | ChangeSortParamsAction
  | DeleteSectionAction
  | EditSectionAction
  | CopySectionUrlAction;

export enum SectionsActionTypes {
  SELECT_FILTER_PARAMS,
  SELECT_SORT_PARAMS,
  UPDATE_SECTIONS_LIST,
  CHANGE_CREATE_SECTION_MODAL_VISIBILITY,
  CHANGE_SECTION_MARK,
  MOVE_TO_SECTION,
  CREATE_SECTION,
  EDIT_SECTION,
  DELETE_SECTION,
  SEARCH_FIELD_CHANGE,
  CHANGE_CREATE_SECTION_TITLE,
  CHANGE_CREATE_SECTION_ICON,
  CHANGE_CREATE_SECTION_PRIVACY,
  COPY_SECTION_URL
}

export interface CopySectionUrlAction {
  readonly type: SectionsActionTypes.COPY_SECTION_URL;
  readonly sectionId: number;
}

export interface EditSectionAction {
  readonly type: SectionsActionTypes.EDIT_SECTION;
  readonly sectionId: number;
}

export interface ChangeCreateSectionTitleAction {
  readonly type: SectionsActionTypes.CHANGE_CREATE_SECTION_TITLE;
  readonly title: string;
}

export interface ChangeCreateSectionIconUrlAction {
  readonly type: SectionsActionTypes.CHANGE_CREATE_SECTION_ICON;
  readonly iconUrl: string;
}

export interface ChangeCreateSectionPrivacyAction {
  readonly type: SectionsActionTypes.CHANGE_CREATE_SECTION_PRIVACY;
  readonly privacy: string;
}

export interface ChangeFilterParamsAction {
  readonly type: SectionsActionTypes.SELECT_FILTER_PARAMS;
  readonly param: FilterParams;
}

export interface ChangeSortParamsAction {
  readonly type: SectionsActionTypes.SELECT_SORT_PARAMS;
  readonly sortParamId: string;
}

export interface UpdateSectionListAction {
  readonly type: SectionsActionTypes.UPDATE_SECTIONS_LIST;
}

export interface ChangeCreateSectionModalVisibilityAction {
  readonly type: SectionsActionTypes.CHANGE_CREATE_SECTION_MODAL_VISIBILITY;
  readonly visibility: boolean;
}

export interface ChangeSectionMarkAction {
  readonly type: SectionsActionTypes.CHANGE_SECTION_MARK;
  readonly sectionId: number;
}

export interface MoveToSectionAction {
  readonly type: SectionsActionTypes.MOVE_TO_SECTION;
  readonly sectionId: number;
}

export interface CreateSectionAction {
  readonly type: SectionsActionTypes.CREATE_SECTION;
}

export interface DeleteSectionAction {
  readonly type: SectionsActionTypes.DELETE_SECTION;
  readonly sectionId: number;
}

export interface ChangeSearchFieldAction {
  readonly type: SectionsActionTypes.SEARCH_FIELD_CHANGE;
  readonly value: string;
}
