import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  alreadyLoginGuardFunction,
  loginGuardFunction
} from "./features/authorization/presentation/guard/auth-guard";
import { AuthorizationComponent } from './features/authorization/presentation/view/authorization.component';
import { MainComponent } from './features/main/presentation/main.component';
import { NewPasswordComponent } from './features/new-password/presentation/view/new-password.component';
import { RegistrationComponent } from './features/registration/presentation/view/registration.component';
import { ResetPasswordComponent } from './features/reset-password/presentation/view/reset-password.component';
import { DocumentComponent } from "./features/document/impl/presentation/view/document.component";
import { MAIN_ROUTES } from './routes/main-routes';

export enum AppPaths{
  DOCUMENT = 'document',
  LOGIN = 'login',
  SIGNUP = 'signup',
  RESET_PASSWORD = 'reset-password',
  NEW_PASSWORD = 'new-password'
}

const appRoutes: Routes = [
  {
    path: AppPaths.LOGIN,
    component: AuthorizationComponent,
    canActivate: [alreadyLoginGuardFunction],
  },
  {
    path: AppPaths.SIGNUP,
    component: RegistrationComponent,
    canActivate: [alreadyLoginGuardFunction],
  },
  {
    path: AppPaths.RESET_PASSWORD,
    component: ResetPasswordComponent,
    canActivate: [alreadyLoginGuardFunction],
  },
  {
    path: AppPaths.NEW_PASSWORD,
    component: NewPasswordComponent,
    canActivate: [alreadyLoginGuardFunction],
  },
  {
    path: AppPaths.DOCUMENT + '/:documentId',
    component: DocumentComponent,
  },
  {
    path: '',
    component: MainComponent,
    children: MAIN_ROUTES,
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
