import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { HierarchyDocumentEntity } from "../../../domain/hierarchy-document-entity";
import {
  ActionElementStyle,
  ActionSection
} from "../../../../../../../core/components/action-menu/action-menu.component";

enum PopupId {ADD='add', EDIT='edit', LINK='link', DELETE='delete'}

const POPUP_SECTIONS: ActionSection[] = [
  {
    elements: [
      {
        id: PopupId.ADD,
        name: 'Добавить',
        icon: 'assets/popup-plus.svg'
      },
      {
        id: PopupId.EDIT,
        name: 'Редактировать',
        icon: 'assets/popup-pencil.svg'
      },
      {
        id: PopupId.LINK,
        name: 'Ссылка',
        icon: 'assets/popup-link.svg'
      },
      {
        id: PopupId.DELETE,
        name: 'Удалить',
        icon: 'assets/trash-red.svg',
        type: ActionElementStyle.DANGER
      },
    ]
  }
]

@Component({
  selector: 'app-document-item',
  templateUrl: './document-item.component.html',
  styleUrls: ['../../common/section.component.scss'],
})
export class DocumentItemComponent {
  @Input() public document: HierarchyDocumentEntity = {
    id: '-1',
    title: 'Not found document',
    isDraft: false,
    documents: [],
  };

  @Input() public openDocuments: Array<string> = new Array<string>();

  @Input() public level: number = 0;

  @Input() public idActiveDocument: string = '-1';

  @Output() arrowClicked = new EventEmitter<string>();

  @Output() createDocumentClicked = new EventEmitter<string>();
  @Output() openDocumentClicked = new EventEmitter<string>();
  @Output() editDocumentClicked = new EventEmitter<string>();
  @Output() linkDocumentClicked = new EventEmitter<string>();
  @Output() deleteDocumentClicked = new EventEmitter<string>();

  @ViewChild('popup') popupBlock!: ElementRef

  private levelStep = 32;
  public blueLineWidth = 3;

  public isPopupVisible: boolean = false;
  public isActive: boolean = false;
  public isOpen: boolean = false;

  ngOnChanges() {
    this.isActive = this.idActiveDocument == this.document.id;
    this.isOpen = this.isOpenCheck();
  }

  isOpenCheck(): boolean {
    return (
      this.openDocuments.find((id) => {
        return id == this.document.id;
      }) != undefined
    );
  }

  onArrowClicked(documentId: string): void {
    this.arrowClicked.emit(documentId);
  }

  onCreateDocumentClicked(parentDocumentId: string): void {
    this.createDocumentClicked.emit(parentDocumentId);
  }

  onOpenDocumentClicked(documentId: string, mouseEvent?: MouseEvent): void {
    if(mouseEvent)
    {
      if(!this.popupBlock.nativeElement.contains(mouseEvent.target))
      {
        this.openDocumentClicked.emit(documentId);
      }
    }
    else {
      this.openDocumentClicked.emit(documentId);
    }
  }
  getMarginLeft(): string {
    return this.level * this.levelStep + 'px';
  }

  popupAction(id: string){
    switch (id){
      case PopupId.ADD:
        this.createDocumentClicked.emit(this.document.id)
        break;
      case PopupId.EDIT:
        this.editDocumentClicked.emit(this.document.id)
        break;
      case PopupId.LINK:
        this.linkDocumentClicked.emit(this.document.id)
        break;
      case PopupId.DELETE:
        this.deleteDocumentClicked.emit(this.document.id)
        break;
    }
    this.isPopupVisible = false
  }

  protected readonly POPUP_SECTIONS = POPUP_SECTIONS;
}
