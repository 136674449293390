export type SectionSettingsMainAction =
  | ChangeTitleAction
  | ChangeOwnerAction
  | ChangePrivacyAction
  | ChangeIconAction
  | SaveChangesAction
  | DropChangesAction;
export enum SectionSettingsMainActionTypes {
  CHANGE_TITLE,
  CHANGE_OWNER,
  CHANGE_PRIVACY,
  CHANGE_ICON,
  SAVE_CHANGES,
  DROP_CHANGES,
}

export interface ChangeTitleAction {
  readonly type: SectionSettingsMainActionTypes.CHANGE_TITLE;
  readonly title: string;
}

export interface ChangeOwnerAction {
  readonly type: SectionSettingsMainActionTypes.CHANGE_OWNER;
  readonly ownerId: string;
}

export interface ChangePrivacyAction {
  readonly type: SectionSettingsMainActionTypes.CHANGE_PRIVACY;
  readonly privacyId: string;
}

export interface ChangeIconAction {
  readonly type: SectionSettingsMainActionTypes.CHANGE_ICON;
  readonly iconUrl: string;
}

export interface SaveChangesAction {
  readonly type: SectionSettingsMainActionTypes.SAVE_CHANGES;
}
export interface DropChangesAction {
  readonly type: SectionSettingsMainActionTypes.DROP_CHANGES;
}
