import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, ViewChild
} from "@angular/core";
import { string } from "slate";

@Component({
  selector: 'app-core-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() className: string | string[] = [];
  @Input() textStyle: string | string[] = []
  @Input() placeholder?: string;
  @Input() label?: string;
  @Input() mask?: string;
  @Input() prefix = '';
  @Input() readonly: boolean = false
  @Input() showMaskTyped = false;
  @Input() shownMaskExpression?: string;
  @Input() defaultValue = '';
  @Input() type?: string;
  @Input() autocomplete?: string;
  @Input() autoFocus: boolean = false;
  @Input() error?: string | null;
  @Input() showError = true;
  @Input() required = false;
  @Input() charsCounterMax: number = 0
  @Input() variant: 'outlined' | 'standard' | 'borderless' = 'outlined';
  @Input() textAlign: 'start' | 'end' | 'center' | 'justify' = 'start';
  @Input() errorShowType: 'onUnFocused' | 'onChange' = 'onChange';
  @Input() hidePlaceholderOnFocus = false;
  @Input() value: string | null = null;

  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onFocused: EventEmitter<string> = new EventEmitter<string>();
  @Output() onUnFocused: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('inputField') inputFieldEl!: ElementRef;

  countChars: number = 0
  currentType?: string
  isPasswordVisible: boolean = false

  onChangeValue(event: any) {
    if (this.onChange) {
      this.localValue = event.target.value;
      this.countChars = this.localValue.length
      this.onChange.emit(this.localValue);
    }
  }

  localValue: string = '';

  focused?: boolean;

  onFocus() {
    this.focused = true;
    this.localValue = this.localValue == '' && this.prefix == '+' ? '7': this.localValue
    this.onFocused.emit(this.localValue)
  }

  onUnFocus() {
    this.focused = false;
    this.onUnFocused.emit(this.localValue)
  }

  ngOnInit() {
    this.localValue = this.defaultValue;
    this.countChars = this.localValue.length
  }

  changePasswordVisible() {
    if(this.type == 'password')
    {
      this.isPasswordVisible = !this.isPasswordVisible
      this.currentType = this.isPasswordVisible ? 'text' : 'password'
    }
  }

  ngAfterViewInit(): void {
    if(this.autoFocus){
      this.inputFieldEl.nativeElement.focus()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value'] && !changes['value'].firstChange) {
      this.localValue = this.value ?? '';
      this.countChars = this.localValue.length
    }
    if (changes['type'] && this.type != undefined){
      this.currentType = this.type
    }
  }
}
