import { Executor } from "src/app/core/mvi/store";
import { RoleAction, RoleActionTypes } from "./role-action";
import { RoleResultAction, RoleResultActionTypes } from "./role-result-action";
import { Injectable } from "@angular/core";
import { RoleNavItems, RoleState } from "./role-state";
import { RoleService } from "src/app/core/services/role-service/role-service";
import { ActivatedRoute, Router } from "@angular/router";
import { EmployeesConstants } from "../../../employees/common/employees-constants";
import { MainRoutesPaths } from "../../../../routes/main-routes";
import { EmployeesRoutesPath } from "../../../../routes/employees-routes";
import { ToastsService } from "../../../../core/components/toast-alert/services/toast-alert.service";

@Injectable()
export class RoleExecutor extends Executor<
  RoleState,
  RoleAction,
  RoleResultAction
> {
  constructor(
    private roleService: RoleService,
    private route: ActivatedRoute,
    private router: Router,
    private toastsService: ToastsService,
  ) {
    super();
  }

  execute(action: RoleAction) {
    switch (action.type) {
      case RoleActionTypes.INIT:
        this.handleInit()
        break
      case RoleActionTypes.CHANGE_NAV_ITEM:
        this.handleChangeNavItem(action.navItem)
        break
      case RoleActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY:
        this.reduce({
          type: RoleResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY,
          value: action.value
        })
        break
      case RoleActionTypes.DELETE:
        if(this.getState().isCustom){
          this.reduce({
            type: RoleResultActionTypes.CHANGE_IS_DELETING,
            value: true
          })
          this.roleService.deleteRole(this.getState().id).subscribe(()=>{
            this.reduce({
              type: RoleResultActionTypes.CHANGE_IS_DELETING,
              value: false
            })
            this.router.navigateByUrl(`/${MainRoutesPaths.EMPLOYEES}/${EmployeesRoutesPath.ROLES}`)
          })
        }
        break
      case RoleActionTypes.SAVE_MAIN_DATA:
        this.roleService.updateRole(this.getState().id, {
          name: action.data.name.trim().split(' ').filter((str) => str.length > 0).join(' '),
          description: action.data.description.trim().split(' ').filter((str) => str.length > 0).join(' '),
          permissions: this.getState().permissions,
        }).subscribe(() => {
            this.reduce({
              type: RoleResultActionTypes.SAVE_MAIN_DATA,
              mainData: action.data,
            })
          }
        )
        break
      case RoleActionTypes.SAVE_PERMISSIONS:
        this.roleService.updateRole(this.getState().id, {
          name: this.getState().mainData.name,
          description: this.getState().mainData.description,
          permissions: action.permissions,
        }).subscribe(() => {
            this.reduce({
              type: RoleResultActionTypes.SAVE_PERMISSIONS,
              permissions: action.permissions
            });
          }
        )
        break
    }
  }

  handleInit(){
    const id = this.route.snapshot.paramMap.get('id')
    if(id){
      this.reduce({
        type: RoleResultActionTypes.CHANGE_IS_LOADING,
        value: true
      })
      this.roleService.getRole(id).subscribe((role)=>{
        const newMainData = {
          name: role.name,
          description: role.description,
        }
        if(!role.isCustom)
        {
          const baseRole = EmployeesConstants.BASE_ROLES.get(role.name)
          if(baseRole)
          {
            newMainData.name = baseRole.name;
            newMainData.description = baseRole.description;
          }
        }
        this.reduce({
          type: RoleResultActionTypes.UPDATE_ROLE,
          id: id,
          isCustom: role.isCustom,
          mainData: newMainData,
          permissions: role.permissions,
          canEdit: role.canEdit,
          canDelete: role.canDelete
        })

        this.reduce({
          type: RoleResultActionTypes.CHANGE_IS_LOADING,
          value: false
        })
      })
    }
  }

  handleChangeNavItem(navItemId: string){
    let navItem = RoleNavItems.MAIN
    switch(navItemId){
      case RoleNavItems.PERMISSIONS:
        navItem = RoleNavItems.PERMISSIONS
        break
      case RoleNavItems.USERS:
        navItem = RoleNavItems.USERS
        break
    }

    this.reduce({
      type: RoleResultActionTypes.CHANGE_NAV_ITEM,
      navItem: navItem
    })
  }
}
