import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  SvgBorderedArrow,
  SvgCompetency,
} from 'src/app/core/components/svg-components/svg.components';
import { CompetencyDto } from '../../../../@data/dto/competency.dto';
import { SkillItemComponent } from '../skill-item/skill-item.component';

@Component({
  standalone: true,
  imports: [CommonModule, SkillItemComponent, SvgBorderedArrow, SvgCompetency],
  selector: 'app-competency-item',
  templateUrl: './competency-item.component.html',
})
export class CompetencyItemComponent {
  @Input() depth: number = 0;
  @Input() competency!: CompetencyDto;
  @Input() opened = false;

  @Output() onOpenCompetency = new EventEmitter<string>();
  @Output() onCloseCompetency = new EventEmitter<string>();

  onToggle() {
    if (this.opened) {
      this.onCloseCompetency.emit(this.competency.id);
    } else {
      this.onOpenCompetency.emit(this.competency.id);
    }
  }

  @Output() onSelectCompetency = new EventEmitter<string>();
  @Output() onSelectSkill = new EventEmitter<string>();
}
