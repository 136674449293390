import { SectionEntity } from '../../domain/section-entity';
import { NavigationItem } from './section-state';
import { PathEntity } from '../../../../../../core/components/path-line/path-line.component';

export type SectionResultAction =
  | UpdateSectionAction
  | ChangeDocumentOpenStateAction
  | ChangeActiveNavItemAction;

export enum SectionResultActionTypes {
  UPDATE_SECTION,
  CHANGE_DOCUMENT_OPEN_STATE,
  CHANGE_ACTIVE_NAV_ITEM,
}

export interface UpdateSectionAction {
  readonly type: SectionResultActionTypes.UPDATE_SECTION;
  readonly section: SectionEntity;
}

export interface ChangeDocumentOpenStateAction {
  readonly type: SectionResultActionTypes.CHANGE_DOCUMENT_OPEN_STATE;
  readonly documentId: string;
}

export interface ChangeActiveNavItemAction {
  readonly type: SectionResultActionTypes.CHANGE_ACTIVE_NAV_ITEM;
  readonly navItem: NavigationItem;
  readonly pathEntities: PathEntity[];
  readonly documentId: string;
}
