<div class="child-routes-size px-[24px] tiny-scrollbar"
     (scroll)="onScroll()"
     #scrollable
>
  <div>
    <div class="w-full flex justify-between">
      <h1 class="font-medium text-[32px] leading-[48px]">{{ NewsConstants.NEWS }}</h1>
      <div class="relative"
      >
        <div
          class="absolute right-[48px] top-[29px]"
        >
          <app-core-button
            (onClick)="isCreate = true"
          >
            Создать новость
          </app-core-button>
        </div>

      </div>
    </div>
    <app-nav-tabs
      [tabs]="NewsConstants.NAV_TABS"
      navType="link"
    />
  </div>

  <router-outlet></router-outlet>
</div>

<app-news-creation-window
  *ngIf="isCreate"
  (backClicked)="isCreate = false"
/>
