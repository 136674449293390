import { Routes } from "@angular/router";
import { EmployeesListComponent } from "../features/employees/modules/employees-list/presentation/employees-list.component";
import { EmployeesRolesComponent } from "../features/employees/modules/employees-roles/presentation/employees-roles.component";

export enum EmployeesRoutesPath { LIST = 'list', ROLES = 'roles' }

export const EMPLOYEES_ROUTES: Routes = [
  {
    path: EmployeesRoutesPath.LIST,
    component: EmployeesListComponent,
  },
  {
    path: EmployeesRoutesPath.ROLES,
    component: EmployeesRolesComponent,
  },
];