import { Component } from '@angular/core';
import { Store } from 'src/app/core/mvi/store';
import {
  CreateSkillAction,
  CreateSkillActionTypes,
} from '../state/create-skill-action';
import { CreateSkillExecutor } from '../state/create-skill-executor';
import { CreateSkillReducer } from '../state/create-skill-reducer';
import { CreateSkillResultAction } from '../state/create-skill-result-action';
import { CreateSkillState } from '../state/create-skill-state';
import {
  AutocompleteItem
} from "../../../../../core/components/fields/autocomplete-field/autocomplete-field.component";
@Component({
  selector: 'app-create-skill',
  templateUrl: './create-skill.component.html',
})
export class CreateSkillComponent extends Store<
  CreateSkillState,
  CreateSkillExecutor,
  CreateSkillAction,
  CreateSkillResultAction
> {
  constructor(
    state: CreateSkillState,
    executor: CreateSkillExecutor,
    reducer: CreateSkillReducer,
  ) {
    super(state, executor, reducer);
  }

  protected readonly CreateSkillActionTypes = CreateSkillActionTypes;

  getRatingScalesItems(): AutocompleteItem[] {
    return this.state.ratingScales.map((ratingScale) => ({
      id: ratingScale.id,
      name: ratingScale.name,
    }));
  }

  getSelectedRatingScale(): AutocompleteItem | null {
    if (!this.state.selectedRatingScale) {
      return null;
    }

    return {
      id: this.state.selectedRatingScale?.id,
      name: this.state.selectedRatingScale?.name,
    };
  }

  levelTrackByFn(_: number, level: any) {
    return level.id;
  }
}
