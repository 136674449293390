import { CommonModule, NgOptimizedImage } from "@angular/common";
import { Injector, NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MessengerInputFieldComponent } from 'src/app/features/messenger/components/messenger-input/input-field.component';
import { MessengerModalComponent } from 'src/app/features/messenger/components/messenger-modal/messenger-modal.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { AvatarComponent } from './avatar/avatar.component';
import { BadgeComponent } from './badge/badge.component';
import { ButtonAddComponent } from './buttons/button-add/button-add.component';
import { ButtonIconComponent } from './buttons/button-icon/button-icon.component';
import { ButtonComponent } from './buttons/button/button.component';
import { CardComponent } from './card/card.component';
import { ClueComponent } from './modal-window/clue/clue.component';
import { DropdownFieldComponent } from './fields/dropdown-field/dropdown-field.component';
import { EditableTextComponent } from './fields/editable-text/editable-text.component';
import { FieldErrorMessageComponent } from './fields/field-error-message/field-error-message.component';
import { FilterMenuComponent } from './filter-menu/filter-menu.component';
import { ActionMenu } from './action-menu/action-menu.component';
import { InputFieldComponent } from './fields/input-field/input-field.component';
import { MainContentCardComponent } from './main-content-card/main-content-card.component';
import { ModalContentFooterComponent } from './modal-window/modal-content-footer/modal-content-footer.component';
import { ModalContentComponent } from './modal-window/modal-content/modal-content.component';
import { ModalComponent } from './modal-window/modal/modal.component';
import { MultiselectFieldComponent } from './fields/multiselect-field/multiselect-field.component';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { PageShadingComponent } from './page-shading/page-shading.component';
import { PathLineComponent } from './path-line/path-line.component';
import { PopupMenuButtonComponent } from './popup-menu/popup-menu-button/popup-menu-button.component';
import { PopupMenuContentComponent } from './popup-menu/popup-menu-content/popup-menu-content.component';
import { PopupMenuOptionComponent } from './popup-menu/popup-menu-option/popup-menu-option.component';
import { PopupMenuComponent } from './popup-menu/popup-menu.component';
import { SearchFieldComponent } from './fields/search-field/search-field.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import {
  SvgArrow,
  SvgBorderedArrow,
  SvgCamera,
  SvgCheckmarkIcon,
  SvgDocText,
  SvgEdit,
  SvgFile,
  SvgLoadingIcon,
  SvgPaperClip,
  SvgPaperPlane,
  SvgPlus,
  SvgSearch,
  SvgSuccess,
  SvgTripleDot,
  SvgVideo,
  SvgWarning,
  SvgXMark,
  PasswordEye,
  PasswordEyeClose,
  SvgCheckmark,
  SvgEmptyCheckbox,
  SvgImageInstrumentsDownloadIcon,
  SvgVideoInstrumentsOpenLink,
  SvgLink,
  SvgFileDownloaderImage,
  SvgFileDownloaderVideo,
  SvgMessage, SvgInfoCircle, SvgError
} from "./svg-components/svg.components";
import { TextareaFieldComponent } from './fields/textarea-field/textarea-field.component';
import { ToastComponent } from './toast-alert/toast-alert.component';
import { InputImageComponent } from './fields/input-image/input-image.component';
import { InputIconComponent } from './fields/input-icon/input-icon.component';
import { FileDownloaderComponent } from './file-downloader/file-downloader.component';
import { FileDownloaderPopupComponent } from './file-downloader/component/file-downloader-popup/file-downloader-popup.component';
import { NavTabsComponent } from './nav-tabs/nav-tabs.component';
import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { ColumnResizeDirective } from '../directives/resize-x.directive';
import { PopupDirective } from '../directives/popup.directive';
import { TextWithHighlightingComponent } from './text-with-highlighting/text-with-highlighting.component';
import { CheckboxFieldComponent } from "./fields/checkbox-field/checkbox-field.component";
import { AutocompleteFieldComponent } from "./fields/autocomplete-field/autocomplete-field.component";
import { HoverInstrumentsBoxComponent } from './fields/input-image/hover-instruments-box/hover-instruments-box.component';
import { VideoInstrumentsComponent } from './fields/video-instruments/video-instruments.component';
import { YooptaEditorComponent } from "./yoopta-editor/yoopta-editor.component";
import { ReactComponentDirective } from "../directives/react-component.directive";
import { UserCardComponent } from './user-card/user-card.component';
import { AutoResizableTextareaDirective } from "../directives/auto-resizeable-textarea.directive";
import { IconButtonComponent } from "./buttons/icon-button/icon-button.component";
import { StrToColoredBgComponent } from "./str-to-colored-bg/str-to-colored-bg.component";
import { SettingsContainerComponent } from './settings/settings-container/settings-container.component';
import { SettingsNavContainerComponent } from './settings/settings-nav-container/settings-nav-container.component';
import { SettingsNavItemComponent } from "./settings/settings-nav-item/settings-nav-item.component";
import { SettingsContentElementComponent } from './settings/settings-content-element/settings-content-element.component';
import { SettingsButtonComponent } from './settings/settings-button/settings-button.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { ImageStubComponent } from './image-stub/image-stub.component';
import { A11yModule } from "@angular/cdk/a11y";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { LoadingShadingComponent } from './loading-shading/loading-shading.component';
import { createCustomElement } from "@angular/elements";
import { AngularReactModule } from "@bubblydoo/angular-react";
import { TooltipBoxComponent } from './yoopta-editor/components/tooltip-box/tooltip-box.component';
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [
    CardComponent,
    MainContentCardComponent,
    InputFieldComponent,
    SearchFieldComponent,
    DropdownFieldComponent,
    FieldErrorMessageComponent,
    PopupMenuComponent,
    PopupMenuButtonComponent,
    PopupMenuContentComponent,
    PopupMenuOptionComponent,
    PageShadingComponent,
    ToastComponent,
    MultiselectFieldComponent,
    CheckboxFieldComponent,
    AuthLayoutComponent,
    AvatarComponent,
    BadgeComponent,
    ButtonComponent,
    ButtonIconComponent,
    IconButtonComponent,
    ModalComponent,
    ClueComponent,
    ModalContentComponent,
    ModalContentFooterComponent,
    TextareaFieldComponent,
    ButtonAddComponent,
    AutocompleteFieldComponent,
    FilterMenuComponent,
    ActionMenu,
    PageLayoutComponent,
    MessengerModalComponent,
    SkeletonComponent,
    PathLineComponent,
    EditableTextComponent,
    MessengerInputFieldComponent,
    InputImageComponent,
    InputIconComponent,
    FileDownloaderComponent,
    FileDownloaderPopupComponent,
    NavTabsComponent,
    ClickOutsideDirective,
    ColumnResizeDirective,
    PopupDirective,
    TextWithHighlightingComponent,
    HoverInstrumentsBoxComponent,
    VideoInstrumentsComponent,
    YooptaEditorComponent,
    UserCardComponent,
    StrToColoredBgComponent,
    SettingsContainerComponent,
    SettingsNavItemComponent,
    SettingsNavContainerComponent,
    SettingsContentElementComponent,
    SettingsButtonComponent,
    AlertModalComponent,
    PageHeaderComponent,
    ImageStubComponent,
    LoadingShadingComponent,
    TooltipBoxComponent,
  ],
  exports: [
    CardComponent,
    MainContentCardComponent,
    InputFieldComponent,
    SearchFieldComponent,
    DropdownFieldComponent,
    FieldErrorMessageComponent,
    PopupMenuComponent,
    PopupMenuButtonComponent,
    PopupMenuContentComponent,
    PopupMenuOptionComponent,
    PageShadingComponent,
    ToastComponent,
    MultiselectFieldComponent,
    CheckboxFieldComponent,
    AuthLayoutComponent,
    AvatarComponent,
    BadgeComponent,
    ButtonComponent,
    ButtonIconComponent,
    IconButtonComponent,
    ModalComponent,
    ClueComponent,
    ModalContentComponent,
    ModalContentFooterComponent,
    TextareaFieldComponent,
    ButtonAddComponent,
    AutocompleteFieldComponent,
    FilterMenuComponent,
    ActionMenu,
    PageLayoutComponent,
    MessengerModalComponent,
    SkeletonComponent,
    PathLineComponent,
    EditableTextComponent,
    MessengerInputFieldComponent,
    InputImageComponent,
    InputIconComponent,
    FileDownloaderComponent,
    NavTabsComponent,
    ClickOutsideDirective,
    ColumnResizeDirective,
    PopupDirective,
    TextWithHighlightingComponent,
    VideoInstrumentsComponent,
    YooptaEditorComponent,
    UserCardComponent,
    StrToColoredBgComponent,
    SettingsContainerComponent,
    SettingsNavItemComponent,
    SettingsNavContainerComponent,
    SettingsContentElementComponent,
    SettingsButtonComponent,
    AlertModalComponent,
    PageHeaderComponent,
    ImageStubComponent,
    LoadingShadingComponent
  ],
  providers: [provideNgxMask()],
  imports: [
    CommonModule,
    AppRoutingModule,
    NgxMaskDirective,
    FormsModule,
    SvgBorderedArrow,
    SvgPlus,
    SvgXMark,
    SvgWarning,
    SvgSearch,
    SvgSuccess,
    SvgEdit,
    SvgLoadingIcon,
    SvgPaperClip,
    SvgCheckmarkIcon,
    SvgArrow,
    SvgPaperPlane,
    SvgDocText,
    SvgCamera,
    SvgVideo,
    SvgTripleDot,
    SvgFile,
    PasswordEye,
    PasswordEyeClose,
    SvgCheckmark,
    SvgEmptyCheckbox,
    SvgImageInstrumentsDownloadIcon,
    SvgVideoInstrumentsOpenLink,
    SvgLink,
    SvgFileDownloaderImage,
    SvgFileDownloaderVideo,
    ReactComponentDirective,
    SvgMessage,
    AutoResizableTextareaDirective,
    SvgInfoCircle,
    SvgError,
    NgOptimizedImage,
    A11yModule,
    MatProgressSpinnerModule,
    AngularReactModule,
    MatTooltipModule
  ]
})
export class ComponentsModule {
  constructor(private injector: Injector) {
    const buttonElement = createCustomElement(ButtonComponent, { injector });
    customElements.define('angular-core-button', buttonElement);
    const inputElement = createCustomElement(InputFieldComponent, { injector });
    customElements.define('angular-core-input', inputElement);
  }
}
