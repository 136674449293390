<div
  *ngIf="open"
  class="fixed z-50 flex top-0 left-0 w-full h-full bg-[#00000059] animate-modal_appearance"
  (click)="onCloseModal($event)"
>
  <div
    class="m-auto min-h-[20%] inset-0 bg-tt-modal-background z-10 rounded-tt-border-radius_l {{className}}"
    [ngClass]="{
      'p-[16px] pr-[8px]': paddingType === 'base'
    }"
    #modal
    cdkTrapFocus
  >
    <button class="
      absolute top-[6px] cursor-pointer right-[-54px] size-[44px]
      flex justify-center items-center
      outline-tt-informer-info-icon group
    "
      (click)="this.onClose.emit()"
    >
      <div class="size-[32px] bg-tt-bg-page rounded-[24px] flex justify-center items-center group-hover:bg-tt-button-hover  ">
        <svg-x-mark class="min-h-[24px] min-w-[24px] text-tt-icon-default"/>
      </div>
    </button>

    <div class="w-full h-full flex overflow-hidden">

      <app-core-loading-shading
        *ngIf="isLoading"
        class="rounded-tt-border-radius_l"
      />

      <ng-content />
    </div>

  </div>
</div>
