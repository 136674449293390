import { UserForm } from "./create-employee-modal-state";
import { EmployeeRoleDto } from "../../../../../../data/dto/employee-dto";
import { QueryList } from "@angular/core";
import { InputFieldComponent } from "../../../../../../../../core/components/fields/input-field/input-field.component";

export type CreateEmployeeModalResultAction =
  | InitResultAction
  | LoadEmployeeResultAction
  | ChangeIsLoadingResultAction
  | ChangeFormDataResultAction
  | ChangeRolesResultAction
  | InitViewResultAction
  | ChangeAvatarColorResultAction
  | ChangeIsSendingRequestAction

export enum CreateEmployeeModalResultActionTypes {
  CHANGE_FORM_DATA,
  INIT,
  INIT_VIEW,
  LOAD_EMPLOYEE,
  CHANGE_IS_LOADING,
  CHANGE_ROLES,
  CHANGE_AVATAR_COLOR,
  CHANGE_IS_SENDING_REQUEST
}

export interface ChangeIsSendingRequestAction{
  readonly type: CreateEmployeeModalResultActionTypes.CHANGE_IS_SENDING_REQUEST
  readonly value: boolean
}

export interface ChangeAvatarColorResultAction {
  readonly type: CreateEmployeeModalResultActionTypes.CHANGE_AVATAR_COLOR;
  readonly colorRGB: string
}

export interface InitViewResultAction {
  readonly type: CreateEmployeeModalResultActionTypes.INIT_VIEW;
  readonly inputs?: QueryList<InputFieldComponent>
}

export interface ChangeRolesResultAction{
  readonly type: CreateEmployeeModalResultActionTypes.CHANGE_ROLES,
  readonly roles: EmployeeRoleDto[]
}

export interface ChangeIsLoadingResultAction{
  readonly type: CreateEmployeeModalResultActionTypes.CHANGE_IS_LOADING
  readonly value: boolean
  readonly employeeId?: number
}

export interface LoadEmployeeResultAction{
  readonly type: CreateEmployeeModalResultActionTypes.LOAD_EMPLOYEE
  readonly userData: UserForm
  readonly employeeId: number
  readonly canDelete: boolean
  readonly canEdit: boolean
  readonly roles: EmployeeRoleDto[]
  readonly isValid: boolean
}

export interface InitResultAction{
  readonly type: CreateEmployeeModalResultActionTypes.INIT
}

export interface ChangeFormDataResultAction {
  readonly type: CreateEmployeeModalResultActionTypes.CHANGE_FORM_DATA
  readonly newData: UserForm
  readonly isValid: boolean
}
