import { ImageUploadResponse } from "@teamtells/image";
import { Editor2ServiceImpl } from "../../../data/editor-service-impl";

export const onImageUrlUpload = (url: string, editorService: Editor2ServiceImpl): Promise<ImageUploadResponse> =>{
  return new Promise((resolve, reject) => {
    const longReadId = sessionStorage.getItem('longreadId');
    if (longReadId) {
      editorService.uploadExternalFile(longReadId, url)
        .subscribe({
          next: (fileDto) => {
            const result: ImageUploadResponse = {
              src: fileDto.id,
              alt: fileDto.name,
            }

            resolve(result)
          },
          error: err => {
            reject(err)
          }
        })
    }
  })
}
