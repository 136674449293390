import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subscriber } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "../../domain/auth.service";
import { ToastsService } from "../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../core/components/toast-alert/toast-alert.component";

type CallerRequest = {
  subscriber: Subscriber<any>;
  failedRequest: HttpRequest<any>;
};

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  private refreshInProgress: boolean = false;
  private requests: CallerRequest[] = [];

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private toastService: ToastsService
  ){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    if(!req.url.includes(environment.apiUrl)) {
      return next.handle(req);
    }

    return new Observable<HttpEvent<any>>((subscriber) => {
      let originalRequestSubscription = next.handle(req)
        .subscribe({
          next: (response) => {
            subscriber.next(response);
          },
          error: (err: HttpErrorResponse) => {
            if (err.status === 401) {
              this.handleUnauthorizedError(subscriber, req);
            } else {
              this.toastService.createToast({
                title: 'Извините, возникла ошибка',
                description: 'Попробуйте позднее',
                state: ToastState.ERROR
              })
              subscriber.error(err);
            }
          },
          complete: () => {
            subscriber.complete();
          },
        })

      return () => {
        originalRequestSubscription.unsubscribe();
      };
    });
  }

  private handleUnauthorizedError(subscriber: Subscriber < any >, request: HttpRequest<any>) {
    if(request.url.includes('refresh-token'))
    {
      this.refreshInProgress = false
      this.requests = []
      this.auth.logout(true);
      return
    }
    this.requests.push({ subscriber, failedRequest: request });
    if(!this.refreshInProgress) {
      this.refreshInProgress = true;
      this.auth.refreshToken().subscribe(
      {
        next: () => {
          this.refreshInProgress = false
          this.repeatFailedRequests()
        },
        error: () => {
          this.auth.logout();
        },
      })
    }
  }

  private repeatFailedRequests() {
    this.requests.forEach((c) => {
      this.repeatRequest(c.failedRequest, c.subscriber);
    });
    this.requests = [];
  }

  private repeatRequest(request: HttpRequest < any >, subscriber: Subscriber<any>) {

    this.http.request(request).subscribe({
      next: (value) => {
        subscriber.next(value)
      },
      error: (err) => {
        if(err.status === 401)
        {
          this.auth.logout();
        }
        else{
          subscriber.error(err);
        }
      },
    })
  }
}
