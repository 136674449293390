import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges
} from "@angular/core";

@Directive({
  standalone: true,
  selector: "textarea[autoResizableTextarea]"
})
export class AutoResizableTextareaDirective implements OnChanges {
  @Input() text: string = "";

  textarea?: HTMLTextAreaElement
  private resizeObserver = new ResizeObserver((entries) => {
    entries.forEach(entry => {
      this.onResize()
    })
  })

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.textarea = el.nativeElement;
    this.resizeObserver.observe(this.textarea!);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['text']){
      this.resizeTextArea()
    }
  }

  onResize(): void {
    this.resizeTextArea()
  }

  resizeTextArea(): void {
    if(this.textarea){
      this.renderer.setStyle(this.textarea, 'min-height', 0, 1)
      this.renderer.setStyle(this.textarea, 'max-height', 0, 1)
      this.renderer.setStyle(this.textarea, 'min-height', `${this.textarea.scrollHeight}px`, 1)
      this.renderer.setStyle(this.textarea, 'max-height', `${this.textarea.scrollHeight}px`, 1)
    }
  }
}
