import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { SettingsSubscriptionComponent } from './presentation/settings-subscription.component';
import {  MatProgressBarModule } from '@angular/material/progress-bar';
import { SettingsSubscriptionOrderComponent } from './presentation/components/settings-subscription-order/settings-subscription-order.component';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { InvoicingModalComponent } from './presentation/components/invoicing-modal/invoicing-modal.component';
import { Validator } from "../../../../core/validators/validator";
import { DateRule, EmptyRule, MaxLengthRule, MinLengthRule } from "../../../../core/validators/rule";
import { SvgDocument, SvgFile, SvgInfoCircle } from "../../../../core/components/svg-components/svg.components";

@NgModule({
  declarations: [
    SettingsSubscriptionComponent,
    SettingsSubscriptionOrderComponent,
    InvoicingModalComponent
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    ComponentsModule,
    NgOptimizedImage,
    SvgInfoCircle,
    SvgDocument,
    SvgFile
  ],
  providers: [
    {
      provide: "InvoicingModalOrganisationNameValidator",
      useExisting: Validator,
      useFactory: SettingsSubscriptionModule.organisationNameValidatorFactory
    },
    {
      provide: "InvoicingModalLegalAddressValidator",
      useExisting: Validator,
      useFactory: SettingsSubscriptionModule.legalAddressValidatorFactory
    },
    {
      provide: "InvoicingModalKPPValidator",
      useExisting: Validator,
      useFactory: SettingsSubscriptionModule.KPPValidatorFactory
    },
    {
      provide: "InvoicingModalSoloProprietorINNValidator",
      useExisting: Validator,
      useFactory: SettingsSubscriptionModule.SoloProprietorINNValidatorFactory
    },
    {
      provide: "InvoicingModalINNValidator",
      useExisting: Validator,
      useFactory: SettingsSubscriptionModule.INNValidatorFactory
    }
  ],
})
export class SettingsSubscriptionModule {
  public static organisationNameValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите название организации'),
    ]);

  public static legalAddressValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите юридический адрес'),
    ]);

  public static KPPValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите КПП'),
      new MaxLengthRule(
        'КПП должен содержать 9 символов',
        9,
      ),
      new MinLengthRule(
        'КПП должен содержать 9 символов',
        9,
      )
    ]);

  public static INNValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите ИНН'),
      new MaxLengthRule(
        'ИНН должен содержать 10 символов',
        10,
      ),
      new MinLengthRule(
        'ИНН должен содержать 10 символов',
        10,
      )
    ]);

  public static SoloProprietorINNValidatorFactory = () =>
    new Validator([
      new EmptyRule('Введите ИНН'),
      new MaxLengthRule(
        'ИНН должен содержать 12 символов',
        12,
      ),
      new MinLengthRule(
        'ИНН должен содержать 12 символов',
        12,
      )
    ]);
}
