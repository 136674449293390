import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { PlatformLocation } from "@angular/common";
import { Clipboard } from "@angular/cdk/clipboard";
import { ToastsService } from "../../../../../core/components/toast-alert/services/toast-alert.service";
import { ToastState } from "../../../../../core/components/toast-alert/toast-alert.component";

@Injectable({
  providedIn: 'root',
})
export class SectionsNavigator {
  baseUrl: string
  constructor(
    private router: Router,
    private platform: PlatformLocation,
    private clipboard: Clipboard,
    private toastService: ToastsService)
  {
    this.baseUrl = platform.port ? platform.hostname + ':' + platform.port : platform.hostname
  }

  navigateToSection(id: number) {
    this.router.navigateByUrl(`section/${id}/content`).then((r) => {});
  }

  copySectionUrl(id: number){
    if(this.clipboard.copy(`${this.baseUrl}/section/${id}/content`)){
      this.toastService.createToast({
        title: 'Ссылка скопированна',
        description: 'Ссылка скопированна в буфер обмена',
        state: ToastState.SUCCESS
      })
    }
    else {
      this.toastService.createToast({
        title: 'Произошла ошибка',
        description: 'Не удалось скопировать ссылку',
        state: ToastState.ERROR
      })
    }
  }

  navigateToSectionEdit(id: number) {
    this.router.navigateByUrl(`/section/${id}/settings`);
  }
}
