import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-core-settings-nav-item',
  templateUrl: './settings-nav-item.component.html',
})
export class SettingsNavItemComponent
{
  @Input() isSelected: boolean = false
  @Output() onClick = new EventEmitter()
}
