import { Injectable } from '@angular/core';
import { DropdownItem } from "../../../../core/components/fields/dropdown-field/dropdown-field.component";

export type RegistrationStatus =
  | 'idle'
  | 'create_loading'
  | 'success'
  | 'create_error'
  | 'get_info_loading'
  | 'get_info_error'
  | 'already_exist_error';

@Injectable()
export class RegistrationState {
  readonly status: RegistrationStatus = 'idle';

  readonly patronymic: string = '';
  readonly patronymicError: string = '';

  readonly surname: string = '';
  readonly surnameError: string = '';

  readonly name: string = '';
  readonly nameError: string = '';

  readonly specializations: DropdownItem[] = [];
  readonly selectedSpecializing: DropdownItem | null = null;
  readonly specializingError: string = '';

  readonly possibleStaffSize: DropdownItem[] = [];
  readonly selectedStaffSize: DropdownItem | null = null;
  readonly staffSizeError: string = '';

  readonly email: string = '';
  readonly emailError: string = '';

  readonly phoneNumber: string = '';
  readonly phoneNumberError: string = '';
}
