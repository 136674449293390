import { Injectable } from "@angular/core";
import { EmployeeSummeryEntity } from "../../../../domain/employee";
import { EmployeeDeleteProps } from "../../../../domain/employee-delete-props";

@Injectable()
export class EmployeesListState {
  readonly isCreateEmployeeModalVisible: boolean = false
  readonly employeeEditId: number = -1
  readonly employees: EmployeeSummeryEntity[] = []
  readonly filteredEmployees: EmployeeSummeryEntity[] = []
  readonly searchFieldValue: string = ''

  readonly isDeleting: boolean = false
  readonly isLoading: boolean = false

  readonly canCreate: boolean = false
  readonly canDelete: boolean = false
  readonly canEdit: boolean = false
  readonly ownerId: number = -1

  readonly isDeleteAlertVisible: boolean = false
  readonly employeeDeleteProps: EmployeeDeleteProps | null = null
}
