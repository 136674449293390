<div class="p-[16px] bg-white shadow-tt-shadow-popup-menu rounded-tt-border-radius_m"
  *ngIf="isVisible"
>
  <div class="h-[45px] flex justify-between items-center border-b-[1px] border-tt-separator-color">
    <div class="h-full flex flex-row gap-4">
      <div class="h-full">
        <button
          class="nav-item"
          [ngClass]="{
              'nav-item__selected': currentPopupItem == PopupItem.UPLOAD
            }"
          (click)="currentPopupItem = PopupItem.UPLOAD"
        >
          Загрузить
        </button>
      </div>
      <div class="h-full">
        <button
          class="nav-item"
          [ngClass]="{
              'nav-item__selected': currentPopupItem == PopupItem.PASTE_URL
            }"
          (click)="currentPopupItem = PopupItem.PASTE_URL"
        >
          Вставить ссылку
        </button>
      </div>
    </div>
    <button class="w-[24px] h-[24px]"
            (click)="isVisible = false"
    >
      <svg-x-mark class="text-tt-icon-default"></svg-x-mark>
    </button>
  </div>

  <div class="mt-[16px]">
    <app-core-button
      *ngIf="currentPopupItem == PopupItem.UPLOAD"
      className="w-full" variant="solid"
      (onClick)="uploadClicked.emit()"
    >
      Загрузить файл
    </app-core-button>

    <div
      *ngIf="currentPopupItem == PopupItem.PASTE_URL"
    >
      <app-core-input-field (onChange)="inputUrlValue = $event" variant="standard" placeholder="Вставьте ссылку на файл"/>

      <app-core-button
        className="mt-[16px] w-full" variant="solid"
        (onClick)="urlConfirmClicked.emit(inputUrlValue)"
      >
        Применить
      </app-core-button>
    </div>
  </div>

</div>
