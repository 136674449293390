import { Component } from '@angular/core';
import { Store } from 'src/app/core/mvi/store';
import { MessengerSideBarState } from './state/sidebar-state';
import { MessengerSidebarReducer } from './state/sidebar-reducer';
import {
  MessengerSideBarAction,
  MessengerSideBarActionTypes,
} from './state/sidebar-action';
import { MessengerSideBarExecutor } from './state/sidebar-executor';
import { MessengerSideBarResultAction } from './state/sidebar-result-action';

@Component({
  selector: 'app-messenger-menu',
  templateUrl: './sidebar.component.html',
})
export class MessengerSideBarComponent extends Store<
  MessengerSideBarState,
  MessengerSideBarExecutor,
  MessengerSideBarAction,
  MessengerSideBarResultAction
> {
  constructor(
    state: MessengerSideBarState,
    executor: MessengerSideBarExecutor,
    reducer: MessengerSidebarReducer,
  ) {
    super(state, executor, reducer);
  }
  protected readonly MessengerSideBarActionTypes = MessengerSideBarActionTypes;
}
