import { ActionSection } from '../../../../../../core/components/action-menu/action-menu.component';
import { SortParams } from '../state/section-content-state';

export class Constants {
  public static NO_ID = '-1';
  public static BASE_NEW_DOC_NAME = 'Новый документ';
  public static SORT_POPUP_SECTION: ActionSection[] = [
    {
      elements: [
        {
          id: SortParams.ALPHABET,
          name: 'От А до Я',
        },
        {
          id: SortParams.ALPHABET_REVERT,
          name: 'От Я до А',
        },
        {
          id: SortParams.NEW_TO_OLD,
          name: 'Сначала новые',
        },
        {
          id: SortParams.OLD_TO_NEW,
          name: 'Сначала старые',
        },
      ],
    },
  ];
}
