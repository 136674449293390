// import { ChevronUpIcon } from '@radix-ui/react-icons';
import { CSSProperties, MouseEvent } from 'react';
import { YooEditor, UI } from '@teamtells/editor';
const { Portal } = UI;

const colors = [
  ['Базовый', '#041E49'],
  ['Серый', '#9B9B9B'],
  ['Красный', '#EB4D3D'],
  ['Оранжевый', '#F19938'],
  ['Желтый', '#F7CD46'],
  ['Зеленый', '#65C366'],
  ['Аквамариновый', '#59C4BD'],
  ['Сине-серый', '#59AEC4'],
  ['Васильковый', '#59ABE1'],
  ['Синий', '#337BF6'],
  ['Аспидно-синий', '#5759CE'],
  ['Фиолетовый', '#A35AD7'],
];

const backgroundColors = [
  ['Default', 'unset'],
  ['Серый', '#9B9B9B1A'],
  ['Красный', '#EB4D3D1A'],
  ['Оранжевый', '#F199381A'],
  ['Желтый', '#F7CD461A'],
  ['Зеленый', '#65C3661A'],
  ['Аквамариновый', '#59C4BD1A'],
  ['Сине-серый', '#59AEC41A'],
  ['Васильковый', '#59ABE11A'],
  ['Синий', '#337BF61A'],
  ['Аспидно-синий', '#5759CE1A'],
  ['Фиолетовый', '#A35AD71A'],
];

const itemStyles = {
  border: '1px solid #e3e3e3',
};

type Props = {
  editor: YooEditor;
  highlightColors: CSSProperties;
  onClose: () => void;
  refs: { setFloating: (el: any) => void };
  floatingStyles: React.CSSProperties;
};

const HighlightColor = ({ editor, highlightColors, onClose, refs, floatingStyles }: Props) => {
  const getItemStyles = (type: string, color: string) => {
    if(highlightColors !== undefined)
    {
      if (
        type === 'color' && highlightColors.color === color
        || type === 'backgroundColor' && highlightColors.backgroundColor === color
      ) {
        return {
          border: '1px solid #0B57D0',
          backgroundColor: color,
        };
      }
    }

    return { backgroundColor: color, ...itemStyles };
  };

  const updateColor = (type: string, color: string) => {
    const value = editor.formats["highlight"].getValue();
    if (value?.[type] === color) {
      editor.formats["highlight"].update({ ...highlightColors, [type]: undefined });
      return;
    }

    editor.formats["highlight"].update({ ...highlightColors, [type]: color });
  };

  return (
    <Portal id="yoo-highlight-color-portal">
      <div style={floatingStyles} ref={refs.setFloating} onClick={(e: MouseEvent) => e.stopPropagation()}>
        <div
          className="yoo-toolbar-bg-[#FFFFFF] p-[8px] rounded-[8px]"
          style={{
            boxShadow: '0px 1px 4px 0px #00000040'
          }}
        >
          <div className="flex items-center justify-between">
            <span className="text-tt-font-size_s text-tt-text-primary">Цвет текста</span>
          </div>
          <div className="yoo-toolbar-flex yoo-toolbar-items-center">
            {colors.map(([label, color]) => (
              <button
                key={label}
                title={label}
                type="button"
                className="yoopta-button yoo-toolbar-w-[25px] yoo-toolbar-h-[25px] yoo-toolbar-rounded-md yoo-toolbar-mx-[2px] yoo-toolbar-my-[5px] yoo-toolbar-border yoo-toolbar-border-solid-[#e3e3e3]"
                style={getItemStyles('color', color)}
                onClick={() => updateColor('color', color)}
              />
            ))}
          </div>
          <div className="flex items-center justify-between mt-[8px]">
            <span className="text-tt-font-size_s text-tt-text-primary">Цвет фона</span>
          </div>
          <div className="yoo-toolbar-flex yoo-toolbar-items-center">
            {backgroundColors.map(([label, backgroundColor]) => (
              <button
                key={label}
                title={label}
                type="button"
                className="yoopta-button yoo-toolbar-w-[25px] yoo-toolbar-h-[25px] yoo-toolbar-rounded-md yoo-toolbar-mx-[2px] yoo-toolbar-my-[5px]"
                style={getItemStyles('backgroundColor', backgroundColor)}
                onClick={() => updateColor('backgroundColor', backgroundColor)}
              />
            ))}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export { HighlightColor };
