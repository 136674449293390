<app-file-downloader
  [title]="'Добавить изображение'"
  [isReadOnly]="isReadOnly"
  [fileType]="'image/*'"
  (fileUpload)="imageUpload.emit($event)"
  (urlChanged)="imageUrlChanged.emit($event)"
  (deleteClicked)="imageDelete.emit()"
  *ngIf="currentImageUrl == initUrl"
/>

<div class="group relative min-h-[40px] flex justify-center"
  *ngIf="currentImageUrl != initUrl"
>
  <img class="min-h-[40px]" src="{{currentImageUrl}}">
  <app-hover-instruments-box
    class="absolute top-2 right-2 group-hover:block"
    [ngClass]="{
      'hidden': !fullImagePopupVisibility,
      'block': fullImagePopupVisibility
    }"
    *ngIf="!isReadOnly"
  >

    <div class="flex items-center"

    >
      <button class="h-[32px] w-[32px] flex justify-center items-center">
        <svg-image-instruments-download-icon />
      </button>

      <div class="h-[32px] w-[1px] bg-tt-editor-instrument-separator"></div>

      <div >
        <button class="w-[32px] h-[32px] text-tt-icon-contrast-background cursor-pointer flex justify-center items-center"
          (click)="fullImagePopupVisibility = !fullImagePopupVisibility"
        >
          <svg-triple-dot class="w-[24px] h-[24px]"/>
        </button>
        <app-core-action-menu
          appPopup
          popupOffsetXSide="right"
          *ngIf="fullImagePopupVisibility"
          (popupClose)="fullImagePopupVisibility = false"
          [sections]="POPUP_SECTIONS"
          (elementClicked)="popupClicked($event)"
        />
      </div>
    </div>

  </app-hover-instruments-box>

  <app-file-downloader-popup
    *ngIf="fileDownloaderPopupVisibility"
    [isVisible]="fileDownloaderPopupVisibility"
    appPopup
    popupOffsetXSide="center"
    class="z-50 max-w-[450px] w-full"
    (uploadClicked)="downloadClick()"
    (urlConfirmClicked)="imageUrlChanged.emit($event)"
  />

</div>
<input type="file" accept="image/*" class="hidden" #inputImage (change)="onSelectFile($event)">
