<div class="flex items-center justify-between gap-tt_l">
  <h2 class="text-tt-font-size_3xl text-tt-text-default font-medium">
    Оценочные сессии
  </h2>
  <app-core-button>Добавить</app-core-button>
</div>

<app-core-search-field className="my-tt_l" />

<div>
  <app-assessment-session-item />
  <app-assessment-session-item />
  <app-assessment-session-item />
  <app-assessment-session-item />
  <app-assessment-session-item />
</div>
