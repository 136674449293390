import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'section-editor-already-edited-modal',
  templateUrl: './already-edited-modal.component.html',
})
export class AlreadyEditedModalComponent {
  @Input() userName: string = ''
  @Input() documentTitle: string = ''
  @Output() closeClicked = new EventEmitter()
}
