import {
  SectionSummaryEntity,
  SectionPrivacy,
} from '../../../sections/impl/domain/section-summary-entity';
import { HierarchyDocumentEntity } from './hierarchy-document-entity';

export class SectionEntity {
  constructor(
    readonly id: number,
    readonly title: string,
    readonly thumbnailUrl: string,
    readonly visibility: SectionPrivacy,
    readonly canEdit: boolean,
    readonly documents: Array<HierarchyDocumentEntity>,
  ) {}
}
