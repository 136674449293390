import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class SectionContentNavigator {
  platformUrl = 'https://dev4.env.teamtells.ru';
  constructor(private router: Router) {}

  navigateToDocument(id: string) {
    window.open(`${this.platformUrl}/document/${id}`, '_self');
  }

  getDocumentLink(id: string): string {
    return `${this.platformUrl}/document/${id}`;
  }

  navigateToDocumentEditor(sectionId: string, documentId:string) {
    this.router.navigateByUrl(`/section/${sectionId}/document/${documentId}`,)
  }
}
