import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { SectionSummaryEntity } from "../../../domain/section-summary-entity";
import {
  ActionElementStyle,
  ActionSection
} from "../../../../../../../core/components/action-menu/action-menu.component";

enum PopupMenuId {
  EDIT = 'EDIT',
  COPY = 'COPY',
  DELETE = 'DELETE'
}

const POPUP_SECTIONS: ActionSection[] = [
  {
    elements: [
      {
        id: PopupMenuId.EDIT,
        name: 'Редактировать',
        icon: 'assets/popup-pencil.svg'
      },
      {
        id: PopupMenuId.COPY,
        name: 'Ссылка',
        icon: 'assets/popup-link.svg'
      },
      {
        id: PopupMenuId.DELETE,
        name: 'Удалить',
        icon: 'assets/trash-red.svg',
        type: ActionElementStyle.DANGER,
      },
    ],
  },
]

@Component({
  selector: 'sections-section-item',
  templateUrl: './section-item.component.html',
})
export class SectionItemComponent {
  @Input() section: SectionSummaryEntity = {
    id: 0,
    title: '',
    url: '',
    isFavorite: false,
  };

  @Output() clicked = new EventEmitter();
  @Output() markClicked = new EventEmitter();
  @Output() editClicked = new EventEmitter();
  @Output() copyUrlClicked = new EventEmitter()
  @Output() deleteClicked = new EventEmitter();

  popupVisibility: boolean = false;
  popupMenuSections: ActionSection[] = POPUP_SECTIONS;

  constructor(private el: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.popupVisibility = false;
    }
  }

  popupClicked(id: string) {
    switch (id) {
      case PopupMenuId.EDIT:
        this.editClicked.emit();
        break;
      case PopupMenuId.COPY:
        this.copyUrlClicked.emit();
        break;
      case PopupMenuId.DELETE:
        this.deleteClicked.emit();
    }
    this.popupVisibility = false;
  }
}
