import { EmployeeRoleDto } from "../../../../../../data/dto/employee-dto";
import { QueryList } from "@angular/core";
import { InputFieldComponent } from "../../../../../../../../core/components/fields/input-field/input-field.component";

export type CreateEmployeeModalAction =
  | InitAction
  | SaveEmployeeAction
  | LoadEmployeeAction
  | ChangeSurnameAction
  | ChangeNameAction
  | ChangeEmailAction
  | ChangePatronymicAction
  | ChangePhoneNumberAction
  | AddRoleAction
  | RemoveRoleAction
  | InitViewAction
  | ChangeAvatarColorAction

export enum CreateEmployeeModalActionTypes {
  CHANGE_SURNAME,
  CHANGE_NAME,
  CHANGE_PATRONYMIC,
  CHANGE_EMAIL,
  CHANGE_PHONE_NUMBER,
  ADD_ROLE,
  REMOVE_ROLE,
  INIT,
  INIT_VIEW,
  SAVE_EMPLOYEE,
  LOAD_EMPLOYEE,
  CHANGE_AVATAR_COLOR,
}

export interface ChangeAvatarColorAction {
  readonly type: CreateEmployeeModalActionTypes.CHANGE_AVATAR_COLOR;
}

export interface InitViewAction {
  readonly type: CreateEmployeeModalActionTypes.INIT_VIEW;
  readonly inputs?: QueryList<InputFieldComponent>
}

export interface AddRoleAction{
  readonly type: CreateEmployeeModalActionTypes.ADD_ROLE,
  readonly role: EmployeeRoleDto
}

export interface RemoveRoleAction{
  readonly type: CreateEmployeeModalActionTypes.REMOVE_ROLE,
  readonly roleId: string
}

export interface ChangeSurnameAction {
  readonly type: CreateEmployeeModalActionTypes.CHANGE_SURNAME;
  readonly value: string
}

export interface ChangeNameAction {
  readonly type: CreateEmployeeModalActionTypes.CHANGE_NAME;
  readonly value: string
}

export interface ChangePatronymicAction {
  readonly type: CreateEmployeeModalActionTypes.CHANGE_PATRONYMIC;
  readonly value: string
}

export interface ChangeEmailAction {
  readonly type: CreateEmployeeModalActionTypes.CHANGE_EMAIL;
  readonly value: string
}

export interface ChangePhoneNumberAction {
  readonly type: CreateEmployeeModalActionTypes.CHANGE_PHONE_NUMBER;
  readonly value: string
}

export interface LoadEmployeeAction {
  readonly type: CreateEmployeeModalActionTypes.LOAD_EMPLOYEE;
  readonly id: number
}

export interface SaveEmployeeAction {
  readonly type: CreateEmployeeModalActionTypes.SAVE_EMPLOYEE;
}

export interface InitAction {
  readonly type: CreateEmployeeModalActionTypes.INIT;
}
