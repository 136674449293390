import { Component } from '@angular/core';

@Component({
  selector: 'app-core-settings-container',
  templateUrl: './settings-container.component.html',
  host: {
    class: 'max-w-[935px] w-full min-h-[660px] flex border-[1px] border-tt-border-default rounded-tt-border-radius_m overflow-hidden bg-tt-menu-main-background m-[24px]',
  }
})
export class SettingsContainerComponent {

}
