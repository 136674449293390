<div
  [ngClass]="{ 'bg-tt-hover-color': params.selected, '': !params.selected! }"
  class="pr-[30px]"
>
  <div class="flex gap-[8px]">
    <div
      [ngClass]="{ 'bg-[#E30611]': params.selected, '': !params.selected }"
      class="w-[2px]"
    ></div>
    <div class="w-fit py-[10px] pl-[10px]">
      <ng-content></ng-content>
    </div>
  </div>
</div>
