import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionsComponent } from './presentation/view/sections.component';
import { ComponentsModule } from '../../../../core/components/components.module';
import { DocumentItemComponent } from '../../section/impl/presentation/components/document-item/document-item.component';
import {
  SvgArrow,
  SvgBookmark,
  SvgBookmarkFill,
  SvgBorderedArrow,
  SvgDot,
  SvgPlus,
  SvgTripleDot,
  SvgXMark,
} from 'src/app/core/components/svg-components/svg.components';
import { SectionContentModule } from '../../section-contents/impl/section-content.module';
import { SectionItemComponent } from './presentation/components/section-item/section-item.component';
import { SelectIconWindowComponent } from './presentation/components/select-icon-window/select-icon-window.component';

@NgModule({
  declarations: [
    SectionsComponent,
    DocumentItemComponent,
    SectionItemComponent,
    SelectIconWindowComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    SvgArrow,
    SvgDot,
    SvgBorderedArrow,
    SvgPlus,
    SvgTripleDot,
    SectionContentModule,
    SvgBookmark,
    SvgBookmarkFill,
    SvgXMark,
  ],
  exports: [DocumentItemComponent, SelectIconWindowComponent],
})
export class SectionsModule {}
