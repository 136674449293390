import { AfterViewInit, Component, EventEmitter, Input, Output } from "@angular/core";
import { ViewportScroller } from "@angular/common";

const START_ID:string = 'popup-menu-element-id'

@Component({
  selector: 'app-core-action-menu',
  templateUrl: './action-menu.component.html',
})
export class ActionMenu implements AfterViewInit{
  @Input() sections: ActionSection[] = [];
  @Input() variant: 'shaded' | 'standard' = 'shaded';
  @Input() isScrollable: boolean = false; //На случай если будет скролится вся страница нужно обернуть её в блок со стилями h-screen overflow-scroll-y
  @Input() selectedId: string = '';

  @Output() elementClicked = new EventEmitter<string>();

  protected readonly PopupMenuElementStyle = ActionElementStyle;
  protected readonly START_ID = START_ID;

  constructor(private scroller: ViewportScroller){}

  ngAfterViewInit() {
    if(this.isScrollable && this.selectedId !== ''){
      this.scroller.scrollToAnchor(START_ID + this.selectedId)
    }
  }

  onElementClick(event: MouseEvent, id: string): void {
    event.stopPropagation()
    this.elementClicked.emit(id)
  }

}

export type ActionSection = {
  elements: ActionElement[];
};

export type ActionElement = {
  id: string;
  name: string;
  icon?: string;
  type?: ActionElementStyle;
};

export enum ActionElementStyle {
  BASE,
  DANGER,
  INACTIVE,
}
