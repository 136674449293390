import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import {
  ActionElement,
  ActionSection,
} from '../action-menu/action-menu.component';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
})
export class FilterMenuComponent {
  @Input() className?: string | string[];
  @Input() menuItems: FilterMenuItem[] = [];
  @Input() currentSortType?: ActionElement;
  @Input() sortTypes: ActionSection[] = [];
  @Output() menuItemClicked = new EventEmitter<number>();
  @Output() sortTypeClicked = new EventEmitter<string>();

  popupVisibility: boolean = false;

  constructor(private el: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.popupVisibility = false;
    }
  }
  getCurrentSortType(): ActionElement {
    return this.currentSortType
      ? this.currentSortType
      : this.sortTypes[0].elements[0];
  }
  sortClicked(event: string) {
    this.sortTypeClicked.emit(event);
    this.popupVisibility = false;
  }
}

export interface FilterMenuItem {
  id: number;
  title: string;
  isActive: boolean;
}
