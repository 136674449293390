import React, { CSSProperties, useEffect, useState } from "react";
import { RenderElementProps } from 'slate-react';
import { ImageElementProps } from "@teamtells/image";
import { useInjected } from "@bubblydoo/angular-react";
import { Editor2ServiceImpl } from "../../../data/editor-service-impl";

type ImageComponentProps = Omit<ImageElementProps, 'sizes'> & {
  width: number;
  height: number;
  layout?: Layout;
} & Pick<RenderElementProps, 'attributes' | 'children'>;

type Layout = 'fill' | 'responsive' | 'intrinsic' | 'fixed';

const ImageComponent = (
  {
    width,
    height,
    src,
    alt,
    fit,
    bgColor,
    attributes,
    children,
    layout = 'intrinsic',
  }: ImageComponentProps) => {

  const [imageSrc, setImageSrc] = React.useState('');
  let [isUpload, setIsUpload] = React.useState(false);
  const editorService = useInjected(Editor2ServiceImpl)

  const [isVisible, setIsVisible] = useState<boolean>()
  const imageRef = React.useRef(null)
  const currentTarget = imageRef.current
  const options = {
    root: document.getElementById("yoopta-editor-scroll"),//Этот id надо проставить у родительского элемента со скроллом
    rootMargin: '200px',
    threshold: 0
  }

  useEffect(() => {
    setIsUpload(false)
    setIsVisible(false)

  }, [src]);

  useEffect(() => {
    const callbackFunction = (entries: any) => {
      const [entry] = entries //const entry = entries[0]
      if(entry.isIntersecting){
        setIsVisible(entry.isIntersecting)
        if(currentTarget) observer.unobserve(currentTarget)
      }
    }

    const observer = new IntersectionObserver(callbackFunction, options)

    if (currentTarget) observer.observe(currentTarget)

    return () => {
      if(currentTarget) observer.unobserve(currentTarget)
    }
  }, [imageRef, options])

  React.useEffect(() => {
    const fetchData = () => {
      if (src && !isUpload && isVisible) {
        editorService.downloadFile(src).subscribe({
          next:(image)=> {
            const imageUrl = URL.createObjectURL(image);
            setIsUpload(true)
            setImageSrc(imageUrl);
          },
          error: err => {
            console.error('Error fetching image:', err);
          }
        })
      }
    };

    fetchData();
  }, [isVisible]);

  const style: CSSProperties = {
    objectFit: fit || 'contain',
    backgroundColor: bgColor || 'transparent',
  };

  const isResponsive = layout === 'responsive';
  const isFixed = layout === 'fixed';
  const isFill = layout === 'fill';
  const isIntrinsic = layout === 'intrinsic';

  if (isResponsive) {
    style.width = '100%';
    style.height = 'auto';
  }

  if (isFixed) {
    style.width = '100%';
    style.height = '100%';
  }

  if (isFill) {
    style.width = '100%';
    style.height = '100%';
  }

  if (isIntrinsic) {
    style.height = '100%';
  }

  return (
    <div className="w-full relative" data-layout={layout} {...attributes} ref={imageRef}>
      {imageSrc && (
        <img src={imageSrc} width={width} height={height} alt={alt || ''} decoding="async" loading="lazy" style={style} />
      )}
      {children}
      {alt && (
        <div className="yoopta-image-alt" title={alt}>
          <div className="yoopta-image-alt-text">ALT</div>
        </div>
      )}
    </div>
  );
};

export { ImageComponent, ImageComponentProps };
