import { Component, Output, Input, EventEmitter, HostListener, ElementRef } from '@angular/core';
import {
  ActionSection,
  ActionElementStyle,
} from 'src/app/core/components/action-menu/action-menu.component';

@Component({
  selector: 'app-messenger-modal',
  templateUrl: './messenger-modal.component.html',
})
export class MessengerModalComponent {
  @Output() sectionClickedEventEmitter = new EventEmitter<string>();
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Input() type: string = '';
  public selectedSection: string = '';
  constructor(
    private el: ElementRef,
    ){}
  messagesMenuSections: ActionSection[] = [
    {
      elements: [
        {
          id: 'reply',
          name: 'Ответить',
          icon: '../../../../assets/icon-reply.svg',
          type: ActionElementStyle.BASE,
        },
        {
          id: 'pin',
          name: 'Закрепить',
          icon: '../../../../assets/pin.svg',
          type: ActionElementStyle.BASE,
        },
        {
          id: 'copy',
          name: 'Копировать',
          icon: '../../../../assets/icon-copy.svg',
          type: ActionElementStyle.BASE,
        },
        {
          id: 'forward',
          name: 'Переслать',
          icon: '../../../../assets/icon-share.svg',
          type: ActionElementStyle.BASE,
        },
        {
          id: 'discuss',
          name: 'Обсудить',
          icon: '../../../../assets/bubble.right.svg',
          type: ActionElementStyle.BASE,
        },
        {
          id: 'select',
          name: 'Выделить',
          icon: '../../../../assets/checkmark.circle.svg',
          type: ActionElementStyle.BASE,
        },
        {
          id: 'delete',
          name: 'Удалить',
          icon: '../../../../assets/trash-red.svg',
          type: ActionElementStyle.DANGER,
        },
      ],
    },
  ];

  chatsMenuSections: ActionSection[] = [
    {
      elements: [
        {
          id: 'pin',
          name: 'Закрепить',
          icon: '../../../../assets/pin.svg',
          type: ActionElementStyle.BASE,
        },
        {
          id: 'leave',
          name: 'Покинуть чат',
          icon: '../../../../assets/rectangle.portrait.and.arrow.forward.svg',
          type: ActionElementStyle.DANGER,
        },
      ]
    }
  ]
  get menuSections(): ActionSection[] {
    return this.type === 'messages' ? this.messagesMenuSections : this.chatsMenuSections;
  }

  onSectionClicked(sectionId: string) {
    this.selectedSection = sectionId;
    this.sectionClickedEventEmitter.emit(sectionId);
    this.isVisibleChange.emit(false)
  }

}
