import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'svg-square',
  templateUrl: '../../../../assets/square.svg',
})
export class SvgSquare {}

@Component({
  standalone: true,
  selector: 'svg-gear-shape',
  templateUrl: '../../../../assets/gearshape.svg',
})
export class SvgGearShape {}

@Component({
  standalone: true,
  selector: 'svg-bordered-arrow',
  templateUrl: '../../../../assets/arrow-borded.svg',
})
export class SvgBorderedArrow {}

@Component({
  standalone: true,
  selector: 'svg-arrow',
  templateUrl: '../../../../assets/arrow.svg',
})
export class SvgArrow {}

@Component({
  standalone: true,
  selector: 'svg-arrow-right',
  templateUrl: '../../../../assets/arrow-right.svg',
})
export class SvgArrowRight {}

@Component({
  standalone: true,
  selector: 'svg-dot',
  templateUrl: '../../../../assets/dot.svg',
})
export class SvgDot {}

@Component({
  standalone: true,
  selector: 'svg-plus',
  templateUrl: '../../../../assets/plus.svg',
})
export class SvgPlus {}

@Component({
  standalone: true,
  selector: 'svg-triple-dot',
  templateUrl: '../../../../assets/triple-dot.svg',
})
export class SvgTripleDot {}

@Component({
  standalone: true,
  selector: 'svg-x-mark',
  templateUrl: '../../../../assets/xmark.svg',
})
export class SvgXMark {}

@Component({
  standalone: true,
  selector: 'svg-warning',
  templateUrl: '../../../../assets/warning.svg',
})
export class SvgWarning {}

@Component({
  standalone: true,
  selector: 'svg-success',
  templateUrl: '../../../../assets/success.svg',
})
export class SvgSuccess {}

@Component({
  standalone: true,
  selector: 'svg-search',
  templateUrl: '../../../../assets/search.svg',
})
export class SvgSearch {}

@Component({
  standalone: true,
  selector: 'svg-loading',
  templateUrl: '../../../../assets/loading.svg',
})
export class SvgLoading {}

@Component({
  standalone: true,
  selector: 'svg-button-loading',
  templateUrl: '../../../../assets/button-loading.svg',
})
export class SvgButtonLoading {}

@Component({
  standalone: true,
  selector: 'svg-all-pages',
  templateUrl: '../../../../assets/all-pages.svg',
})
export class SvgAllPages {}

@Component({
  standalone: true,
  selector: 'svg-square-and-pencil',
  templateUrl: '../../../../assets/square.and.pencil.svg',
})
export class SvgSquareAndPencil {}

@Component({
  standalone: true,
  selector: 'svg-paper-plane',
  templateUrl: '../../../../assets/paperplane.svg',
})
export class SvgPaperPlane {}

@Component({
  standalone: true,
  selector: 'svg-link',
  templateUrl: '../../../../assets/link.svg',
})
export class SvgLink {}

@Component({
  standalone: true,
  selector: 'svg-edit',
  templateUrl: '../../../../assets/icon_edit.svg',
})
export class SvgEdit {}

@Component({
  standalone: true,
  selector: 'svg-document',
  templateUrl: '../../../../assets/doc-icon.svg',
})
export class SvgDocument {}

@Component({
  standalone: true,
  selector: 'svg-pencil',
  templateUrl: '../../../../assets/pencil-icon.svg',
})
export class SvgPencil {}

@Component({
  standalone: true,
  selector: 'svg-bookmark-fill',
  templateUrl: '../../../../assets/bookmark-fill.svg',
})
export class SvgBookmarkFill {}

@Component({
  standalone: true,
  selector: 'svg-bookmark',
  templateUrl: '../../../../assets/bookmark.svg',
})
export class SvgBookmark {}

@Component({
  standalone: true,
  selector: 'svg-loading-icon',
  templateUrl: '../../../../assets/loading-icon.svg',
})
export class SvgLoadingIcon {}

@Component({
  standalone: true,
  selector: 'svg-checkmark-circle',
  templateUrl: '../../../../assets/checkmark.circle.svg',
})
export class SvgCheckmarkCircleIcon {}

@Component({
  standalone: true,
  selector: 'svg-xmark-circle',
  templateUrl: '../../../../assets/xmark.circle.svg',
})
export class SvgXmarkCircleIcon {}

@Component({
  standalone: true,
  selector: 'svg-checkmark',
  templateUrl: '../../../../assets/checkmark.svg',
})
export class SvgCheckmarkIcon {}

@Component({
  standalone: true,
  selector: 'svg-paper-clip',
  templateUrl: '../../../../assets/paperclip.svg',
})
export class SvgPaperClip {}

@Component({
  standalone: true,
  selector: 'svg-chevron-forward',
  templateUrl: '../../../../assets/chevron.forward.svg',
})
export class SvgChevronForward {}

@Component({
  standalone: true,
  selector: 'svg-office-building',
  templateUrl: '../../../../assets/office-building.svg',
})
export class SvgOfficeBuilding {}

@Component({
  standalone: true,
  selector: 'svg-doc-text',
  templateUrl: '../../../../assets/doc.text.svg',
})
export class SvgDocText {}

@Component({
  standalone: true,
  selector: 'svg-video',
  templateUrl: '../../../../assets/video.svg',
})
export class SvgVideo {}

@Component({
  standalone: true,
  selector: 'svg-camera',
  templateUrl: '../../../../assets/camera.svg',
})
export class SvgCamera {}

@Component({
  standalone: true,
  selector: 'svg-turn-up-backward',
  templateUrl: '../../../../assets/arrowshape.turn.up.backward.svg',
})
export class SvgTurnUpBackward {}

@Component({
  standalone: true,
  selector: 'svg-leave',
  templateUrl: '../../../../assets/rectangle.portrait.and.arrow.forward.svg',
})
export class SvgLeave {}
@Component({
  standalone: true,
  selector: 'svg-trash-red',
  templateUrl: '../../../../assets/trash-red.svg',
})
export class SvgTrashRed {}

@Component({
  standalone: true,
  selector: 'svg-reply',
  templateUrl: '../../../../assets/icon-reply.svg',
})
export class SvgReply {}

@Component({
  standalone: true,
  selector: 'svg-folder',
  templateUrl: '../../../../assets/folder.svg',
})
export class SvgFolder {}

@Component({
  standalone: true,
  selector: 'svg-competency',
  templateUrl: '../../../../assets/competency.svg',
})
export class SvgCompetency {}

@Component({
  standalone: true,
  selector: 'svg-skill',
  templateUrl: '../../../../assets/skill.svg',
})
export class SvgSkill {}

@Component({
  standalone: true,
  selector: 'svg-add-folder',
  templateUrl: '../../../../assets/add-folder.svg',
})
export class SvgAddFolder {}

@Component({
  standalone: true,
  selector: 'svg-checkmark-sended',
  templateUrl: '../../../../assets/checkmark.sended.svg',
})
export class SvgCheckmarkSended {}

@Component({
  standalone: true,
  selector: 'svg-checkmark-checked',
  templateUrl: '../../../../assets/checkmark.checked.svg',
})
export class SvgCheckmarkChecked {}

@Component({
  standalone: true,
  selector: 'svg-edit-pencil',
  templateUrl: '../../../../assets/pencil.svg',
})
export class SvgEditPencil {}

@Component({
  standalone: true,
  selector: 'svg-arrow-down',
  templateUrl: '../../../../assets/arrow.down.svg',
})
export class SvgArrowDown {}

@Component({
  standalone: true,
  selector: 'svg-popup-color-cancel',
  templateUrl: '../../../../assets/popup-color-cancel.svg',
})
export class SvgPopupColorCancel {}

@Component({
  standalone: true,
  selector: 'svg-to-full-screen',
  templateUrl: '../../../../assets/to-full-screen.svg',
})
export class SvgToFullScreen {}

@Component({
  standalone: true,
  selector: 'svg-to-normal-screen',
  templateUrl: '../../../../assets/to-normal-screen.svg',
})
export class SvgToNormalScreen {}

@Component({
  standalone: true,
  selector: 'svg-file',
  templateUrl: '../../../../assets/file.svg',
})
export class SvgFile {}

@Component({
  standalone: true,
  selector: 'svg-bell',
  templateUrl: '../../../../assets/bell.svg',
})
export class SvgBell {}

@Component({
  standalone: true,
  selector: 'svg-calendar',
  templateUrl: '../../../../assets/calendar.svg'
})
export class SvgCalendar {}

@Component({
  standalone: true,
  selector: 'svg-header-arrow',
  templateUrl: '../../../../assets/header-arrow.svg'
})
export class SvgHeaderArrow {}

@Component({
  standalone: true,
  selector: 'svg-breadcrumbs-arrow',
  templateUrl: '../../../../assets/breadcrumbs-arrow.svg'
})
export class SvgBreadCrumbsArrow {}

@Component({
  standalone: true,
  selector: 'svg-play',
  templateUrl: '../../../../assets/play.svg'
})
export class SvgPlay {}

@Component({
  standalone: true,
  selector: 'svg-password-eye',
  templateUrl: '../../../../assets/password-eye.svg'
})
export class PasswordEye {}

@Component({
  standalone: true,
  selector: 'svg-password-eye-close',
  templateUrl: '../../../../assets/password-eye-close.svg'
})
export class PasswordEyeClose {}

@Component({
  standalone: true,
  selector: 'svg-space-settings-home',
  templateUrl: '../../../../assets/space-settings-home.svg'
})
export class SpaceSettingsHome {}

@Component({
  standalone: true,
  selector: 'svg-person',
  templateUrl: '../../../../assets/person.svg'
})
export class SvgPerson {}

@Component({
  standalone: true,
  selector: 'svg-lock',
  templateUrl: '../../../../assets/lock.svg',
})
export class SvgLock {}

@Component({
  standalone: true,
  selector: 'svg-checkmark-fill',
  templateUrl: '../../../../assets/checkmark.square.fill.svg',
})
export class SvgCheckmark {}

@Component({
  standalone: true,
  selector: 'svg-checkbox-empty',
  templateUrl: '../../../../assets/empty-checkbox.svg',
})
export class SvgEmptyCheckbox{}

@Component({
  standalone: true,
  selector: 'svg-grid2x2',
  templateUrl: '../../../../assets/grid2x2.svg',
})
export class SvgGrid2x2{}

@Component({
  standalone: true,
  selector: 'svg-checklist',
  templateUrl: '../../../../assets/checklist.svg',
})
export class SvgChecklist{}

@Component({
  standalone: true,
  selector: 'svg-image-instruments-download-icon',
  templateUrl: '../../../../assets/image-instruments-download-icon.svg',
})
export class SvgImageInstrumentsDownloadIcon{}

@Component({
  standalone: true,
  selector: 'svg-video-instruments-open-link',
  templateUrl: '../../../../assets/video-instruments-open-link.svg',
})
export class SvgVideoInstrumentsOpenLink{}

@Component({
  standalone: true,
  selector: 'svg-file-downloader-image',
  templateUrl: '../../../../assets/file-downloader-image.svg',
})
export class SvgFileDownloaderImage{}

@Component({
  standalone: true,
  selector: 'svg-file-downloader-video',
  templateUrl: '../../../../assets/file-downloader-video.svg',
})
export class SvgFileDownloaderVideo{}

@Component({
  standalone: true,
  selector: 'svg-document-back-arrow',
  templateUrl: '../../../../assets/back-arrow.svg',
})
export class SvgDocumentArrowBack {}

@Component({
  standalone: true,
  selector: 'svg-message',
  templateUrl: '../../../../assets/message.svg',
})
export class SvgMessage {}

@Component({
  standalone: true,
  selector: 'svg-pencil-header',
  templateUrl: '../../../../assets/pencil-header.svg',
})
export class SvgPencilHeader {}

@Component({
  standalone: true,
  selector: 'svg-photo',
  templateUrl: '../../../../assets/create-employee-modal/photo.svg',
})
export class SvgPhoto {}

@Component({
  standalone: true,
  selector: 'svg-chips-x',
  templateUrl: '../../../../assets/chips-x.svg',
})
export class SvgChipsX {}

@Component({
  standalone: true,
  selector: 'svg-question-mark-circle',
  templateUrl: '../../../../assets/questionmark.circle.svg',
})
export class SvgQuestionMarkCircle {}

@Component({
  standalone: true,
  selector: 'svg-info-circle',
  templateUrl: '../../../../assets/invoicing-modal/info.circle.svg',
})
export class SvgInfoCircle {}

@Component({
  standalone: true,
  selector: 'svg-trash',
  templateUrl: '../../../../assets/trash.svg',
})
export class SvgTrash {}

@Component({
  standalone: true,
  selector: 'svg-error',
  templateUrl: '../../../../assets/error.svg',
})
export class SvgError {}

@Component({
  standalone: true,
  selector: 'svg-nav-settings',
  templateUrl: '../../../../assets/navigation-icons/settings.svg',
})
export class SvgNavSettings {}

@Component({
  standalone: true,
  selector: 'svg-nav-news',
  templateUrl: '../../../../assets/navigation-icons/news.svg',
})
export class SvgNavNews {}

@Component({
  standalone: true,
  selector: 'svg-nav-my-team',
  templateUrl: '../../../../assets/navigation-icons/my-team.svg',
})
export class SvgNavMyTeam {}

@Component({
  standalone: true,
  selector: 'svg-nav-knowledge-base',
  templateUrl: '../../../../assets/navigation-icons/knowledge-base.svg',
})
export class SvgNavKnowledgeBase {}
