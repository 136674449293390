import { NavItem } from './section-settings-state';

export type SectionSettingsAction = ChangeItemAction;
export enum SectionSettingsActionTypes {
  SELECT_ITEM,
}

export interface ChangeItemAction {
  readonly type: SectionSettingsActionTypes.SELECT_ITEM;
  readonly item: NavItem;
}
