import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-core-checkbox-field',
  templateUrl: './checkbox-field.component.html',
})
export class CheckboxFieldComponent {
  @Input() className: string | string[] = [];
  @Input() id: string = Date.now().toString();
  @Input() label: string = '';
  @Input() inactive: boolean = false
  @Input() value: boolean = false;
  @Input() hoverType: 'base' | 'blue' = 'base'
  @Input() inactiveStyle: 'base' | 'gray' = 'gray'

  @Output() onToggle: EventEmitter<void> = new EventEmitter<void>();

  onToggleValue() {
    if (!this.onToggle || this.inactive) return;
    this.onToggle.emit();
  }
}
