import { Injectable } from '@angular/core';
import { HierarchyDocumentEntity } from '../../domain/hierarchy-document-entity';
import { Constants } from '../common/constants';
import { PathEntity } from '../../../../../../core/components/path-line/path-line.component';

@Injectable()
export class SectionState {
  readonly id: number = Constants.NO_ID;
  readonly title: string = '';
  readonly url: string = '';
  readonly documents: Array<HierarchyDocumentEntity> = [];
  readonly openDocuments: Array<string> = new Array<string>();
  readonly navItem: NavigationItem = NavigationItem.ALL_PAGES;
  readonly pathEntities: PathEntity[] = [];
  readonly idActiveDocument: string = Constants.NO_ID.toString();
  readonly isLoading: boolean = true;
}

export enum NavigationItem {
  SETTINGS,
  ALL_PAGES,
  DOCUMENT,
}
