import { YooptaEditorComponent } from "../../../../../core/components/yoopta-editor/yoopta-editor.component";

export type DocumentAction =
  | ChangeTitleAction
  | SaveAction
  | InitChildrenAction
  | BackAction
  | CloseGoToSpaceModalAction
  | BackClickedAction
  | SaveAndMoveAction
  | CloseAfkModalAction
  | CloseBackModalAction
  | CloseSessionAction

export enum DocumentActionTypes {
  CHANGE_TITLE,
  SAVE_CLICKED,
  BACK_CLICKED,
  INIT_CHILDREN,
  MOVE_BACK,
  SAVE_AND_MOVE,
  CLOSE_GO_TO_SPACE_MODAL,
  CLOSE_AFK_MODAL,
  CLOSE_BACK_MODAL,
  CLOSE_SESSION,
}
export interface CloseSessionAction {
  readonly type: DocumentActionTypes.CLOSE_SESSION;
}

export interface CloseBackModalAction {
  readonly type: DocumentActionTypes.CLOSE_BACK_MODAL;
}

export interface CloseAfkModalAction {
  readonly type: DocumentActionTypes.CLOSE_AFK_MODAL;
}

export interface SaveAndMoveAction {
  readonly type: DocumentActionTypes.SAVE_AND_MOVE;
}

export interface BackClickedAction {
  readonly type: DocumentActionTypes.BACK_CLICKED;
}

export interface CloseGoToSpaceModalAction {
  readonly type: DocumentActionTypes.CLOSE_GO_TO_SPACE_MODAL;
}

export interface BackAction {
  readonly type: DocumentActionTypes.MOVE_BACK;
}

export interface InitChildrenAction{
  readonly type: DocumentActionTypes.INIT_CHILDREN;
  readonly editor?: YooptaEditorComponent
}

export interface SaveAction{
  readonly type: DocumentActionTypes.SAVE_CLICKED;
}

export interface ChangeTitleAction {
  readonly type: DocumentActionTypes.CHANGE_TITLE;
  readonly title: string;
}
