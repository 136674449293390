import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { SectionSummaryEntity } from "../../../domain/section-summery-entity";
import { SpaceConstants } from "../../common/space-constants";
import { parseToHighlightedParts } from "src/app/core/utils/parse-to-highlighted-parts";
import {
  HighlightedPartsStr
} from "../../../../../../../core/components/text-with-highlighting/text-with-highlighting.component";
import { DocumentSummaryEntity } from "../../../domain/document-summery-entity";

@Component({
  selector: 'space-section-item',
  templateUrl: './section-item.component.html',
  styleUrls: ['../../common/space.component.scss']
})
export class SectionItemComponent implements OnChanges{
  @Input() section: SectionSummaryEntity = {
    id: '',
    name: '',
    isOpen: false,
    countSearchMatches: 0,
    documents: []
  }
  @Input() canEdit: boolean = false
  @Input() searchStr: string = ''
  @Input() selectedDocumentId: string = ''

  @Output() deleteClicked = new EventEmitter<string>()
  @Output() editClicked = new EventEmitter<string>()
  @Output() createClicked = new EventEmitter<string>()

  @Output() documentClicked = new EventEmitter<string>()
  @Output() documentCreateClicked = new EventEmitter<string>()
  @Output() documentDeleteClicked = new EventEmitter<{id: string, name: string}>()
  @Output() documentEditClicked = new EventEmitter<string>()

  isPopupOpen: boolean = false
  titleStrings: HighlightedPartsStr[] = []

  protected readonly SpaceConstants = SpaceConstants

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['searchStr'] || changes['section']){
      this.titleStrings = parseToHighlightedParts(this.section.name, this.searchStr)
    }
  }

  arrowClick(){
    this.section.isOpen = !this.section.isOpen
  }

  popupElementClicked(id: string){
    switch(id){
      case 'edit':
        this.editClicked.emit(this.section.id)
        break;
      case 'delete':
        this.deleteClicked.emit(this.section.id)
    }
    this.isPopupOpen = false
  }
}
