import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ToastsService } from 'src/app/core/components/toast-alert/services/toast-alert.service';
import { ToastState } from 'src/app/core/components/toast-alert/toast-alert.component';
import { Executor } from 'src/app/core/mvi/store';
import { Validator } from 'src/app/core/validators/validator';
import { RatingScaleService } from '../../../@data/services/rating-scale.service';
import {
  CreateRatingScaleAction,
  CreateRatingScaleActionTypes,
} from './create-rating-scale-action';
import {
  CreateRatingScaleResultAction,
  CreateRatingScaleResultActionTypes,
} from './create-rating-scale-result-action';
import {
  CreateRatingScaleError,
  CreateRatingScaleState,
} from './create-rating-scale-state';

@Injectable({
  providedIn: 'any',
})
export class CreateRatingScaleExecutor extends Executor<
  CreateRatingScaleState,
  CreateRatingScaleAction,
  CreateRatingScaleResultAction
> {
  constructor(
    @Inject('CreateRatingScaleNameValidator')
    private nameValidator: Validator,

    @Inject('CreateRatingScaleLevelDescriptionValidator')
    private levelDescriptionValidator: Validator,

    @Inject('CreateRatingScaleLevelPointsValidator')
    private levelPointsValidator: Validator,

    private toastsService: ToastsService,
    private ratingScaleService: RatingScaleService,
  ) {
    super();
  }

  execute(action: CreateRatingScaleAction) {
    switch (action.type) {
      case CreateRatingScaleActionTypes.OPEN_MODAL:
        if (action.ratingScale) {
          this.reduce({
            type: CreateRatingScaleResultActionTypes.SET_DEFAULT_RATING_SCALE,
            ratingScale: action.ratingScale,
          });
        }
        this.reduce({ type: CreateRatingScaleResultActionTypes.OPEN_MODAL });
        break;

      case CreateRatingScaleActionTypes.CLOSE_MODAL:
        this.reduce({ type: CreateRatingScaleResultActionTypes.CLOSE_MODAL });
        break;

      case CreateRatingScaleActionTypes.CHANGE_NAME:
        this.reduce({
          type: CreateRatingScaleResultActionTypes.CHANGE_NAME,
          name: action.name,
        });
        break;

      case CreateRatingScaleActionTypes.CHANGE_LEVEL_NAME:
        this.reduce({
          type: CreateRatingScaleResultActionTypes.CHANGE_LEVEL_NAME,
          levelLocalId: action.levelLocalId,
          levelName: action.levelName,
        });
        break;

      case CreateRatingScaleActionTypes.CHANGE_LEVEL_POINTS:
        {
          const levelPointsError = this.levelPointsValidator.validate(
            action.levelPoints,
          );

          if (levelPointsError) {
            this.reduce({
              type: CreateRatingScaleResultActionTypes.CHANGE_LEVEL_POINTS_ERROR_STATUS,
              levelLocalId: action.levelLocalId,
              levelPointsError: levelPointsError,
            });
          } else {
            this.reduce({
              type: CreateRatingScaleResultActionTypes.CHANGE_LEVEL_POINTS,
              levelLocalId: action.levelLocalId,
              levelPoints: parseInt(action.levelPoints),
            });
          }
        }
        break;

      case CreateRatingScaleActionTypes.ADD_LEVEL:
        this.reduce({ type: CreateRatingScaleResultActionTypes.ADD_LEVEL });
        break;

      case CreateRatingScaleActionTypes.REMOVE_LEVEL:
        this.reduce({
          type: CreateRatingScaleResultActionTypes.REMOVE_LEVEL,
          levelLocalId: action.levelLocalId,
        });
        break;

      case CreateRatingScaleActionTypes.SAVE_RATING_SCALE:
        {
          let hasError = false;
          const state = this.getState();

          if (state.levels.length < 1) {
            hasError = true;
            this.toastsService.createToast({
              title: 'Уровни не определены',
              description: 'Добавьте хотя бы один уровень',
              state: ToastState.ERROR,
            });
          }

          const nameError = this.nameValidator.validate(state.name);
          if (nameError) {
            hasError = true;
            this.reduce({
              type: CreateRatingScaleResultActionTypes.CHANGE_NAME_ERROR_STATUS,
              nameError,
            });
          }

          for (const level of state.levels) {
            if (level.pointsError) {
              hasError = true;
            }

            const levelNameError = this.levelDescriptionValidator.validate(
              level.name,
            );
            if (levelNameError) {
              hasError = true;
              this.reduce({
                type: CreateRatingScaleResultActionTypes.CHANGE_LEVEL_NAME_ERROR_STATUS,
                levelId: level.localId,
                levelNameError,
              });
            }

            const levelPointsError = this.levelPointsValidator.validate(
              level.points ? level.points.toString() : 'shit',
            );
            if (levelPointsError) {
              hasError = true;
              this.reduce({
                type: CreateRatingScaleResultActionTypes.CHANGE_LEVEL_POINTS_ERROR_STATUS,
                levelLocalId: level.localId,
                levelPointsError,
              });
            }
          }

          if (!hasError) {
            this.reduce({
              type: CreateRatingScaleResultActionTypes.CHANGE_LOADING_STATUS,
              isLoading: true,
            });
            if (state.id) {
              this.ratingScaleService
                .updateRatingScale({
                  id: state.id,
                  name: state.name,
                  levels: state.levels.map((level) => ({
                    id: level.id,
                    name: level.name!,
                    points: level.points!,
                  })),
                })
                .pipe(
                  finalize(() => {
                    this.reduce({
                      type: CreateRatingScaleResultActionTypes.CHANGE_LOADING_STATUS,
                      isLoading: false,
                    });
                    this.ratingScaleService.createRatingScaleEvent$.next();
                  }),
                )
                .subscribe({
                  next: () => {
                    this.toastsService.createToast({
                      title: 'Оценочная шкала успешно обновлена',
                      description: '',
                      state: ToastState.SUCCESS,
                    });
                    this.reduce({
                      type: CreateRatingScaleResultActionTypes.RESET_STATE,
                    });
                  },
                  error: (error: HttpErrorResponse) => {
                    this.reduce({
                      type: CreateRatingScaleResultActionTypes.CHANGE_ERROR_STATUS,
                      error: (() => {
                        this.toastsService.createToast({
                          title: 'Не удалось обновить оценочную шкалу',
                          description: 'Код ошибки: ' + error.status,
                          state: ToastState.ERROR,
                        });

                        switch (error.status) {
                          case HttpStatusCode.Unauthorized:
                            return CreateRatingScaleError.INCORRECT_CREDENTIALS;
                          default:
                            return CreateRatingScaleError.CONNECTION_ERROR;
                        }
                      })(),
                    });
                  },
                });
            } else {
              this.ratingScaleService
                .createRatingScale({
                  name: state.name,
                  levels: state.levels.map((level) => ({
                    name: level.name!,
                    points: level.points!,
                  })),
                })
                .pipe(
                  finalize(() => {
                    this.reduce({
                      type: CreateRatingScaleResultActionTypes.CHANGE_LOADING_STATUS,
                      isLoading: false,
                    });
                    this.ratingScaleService.createRatingScaleEvent$.next();
                  }),
                )
                .subscribe({
                  next: () => {
                    this.toastsService.createToast({
                      title: 'Оценочная шкала успешно создана',
                      description: '',
                      state: ToastState.SUCCESS,
                    });
                    this.reduce({
                      type: CreateRatingScaleResultActionTypes.RESET_STATE,
                    });
                  },
                  error: (error: HttpErrorResponse) => {
                    this.reduce({
                      type: CreateRatingScaleResultActionTypes.CHANGE_ERROR_STATUS,
                      error: (() => {
                        this.toastsService.createToast({
                          title: 'Не удалось создать оценочную шкалу',
                          description: 'Код ошибки: ' + error.status,
                          state: ToastState.ERROR,
                        });

                        switch (error.status) {
                          case HttpStatusCode.Unauthorized:
                            return CreateRatingScaleError.INCORRECT_CREDENTIALS;
                          default:
                            return CreateRatingScaleError.CONNECTION_ERROR;
                        }
                      })(),
                    });
                  },
                });
            }
          }
        }
        break;
    }
  }
}
