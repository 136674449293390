<div
  class="flex justify-between text-tt-font-size_l font-normal"
  [ngClass]="className"
>
  <div class="flex gap-[8px]">
    <div
      *ngFor="let item of menuItems"
      class="px-[8px] py-[4px] leading-[20px] cursor-pointer rounded-tt-border-radius_m hover:bg-tt-hover-color"
      [ngClass]="{
        'text-tt-primary border-[1px] border-tt-border-default': item.isActive,
        'text-tt-text-secondary': !item.isActive
      }"
      (click)="menuItemClicked.emit(item.id)"
    >
      {{ item.title }}
    </div>
  </div>
  <div class="relative">
    <div
      class="flex text-tt-text-secondary rounded-tt-border-radius_m cursor-pointer hover:bg-tt-hover-color"
      (click)="popupVisibility = !popupVisibility"
      [ngClass]="{ 'bg-tt-hover-color': popupVisibility }"
    >
      <p class="px-[8px] py-[4px] leading-[20px]">
        Сортировка: {{ getCurrentSortType().name }}
      </p>
      <svg-arrow class="my-auto text-tt-icon-secondary w-6 h-6 rotate-90" />
    </div>
    <app-core-action-menu
      class="absolute z-50 top-[30px] right-[0px]"
      *ngIf="popupVisibility"
      variant="standard"
      [sections]="sortTypes"
      (elementClicked)="sortClicked($event)"
    />
  </div>
</div>
