import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-core-modal-content-footer',
  templateUrl: './modal-content-footer.component.html',
  styleUrls: ['./modal-content-footer.component.scss'],
})
export class ModalContentFooterComponent {
  @Input() className: string | string[] = [];
  @Input() widthType: 'full' | 'base' = 'base'
}
