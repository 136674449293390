import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-file-downloader-popup',
  templateUrl: './file-downloader-popup.component.html',
  styleUrls: ['../../../../../features/main/presentation/main.component.scss']
})
export class FileDownloaderPopupComponent {
  @Input() isVisible: boolean = true
  @Output() uploadClicked = new EventEmitter()
  @Output() urlConfirmClicked = new EventEmitter<string>()

  inputUrlValue: string = ''
  currentPopupItem: PopupItem = PopupItem.UPLOAD
  protected readonly PopupItem = PopupItem;
}

enum PopupItem {
  UPLOAD,
  PASTE_URL
}
