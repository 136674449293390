import {
  FormField
} from "../../../../../../../employees/modules/employees-list/presentation/components/create-employee-modal/state/create-employee-modal-state";

export type InvoicingModalResultAction =
  | ChangeOrganisationResultNameAction
  | ChangeLegalAddressResultAction
  | ChangeINNResultAction
  | ChangeKPPResultAction
  | ChangePhoneNumberResultAction
  | ToggleSoleProprietorResultAction
  | InitOnChangesResultAction
  | OpenInvoicePaymentModalResultAction
  | ValidateFieldsResultAction

export enum InvoicingModalResultActionTypes {
  CHANGE_ORGANISATION_NAME,
  CHANGE_LEGAL_ADDRESS,
  TOGGLE_I_SOLE_PROPRIETOR,
  CHANGE_INN,
  CHANGE_KPP,
  CHANGE_PHONE_NUMBER,
  INIT_ON_CHANGES,
  OPEN_INVOICE_PAYMENT_MODAL,
  VALIDATE_FIELDS
}

export interface OpenInvoicePaymentModalResultAction{
  readonly type: InvoicingModalResultActionTypes.OPEN_INVOICE_PAYMENT_MODAL,
}

export interface InitOnChangesResultAction{
  readonly type: InvoicingModalResultActionTypes.INIT_ON_CHANGES,
  readonly countUsers: number
  readonly periodType: 'month' | 'year'
}

export interface ChangeOrganisationResultNameAction {
  readonly type: InvoicingModalResultActionTypes.CHANGE_ORGANISATION_NAME,
  readonly value: FormField
}

export interface ChangeLegalAddressResultAction {
  readonly type: InvoicingModalResultActionTypes.CHANGE_LEGAL_ADDRESS;
  readonly value: FormField
}

export interface ChangeINNResultAction {
  readonly type: InvoicingModalResultActionTypes.CHANGE_INN;
  readonly value: FormField
}

export interface ChangeKPPResultAction {
  readonly type: InvoicingModalResultActionTypes.CHANGE_KPP;
  readonly value: FormField
}

export interface ChangePhoneNumberResultAction {
  readonly type: InvoicingModalResultActionTypes.CHANGE_PHONE_NUMBER;
  readonly value: FormField
}

export interface ToggleSoleProprietorResultAction {
  readonly type: InvoicingModalResultActionTypes.TOGGLE_I_SOLE_PROPRIETOR;
  readonly value: boolean
}

export interface ValidateFieldsResultAction {
  readonly type: InvoicingModalResultActionTypes.VALIDATE_FIELDS;
  readonly organisationName: FormField;
  readonly legalAddress: FormField;
  readonly INN: FormField;
  readonly KPP: FormField;
  readonly phoneNumber: FormField;
}
