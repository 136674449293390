import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from 'src/app/core/components/components.module';
import {
  SvgCheckmarkCircleIcon,
  SvgXmarkCircleIcon,
} from 'src/app/core/components/svg-components/svg.components';
import { EmptyRule } from 'src/app/core/validators/rule';
import { Validator } from 'src/app/core/validators/validator';
import { CreateSkillExecutor } from './presentation/state/create-skill-executor';
import { CreateSkillReducer } from './presentation/state/create-skill-reducer';
import { CreateSkillState } from './presentation/state/create-skill-state';
import { CreateSkillComponent } from './presentation/view/create-skill.component';

@NgModule({
  declarations: [CreateSkillComponent],
  exports: [CreateSkillComponent],
  providers: [
    CreateSkillState,
    CreateSkillReducer,
    CreateSkillExecutor,
    {
      provide: 'CreateSkillNameValidator',
      useExisting: Validator,
      useFactory: CreateSkillModule.nameValidatorFactory,
    },
    {
      provide: 'CreateSkillLevelDescriptionValidator',
      useExisting: Validator,
      useFactory: CreateSkillModule.levelDescriptionValidatorFactory,
    },
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    SvgCheckmarkCircleIcon,
    SvgXmarkCircleIcon,
  ],
})
export class CreateSkillModule {
  public static nameValidatorFactory = () =>
    new Validator([new EmptyRule('Введите название навыка')]);

  public static levelDescriptionValidatorFactory = () =>
    new Validator([new EmptyRule('Введите описание уровня')]);
}
