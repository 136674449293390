import { SectionEditEntity } from '../../../../domain/section-edit-entity';

export type SectionSettingsMainResultAction =
  | UpdateSectionResultAction
  | ChangeCurrentSectionResultAction
  | UpdateIconUrlsListResultAction;
export enum SectionSettingsResultActionTypes {
  UPDATE_SECTION,
  UPDATE_ICONS,
  CHANGE_CURRENT_SECTION,
}

export interface UpdateIconUrlsListResultAction {
  readonly type: SectionSettingsResultActionTypes.UPDATE_ICONS;
  readonly iconUrls: string[]
}

export interface UpdateSectionResultAction {
  readonly type: SectionSettingsResultActionTypes.UPDATE_SECTION;
  readonly section: SectionEditEntity;
}

export interface ChangeCurrentSectionResultAction {
  readonly type: SectionSettingsResultActionTypes.CHANGE_CURRENT_SECTION;
  readonly section: SectionEditEntity;
  readonly titleError: string;
}
