import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ComponentsModule } from 'src/app/core/components/components.module';
import {
  SvgEditPencil,
  SvgOfficeBuilding,
  SvgTrashRed,
  SvgTripleDot,
} from 'src/app/core/components/svg-components/svg.components';
import { CrateRatingScaleModule } from '../create-rating-scale/create-rating-scale.module';
import { RatingScaleItemComponent } from './components/rating-scale-item/rating-scale-item.component';
import { RatingScalesComponent } from './presentation/view/rating-scales.component';

@NgModule({
  declarations: [RatingScalesComponent, RatingScaleItemComponent],
  exports: [RatingScalesComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterOutlet,
    CrateRatingScaleModule,
    SvgOfficeBuilding,
    SvgTripleDot,
    SvgTrashRed,
    SvgEditPencil,
  ],
})
export class RatingScalesModule {}
