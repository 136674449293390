import { Injectable } from '@angular/core';
import { FilterMenuItem } from '../../../../../../core/components/filter-menu/filter-menu.component';
import { DocumentItemEntity } from '../../domain/document-item-entity';
import { ActionElement } from '../../../../../../core/components/action-menu/action-menu.component';
import { Constants } from '../../../../sections/impl/presentation/common/сonstants';

@Injectable()
export class SectionContentState {
  readonly selectedItem: FilterParams = FilterParams.ALL;
  readonly documents: DocumentItemEntity[] = [];
  readonly filteredDocuments: DocumentItemEntity[] = [];
  readonly filterState: FiltersState = new FiltersState();
  readonly filterItems: FilterMenuItem[] = [
    {
      id: FilterParams.ALL.valueOf(),
      title: 'Все',
      isActive: true,
    },
    {
      id: FilterParams.MARKED.valueOf(),
      title: 'Отмеченные',
      isActive: false,
    },
  ];
}

export class FiltersState {
  readonly filter: string = '';
  readonly filterParam: FilterParams = FilterParams.ALL;
  readonly sortParam: ActionElement =
    Constants.SORT_POPUP_SECTION[0].elements[0];
}

export enum FilterParams {
  ALL,
  MARKED,
}

export enum SortParams {
  ALPHABET = 'alphabet',
  ALPHABET_REVERT = 'alphabet-revert',
  NEW_TO_OLD = 'new-to-old',
  OLD_TO_NEW = 'old-to-new',
}
