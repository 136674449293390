import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { AppPaths } from "../../../../../../app-routing.module";

@Injectable({providedIn: 'root'})
export class SpaceNavigator {
  baseUrl: string;

  constructor(
    private platform: PlatformLocation,
    private router: Router)
  {
    this.baseUrl = platform.port ? 'http://' + platform.hostname + ':' + platform.port : 'https://' + platform.hostname
  }

  openEditDocument(documentId: string) {
    this.router.navigateByUrl(AppPaths.DOCUMENT+ '/' + documentId);
    //window.open(`${this.baseUrl}/document/${documentId}`, '_blank');
  }
}
