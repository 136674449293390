import { SectionService } from '../domain/section-service';
import { SectionEntity } from '../domain/section-entity';
import { HierarchyDocumentEntity } from '../domain/hierarchy-document-entity';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SectionPrivacy } from '../../../sections/impl/domain/section-summary-entity';
import { environment } from '../../../../../../environments/environment';
import {
  DocumentJson,
  GetSectionResponseJson,
} from './json/section-response-json';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Constants } from '../presentation/common/constants';

@Injectable()
export class SectionServiceImpl implements SectionService {
  private mutableSection: BehaviorSubject<SectionEntity> =
    new BehaviorSubject<SectionEntity>(
      new SectionEntity(
        Constants.NO_ID,
        '',
        '',
        SectionPrivacy.PUBLIC,
        true,
        [],
      ),
    );

  section: Observable<SectionEntity> = this.mutableSection.asObservable();

  constructor(private http: HttpClient) {}

  fetchSection(sectionId: number) {
    this.http
      .get<GetSectionResponseJson>(
        `${environment.apiUrl}/documentation/section/${sectionId}`,
      )
      .pipe(
        map((getSectionResponse) => {
          const section: SectionEntity = {
            id: getSectionResponse.id,
            title: getSectionResponse.title,
            thumbnailUrl: getSectionResponse.thumbnailUrl,
            visibility: SectionPrivacy.PUBLIC,
            canEdit: getSectionResponse.canEdit,
            documents: this.toHierarchyDocumentEntities(
              getSectionResponse.documents,
            ),
          };
          return section;
        }),
      )
      .subscribe((section) => {
        this.mutableSection.next(section);
      });
  }

  getSection(): Observable<SectionEntity> {
    return this.section;
  }

  createDocument(
    sectionId: number,
    title: string,
    parentDocumentId: string,
  ): Observable<string> {
    const body = {
      title: title,
      parentDocumentId:
        parentDocumentId != Constants.NO_ID.toString()
          ? parentDocumentId
          : null,
      sectionId: sectionId,
    };

    return this.http
      .post<string>(`${environment.apiUrl}/documentation/document/create`, body)
      .pipe(
        map((documentId) => {
          this.fetchSection(this.mutableSection.value.id);
          return documentId;
        }),
      );
  }

  deleteDocument(id: string): Observable<null> {
    return this.http.delete<null>(
      `${environment.apiUrl}/documentation/document/${id}`,
    );
  }

  private toHierarchyDocumentEntities(
    documentJsons: DocumentJson[],
  ): HierarchyDocumentEntity[] {
    return documentJsons.map((documentJson) => {
      return new HierarchyDocumentEntity(
        documentJson.id,
        documentJson.title,
        documentJson.isDraft,
        this.toHierarchyDocumentEntities(documentJson.children),
      );
    });
  }
}
