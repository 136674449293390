import { Injectable } from '@angular/core';
import { clone } from 'cloneable-ts';
import { Reducer } from 'src/app/core/mvi/store';
import {
  SpacesResultAction,
  SpacesResultActionTypes,
} from './spaces-result-action';
import { SpacesState } from './spaces-state';
import { DocumentSummaryEntity } from "../../../space/domain/document-summery-entity";
import { SpaceSummeryEntity } from "../components/space-item/space-item.component";

@Injectable()
export class SpacesReducer
  implements Reducer<SpacesState, SpacesResultAction>
{
  reduce(state: SpacesState, action: SpacesResultAction): SpacesState {
    switch (action.type) {
      case SpacesResultActionTypes.CHANGE_VISIBLE_CREATE_SPACE_MODAL:
        return {...state, isCreateSpaceModalVisible: action.value}
      case SpacesResultActionTypes.UPDATE_SPACES:
        return {...state,
          canCreate: action.canCreate,
          spaces: this.sortSpaces(action.spaces),
          recentSpaces: this.sortSpaces(action.recentSpaces)
        }
      case SpacesResultActionTypes.CHANGE_IS_LOADING:
        return {...state,
          isLoading: action.value
        }
      case SpacesResultActionTypes.FILTER_SPACES:
        return {...state,
          filterString: action.filterStr,
          filteredSpaces: this.sortSpaces(action.filteredSpaces)
        }
      case SpacesResultActionTypes.CHANGE_IS_CREATING:
        return {...state, isCreating: action.value}
    }
  }
  sortSpaces(spaces: SpaceSummeryEntity[]): SpaceSummeryEntity[] {
    return spaces.sort((a, b) => {
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    })
  }
}
