import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from "@angular/core";
import { SpaceState } from './state/space-state';
import { SpaceExecutor } from './state/space-executor';
import { SpaceAction, SpaceActionTypes } from './state/space-action';
import { SpaceResultAction } from './state/space-result-action';
import { SpaceReducer } from './state/space-reducer';
import { Store } from 'src/app/core/mvi/store';
import { SpaceConstants } from './common/space-constants';
import { SpaceNavItems } from '../domain/nav-items';
import { HeaderService } from 'src/app/features/main/data/header-service';
import { KnowledgeBaseConstants } from '../../../common/knowledge-base-constants';
import { MainRoutesPaths } from 'src/app/routes/main-routes';
import { onSpaceScroll, resizeDocumentsContainer } from './utils/space-scroll';
import { MainConstants } from "../../../../main/presentation/common/main-constants";

@Component({
  selector: 'app-space-component',
  templateUrl: './space.component.html',
  styleUrls: [
    './common/space.component.scss',
    '../../../../main/presentation/main.component.scss',
  ],
  providers: [
    SpaceState,
    SpaceExecutor,
    SpaceReducer,
  ]
})
export class SpaceComponent
extends Store<
  SpaceState,
  SpaceExecutor,
  SpaceAction,
  SpaceResultAction
>
implements AfterViewInit, OnInit
{
  @ViewChild('documentsList') documentsList?: ElementRef
  @ViewChild('scrollableEl') scrollableEl?: ElementRef
  @ViewChild('documentsSearch') documentsSearch?: ElementRef
  @ViewChild('createButton') createButton?: ElementRef
  @ViewChild('editorSide') editorSide?: ElementRef
  @ViewChild('editorContainer') editorContainer?: ElementRef

  scrollTopValue: number = 0
  sideBarWidth: number = 300
  documentPadding: number = SpaceConstants.CONTENT_TOP_OFFSET
  editorMaxWidth: number = 800

  protected readonly SpaceNavItems = SpaceNavItems
  protected readonly SpaceConstants = SpaceConstants
  protected readonly SpaceActionTypes = SpaceActionTypes

  constructor(
    state: SpaceState,
    executor: SpaceExecutor,
    reducer: SpaceReducer,
    private headerService: HeaderService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    super(state, executor, reducer);
  }

  ngOnInit(): void {
    this.performAction({
      type: SpaceActionTypes.UPDATE_SPACE
    })

    this.headerService.ChangeBreadCrumbs([{
      name: KnowledgeBaseConstants.KNOWLEDGE_BASE,
      href: '/' + MainRoutesPaths.KNOWLEDGE_BASE
    }])
    this.headerService.ChangeStyle('bg-tt-bg-page')
  }

  ngAfterViewInit(): void {
    resizeDocumentsContainer(this.renderer, this.documentsSearch, this.documentsList)
    this.changeEditorWidth()
  }

  onScroll(){
    if(this.scrollableEl){
      this.scrollTopValue = onSpaceScroll(
        this.scrollableEl,
        this.headerService,
        this.state.id,
        this.state.name,
        this.renderer,
        this.documentsSearch,
        this.documentsList
      )
    }
    this.changeDocumentPadding()
  }

  changeSideBarWidth(size: number){
    this.sideBarWidth = size
    this.changeDocumentPadding()
    this.changeEditorWidth()
  }

  @HostListener('window:resize', ['$event'])
  onResize(){
    this.onScroll()
    this.changeEditorWidth()
  }

  changeEditorWidth(){
    const newWidth = window.innerWidth - 256 - this.sideBarWidth - 52//24 padding x
    this.editorMaxWidth = 800 > newWidth? newWidth : 800

    if(this.editorContainer)
    {
      this.renderer.setStyle(this.editorContainer.nativeElement, 'max-width', this.editorMaxWidth + 'px', 1)
    }
  }

  changeDocumentPadding(){
    this.documentPadding = this.sideBarWidth

    if(this.editorSide)
    {
      this.renderer.setStyle(this.editorSide.nativeElement, 'padding-left', this.documentPadding + 'px', 1)
    }
  }
}
