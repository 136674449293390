import { Injectable } from '@angular/core';
import { RatingScaleDto } from '../../../@data/dto/rating-scale.dto';

@Injectable()
export class RatingScalesState {
  readonly selectedRatingScale: string | null = null;
  readonly search: string = '';
  readonly ratingScales: RatingScaleDto[] = [];
  readonly isLoading: boolean = true;
  readonly error: RatingScalesError | null = null;
  readonly isLoadingDelete: string | null = null;
  readonly errorDelete: RatingScalesError | null = null;
}

export enum RatingScalesError {
  INCORRECT_CREDENTIALS,
  CONNECTION_ERROR,
}
