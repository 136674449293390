import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NewsComponent } from './presentation/news.component';
import { ComponentsModule } from "../../core/components/components.module";
import { RouterOutlet } from "@angular/router";
import { SvgArrow, SvgPlus, SvgXMark } from "../../core/components/svg-components/svg.components";
import { NewsListModule } from "./modules/news-list/news-list.module";
import { NewsCreationWindowComponent } from './presentation/components/news-creation-window/news-creation-window.component';
import { InputNewsImageComponent } from './presentation/components/input-news-image/input-news-image.component';

@NgModule({
  declarations: [
    NewsComponent,
    NewsCreationWindowComponent,
    InputNewsImageComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    NewsListModule,
    RouterOutlet,
    SvgPlus,
    SvgArrow,
    SvgXMark,
    NgOptimizedImage
  ]
})
export class NewsModule { }
