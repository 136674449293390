import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { AssessmentSessionItemComponent } from './components/assessment-session-item/assessment-session-item.component';
import { AssessmentSessionsComponent } from './presentation/view/assessment-sessions.component';

@NgModule({
  declarations: [AssessmentSessionsComponent, AssessmentSessionItemComponent],
  exports: [AssessmentSessionsComponent],
  imports: [CommonModule, ComponentsModule, RouterOutlet],
})
export class AssessmentSessionsModule {}
