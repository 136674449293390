import { Injectable } from "@angular/core";
import { NavigationItem, SectionState } from "./section-state";
import { SectionAction, SectionActionTypes } from "./section-action";
import { SectionResultAction, SectionResultActionTypes } from "./section-result-action";
import { SectionService } from "../../domain/section-service";
import { Executor, Reducer } from "../../../../../../core/mvi/store";
import { ActivatedRoute } from "@angular/router";
import { SectionNavigator } from "../../navigation/section-navigator";
import { Constants } from "../common/constants";
import { HierarchyDocumentEntity } from "../../domain/hierarchy-document-entity";
import { PathEntity } from "../../../../../../core/components/path-line/path-line.component";

@Injectable()
export class SectionExecutor extends Executor<
  SectionState,
  SectionAction,
  SectionResultAction
> {
  constructor(
    private route: ActivatedRoute,
    private sectionService: SectionService,
    private sectionNavigator: SectionNavigator,
  ) {
    super();
  }

  override init(
    reducer: Reducer<SectionState, SectionResultAction>,
    getState: () => SectionState,
    onReduced: (state: SectionState) => void,
  ) {
    super.init(reducer, getState, onReduced);
    this.sectionService.getSection().subscribe((section) => {
      this.reduce({
        type: SectionResultActionTypes.UPDATE_SECTION,
        section: section,
      });
    });
    this.route.url.subscribe(()=>{
      const snapshot = this.route.snapshot
      const sectionId = <number | null>snapshot.paramMap.get('sectionId')
      const documentId = snapshot.children[0].paramMap.get('documentId')
      if(sectionId !== null)
      {
        this.sectionService.fetchSection(sectionId);
      }
      if(documentId !== null)
      {
        this.handleOpenDocument(documentId)
      }
    })
  }

  execute(action: SectionAction) {
    switch (action.type) {
      case SectionActionTypes.CHANGE_DOCUMENT_OPEN_STATE:
        this.reduce({
          type: SectionResultActionTypes.CHANGE_DOCUMENT_OPEN_STATE,
          documentId: action.documentId,
        });
        break;

      case SectionActionTypes.CREATE_DOCUMENT:
        const state = this.getState();
        this.sectionService
          .createDocument(
            state.id,
            'Новый документ',
            action.documentId.toString(),
          )
          .subscribe((docId) => {
            console.log(docId);
          });
        break;

      case SectionActionTypes.OPEN_CONTENT:
        this.handleOpenContent();
        break;

      case SectionActionTypes.OPEN_SETTINGS:
        this.sectionNavigator.openSettings(this.getState().id);
        this.reduce({
          type: SectionResultActionTypes.CHANGE_ACTIVE_NAV_ITEM,
          navItem: NavigationItem.SETTINGS,
          pathEntities: [],
          documentId: Constants.NO_ID.toString(),
        });
        break;

      case SectionActionTypes.OPEN_DOCUMENT:
        this.handleOpenDocument(action.documentId)
        break;

      case SectionActionTypes.COPY_SECTION_LINK:
        this.sectionNavigator.copySectionUrl(this.getState().id)
        break;

      case SectionActionTypes.COPY_DOCUMENT_LINK:
        this.sectionNavigator.copyDocumentUrl(action.documentId)
        break;

      case SectionActionTypes.EDIT_DOCUMENT:
        this.sectionNavigator.openEditDocument(action.documentId)
        break;

      case SectionActionTypes.DELETE_DOCUMENT:
        this.sectionService.deleteDocument(action.documentId).subscribe()
        break;
    }
  }

  handleOpenDocument(documentId: string){
    if (documentId != Constants.NO_ID.toString()) {
      this.sectionNavigator.openDocument(
        this.getState().id,
        documentId,
      );
      this.reduce({
        type: SectionResultActionTypes.CHANGE_ACTIVE_NAV_ITEM,
        navItem: NavigationItem.DOCUMENT,
        pathEntities: this.getPathEntities(documentId),
        documentId: documentId,
      });
    } else {
      this.handleOpenContent();
    }
  }

  handleOpenContent() {
    this.sectionNavigator.openContent(this.getState().id);
    this.reduce({
      type: SectionResultActionTypes.CHANGE_ACTIVE_NAV_ITEM,
      navItem: NavigationItem.ALL_PAGES,
      pathEntities: [{ id: Constants.NO_ID.toString(), text: this.getState().title }],
      documentId: Constants.NO_ID.toString(),
    });
  }

  getPathEntities(documentId: string): PathEntity[] {
    const docs = this.getState().documents;
    const findDoc = docs.find((doc) => {
      return doc.id == documentId;
    });
    let pathEntities: PathEntity[] = [
      { id: Constants.NO_ID.toString(), text: this.getState().title },
    ];
    if (findDoc) {
      pathEntities.push({ id: findDoc.id, text: findDoc.title });
    } else {
      docs.forEach((doc) => {
        const findPath = this.searchDocsPath(documentId, doc, pathEntities);
        if (findPath.length > pathEntities.length + 1) {
          pathEntities = findPath;
        }
      });
    }
    return pathEntities;
  }

  searchDocsPath(
    documentId: string,
    currentDoc: HierarchyDocumentEntity,
    pathEntities: PathEntity[],
  ): PathEntity[] {
    let currentPath = pathEntities.slice();
    currentPath.push({ id: currentDoc.id, text: currentDoc.title });
    const findDoc = currentDoc.documents.find((doc) => {
      return doc.id == documentId;
    });
    if (findDoc) {
      currentPath.push({ id: findDoc.id, text: findDoc.title });
    } else {
      currentDoc.documents.forEach((doc) => {
        const findPath = this.searchDocsPath(documentId, doc, currentPath);
        if (findPath.length > currentPath.length + 1) {
          currentPath = findPath;
        }
      });
    }
    return currentPath;
  }
}
