import { Reducer } from 'src/app/core/mvi/store';
import {
  EmployeesRolesResultAction,
  EmployeesRolesResultActionTypes,
} from './employees-roles-result-action';
import { Injectable } from '@angular/core';
import { EmployeesRolesState } from './employees-roles-state';
import {
  SpaceSummeryEntity
} from "../../../../knowledge-base/modules/spaces/presentation/components/space-item/space-item.component";
import { RoleSummeryEntity } from "../../../domain/role";

@Injectable()
export class EmployeesRolesReducer
  implements Reducer<EmployeesRolesState, EmployeesRolesResultAction>
{
  reduce(
    state: EmployeesRolesState,
    action: EmployeesRolesResultAction,
  ): EmployeesRolesState {
    switch (action.type) {
      case EmployeesRolesResultActionTypes.UPDATE_ROLES:
        return {...state,
          canCreate: action.canCreate,
          canEdit: action.canEdit,
          canDelete: action.canDelete,
          roles: this.sortRoles(action.roles),
          filteredRoles: this.sortRoles(action.filteredRoles)
        }
      case EmployeesRolesResultActionTypes.CHANGE_CREATE_ROLE_MODAL_VISIBLE:
        return {...state,
          isCreateRoleModalVisible: action.value,
          editRoleId: !action.value ? '' : state.editRoleId,
        }
      case EmployeesRolesResultActionTypes.FILTER_ROLES:
        return{...state,
          searchFieldValue: action.filterString,
          filteredRoles: this.sortRoles(action.filteredRoles)
        }
      case EmployeesRolesResultActionTypes.EDIT_ROLE:
        return {...state,
          editRoleId: action.id,
          isCreateRoleModalVisible: true
        }
      case EmployeesRolesResultActionTypes.CHANGE_DELETE_ALERT_MODAL_VISIBILITY:
        return {...state,
          isDeleteAlertVisible: action.value,
          roleDeleteProps: action.deleteProps ? action.deleteProps : null,
        }
      case EmployeesRolesResultActionTypes.CHANGE_IS_LOADING:
        return {...state, isLoading: action.value}
      case EmployeesRolesResultActionTypes.CHANGE_IS_DELETING:
        return {...state, isDeleting: action.value}
    }
  }
  sortRoles(roles: RoleSummeryEntity[]): RoleSummeryEntity[] {
    return roles.sort((a, b) => {
      if(a.canEdit)
      {
        return b.canEdit ? (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : 1
      }
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    })
  }
}
