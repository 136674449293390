<div class="flex" [ngClass]="variant">
  <button (click)="isOpen = !isOpen">
    <img
      class="mt-[8px] w-20 h-20 rounded-tt-border-radius_s"
      src="{{ currentIconUrl }}"
    />
  </button>

  <div
    *ngIf="isOpen"
    class="p-[16px] absolute left-[96px] bg-white shadow-tt-shadow-popup-menu rounded-tt-border-radius_m z-10 flex flex-col gap-[12px] w-[296px]"
  >
    <div class="flex justify-between">
      <p
        class="text-tt-text-default my-auto font-medium text-tt-font-size_l leading-[16px]"
      >
        {{ title }}
      </p>
      <svg-x-mark class="cursor-pointer" (click)="isOpen = false" />
    </div>

    <p
      class="font-normal text-tt-text-default text-tt-font-size_l leading-[18px]"
    >
      Выберите значок или
      <span
        (click)="downloadClicked()"
        class="font-medium text-tt-secondary cursor-pointer"
        >загрузите изображение</span
      >
    </p>

    <div class="w-full h-[1px] bg-tt-separator-color"></div>

    <div class="flex gap-x-6 gap-y-3 flex-wrap">
      <img
        *ngFor="let url of iconUrls"
        class="cursor-pointer w-[48px] h-[48px]"
        (click)="iconClicked(url)"
        src="{{ url }}"
      />
    </div>
  </div>
</div>
