<div class="flex flex-col justify-between w-full h-full">
  <div>
    <app-core-settings-content-element
      [title]="'Разрешения доступа'"
    >
      <p>Чтобы защитить ваши данные, вы можете настроить роли пользователей: только чтение, редактирование, полный доступ или запретить доступ. <span class="text-tt-primary cursor-pointer">Узнать больше..</span></p>
    </app-core-settings-content-element>

    <div class="h-[1px] w-full bg-tt-border-default"></div>

    <app-core-settings-content-element>
      <create-role-modal-permissions
        [permissions]="permissions"
        [isReadOnly]="isReadOnly"
      />

    </app-core-settings-content-element>
  </div>

  <app-core-settings-content-element
    *ngIf="!isInitEqual()"
  >
    <div class="flex w-full justify-end gap-[16px]">
      <app-core-button
        variant="soft"
        (onClick)="decline()"
      >
        Отменить
      </app-core-button>
      <app-core-button
        (onClick)="save()"
      >
        Сохранить
      </app-core-button>
    </div>

  </app-core-settings-content-element>
</div>
