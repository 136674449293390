<div
  (click)="
    performAction({
      type: CreateRatingScaleActionTypes.OPEN_MODAL,
      ratingScale
    })
  "
>
  <ng-content />
</div>

<app-core-modal
  [open]="this.state.isOpen"
  className="h-[500px] w-full max-w-[900px] flex flex-row"
>
  <app-core-clue
    class="flex-shrink-0"
    className="py-tt_l pl-tt_l"
    title="Оценочная шкала"
  >
    Шкалы описывают уровни проявления навыка и используются для их оценки
  </app-core-clue>
  <app-core-modal-content class="flex-grow" [footer]="true">
    <div class="flex flex-col gap-tt_l">
      <app-core-input-field
        label="Укажите название шкалы"
        variant="standard"
        [required]="true"
        [error]="this.state.nameError"
        [value]="state.name"
        (onChange)="
          performAction({
            type: CreateRatingScaleActionTypes.CHANGE_NAME,
            name: $event
          })
        "
      />
      <div>
        <div class="flex flex-col gap-tt_s mb-tt_s">
          <div class="flex flex-row gap-2">
            <span
              class="flex-grow mb-tt_extra-s text-tt-font-size_l text-tt-text-secondary"
            >
              Уровни
            </span>
            <span
              class="inline-block w-[70px] mb-tt_extra-s text-tt-font-size_l text-tt-text-secondary"
            >
              Баллы
            </span>
            <div class="w-[40px]"></div>
          </div>
          <app-level-field
            *ngFor="let level of this.state.levels; trackBy: levelTrackByFn"
            [level]="level"
            (onChangeName)="
              performAction({
                type: CreateRatingScaleActionTypes.CHANGE_LEVEL_NAME,
                levelLocalId: level.localId,
                levelName: $event
              })
            "
            (onChangeValue)="
              performAction({
                type: CreateRatingScaleActionTypes.CHANGE_LEVEL_POINTS,
                levelLocalId: level.localId,
                levelPoints: $event
              })
            "
            (onRemove)="
              performAction({
                type: CreateRatingScaleActionTypes.REMOVE_LEVEL,
                levelLocalId: level.localId
              })
            "
          />
        </div>
        <app-core-button-add
          (onClick)="
            performAction({ type: CreateRatingScaleActionTypes.ADD_LEVEL })
          "
        >
          Добавить уровень
        </app-core-button-add>
      </div>
    </div>
    <app-core-modal-content-footer>
      <app-core-button
        [disabled]="this.state.isLoading"
        (onClick)="
          performAction({ type: CreateRatingScaleActionTypes.CLOSE_MODAL })
        "
        variant="soft"
      >
        Отмена
      </app-core-button>
      <app-core-button
        [loading]="this.state.isLoading"
        (onClick)="
          performAction({
            type: CreateRatingScaleActionTypes.SAVE_RATING_SCALE
          })
        "
      >
        Сохранить
      </app-core-button>
    </app-core-modal-content-footer>
  </app-core-modal-content>
</app-core-modal>
