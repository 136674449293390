export type SectionAction =
  | ChangeDocumentOpenStateAction
  | CreateDocumentAction
  | OpenContent
  | OpenSettings
  | OpenDocumentAction
  | CopySectionLinkAction
  | EditDocumentAction
  | CopyDocumentLinkAction
  | DeleteDocumentAction;

export enum SectionActionTypes {
  CHANGE_DOCUMENT_OPEN_STATE,
  CREATE_DOCUMENT,
  EDIT_DOCUMENT,
  COPY_DOCUMENT_LINK,
  DELETE_DOCUMENT,
  OPEN_CONTENT,
  OPEN_SETTINGS,
  OPEN_DOCUMENT,
  COPY_SECTION_LINK
}

export interface CopySectionLinkAction {
  readonly type: SectionActionTypes.COPY_SECTION_LINK;
}

export interface ChangeDocumentOpenStateAction {
  readonly type: SectionActionTypes.CHANGE_DOCUMENT_OPEN_STATE;
  readonly documentId: string;
}

export interface CreateDocumentAction {
  readonly type: SectionActionTypes.CREATE_DOCUMENT;
  readonly documentId: string;
}

export interface EditDocumentAction {
  readonly type: SectionActionTypes.EDIT_DOCUMENT;
  readonly documentId: string;
}

export interface CopyDocumentLinkAction {
  readonly type: SectionActionTypes.COPY_DOCUMENT_LINK;
  readonly documentId: string;
}

export interface DeleteDocumentAction {
  readonly type: SectionActionTypes.DELETE_DOCUMENT;
  readonly documentId: string;
}

export interface OpenContent {
  readonly type: SectionActionTypes.OPEN_CONTENT;
}

export interface OpenSettings {
  readonly type: SectionActionTypes.OPEN_SETTINGS;
}

export interface OpenDocumentAction {
  readonly type: SectionActionTypes.OPEN_DOCUMENT;
  readonly documentId: string;
}
