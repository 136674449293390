<div class="group pl-[24px] h-[60px] bg-white w-full pr-[16px] flex justify-between items-center hover:bg-tt-light-blue"
  (mouseover)="isHover = true"
  (mouseout)="isHover = false"
>
  <div class="flex w-full flex-col gap-1"
    [ngClass]="{
      'name-description-size': !role.canEdit || (role.canEdit && isHover) || isPopupVisible
    }"
  >
    <a [routerLink]="'/' + MainRoutesPaths.ROLE + '/' + role.id">
      <p
        class="text-[14px] text-tt-text-default leading-[18px] line-clamp-1"
        app-core-with-highlighting
        [text]="role.nameParts"
      ></p>
      <p class="text-[13px] text-tt-text-secondary leading-[16px] line-clamp-1">{{role.description}}</p>

    </a>
  </div>

  <div class="ml-[16px] w-[48px] h-[48px]"
    [ngClass]="{
      'hidden group-hover:block': false
    }"
  >
    <svg-lock
      *ngIf="!role.canEdit"
      class="w-full h-full flex justify-center items-center text-tt-icon-default"
    />

    <button
      class="w-full h-full flex justify-center items-center group/tripledot"
      *ngIf="sections[0] ? sections[0].elements.length > 0 : false"
      [ngClass]="{
        'visible': isPopupVisible,
        'invisible': !isPopupVisible || !role.canEdit,
        'group-hover:visible': role.canEdit
      }"
      (click)="isPopupVisible = true"
    >
      <div class="size-[40px] flex items-center text-tt-icon-default justify-center rounded-[20px] group-hover/tripledot:bg-tt-button-hover">
        <svg-triple-dot />
      </div>
    </button>

    <app-core-action-menu
      *ngIf="isPopupVisible"
      appPopup
      (popupClose)="isPopupVisible = false"
      [parentOffset]="{x: -4, y: -44}"
      [sections]="sections"
      (elementClicked)="onElementClick($event)"
    />
  </div>
</div>
