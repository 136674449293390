import { Routes } from "@angular/router";
import { EmployeesComponent } from "../features/employees/presentation/employees.component";
import { EMPLOYEES_ROUTES } from "./employees-routes";
import { SettingsComponent } from "../features/settings/presentation/settings.component";
import { SectionsComponent } from "../features/documentation/sections/impl/presentation/view/sections.component";
import { SectionComponent } from "../features/documentation/section/impl/presentation/view/section.component";
import { SECTION_ROUTES } from "./section-routes";
import { ProfileComponent } from "../features/profile/profile/profile.component";
import { PROFILE_ROUTS } from "./profile-routes";
import { MessengerComponent } from "../features/messenger/presentation/view/messenger.component";
import { GradeComponent } from "../features/grade/grade/presentation/view/grade.component";
import { PERSONNEL_ASSESSMENT_ROUTES } from "./personnel-assessment-routes";
import { KnowledgeBaseComponent } from "../features/knowledge-base/presentation/knowledge-base.component";
import { KNOWLEDGE_BASE_ROUTES } from "./knowledge-base-routes";
import { SETTINGS_ROUTES } from "./settings-routes";
import { NewsComponent } from "../features/news/presentation/news.component";
import { NEWS_ROUTES } from "./news-routes";
import { RoleComponent } from "../features/role/presentation/role.component";

export enum MainRoutesPaths {
  EMPLOYEES = 'employees',
  ROLE = 'employees/role',
  SETTINGS = 'settings',
  // SECTIONS = 'sections',
  // SECTION = 'section',
  // PROFILE = 'profile',
  // MESSENGER = 'messenger',
  // GRADE = 'grade',
  KNOWLEDGE_BASE = 'knowledge-base',
  NEWS = 'news'
}

export const MAIN_ROUTES: Routes =
[
  {
    path: MainRoutesPaths.ROLE + '/:id',
    component: RoleComponent,
  },
  {
    path: MainRoutesPaths.EMPLOYEES,
    component: EmployeesComponent,
    children: EMPLOYEES_ROUTES,
  },
  {
    path: MainRoutesPaths.SETTINGS,
    component: SettingsComponent,
    children: SETTINGS_ROUTES
  },
  // {
  //   path: MainRoutesPaths.SECTIONS,
  //   component: SectionsComponent,
  // },
  // {
  //   path: MainRoutesPaths.SECTION + '/:sectionId',
  //   component: SectionComponent,
  //   children: SECTION_ROUTES,
  // },
  // {
  //   path: MainRoutesPaths.PROFILE,
  //   component: ProfileComponent,
  //   children: PROFILE_ROUTS,
  // },
  // {
  //   path: MainRoutesPaths.MESSENGER,
  //   component: MessengerComponent,
  // },
  // {
  //   path: MainRoutesPaths.GRADE,
  //   component: GradeComponent,
  //   children: PERSONNEL_ASSESSMENT_ROUTES,
  // },
  {
    path: MainRoutesPaths.KNOWLEDGE_BASE,
    component: KnowledgeBaseComponent,
    children: KNOWLEDGE_BASE_ROUTES
  },
  {
    path: MainRoutesPaths.NEWS,
    component: NewsComponent,
    children: NEWS_ROUTES
  },
];
