import { SectionEntity } from './section-entity';
import { Observable } from 'rxjs';

export abstract class SectionService {
  abstract section: Observable<SectionEntity>;

  abstract fetchSection(sectionId: number): void;

  abstract getSection(): Observable<SectionEntity>;

  abstract deleteDocument(id: string): Observable<null>;

  abstract createDocument(
    sectionId: number,
    title: string,
    parentDocumentId: string,
  ): Observable<string>;
}
