<div class="h-full w-full">
  <div
    class="relative h-full w-full"
    [ngClass]="{
      'pb-[48px]': footer,
      'pt-[166px]': horizontalClue
    }"
  >
    <app-nav-tabs
      *ngIf="withNavTabs"
      [tabs]="tabs"
      [selectedId]="selectedTabId"
      [startFromLine]="true"
      (tabClicked)="tabClicked.emit($event)"
    />

    <div
      class="w-full tiny-scrollbar"
      [style.height]="'calc(100%' + (withNavTabs ? ' - 41px' : '') + ')'"
      [ngClass]="{
        'overflow-y-auto': overflowY === 'auto',
        'overflow-y-scroll': overflowY === 'scroll',
      }"
    >
      <div [ngClass]="{
        'h-full': contentHeight === 'full'
      }">
        <ng-content />
      </div>
    </div>
  </div>
</div>
