<div class="w-full h-[80px] p-[8px] flex items-center rounded-tt-border-radius_l"
     [ngClass]="{
    'dashed-border': !filesHovered,
    'hover-dashed-border bg-tt-bg-unaccented': filesHovered
  }"
>
  <button (click)="showImageWindow($event)">
    <img src="{{currentImageUrl}}" draggable="false" width="64" height="64">
  </button>
  <div class="py-[4px] text-tt-text-placeholder text-[14px] leading-[18px] ml-[16px]">
    <p *ngIf="fileName == ''">
      <span
        class="text-tt-text-link cursor-pointer"
        (click)="showImageWindow($event)"
      >Выберите иконку</span>
      из готовых вариантов или
      <span
        class="text-tt-text-link cursor-pointer"
        (click)="downloadClick()"
      >загрузите</span>
      своё изображение
      <br/>
      Поддерживаем
      <span class="font-semibold">JPEG, JPG, PNG</span>
      размером до
      <span class="font-semibold">3МБ</span>
    </p>
    <div class="flex items-center" *ngIf="fileName != ''">
      <p>
        {{fileName}}
      </p>
      <svg-x-mark
        *ngIf="!isReadOnly"
        class="cursor-pointer ml-[8px] w-[24px] h-[24px]"
        (click)="cancelImage()"
      />
    </div>
  </div>
</div>

<input type="file" accept="image/*" class="hidden" #inputImage (change)="onSelectFile($event)">

<div
  class="p-[16px] absolute bg-white shadow-tt-shadow-popup-menu rounded-tt-border-radius_m z-10 flex flex-col gap-[12px] w-[296px]"
  *ngIf="isIconsWindowVisible"
  [style.top]="iconsWindowOffset.top + 'px'"
  [style.left]="iconsWindowOffset.left + 'px'"
  #iconsWindow
>
  <div class="flex justify-between">
    <p
      class="text-tt-text-default my-auto font-medium text-tt-font-size_l leading-[16px]"
    >
      Изменить значок раздела
    </p>
    <svg-x-mark class="cursor-pointer" (click)="isIconsWindowVisible = false" />
  </div>

  <div class="w-full h-[1px] bg-tt-separator-color"></div>

  <div class="flex gap-x-6 gap-y-3 flex-wrap">
    <img
      *ngFor="let url of windowIcons"
      class="cursor-pointer w-[48px] h-[48px]"
      (click)="iconSelected(url)"
      src="{{ url }}"
    />
  </div>
</div>
