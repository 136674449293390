import {Injectable} from '@angular/core';
import { HeaderEmployee } from "../components/main-header/main-header.component";
import { NavigationSection } from "../components/navigation-section/navigation-section.component";

@Injectable()
export class MainState {
  readonly selectedItem: Tabs = Tabs.KNOWLEDGE_BASE;
  readonly enabledItemsIds: string[] = []
  readonly navSections: NavigationSection[] = []

  readonly showSideBar: boolean = false;
  readonly companyName: string = '';
  readonly isLoading: boolean = false;
  readonly headerEmployee: HeaderEmployee = {
    name: '',
    surname: '',
    patronymic: '',
    email: '',
    avatarUrl: '',
    userAvatarPlaceholder: '',
    userAvatarColor: ''
  }

  readonly subscriptionDaysLeft?: number
  readonly isSubscriptionModalVisible: boolean = false;
}

export enum Tabs {
  KNOWLEDGE_BASE = 'knowledgebase',
  NEWS = 'news',
  EMPLOYEES = 'employees',
  COMPANY_SETTINGS = 'companySettings',
  ROLE = 'role',
  BILLING = 'billing',
}
