import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SpaceDto } from './dto/space-dto';
import { environment } from 'src/environments/environment';
import { KnowledgeBaseListDto } from './dto/knowledge-base-list-dto';
import { SpaceContentDto } from './dto/space-content-dto';
import { KnowledgeBaseCreateDocumentDto } from './dto/create-document-dto';
import { CreateDocumentResponseDto } from "./dto/createDocumentResponseDto";
import { GetDocumentDto } from "./dto/getDocumentDto";

@Injectable()
export class KnowledgeBaseService {
  private readonly apiUrl: string = `${environment.apiUrl}/knowledgebase`;

  constructor(private httpClient: HttpClient) { }

  knowledgeBaseList(): Observable<KnowledgeBaseListDto> {
    return this.httpClient.get<KnowledgeBaseListDto>(`${this.apiUrl}/list`)
  }

  getSpace(id: string): Observable<SpaceDto> {
    return this.httpClient.get<SpaceDto>(`${this.apiUrl}/${id}`)
  }

  getSpaceContent(id: string): Observable<SpaceContentDto> {
    return this.httpClient.get<SpaceContentDto>(`${this.apiUrl}/${id}/content`)
  }

  updateSpace(id: string, name: string): Observable<null> {
    const body = {
      name: name.trim().split(' ').filter((str) => str.length > 0).join(' ')
    }
    return this.httpClient.put<null>(`${this.apiUrl}/${id}`, body)
  }

  createSpace(name: string): Observable<{id: string}> {
    const body = {
      name: name.trim().split(' ').filter((str) => str.length > 0).join(' ')
    }
    return this.httpClient.post<{id: string}>(`${this.apiUrl}/create`, body)
  }

  deleteSpace(id: string): Observable<null> {
    return this.httpClient.delete<null>(`${this.apiUrl}/${id}`)
  }

  getDocument(id: string): Observable<GetDocumentDto>{
    return this.httpClient.get<GetDocumentDto>(`${this.apiUrl}/content/${id}/editor`)
  }

  createDocument(documentDto: KnowledgeBaseCreateDocumentDto): Observable<CreateDocumentResponseDto> {
    return this.httpClient.post<CreateDocumentResponseDto>(`${this.apiUrl}/document/create`, documentDto)
  }

  updateDocument(id: string, documentDto: KnowledgeBaseCreateDocumentDto): Observable<null> {
    const body = {...documentDto, name: documentDto.name?.trim().split(' ').filter((str) => str.length > 0).join(' ')}
    return this.httpClient.put<null>(`${this.apiUrl}/document/${id}`, body)
  }

  deleteDocument(id: string): Observable<null> {
    return this.httpClient.delete<null>(`${this.apiUrl}/document/${id}`)
  }

  moveDocument(id: string, spaceId: string, newParentId?: string, nextDocumentId?: string): Observable<null> {
    const body = {
      knowledgeBaseId: spaceId,
      parentId: newParentId,
      nextDocumentId: nextDocumentId
    }

    return this.httpClient.post<null>(`${this.apiUrl}/document/${id}/move`, body)
  }
}
