import { Injectable } from '@angular/core';

@Injectable()
export class SpaceSettingsState {
  readonly spaceId: string = ''

  readonly spaceTitle: string = ''
  readonly newSpaceTitle: string = ''
  readonly spaceTitleError: string | null = null
  readonly currentNavItem: SpaceSettingsNavItems = SpaceSettingsNavItems.MAIN

  readonly isDeleteAlertVisible: boolean = false
  readonly alertIsLoading: boolean = false
}

export enum SpaceSettingsNavItems {MAIN, RIGHTS}
