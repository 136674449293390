import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { IconsResponseJson } from "./json/icons-response-json";
import { map } from "rxjs/operators";

@Injectable()
export class DocumentationService{
  constructor(private http: HttpClient) {}

  getIcons(): Observable<string[]>
  {
    return this.http.get<IconsResponseJson>(`${environment.apiUrl}/documentation/sections/icons`)
      .pipe(
        map((response)=>{
          return response.icons
        })
      )
  }
}
