import { Component } from '@angular/core';
import { Store } from '../../../../../../core/mvi/store';
import {
  SectionContentAction,
  SectionContentActionTypes,
} from '../state/section-content-action';
import {
  SectionContentState,
  FilterParams,
} from '../state/section-content-state';
import { SectionContentExecutor } from '../state/section-content-executor';
import { SectionContentResultAction } from '../state/section-content-result-action';
import { SectionContentReducer } from '../state/section-content-reducer';
import { Constants } from '../../../../sections/impl/presentation/common/сonstants';
import { SectionContentNavigator } from '../../navigation/section-content.navigator';
import { SectionContentService } from '../../domain/section-content-service';
import { SectionContentServiceImpl } from '../../data/section-content-service-impl';
@Component({
  selector: 'app-section-content',
  templateUrl: './section-content.component.html',
  styleUrls: [
    '../../../../sections/impl/presentation/view/sections.component.scss',
    '../../../../section/impl/presentation/common/section.component.scss'
  ],
  providers: [
    SectionContentState,
    SectionContentExecutor,
    SectionContentReducer,
    SectionContentNavigator,
    {
      provide: SectionContentService,
      useClass: SectionContentServiceImpl,
    },
  ],
})
export class SectionContentComponent extends Store<
  SectionContentState,
  SectionContentExecutor,
  SectionContentAction,
  SectionContentResultAction
> {
  constructor(
    state: SectionContentState,
    executor: SectionContentExecutor,
    reducer: SectionContentReducer,
  ) {
    super(state, executor, reducer);
  }

  protected readonly NavItem = FilterParams;
  protected readonly SectionContentActionTypes = SectionContentActionTypes;
  protected readonly Constants = Constants;
}
