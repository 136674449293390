import { Injectable } from '@angular/core';
import { clone } from 'cloneable-ts';
import { Reducer } from 'src/app/core/mvi/store';
import {
  SectionContentResultAction,
  SectionContentResultActionTypes,
} from './section-content-result-action';
import { SectionContentState } from './section-content-state';

@Injectable()
export class SectionContentReducer
  implements Reducer<SectionContentState, SectionContentResultAction>
{
  reduce(
    state: SectionContentState,
    action: SectionContentResultAction,
  ): SectionContentState {
    switch (action.type) {
      case SectionContentResultActionTypes.CHANGE_FILTER_ITEM:
        return clone(state, { filterItems: action.items });
      case SectionContentResultActionTypes.UPDATE_DOCUMENTS:
        return clone(state, {
          documents: action.documents,
          filteredDocuments: action.filteredDocuments,
        });
      case SectionContentResultActionTypes.CHANGE_FILTER_STATE:
        return clone(state, {
          filterState: action.filterState,
          filteredDocuments: action.filteredDocuments,
        });
      case SectionContentResultActionTypes.CHANGE_DOCUMENT_MARK:
        return clone(state, {
          documents: action.documents,
          filteredDocuments: action.filteredDocuments
        })
    }
  }
}
