import { Routes } from "@angular/router";
import { SettingsSubscriptionComponent } from "../features/settings/modules/subscription/presentation/settings-subscription.component";
import { SettingsMainComponent } from "../features/settings/modules/main/presentation/settings-main.component";

export enum SettingsRoutesPaths {MAIN = 'main', BILLING = 'billing'}

export const SETTINGS_ROUTES: Routes = [
  {
    path: SettingsRoutesPaths.BILLING,
    component: SettingsSubscriptionComponent,
  },
  {
    path: SettingsRoutesPaths.MAIN,
    component: SettingsMainComponent,
  },
];
