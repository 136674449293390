<div
  class="news-creation-window"
>
  <div class="h-[60px] w-screen pl-[8px] pr-[24px]
    flex justify-between items-center
    bg-tt-bg-page border-[1px] border-tt-border-default"
  >
    <div class="flex gap-[8px] items-center">
      <button class="size-[48px] flex justify-center items-center"
        (click)="backClicked.emit()"
      >
        <svg-arrow
          class="icon rotate-180"
        />
      </button>

      <p class="text-[14px] leading-[20px] text-tt-text-default">Создание новости</p>
    </div>

    <app-core-button
      (onClick)="isPublish = true"
    >
      Перейти к публикации
    </app-core-button>
  </div>

  <app-yoopta-editor
    class="flex-grow-1 w-full flex justify-center pt-[40px]"
    [longreadId]="'-1'"
  />
</div>

<app-core-page-shading
  class="relative"
  [ngClass]="{
    'hidden': !isPublish
  }"
>
  <div class="absolute right-0 max-w-[935px] w-full h-screen bg-tt-bg-page">
    <div class="h-[64px] w-full px-[16px] flex items-center justify-between border-tt-border-default border-[1px]">
      <div class="flex gap-[16px] items-center">
        <button class="size-[48px] flex justify-center items-center"
                (click)="isPublish = false"
        >
          <svg-x-mark class="icon"/>
        </button>

        <p class="text-[22px] leading-[26px] text-tt-text-default">Настройки публикации</p>
      </div>

      <app-core-button

      >
        Опубликовать
      </app-core-button>
    </div>

    <div class="p-[24px] w-full flex flex-col gap-[24px] h-full">
      <div class="flex gap-[16px] w-full">
        <div class="flex flex-col gap-[16px] w-full">
          <app-core-input-field
            label="Заголовок"
            variant="standard"
            placeholder="Введите заголовок"
            error="{{ titleError }}"
            [charsCounterMax]="128"
            [defaultValue]="news.title"
            [required]="true"
            (onChange)="title = $event"
          />

          <app-core-textarea-field
            label="Описание"
            variant="standard"
            placeholder="Введите описание"
            [height]="92"
            [charsCounterMax]="180"
            [isResizable]="false"
            [autocomplete]=""
            [required]="true"
            (onChange)="description = $event"
          />
        </div>

        <app-input-news-image
          (imageUpload)="image = $event"
        />
      </div>

      <div class="h-[40px] w-full flex justify-between items-center">
        <p class="text-[16px] font-medium leading-[30px] text-tt-text-primary">
          Предпросмотр
        </p>

        <app-grid-list-toggle-button
          [currentType]="previewType"
          (toggleGrid)="previewType = 'grid-cell'"
          (toggleList)="previewType = 'list-element'"
        />
      </div>

      <app-news-item
        class="mt-[8px] w-full flex justify-center"
        [news]="news"
        [type]="previewType"
      />
    </div>


  </div>
</app-core-page-shading>
