import { FileUploadResponse } from "@teamtells/file";
import { Editor2ServiceImpl } from "../../../data/editor-service-impl";

export const onFileUpload = (file: File, editorService: Editor2ServiceImpl): Promise<FileUploadResponse> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if(event.target && event.target.result)
      {
        const longReadId = sessionStorage.getItem('longreadId');
        let formData = new FormData();
        formData.append('file', file, file.name);
        if(longReadId)
        {
          editorService.uploadFile(longReadId, formData)
            .subscribe({
              next: (fileDto)=>{
                const result: FileUploadResponse =
                  {
                    size: fileDto.size,
                    name: fileDto.name,
                    src: fileDto.id,
                  }

                resolve(result)
              },
              error: err => {
                reject(err)
              }
            })
        }
      }
    }
    reader.readAsDataURL(file)
  })
}
