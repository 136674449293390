import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-core-popup-menu-option',
  templateUrl: './popup-menu-option.component.html',
})
export class PopupMenuOptionComponent {
  @Input() className: string | string[] = [];
  @Input() divider = false;
  @Input() loading = false;
  @Output() onClick = new EventEmitter<void>();

  onClickOption() {
    if (this.onClick) {
      this.onClick.emit();
    }
  }
}
