import { FileTextIcon } from '@radix-ui/react-icons';
import { MouseEvent } from 'react';
import { FileElementProps } from "@teamtells/file";
import { onFileDownload } from "../../../utils/on-file-download";
import { useInjected } from "@bubblydoo/angular-react";
import { Editor2ServiceImpl } from "../../../data/editor-service-impl";

type FileComponentProps = Partial<FileElementProps> & {
  blockId: string;
  align?: 'left' | 'center' | 'right';
};

function formatBytesToKilobytes(bytes : any) {
  if (typeof bytes !== 'number' || isNaN(bytes)) {
    return null;
  }
  const kilobytes = bytes / 1024;
  return kilobytes.toFixed(2) + ' KB';
}

const FileComponent = ({ name, src, size, format, align }: FileComponentProps) => {
  if (!src) return null;

  const editorService = useInjected(Editor2ServiceImpl)

  const onOpen = (e: MouseEvent) => {
    if (!src) return;
    e.preventDefault();
    e.stopPropagation();

    onFileDownload(src, editorService, name);
  };

  const currentAlign = align || 'left';
  const alignClass = `yoopta-align-${currentAlign}`;

  return (
    <div className="w-full cursor-pointer" contentEditable={false} onClick={onOpen}>
      <div
        className={`yoopta-file-component ${alignClass} `}
      >
        <div className="yoopta-file-component-text">
          <FileTextIcon width={16} height={16} />
          <span className="ml-[6px] text-[14px]">{format && !name?.endsWith(format) ? `${name}.${format}` : `${name}`}</span>
        </div>
        <div className="yoopta-file-component-size">
          {formatBytesToKilobytes(size)}
        </div>
      </div>
    </div>
  );
};

export { FileComponent, FileComponentProps };
