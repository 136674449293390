import { Component, ElementRef, ViewChild } from "@angular/core";
import { MainRoutesPaths } from "../../../routes/main-routes";
import { HeaderService } from "../../main/data/header-service";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { filter } from "rxjs";
import { NewsConstants } from "../common/news-constants";
import { NewsRoutesPath } from "../../../routes/news-routes";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss', '../../main/presentation/main.component.scss']
})
export class NewsComponent {
  @ViewChild('scrollable', { read: ElementRef }) scrollableEl?: ElementRef

  isCreate: boolean = false;

  constructor(
    private headerService: HeaderService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    headerService.ChangeStyle('bg-tt-bg-page')
    headerService.ChangeBreadCrumbs([])

    if(route.children.length == 0){
      router.navigateByUrl(`${MainRoutesPaths.NEWS}/${NewsRoutesPath.LIST}`)
    }

    router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((event) => {
      if(event instanceof NavigationStart && event.url.replaceAll('/', '') == MainRoutesPaths.NEWS){
        router.navigateByUrl(`${MainRoutesPaths.NEWS}/${NewsRoutesPath.LIST}`)
      }
    });
  }

  onScroll(){
    if(this.scrollableEl){
      if(this.scrollableEl.nativeElement.scrollTop > 0){
        this.headerService.ChangeStyle('bg-tt-bg-page border-b-[1px] border-tt-border-default')
      }
      else{
        this.headerService.ChangeStyle('bg-tt-bg-page')
      }
      if(this.scrollableEl.nativeElement.scrollTop > 50){
        this.headerService.ChangeBreadCrumbs([
          {
            name: NewsConstants.NEWS,
            href: '/' + MainRoutesPaths.NEWS
          }
        ])
      }
      else{
        this.headerService.ChangeBreadCrumbs([])
      }
    }
  }

  protected readonly NewsConstants = NewsConstants;
}
