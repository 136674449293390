<a class="
  flex flex-col justify-end gap-[10px]
  space-item-container px-[20px] py-[12px] hover:bg-tt-light-blue"
  routerLink="space/{{space.id}}"
>
  <p class="text-tt-text-default text-[16px] leading-[20px] font-normal"
    app-core-with-highlighting
    [text]="space.nameParts"
  ></p>
  <p class="text-tt-text-primary text-[14px] leading-[18px] font-normal">
    <span *ngIf="space.sectionsCount > 0">{{space.sectionsCount}} {{sections}} ·</span> {{space.documentsCount}} {{documents}}
  </p>
</a>
