import { ChatState } from '../../../chats/domain/model/messenger-chat-state';

export type MessengerSideBarResultAction =
  | UpdateInfoResultAction
  | ShowChatResultAction;

export enum MessengerSideBarResultActionTypes {
  UPDATE_INFO,
  SHOW_CHAT,
}

export interface UpdateInfoResultAction {
  readonly type: MessengerSideBarResultActionTypes.UPDATE_INFO;
  readonly menuState: boolean;
}

export interface ShowChatResultAction {
  readonly type: MessengerSideBarResultActionTypes.SHOW_CHAT;
  readonly actualchat: ChatState | null;
}
