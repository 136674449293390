<div class="px-[16px] pt-[16px] flex flex-col gap-[16px] page-height overflow-y-scroll tiny-scrollbar">

  <div class="flex flex-col w-full sections-height rounded-tt-border-radius_l bg-tt-bg-default">
    <div
      class="flex flex-col h-full p-[16px] w-full"
    >
      <div class="flex shrink-0 justify-between w-full">
        <h1 class="text-tt-font-size_4xl font-semibold relative">Разделы</h1>
        <app-core-button
          (onClick)="
          performAction({
            type: SectionsActionTypes.CHANGE_CREATE_SECTION_MODAL_VISIBILITY,
            visibility: true
          })
        "
        >Новый раздел</app-core-button>
      </div>

      <app-core-search-field
        className="mt-[16px] shrink-0"
        (onChange)="
        performAction({
          type: SectionsActionTypes.SEARCH_FIELD_CHANGE,
          value: $event
        })
      "
      />

      <app-filter-menu
        className="mt-[16px] shrink-0"
        [menuItems]="state.filterItems"
        [currentSortType]="state.filtersState.sortParam"
        [sortTypes]="Constants.SORT_POPUP_SECTION"
        (menuItemClicked)="
        performAction({
          type: SectionsActionTypes.SELECT_FILTER_PARAMS,
          param: $event
        })
      "
        (sortTypeClicked)="
        performAction({
          type: SectionsActionTypes.SELECT_SORT_PARAMS,
          sortParamId: $event
        })
      "
      />

      <div class="w-full h-full mt-[16px]">
        <div
          *ngIf="state.filteredSections.length != 0 && !state.isLoading"
          class="flex flex-col"
        >
          <sections-section-item
            *ngFor="let item of state.filteredSections"
            [section]="item"
            (clicked)="
            performAction({
              type: SectionsActionTypes.MOVE_TO_SECTION,
              sectionId: item.id
            })"
            (markClicked)="
            performAction({
              type: SectionsActionTypes.CHANGE_SECTION_MARK,
              sectionId: item.id
            })"
            (deleteClicked)="
            performAction({
              type: SectionsActionTypes.DELETE_SECTION,
              sectionId: item.id
            })"
            (editClicked)="
            performAction({
              type: SectionsActionTypes.EDIT_SECTION,
              sectionId: item.id
            })"
            (copyUrlClicked)="
            performAction({
              type: SectionsActionTypes.COPY_SECTION_URL,
              sectionId: item.id
            })"
          />
        </div>
        <div class="flex flex-col" *ngIf="state.isLoading">
          <app-core-skeleton
            [amount]="8"
            className="w-full h-[56px] rounded-none"
          />
        </div>
      </div>
      <div
        class="flex justify-around align-middle mx-auto"
        *ngIf="state.filteredSections.length == 0 && !state.isLoading"
      >
        <img src="assets/empty-section.svg" draggable="false" alt="" />
      </div>
    </div>
  </div>

</div>

<app-core-modal
  [open]="state.createSectionModalVisibility"
  className="h-[700px] w-full max-w-[900px] flex flex-row relative"
  (onClose)="performAction({
               type: SectionsActionTypes.CHANGE_CREATE_SECTION_MODAL_VISIBILITY,
               visibility: false
             })"
>
  <app-core-clue title="Раздел" class="flex-shrink-0 py-tt_l pl-tt_l">
    Предназначен для хранения и обмена информацией по определенной теме или
    области.
  </app-core-clue>
  <app-core-modal-content class="flex-grow" [footer]="true">
    <div
      class="flex flex-col gap-tt_l text-tt-text-secondary font-normal leading-[18px] text-tt-font-size_l"
    >
      <app-core-input-field
        label="Название"
        variant="standard"
        placeholder="Придумайте название"
        error="{{ state.createSectionTitleError }}"
        [value]="state.createSectionDto.title"
        [required]="true"
        (onChange)="
          performAction({
            type: SectionsActionTypes.CHANGE_CREATE_SECTION_TITLE,
            title: $event
          })
        "
      />
      <app-core-autocomplete-field
        label="Приватность раздела"
        variant="standard"
        [required]="true"
        [items]="Constants.PRIVACY_FIELD_VALUES"
        [staticItems]="false"
        [selectedItem]="state.currentPrivacyValue"
        (onSelect)="
          performAction({
            type: SectionsActionTypes.CHANGE_CREATE_SECTION_PRIVACY,
            privacy: $event
          })
        "
      />
      <div>
        <p>Значок раздела</p>
        <div class="mt-[8px]">
          <app-core-input-icon
            [windowIcons]="state.iconUrls"
            [initUrl]="state.createSectionDto.url"
            (imageChanged)="performAction({
              type: SectionsActionTypes.CHANGE_CREATE_SECTION_ICON,
              iconUrl: $event
            })"
          />
        </div>
      </div>
    </div>
    <app-core-modal-content-footer>
      <app-core-button
        (onClick)="
          performAction({
            type: SectionsActionTypes.CHANGE_CREATE_SECTION_MODAL_VISIBILITY,
            visibility: false
          })
        "
        variant="soft"
      >
        Отмена
      </app-core-button>
      <app-core-button
        (onClick)="performAction({ type: SectionsActionTypes.CREATE_SECTION })"
      >
        Продолжить
      </app-core-button>
    </app-core-modal-content-footer>
  </app-core-modal-content>

</app-core-modal>
