<svg
  [ngClass]="class"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  [attr.fill]="backgroundColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12.0508 18.0254C11.9271 18.0254 11.8099 17.9993 11.6992 17.9473C11.5951 17.9017 11.4974 17.8333 11.4063 17.7422L3.83789 10.0078C3.66211 9.83203 3.57422 9.61719 3.57422 9.36328C3.57422 9.20052 3.61328 9.05078 3.69141 8.91406C3.76953 8.77734 3.87695 8.66992 4.01367 8.5918C4.14388 8.51367 4.29362 8.47461 4.46289 8.47461C4.70378 8.47461 4.91536 8.55924 5.09766 8.72852L12.0508 15.8379L19.0039 8.72852C19.1862 8.55924 19.3978 8.47461 19.6387 8.47461C19.8079 8.47461 19.9577 8.51367 20.0879 8.5918C20.2246 8.66992 20.332 8.77734 20.4102 8.91406C20.4883 9.05078 20.5273 9.20052 20.5273 9.36328C20.5273 9.61719 20.4427 9.83203 20.2734 10.0078L12.6953 17.7422C12.6042 17.8333 12.5033 17.9017 12.3926 17.9473C12.2884 17.9993 12.1745 18.0254 12.0508 18.0254Z"
    [attr.fill]="pictureColor"
  />
</svg>
