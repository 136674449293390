<app-core-page-shading
  *ngIf="isVisible"
  [appearanceType]="'animate'"
>
  <div
    class="m-auto w-[376px] rounded-[16px] order-bg-gradient relative animate-alert_appearance"
  >
    <img ngSrc="assets/saly.png" class="w-full" height="208" width="376" draggable="false" priority="priority"/>

    <div class="p-[24px]">
      <p class="font-semibold text-[22px] leading-[27px] text-tt-text-default">
        <span *ngIf="daysLeft && daysLeft > 0">Лицензия заканчивается через <span class="days-left-color">{{daysLeft}} дней</span></span>
        <span *ngIf="!daysLeft || daysLeft <= 0">Лицензия заканчилась</span>
      </p>
      <p class="text-[14px] leading-[17px] mt-[8px] text-tt-text-primary">
        Для продления лицензии перейдите в раздел “Настройки” на вкладку “Подписка” или нажмите “Перейти к оплате”
      </p>

      <button
        class="slider-bg w-full mt-[48px] rounded-[20px] py-[11px] flex justify-center"
        (click)="goToPayment()"
      >
        <p class="text-[16px] leading-[18px] text-tt-text-contrast">Перейти к оплате</p>
      </button>

      <button
        class="w-full mt-[12px]"
        (click)="close()"
        *ngIf="daysLeft && daysLeft > 0"
      >
        <p class="text-[14px] leading-[17px] text-tt-text-primary">Напомнить позднее</p>
      </button>
    </div>

    <button
      class="absolute top-[4px] cursor-pointer right-[-40px] w-[32px] h-[32px] flex justify-center items-center bg-tt-bg-page rounded-[24px]"
      (click)="close()"
    >
      <svg-x-mark class="min-h-[24px] min-w-[24px] text-tt-icon-default"/>
    </button>
  </div>
</app-core-page-shading>
