import { Observable } from 'rxjs';
import { DocumentItemEntity } from './document-item-entity';
import { ResponseStatus } from "../../../../../core/utils/response-status";

export abstract class SectionContentService {
  abstract documents: Observable<Array<DocumentItemEntity>>;

  abstract fetchDocuments(sectionId: number): void;

  abstract createDocument(
    sectionId: number,
    title: string,
    parentDocumentId: string | null,
  ): Observable<string>;

  abstract getDocuments(): Observable<Array<DocumentItemEntity>>;

  abstract deleteDocument(id: string): Observable<null>;

  abstract updateDocumentFavorite(
    id: string,
    isFavorite: boolean,
  ): Observable<ResponseStatus>;
}
