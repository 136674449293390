import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompetenciesAndSkillsDto } from '../../@data/dto/competencies-and-skills.dto';
import { CompetencyItemComponent } from './components/competency-item/competency-item.component';
import { FolderItemComponent } from './components/folder-item/folder-item.component';
import { SkillItemComponent } from './components/skill-item/skill-item.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FolderItemComponent,
    CompetencyItemComponent,
    SkillItemComponent,
  ],
  selector: 'app-tree-of-competencies-and-skills',
  templateUrl: './tree-of-competencies-and-skills.component.html',
})
export class TreeOfCompetenciesAndSkillsComponent {
  @Input() competenciesAndSkills!: CompetenciesAndSkillsDto;
  @Input() openedFolders: string[] = [];
  @Input() openedCompetencies: string[] = [];

  @Output() onOpenFolder = new EventEmitter<string>();
  @Output() onOpenCompetency = new EventEmitter<string>();
  @Output() onCloseFolder = new EventEmitter<string>();
  @Output() onCloseCompetency = new EventEmitter<string>();

  @Output() onSelectFolder = new EventEmitter<string>();
  @Output() onSelectCompetency = new EventEmitter<string>();
  @Output() onSelectSkill = new EventEmitter<string>();
}
