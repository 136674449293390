import { RatingScaleDto } from '../../../@data/dto/rating-scale.dto';
import { RatingScalesError } from './rating-scales-state';

export type RatingScalesResultAction =
  | UpdateRatingScalesResultAction
  | ChangeSelectedRatingScaleResultAction
  | ChangeSearchResultAction
  | ChangeLoadingStatusResultAction
  | ChangeErrorStatusResultAction
  | ChangeLoadingDeleteStatusResultAction
  | ChangeErrorDeleteStatusResultAction
  | UpdateRatingScaleResultAction
  | AddRatingScaleResultAction;

export enum RatingScalesResultActionTypes {
  ADD_RATING_SCALE,
  UPDATE_RATING_SCALE,
  UPDATE_RATING_SCALES,
  TOGGLE_SELECTED_RATING_SCALE,
  CHANGE_SEARCH,
  CHANGE_LOADING_STATUS,
  CHANGE_ERROR_STATUS,
  CHANGE_LOADING_DELETE_STATUS,
  CHANGE_ERROR_DELETE_STATUS,
}

export interface UpdateRatingScaleResultAction {
  readonly type: RatingScalesResultActionTypes.UPDATE_RATING_SCALE;
  readonly ratingScale: RatingScaleDto;
}

export interface AddRatingScaleResultAction {
  readonly type: RatingScalesResultActionTypes.ADD_RATING_SCALE;
  readonly ratingScale: RatingScaleDto;
}

export interface ChangeSearchResultAction {
  readonly type: RatingScalesResultActionTypes.CHANGE_SEARCH;
  readonly search: string;
}

export interface ChangeSelectedRatingScaleResultAction {
  readonly type: RatingScalesResultActionTypes.TOGGLE_SELECTED_RATING_SCALE;
  readonly selectedRatingScale: string;
  readonly selected: boolean;
}

export interface UpdateRatingScalesResultAction {
  readonly type: RatingScalesResultActionTypes.UPDATE_RATING_SCALES;
  readonly ratingScales: RatingScaleDto[];
}

export interface ChangeLoadingStatusResultAction {
  readonly type: RatingScalesResultActionTypes.CHANGE_LOADING_STATUS;
  readonly isLoading: boolean;
}

export interface ChangeErrorStatusResultAction {
  readonly type: RatingScalesResultActionTypes.CHANGE_ERROR_STATUS;
  readonly error: RatingScalesError | null;
}

export interface ChangeLoadingDeleteStatusResultAction {
  readonly type: RatingScalesResultActionTypes.CHANGE_LOADING_DELETE_STATUS;
  readonly isLoadingDelete: string | null;
}

export interface ChangeErrorDeleteStatusResultAction {
  readonly type: RatingScalesResultActionTypes.CHANGE_ERROR_DELETE_STATUS;
  readonly errorDelete: RatingScalesError | null;
}
