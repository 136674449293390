<div
  [style.padding-left.px]="depth * 32"
  (click)="onToggle()"
  class="flex items-center h-tt-button-height_l py-tt_extra-s rounded-tt-border-radius_m bg-tt-bg-default transition-colors duration-75 hover:bg-tt-hover-color cursor-pointer"
>
  <div class="whitespace-nowrap px-tt_s flex items-center gap-tt_s">
    <svg-bordered-arrow
      class="text-tt-icon-field -rotate-90 transition-transform duration-75"
      [ngClass]="{ 'rotate-0': opened }"
    />
    <svg-folder class="text-[#FFCA5A]" />
    <span class="text-tt-font-size_l text-tt-text-default">
      {{ folder.name }}
    </span>
  </div>
</div>

<ng-container *ngIf="opened">
  <app-folder-item
    *ngFor="let subfolder of folder.folders"
    [folder]="subfolder"
    [depth]="depth + 1"
    [opened]="openedFolders.includes(subfolder.id)"
    [openedFolders]="openedFolders"
    [openedCompetencies]="openedCompetencies"
    (onOpenFolder)="onOpenFolder.emit($event)"
    (onOpenCompetency)="onOpenCompetency.emit($event)"
    (onCloseFolder)="onCloseFolder.emit($event)"
    (onCloseCompetency)="onCloseCompetency.emit($event)"
  />

  <app-competency-item
    *ngFor="let competency of folder.competencies"
    [competency]="competency"
    [depth]="depth + 1"
    [opened]="openedCompetencies.includes(competency.id)"
    (onOpenCompetency)="onOpenCompetency.emit($event)"
    (onCloseCompetency)="onCloseCompetency.emit($event)"
  />

  <app-skill-item
    *ngFor="let skill of folder.skills"
    [skill]="skill"
    [depth]="depth + 1"
  />
</ng-container>
